import React, { useContext, useRef } from 'react'
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification, sendPasswordResetEmail } from 'firebase/auth'
import { useState } from 'react'
import { auth, db } from '../../firebase'
import { doc, setDoc } from 'firebase/firestore'
import './register.scss'
import { useAlertsContext } from '../../providers/Alerts/AlertsProvider';
import { v4 as uuid } from 'uuid'
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '../../App';
import { AppShell, BackgroundImage, Box, Button, Center, Container, Paper, Stack, TextInput, Title } from '@mantine/core'
import { Header } from 'semantic-ui-react'
import CustomHeader from 'components/header/header'
import { useForm } from '@mantine/form'
import { useSelector } from 'react-redux'
export default function Register() {
    const alertsProvider = useAlertsContext()
    const userDetails = useSelector(state => state.authUser.userDetails)


    const navigate = useNavigate()


    const onSubmit = async (email, name, companyName) => {
        let originalUser = userDetails

        const password = uuid()
        if (name !== '' || companyName !== '') {
            createUserWithEmailAndPassword(auth, email, password)
                .then((userCredential) => {
                    const newUser = userCredential.user;
                    sendPasswordResetEmail(auth, newUser.email)
                        .then(() => {
                            alertsProvider.addAlert({ header: 'Added a new client!', content: "The new client can set their password, by clicking on a link in their email.", timeout: 2000, variation: 'positive' })
                            const uid = newUser.uid;
                            const rolesRef = doc(db, 'users', uid)

                            setDoc(rolesRef, { email: email, role: 'client', coach: originalUser.uid, name: name, companyName: companyName }, { merge: true }).then(() => {

                                navigate('/')
                                auth.updateCurrentUser(originalUser)
                            })

                        })
                        .catch((error) => {
                            const errorCode = error.code;
                            const errorMessage = error.message;
                            alertsProvider.addAlert({ header: errorCode, content: errorMessage, variation: 'error', timeout: 2000 })
                        })
                })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    alertsProvider.addAlert({ header: errorCode, content: errorMessage, variation: 'error', timeout: 2000 })

                })
        } else {
            const errorCode = "Missing fields"
            const errorMessage = "Not every field is filled in..."
            alertsProvider.addAlert({ header: errorCode, content: errorMessage, variation: 'error', timeout: 2000 })
        }
    }


    const form = useForm({
        initialvalues: {
            email: '',
            name: '',
            companyName: ''
        }
    })



    return (
        <AppShell
            padding={0}

            styles={(theme) => ({
                main: {
                    backgroundColor:
                        theme.colorScheme === "dark"
                            ? theme.colors.dark[8]
                            : theme.colors.gray[0],
                },
            })}
        >
            <BackgroundImage src={require("../../assets/background-3.jpg")}  >
                <Box p={'xl'} sx={{
                    minHeight: '100vh',

                }}>
                    <Container px={'lg'}>
                        <Center px='lg' width={500} >
                            <Stack>
                                <Title tt={'uppercase'} color={'white'} order={1} align={'left'}>Add a new Client</Title>
                                <form onSubmit={form.onSubmit(({ email, name, companyName }) => onSubmit(email, name, companyName))} style={{ width: '400px' }}>
                                    <TextInput
                                        label={<Title order={6} color={'white'}>Email</Title>}
                                        placeholder="your@email.com"
                                        {...form.getInputProps('email')}
                                        mb={'md'}
                                    />
                                    <TextInput
                                        label={<Title order={6} color={'white'}>Name</Title>}
                                        placeholder="Firstname Lastname"
                                        {...form.getInputProps('name')}
                                        mb={'md'}
                                    />
                                    <TextInput
                                        label={<Title order={6} color={'white'}>Company name</Title>}
                                        placeholder="Progrezr"
                                        {...form.getInputProps('companyName')}
                                        mb={'md'}
                                    />


                                    <Button type="submit" my={'md'} >Add new user</Button>
                                </form>
                            </Stack>
                        </Center>
                    </Container>
                </Box>
            </BackgroundImage>
        </AppShell >
        // <div className='register'>
        //     <Container >
        //         <div className="header-container">
        //             <h2>Add a new Client</h2>
        //             <Button animated onClick={() => navigate('/')}>
        //                 <Button.Content visible>Back</Button.Content>
        //                 <Button.Content hidden>
        //                     <Icon name='arrow left' />
        //                 </Button.Content>
        //             </Button>
        //         </div>
        //         <Form onSubmit={onSubmit}>
        //             <Form.Field>
        //                 <label  >Email</label>
        //                 <input maxLength={50} type="text" placeholder='Email' onChange={(e) => setEmail(e.target.value)} />
        //             </Form.Field>
        //             <Form.Field>
        //                 <label >Name</label>
        //                 <input maxLength={50} type="text" placeholder='Name' onChange={(e) => setName(e.target.value)} />
        //             </Form.Field>
        //             <Form.Field>
        //                 <label >Company</label>
        //                 <input maxLength={50} type="text" placeholder='Company Name' onChange={(e) => setCompanyName(e.target.value)} />
        //             </Form.Field>
        //             <Form.Button content='Add' />
        //         </Form>
        //     </Container>
        // </div >
    )
}

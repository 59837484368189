export default {
  'Opened': 0,
  'Started': 1,
  'On hold': 2,
  'Delayed': 3,
  'Completed': 4,
  'Cancelled': 5
}
// Opened : 'bruin', Started: 'geel', 'On hold/ Delayed: oranje', 'completed: groen', 'cancelled: rood
export const statusColors = {
  'Opened': 'beige',
  'Started': 'yellow',
  "On hold": 'orange',
  'Delayed': 'orange',
  'Completed': 'green',
  'Cancelled': 'red'
}
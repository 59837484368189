export const typeOptions = [
    {
        key: 'Yes',
        text: 'Yes',
        value: 'Yes',
        label: 'Yes'
    },
    {
        key: 'No',
        text: 'No',
        value: 'No',
        label: 'No'
    }

]
export const currencyOptions = [
    {
        key: 'USD',
        text: 'USD',
        value: 'USD',
        label: 'USD'
    },
    {
        key: 'EUR',
        text: 'EUR',
        value: 'EUR',
        label: 'EUR'
    },
    {
        key: 'CHF',
        text: 'CHF',
        value: 'CHF',
        label: 'CHF'
    }, {
        key: 'GBP',
        text: 'GBP',
        value: 'GBP',
        label: 'GBP'
    }
]

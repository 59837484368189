import { confirmPasswordReset, sendPasswordResetEmail } from "firebase/auth";
import { db } from "../firebase";
import { doc, setDoc } from "firebase/firestore";
import { auth } from "../firebase";
import { Navigate } from "react-router-dom";
export class passwordService {


    /**
     * 
     * @param {*} auth 
     * @param {*} user 
     * @param {*} og 
     */
    static passwordResetEmail(auth, user, og) {
        const originalUser = og
        const { email, id, name, companyName } = user
        sendPasswordResetEmail(auth, user.email)
            .then(() => {
                const uid = id
                const rolesRef = doc(db, 'users', uid)
                setDoc(rolesRef, { email: email, role: 'client', coach: originalUser.uid, name: name, companyName: companyName }, { merge: true }).then(() => {
                    // auth.updateCurrentUser(originalUser)
                })

            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
            })
    }

    static async resetPassword(code, newPassword, addAlert) {
        console.log(code, newPassword)
        confirmPasswordReset(auth, code, newPassword)
            .then(() => {
                return true
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                addAlert(errorCode, errorMessage)
                throw error;
            })
    }
}
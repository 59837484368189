import { configureStore } from '@reduxjs/toolkit';
import brandingReducer from './branding/brandingSlice';
import tokensReducer from './tokens/tokenSlice';
import loggingReducer from './logging/loggingSlice';
import authUserReducer from './authUser/authUserSlice';
import firestoreUserReducer from './firestoreUser/firestoreUserSlice';
import viewerReducer from './viewer/viewerSlice';
import stripeReducer from './stripe/stripeSlice';
import loaderReducer from './loader/loaderSlice';
export default configureStore({
    reducer: {
        branding: brandingReducer,
        tokens: tokensReducer,
        logging: loggingReducer,
        authUser: authUserReducer,
        firestoreUser: firestoreUserReducer,
        viewer: viewerReducer,
        stripe: stripeReducer,
        loader: loaderReducer
    },
});
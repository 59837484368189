import "./ImageUPload.scss"

import React, { useRef, useState } from 'react'
import { useEffect } from 'react'
import { Button } from '@mantine/core'
import defaultLogo from "../../../assets/logoBlacvk.png"
import { useCoachContext } from '../../../providers/CoachProvider/CoachProvider'
import { IconPlus } from "@tabler/icons-react"
import { Title } from "@mantine/core"
export default function ImageUpload({ name, getImageUrl, resetImage, addImage, defaultLogo }) {
    const context = useCoachContext()
    const hiddenFileInput = useRef(null)
    const [update, setUpdate] = useState(false)
    const [image, setImage] = useState('')

    useEffect(() => {
        getImageUrl().then((url) => {
            setImage(url)
        })
    }, [])

    useEffect(() => {
        if (update) {
            getImageUrl().then((url) => {
                setImage(url)
                setUpdate(false)
            }).catch((error) => {

            })
        }
    }, [update])

    function addFile() {
        hiddenFileInput.current.click()
    }
    async function resetFile() {
        resetImage().then(() => {
            setImage(defaultLogo)
        })
    }

    async function onFileChange(event) {
        const files = event.target.files
        if (files[0]) {
            addImage(files[0]).then(() => {
                getImageUrl().then((url) => {
                    setImage(url)
                })
            })
        }
    }


    return (
        <div className='imageUpload'>
            <div className="imageUpload-buttons">
                <input type="file" ref={hiddenFileInput} style={{ display: 'none' }} onChange={(e) => onFileChange(e)} />
                <Button
                    variant="default"
                    leftIcon={<IconPlus stroke={"3"} size={20} />}
                    onClick={() => {
                        addFile()
                    }}
                >
                    <Title order={6}>Upload {name}</Title>
                </Button>
                <Button
                    variant="default"
                    leftIcon={<IconPlus stroke={"3"} size={20} />}
                    onClick={() => {
                        resetFile()
                    }}
                >
                    <Title order={6}>Reset {name}</Title>
                </Button>
                {/* <Button onClick={() => addFile()}></Button>
                <Button onClick={() => resetFile()}>Reset {name}</Button> */}
            </div>
            <div className="imageUpload-container">
                <img src={image ? image : defaultLogo} alt="Image" />
            </div>
        </div>
    )

}

import React, { useContext, useState } from 'react'
import { AppShell, Navbar, Group, Header, Box, TextInput, Button, PasswordInput, Title, BackgroundImage, SimpleGrid, Image, List, ThemeIcon, Center, Anchor, Flex, Badge, Text, Card, Container, LoadingOverlay } from "@mantine/core";
import { AlertsProvider, useAlertsContext } from '../../providers/Alerts/AlertsProvider';
import CustomHeader from 'components/header/header';
import { useForm } from '@mantine/form';
import { IconCircleCheck, IconLink } from '@tabler/icons-react';
import { useDispatch, useSelector } from 'react-redux';
import { stripeService } from 'services/stripeService';
import { collection, doc, setDoc, addDoc, onSnapshot } from 'firebase/firestore';
import { db } from '../../firebase';
export default function AddCredits() {

    const alertsProvider = useAlertsContext()
    const primaryColor = useSelector(state => state.branding.branding.primaryColor)
    const products = useSelector(state => state.stripe.products)
    const [loading, setLoading] = useState(false)

    return (
        <AppShell
            padding={0}
            header={
                <Header
                    height={80}
                    sx={(theme) => ({
                        backgroundColor: primaryColor,
                    })}
                >
                    <CustomHeader />
                </Header>
            }
            styles={(theme) => ({
                main: {
                    backgroundColor:
                        theme.colorScheme === "dark"
                            ? theme.colors.dark[8]
                            : theme.colors.gray[0],
                },
            })}
        >
            <BackgroundImage src={require("../../assets/background-4.jpg")}  >

                <Container
                    size={'lg'}
                    sx={{
                        minHeight: 'calc(100vh - 70px)',
                        display: 'flex',
                        alignItems: 'center',
                    }}

                >
                    <LoadingOverlay visible={loading} overlayBlur={2} color='red' />
                    <Center>
                        <SimpleGrid cols={4} p={'1rem'}

                            breakpoints={[
                                { maxWidth: '62rem', cols: 3, spacing: 'md' },
                                { maxWidth: '48rem', cols: 2, spacing: 'sm' },
                                { maxWidth: '36rem', cols: 1, spacing: 'sm' },
                            ]}
                        >
                            {products && Object.values(products).sort((a, b) => a.name.split(" ")[0] - b.name.split(" ")[0]).map((product, index) => {
                                return <ProductCard key={index} product={product} setLoading={setLoading} />
                            })}
                        </SimpleGrid>
                    </Center>
                </Container>
            </BackgroundImage>
        </AppShell>
    )
}

export function ProductCard({ product, setLoading }) {
    const dispatch = useDispatch()

    function loadCheckout() {
        setLoading(true)
        stripeService.loadCheckout(uid, product.prices.priceId, setLoading)
    }

    const { uid } = useSelector(state => state.authUser.userDetails)
    return (
        <>
            {
                (product && product.prices) ?
                    <Card shadow="sm" padding="lg" radius="md" withBorder>
                        <Card.Section>
                            <Image
                                src={product.images && product.images[0] || "https://images.unsplash.com/photo-1527004013197-933c4bb611b3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=720&q=80"}
                                height={160}
                                alt="Norway"
                            />
                        </Card.Section>
                        <Group position="apart" mt="md" mb="xs">
                            <Text weight={500} mih={'50px'}>{product.name}</Text>
                            <Badge color="pink" variant="light">
                                Available
                            </Badge>
                        </Group>
                        <Text size="sm" mih={'100px'} color="dimmed">
                            {product.description || "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, voluptatum."}
                        </Text>
                        <Title order={3} mt="md" mb="xs" tt={'uppercase'} ta={'center'}>
                            {(product.prices.priceData.unit_amount / 100).toLocaleString("en-UK", { style: "currency", currency: product.prices.priceData.currency })} {product.prices.priceData.currency}
                        </Title>
                        <Button color='red.6' onClick={() => loadCheckout()} fullWidth mt="md" radius="md">
                            Buy
                        </Button>
                    </Card > : <LoadingOverlay visible={true} overlayBlur={2} color='red' />
            }
        </>
    )
}
import React, { useState, useEffect } from "react";
import { Icon, Button, Segment } from "semantic-ui-react";
import "./clientsMobileMenu.scss";

import clientTabs from "../../utils/clientTabs";
import { map } from "@firebase/util";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { Burger, Paper, Modal, Title, Tooltip, Group } from "@mantine/core";
import { NavbarItem } from "pages/home/home";
import CoachItem from "components/utils/CoachItem";
import { NormalButton } from "components/utils/MantineUtils";
import { IconArrowBack } from "@tabler/icons-react";
import { useDispatch, useSelector } from "react-redux";
import { setNavbarItems, setNavbarOn, setNavbarTitle, setActiveTab, toOverview } from "../../../redux/viewer/viewerSlice";
export default function ClientsMobileMenu() {
    const [opened, { toggle }] = useDisclosure(false);
    const label = opened ? "Close navigation" : "Open navigation";
    const { navbarItems, view, activeTab } = useSelector(state => state.viewer);
    const primaryColor = useSelector(state => state.branding.branding.primaryColor)
    const matches = useMediaQuery("(max-width: 768px)");
    const dispatch = useDispatch()

    function selectTab(tab) {
        dispatch(setActiveTab(tab));
        toggle();
    }

    return (
        <>
            {(view === "client" || view === 'admin') && matches && (
                <>
                    <Modal
                        opened={opened}
                        onClose={toggle}
                        fullScreen
                        withCloseButton={false}
                        padding={0}
                        transitionProps={{ transition: "slide-right" }}
                    >
                        <Paper
                            shadow={"xl"}
                            sx={(theme) => ({
                                borderRadius: 0,
                                backgroundColor: primaryColor,
                                color: theme.colors.white,
                                padding: theme.spacing.md,
                                width: "100vw",
                            })}
                        >
                            <Group position={'apart'}>
                                <Title tt={"uppercase"} order={3} color="white">
                                    menu
                                </Title>
                                <CoachItem>
                                    <Tooltip label={"Back to overview"}>

                                        <Group>
                                            <Title tt={"uppercase"} order={6} color="white">
                                                Overview
                                            </Title>
                                            <NormalButton fn={() => dispatch(toOverview())}>
                                                <IconArrowBack color={'white'} />
                                            </NormalButton>
                                        </Group>
                                    </Tooltip>
                                </CoachItem>
                            </Group>
                        </Paper>
                        <Paper sx={(theme) => ({ padding: theme.spacing.md })}>
                            {navbarItems.map((item) => (
                                <div key={item} onClick={toggle}>
                                    <NavbarItem
                                        setActiveTab={dispatch(setActiveTab)}
                                        activeTab={activeTab}
                                        name={item}
                                    />
                                </div>
                            ))}
                        </Paper>
                    </Modal>
                    <Paper
                        onClick={() => toggle()}
                        radius="sm"
                        shadow={"xl"}
                        padding="sm"
                        sx={(theme) => ({
                            position: "fixed",
                            bottom: 25,
                            right: 25,
                            zIndex: 1000,
                            width: "48px",
                            height: "48px",
                            backgroundColor: primaryColor,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        })}
                    >
                        <Burger
                            color="white"
                            opened={opened}
                            onClick={toggle}
                            aria-label={label}
                        />
                    </Paper>
                    {/* <div className="mobile-client-tabs">
                    <div className={'tabs ' + (opened ? 'visible' : 'hidden')}>
                        {clientTabs.map((tab, i) => {
                            return <Segment className={'item ' + (activeTab === tab ? 'active' : '')} onClick={() => selectTab(tab)} key={i} vertical >{tab}</Segment>
                        })}
                    </div>
                </div> */}
                </>
            )}
        </>
    );
}

import React, { useContext, createContext, useEffect, useState } from "react";
import { db } from "../../firebase";
import { doc } from "firebase/firestore";
import { passwordService } from '../../services/passwordService'
import { calculateDuration, calculateTotalAmount, formatMoney } from 'components/clients/timesheet/timesheetUtils'
import { useUserContext } from "../../App";
import { useAlertsContext } from "../Alerts/AlertsProvider";
import { auth } from "../../firebase";
import { useDispatch, useSelector } from "react-redux";
import { firestore } from '../../redux/logging/loggingSlice'
import dayjs from "dayjs";
export const OverviewContext = React.createContext([]);

export const useOverviewContext = () => React.useContext(OverviewContext);

export default function OverviewProvider(props) {
    const dispatch = useDispatch()
    const alertsProvider = useAlertsContext()
    const userDetails = useSelector(state => state.authUser.userDetails)
    const clients = useSelector(state => state.firestoreUser.clients)
    const [clientsData, setClientsData] = useState({})
    const [coachSummary, setCoachSummary] = useState({})

    useEffect(() => {
        setCoachSummary(getCoachSummary(clientsData))
    }, [clientsData])

    useEffect(() => {
        if (clients) {
            getClientsData().then((data) => {
                setClientsData(data)
                setCoachSummary(getCoachSummary(data))
            })
        }
    }, [clients])

    function getCoachSummary(data) {
        let totalHours = 0
        let totalRevenue = 0
        let totalInvoiced = 0
        let totalPaid = 0
        for (const id of Object.keys(data)) {
            totalHours += getTotal(id).totalHours
            totalRevenue += getTotal(id).totalRevenue
            totalInvoiced += getTotal(id).totalInvoiced
            totalPaid += getTotal(id).totalPaid
        }
        const res = {
            totalHours, totalRevenue, totalInvoiced, totalPaid

        }
        return res
    }
    async function getClientsData() {
        let clientsDataNew = {}
        for (const client in clients) {
            const id = clients[client].id
            const data = await getClientData(id)
            clientsDataNew = { ...clientsDataNew, [id]: data }
        }
        return clientsDataNew
    }

    async function getClientData(clientId) {
        const ref = doc(db, 'clients', clientId)
        const snap = await firestore.getDoc(ref)(dispatch);
        if (snap.exists()) {
            const data = snap.data()
            return data
        } else {
            return
        }
    }

    function getTotal(clientId) {
        const records = clientsData?.[clientId]?.timesheet?.R
        let totalHours = 0
        let totalRevenue = 0
        let totalInvoiced = 0
        let totalPaid = 0
        if (records) {
            records.forEach((record) => {
                if (record.startTime && record.endTime) {
                    const duration = calculateDuration(record.startTime, record.endTime).split(".")[0]
                    const amount = calculateAmount(record, duration)
                    const today = new Date().toISOString().slice(0, 4)
                    const date = dayjs(record.date).format('YYYY')
                    if (parseInt(date) >= parseInt(today)) {
                        totalHours += parseInt(duration)
                        totalRevenue += amount
                        if (record.invoiced !== '-') {
                            record.invoiced = record.invoiced.slice(0, 4)
                            if (parseInt(dayjs(record.invoiced).format('YYYY')) >= parseInt(today) ) {

                            }
                            totalInvoiced += amount
                            if (record.paid !== '-') {
                                if (parseInt(dayjs(record.paid).format('YYYY')) >= parseInt(today) ) {

                                    totalPaid += amount
                                }
                            }
                        }
                    }

                }
            })
        }
        const res = {
            totalHours, totalRevenue: totalRevenue, totalInvoiced, totalPaid
        }
        return res
    }
    function getTotalHours(clientId) {
        if (clientsData?.[clientId]) {
            let amount = 0
            const records = clientsData?.[clientId]?.timesheet?.R
            if (records) {
                for (const record of records) {
                    if (record.startTime && record.endTime) {
                        const duration = calculateDuration(record.startTime, record.endTime).split(".")[0]
                        amount += parseInt(duration)
                    }
                }
            }
            return amount
        }
        return 0
    }

    function calculateAmount(record, duration) {
        let res = 0
        if (record.singleCharge) {
            res += parseInt(record.hourlyRate)
        } else {
            const amount = parseInt(calculateTotalAmount(record.hourlyRate, duration))
            res += (amount && amount > 0) ? amount : 0
        }
        return res
    }

    function resetPassword(e, client) {
        e.stopPropagation();
        passwordService.passwordResetEmail(auth, client, userDetails)
        alertsProvider.addAlert({ header: 'Sent password reset!', content: "The new client can set their password, by clicking on a link in their email.", timeout: 2000, variation: 'positive' })
    }

    const values = {
        clientsData,
        setClientsData,
        coachSummary,
        setCoachSummary,
        getTotal,
        resetPassword,
        getTotalHours
    }

    return (
        <OverviewContext.Provider value={values}>{props.children}</OverviewContext.Provider>
    );
}

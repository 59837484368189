import { createSlice } from "@reduxjs/toolkit";
import defaultLogo from '../../assets/logowhite.png'
import { brandingService } from "services/brandingService";

export const initialBrandingState = {
    primaryColor: '#b40015',
}

export const brandingSlice = createSlice({
    name: "branding",
    initialState: {
        logo: defaultLogo,
        branding: initialBrandingState,
    },
    reducers: {
        setLogo: (state, action) => {
            state.logo = action.payload;
        },
        setBranding: (state, action) => {
            state.branding = action.payload;
        },
        restoreLogo: (state) => {
            state.logo = defaultLogo;
        },
        restoreBranding: (state) => {
            state.branding = initialBrandingState;
        },
    },
});

export const fetchLogoAsync = (coach, role, uid) => async (dispatch) => {
    try {
        const url = await brandingService.fetchLogo(coach, role, uid); // Assuming this returns the URL
        if (!url) return;
        dispatch(setLogo(url));
    } catch (error) {
        dispatch(setLogo(defaultLogo));
    }
};

export const fetchBrandingAsync = (coach, role, uid) => async (dispatch) => {
    try {
        const branding = await brandingService.getBranding(coach, role, uid); // Assuming this returns the URL
        if (!branding) return;
        dispatch(setBranding(branding));
    } catch (error) {
        dispatch(setBranding(initialBrandingState));
    }
};

export const { setLogo, setBranding, restoreLogo, restoreBranding } = brandingSlice.actions;

export default brandingSlice.reducer;



import React from 'react'
import { useSelector } from 'react-redux'
export default function ClientItem(props) {
    const UContext = useSelector(state => state.firestoreUser.userData)


    return (

        <div>{UContext.role === "client" && props.children}</div>
    )
}

import { createSlice } from "@reduxjs/toolkit";
import { getDoc as FGetDoc, getDocs as FGetDocs, setDoc as FSetDoc, updateDoc as FUpdateDoc, deleteDoc as FDeleteDoc } from 'firebase/firestore';

export const initialLoggingState = {
    firestore: {
        getDoc: 0,
        getDocs: 0,
        setDoc: 0,
        updateDoc: 0,
        deleteDoc: 0,
    },
    storage: {
        uploadFile: 0,
        deleteFile: 0,
        readFile: 0,
    },
    auth: {
        login: 0,
        update: 0,
    },
    logs: [

    ],
}

export const loggingSlice = createSlice({
    name: "tokens",
    initialState: initialLoggingState,
    reducers: {
        incrementLog: (state, action) => {
            const { category, field, logString } = action.payload;
            state[category][field] += 1;
            state.logs = [...state.logs, logString]
        },
    },
});

function createLogString({ field, category, ref }) {
    return `${category} ${field} ${ref._key.path.segments.toString()}`
}

export class firestore {
    static category = "firestore";

    static getDoc(ref) {
        return async function (dispatch) {
            try {
                const logString = createLogString({ field: 'getDoc', category: firestore.category, ref })
                dispatch(incrementLog({ field: 'getDoc', category: firestore.category, logString: logString }));
                return await FGetDoc(ref);
            }
            catch (error) {
                console.log(error)
                return error;
            }
        }
    }

    static getDocs(query) {
        return async function (dispatch) {
            try {
                dispatch(incrementLog({ field: 'getDocs', category: firestore.category }));
                return await FGetDocs(query);
            }
            catch (error) {
                console.log(error)
                return error;
            }
        }
    }

    static setDoc(ref, data) {
        return async function (dispatch) {
            try {
                dispatch(incrementLog({ field: 'setDoc', category: firestore.category }));
                return await FSetDoc(ref, data);
            }
            catch (error) {
                console.log(error)
                return error;
            }
        }
    }
    static updateDoc(ref, data) {
        return async function (dispatch) {
            try {
                dispatch(incrementLog({ field: 'updateDoc', category: firestore.category }));
                return await FUpdateDoc(ref, data);
            }
            catch (error) {
                console.log(error)
                return error;
            }
        }
    }
    static deleteDoc(ref) {
        return async function (dispatch) {
            try {
                dispatch(incrementLog({ field: 'deleteDoc', category: firestore.category }));
                return await FDeleteDoc(ref);
            }
            catch (error) {
                console.log(error)
                return error;
            }
        }
    }
}


export const { incrementLog } = loggingSlice.actions;

export default loggingSlice.reducer;



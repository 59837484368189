import React, { useEffect, useState, createRef, useRef } from "react";
import {
    Button,
    Radio,
    Header,
    Form,
    Icon,
    Divider,
    TextArea,
    List,
    Input,
    Message,
    Checkbox,
} from "semantic-ui-react";
import { useClientContext } from "../../../providers/ClientProvider/ClientProvider";
import "./intakeState";
import { intakeICInput, intakeASInput, intakeIQInput } from "./intakeUtils";
import BItem from "../../utils/BItem";
import intakeState from "./intakeState";
import "./intake.scss";
import CoachItem from "../../utils/CoachItem";
import ClientService from "../ClientService";
import {
    CancelButton,
    DeleteButton,
    EditButton,
    SaveButton,
} from "../../utils/buttons/buttons";
import { ActionIcon, Group, Text, TextInput } from "@mantine/core";
import { DateInput, TimeInput } from "@mantine/dates";
import dayjs from "dayjs";
import { IconClock } from "@tabler/icons-react";
import { SafeDateInput } from "components/utils/MantineUtils";
export default function IntakeComponent(props) {
    const cContext = useClientContext();
    const intake = cContext.clientData.intake;

    const [height, setHeight] = useState(0);
    const [scrollTop, setScrollTop] = useState(0);
    const [editing, setEditing] = useState(false);
    const [editedIntake, setEditedIntake] = useState(
        intake === undefined ? {} : JSON.parse(JSON.stringify(intake))
    );

    const sticky = createRef();
    const iqRef = useRef(null);
    const icRef = useRef(null);
    const asRef = useRef(null);

    let cs = new ClientService(setEditedIntake, setScrollTop);

    useEffect(() => {
        if (intake === undefined) {
            setEditedIntake(intakeState);
            save();
        }
    }, [intake, editedIntake]);

    useEffect(() => {
        setHeight(sticky.current.getBoundingClientRect().top);
    }, [sticky.current]);

    useEffect(() => {
        window.addEventListener("scroll", (e) => handleScroll(e), true);
    }, []);

    function scrollToLast(ref) {
        cs.scrollToRef(ref);
    }

    function handleScroll(event) {
        cs.handleScroll(event, setScrollTop);
    }

    function cancel() {
        setEditedIntake(intake);
        setEditing(false);
    }

    function save() {
        setEditing(false);
        cContext.saveClientIntake(editedIntake);
    }
    function deleteInput(cat, id) {
        cs.deleteInput(editedIntake, cat, id);
    }

    function addInput(cat) {
        let template;
        switch (cat) {
            case "IC":
                template = intakeICInput;
                break;
            case "AS":
                template = intakeASInput;
                break;
            case "IQ":
                template = intakeIQInput;
                break;
            default:
                template = intakeIQInput;
                break;
        }
        cs.addInput(editedIntake, cat, template);
    }

    function handleChange(data, cat, id, key) {
        cs.handleChange(editedIntake, data, cat, id, key);
    }
    function handleChangeMD(data, key) {
        let old = Object.assign({}, editedIntake["MD"]);
        old[key] = data;
        setEditedIntake((prevState) => ({
            ...prevState,
            ["MD"]: old,
        }));
    }

    function toggleEditing() {
        setEditing(!editing);
    }

    return (
        <div className="Intake">
            <div
                className={
                    "Intake-topbar " + (scrollTop > height ? "scrolled" : "")
                }
                ref={sticky}
            >
                <div className="left">
                    <Header as={"h2"}>Intake</Header>
                    <CoachItem>
                        {!editing ? (
                            <EditButton click={toggleEditing} />
                        ) : (
                            <>
                                <SaveButton click={save} />
                                <CancelButton click={cancel} />
                            </>
                        )}
                    </CoachItem>
                </div>

                {editing && (
                    <div className="adders">
                        <Button
                            onClick={() => {
                                addInput("IQ");
                                scrollToLast(iqRef);
                            }}
                        >
                            <Button.Content visible>
                                Add intake question
                            </Button.Content>
                        </Button>
                        <Button
                            onClick={() => {
                                addInput("IC");
                                scrollToLast(icRef);
                            }}
                        >
                            <Button.Content visible>
                                Add topic and proposed solution
                            </Button.Content>
                        </Button>
                        <Button
                            onClick={() => {
                                addInput("AS");
                                scrollToLast(asRef);
                            }}
                        >
                            <Button.Content visible>
                                Add assessment
                            </Button.Content>
                        </Button>
                    </div>
                )}
            </div>
            <Divider />
            {intake === undefined ? (
                <></>
            ) : (
                <div className="Intake-content">
                    <Header as={"h3"}>Intake appointment</Header>
                    <div className="meta">
                        <MD
                            handleChange={handleChangeMD}
                            MD={{
                                key: "date",
                                value: editedIntake["MD"]["date"],
                                type: "date",
                            }}
                            editing={editing}
                        />
                        <MD
                            handleChange={handleChangeMD}
                            MD={{
                                key: "time",
                                value: editedIntake["MD"]["time"],
                                type: "time",
                            }}
                            editing={editing}
                        />
                        <MD
                            handleChange={handleChangeMD}
                            MD={{
                                key: "location",
                                value: editedIntake["MD"]["location"],
                                type: "location",
                            }}
                            editing={editing}
                        />
                    </div>
                    <Header as={"h3"}>Intake questions</Header>
                    <div>
                        <Message>
                            {editedIntake["IQ"].map((question, i) => (
                                <IQ
                                    handleChange={handleChange}
                                    deleteInput={deleteInput}
                                    IQ={question}
                                    key={question.id}
                                    editing={editing}
                                />
                            ))}
                            <div ref={iqRef}></div>
                        </Message>
                    </div>

                    <Header as={"h3"}>
                        Identified topics and proposed solutions
                    </Header>
                    {editedIntake["IC"].map((input, i) => (
                        <IC
                            handleChange={handleChange}
                            deleteInput={deleteInput}
                            IC={input}
                            key={i}
                            editing={editing}
                        />
                    ))}
                    <div ref={icRef}></div>
                    <Header as={"h3"}>Assessment</Header>
                    <div className="radios">
                        {editedIntake["AS"].map((option, i) => (
                            <AS
                                handleChange={handleChange}
                                key={i}
                                deleteInput={deleteInput}
                                AS={option}
                                editing={editing}
                            />
                        ))}
                    </div>
                    <div ref={asRef}></div>
                </div>
            )}
        </div>
    );
}
export function MD(props) {
    const cs = new ClientService(null, null);
    const ref = useRef();
    const [value, setValue] = useState(props.MD.value);


    function EditField() {
        switch (props.MD.type) {
            case "date":
                return (
                    <SafeDateInput
                        date={props.MD.value}
                        handleChange={props.handleChange}
                        params={[props.MD.key]}

                    />
                );
            case "time":
                return (
                    <TimeInput
                        placeholder={props.MD.value}
                        onChange={(e) => props.handleChange(e.target.value, props.MD.key)}
                        ref={ref}
                    />
                )
            case "location":
                return (
                    <TextInput
                        type={props.MD.type}
                        placeholder={props.MD.value}
                        value={props.MD.value}
                        onChange={(e) => props.handleChange(e.target.value, props.MD.key)}
                    />
                );
            default:
                return (
                    <DateInput
                        value={props.MD.value}
                        onChange={(e) => props.handleDate(e, props.MD.key)}
                    />
                );
        }
    }

    const MD = props.MD;
    return (
        <Group position="apart">
            <Text fw={'bold'} tt={'capitalize'}>{MD.key}</Text>
            <BItem editing={props.editing}>
                <Text >
                    {MD.value}
                </Text>
                {EditField()}

                {/* <TextInput type={MD.type} placeholder={MD.value} value={MD.value} onChange={(e) => props.handleChange(e.target.value, MD.key)}></TextInput> */}
            </BItem>
        </Group>
        // <div className="MD">
        //     <label htmlFor="">{MD.key}: </label>
        //     <BItem editing={props.editing}>
        //         <p>{MD.type === 'date' ? cs.formatDate(MD.value) : MD.value}</p>
        //         <>
        //             <Input type={MD.type} placeholder={MD.value} value={MD.value} onChange={(e) => props.handleChange(e.target.value, MD.key)}></Input>
        //         </>

        //     </BItem>
        // </div >
    );
}
export function AS(props) {
    const AS = props.AS;
    function deleteInput() {
        props.deleteInput("AS", AS.id);
    }
    return (
        <div className="AS">
            {props.editing && (
                <Form>
                    <TextArea
                        type="text"
                        value={AS.name}
                        onChange={(e) =>
                            props.handleChange(
                                e.target.value,
                                "AS",
                                AS.id,
                                "name"
                            )
                        }
                    ></TextArea>
                </Form>
            )}
            <Checkbox
                className={props.editing ? "" : "locked"}
                label={!props.editing ? AS.name : ""}
                value={AS.value ? 1 : 0}
                checked={props.AS.value}
                onChange={(e) =>
                    props.handleChange(!AS.value, "AS", AS.id, "value")
                }
            />
            {props.editing && <DeleteButton click={deleteInput} />}
        </div>
    );
}

function IQ(props) {
    const IQ = props.IQ;
    function deleteInput() {
        props.deleteInput("IQ", IQ.id);
    }
    return (
        <div className="IQ">
            <BItem editing={props.editing}>
                <div className="client">
                    <Icon name="circle" size="small" />
                    <span className="newLines">{IQ.question} </span>
                </div>
                <div className="editing">
                    <Form>
                        <TextArea
                            type="text"
                            value={IQ.question}
                            onChange={(e) =>
                                props.handleChange(
                                    e.target.value,
                                    "IQ",
                                    IQ.id,
                                    "question"
                                )
                            }
                        ></TextArea>
                    </Form>
                    <DeleteButton click={deleteInput}></DeleteButton>
                </div>
            </BItem>
        </div>
    );
}

function IC(props) {
    const IC = props.IC;
    function deleteInput() {
        props.deleteInput("IC", IC.id);
    }
    return (
        <Message className="IandC">
            <div className="top">
                <BItem editing={props.editing}>
                    <Header as={"h3"}>{IC.title} </Header>
                    <div className="editing">
                        <Input
                            type="text"
                            value={IC.title}
                            onChange={(e) =>
                                props.handleChange(
                                    e.target.value,
                                    "IC",
                                    IC.id,
                                    "title"
                                )
                            }
                        ></Input>
                        <DeleteButton click={deleteInput} />
                    </div>
                </BItem>
            </div>
            <Divider />
            <div className="bottom">
                <BItem editing={props.editing}>
                    <div className="description newLines">{IC.description}</div>
                    <Form>
                        <label>Description</label>
                        <TextArea
                            value={IC.description}
                            onChange={(e) =>
                                props.handleChange(
                                    e.target.value,
                                    "IC",
                                    IC.id,
                                    "description"
                                )
                            }
                        ></TextArea>
                    </Form>
                </BItem>
                <BItem editing={props.editing}>
                    <div className="solution newLines">{IC.solution}</div>
                    <Form>
                        <label>Answer</label>
                        <TextArea
                            className="newLines"
                            value={IC.solution}
                            onChange={(e) =>
                                props.handleChange(
                                    e.target.value,
                                    "IC",
                                    IC.id,
                                    "solution"
                                )
                            }
                        ></TextArea>
                    </Form>
                </BItem>
            </div>
        </Message>
    );
}

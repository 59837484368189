export const roadMapInput = {
    id: -1,
    priority: 'medium',
    topic: '',
    objective: '',
    impact: '',
    actions: '',
    direction: 'start doing',
    frequency: '',
    kpi: '',
    byWhen: '',
    status: ''
}
export const priorityTypes = {
    'low': 0,
    'medium': 1,
    "high": 2
}
export const directionTypes = {
    'start doing': 0,
    'keep on doing': 1,
    "stop doing": 2
}
export const statusTypes = {
    'not started': 0,
    'started': 1,
    "on track": 2,
    'delayed': 3,
    'ahead': 4,
    "completed": 5,
    'cancelled': 6,

}
export const statusColors = {
    'not started': 'red',
    'started': 'orange',
    "on track": 'green',
    'delayed': 'red',
    'ahead': 'green',
    "completed": 'green',
    'cancelled': 'red',
}
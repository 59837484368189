import React, { useEffect, useState } from "react";
import { useDisclosure } from "@mantine/hooks";
import { ActionIcon, Anchor, Button, Checkbox, Modal, Stack, Text, Title } from "@mantine/core";
import { useDispatch, useSelector } from "react-redux";
import { acceptGDPR } from "../../redux/firestoreUser/firestoreUserSlice";
export default function GDPR() {
    const [opened, { open, close }] = useDisclosure(false);
    const dispatch = useDispatch();
    const [showGDPR, setShowGDPR] = useState(false);
    const [checked, setChecked] = useState(false);
    const gdpr = useSelector((state) => state.firestoreUser.userData.gdpr);
    const userDetails = useSelector((state) => state.authUser.userDetails);

    useEffect(() => {
        if (!gdpr) {
            open();
        } else if (gdpr === true) {
            close();
        }
    }, [gdpr]);

    function downloadGDPR() {
        fetch("privacy_policy.pdf").then((response) => {
            response.blob().then((blob) => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement("a");
                alink.href = fileURL;
                alink.download = "Progrezr_Privacy_Policy.pdf";
                alink.click();
            });
        });
    }

    function accept() {
        if (checked && userDetails) {
            setShowGDPR(false);
            dispatch(acceptGDPR(userDetails.uid));
        }
    }

    return (
        <>
            {opened && (
                <>
                    {
                        <Modal
                            opened={opened}
                            onClose={close}
                            title={"Terms and Conditions"}
                            withCloseButton={false}
                            closeOnClickOutside={false}
                            centered
                        >
                            {/* Modal content */}
                            <Stack spacing="md">
                                <Text>
                                    Please read the attached agreement carefully
                                    before accessing our website. By ticking the
                                    acceptance box, you acknowledge that you
                                    have read, understood, and agreed to be
                                    bound by these terms. If you do not agree
                                    with any of the terms outlined in this
                                    agreement, please do not proceed accessing
                                    the website.
                                </Text>
                                <Anchor href="./privacy_policy.pdf" target="_blank">
                                    Privacy Policy
                                </Anchor>
                                <Checkbox checked={checked} label={'I have read the attached documents'} onChange={(event) => setChecked(event.currentTarget.checked)} />
                                <Button onClick={accept}>
                                    Accept and continue
                                </Button>
                            </Stack>
                        </Modal>
                        // <Modal
                        //     closeOnDimmerClick={false}
                        //     size="large"
                        //     id="modal"
                        //     className="modalE"
                        //     open={showGDPR}
                        // >
                        //     <Modal.Content>
                        //         <Header as={"h2"}>Terms and Conditions</Header>
                        //         <div className="gdpr-column">
                        //             <p>
                        //                 Please read the attached agreement
                        //                 carefully before accessing our website.
                        //                 By ticking the acceptance box, you
                        //                 acknowledge that you have read,
                        //                 understood, and agreed to be bound by
                        //                 these terms. If you do not agree with
                        //                 any of the terms outlined in this
                        //                 agreement, please do not proceed
                        //                 accessing the website.
                        //             </p>
                        //             <Button icon onClick={() => downloadGDPR()}>
                        //                 Privacy Policy
                        //                 <Icon name="file" />
                        //             </Button>
                        //             <Checkbox
                        //                 checked={checked}
                        //                 onChange={(event) =>
                        //                     handleCheckboxChange(event)
                        //                 }
                        //                 label="I have read the attached document"
                        //             />
                        //             <div className="accept">
                        //                 <Button onClick={() => accept()}>
                        //                     Accept and Continue
                        //                 </Button>
                        //             </div>
                        //         </div>
                        //     </Modal.Content>
                        // </Modal>
                    }
                </>
            )}
        </>
    );
}

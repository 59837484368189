import React from 'react'
import { useState, createRef, useContext } from 'react'
import BItem from "../../utils/BItem"
import { EditButton, SaveButton, CancelButton, ResetButton, ResetButtonWarning, WarningButton } from '../../utils/buttons/buttons'
// import { Header, Form, TextArea, Input, Divider, Dropdown, Container, Table, Checkbox } from 'semantic-ui-react'
import "./Invoicing.scss"
import { CoachContext, useCoachContext } from '../../../providers/CoachProvider/CoachProvider'
import { useEffect } from 'react'
import InvoiceSchema from './InvoiceSchema'
import { Template, generate } from '@pdfme/generator';
import { Viewer } from "@pdfme/ui";
import ImageUpload from '../../utils/ImageU/ImageUpload'
import { calculateDuration, calculateTotalAmount, formatMoney } from 'components/clients/timesheet/timesheetUtils'
import { PDFDocument } from 'pdf-lib'
import defaultLogo from "../../../assets/logoBlacvk.png"
import { Container, Grid, Group, Image, Modal, NumberInput, Paper, SimpleGrid, Stack, Switch, Table, Text, Textarea, TextInput, Title } from '@mantine/core'
import { formattedDate, PageMenu, SafeDateInput } from 'components/utils/MantineUtils'
import { Divider } from '@mantine/core'
import { Button, Select } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'

export default function InvoicingComponent() {
    const [editing, setEditing] = useState(false)
    const coachContext = useCoachContext()
    const [currentClient, setCurrentClient] = useState()

    const [creating, { open, close }] = useDisclosure(false)


    const [editedInvoicing, setEditedInvoicing] = useState(coachContext.getInvoicing() || {})

    useEffect(() => {
        setEditedInvoicing(coachContext.getInvoicing())
    }, [coachContext])

    useEffect(() => {
    }, [currentClient])


    function handleChange(data, key) {
        setEditedInvoicing((prevState) => ({
            ...prevState,
            [key]: data
        }))
    }



    function toggleEditing() {
        setEditing(!editing)
    }
    function filterClients(clients) {
        let filteredClients = []
        if (clients === {}) return []
        for (const clientIndex in clients) {
            const client = clients[clientIndex]
            // Check if general info of editedInvoice is present
            if ('name', 'VAT', 'chamberOfCommerce', 'city', 'companyName', 'companySite', 'country', 'email', 'number', 'phone', 'street', 'zipCode' in editedInvoicing) {
                // Check if general info of client is present
                if ('email', 'id', 'key', 'name' in client) {
                    // Check if timesheet of client is present
                    if ('value' in client && client.value !== undefined && 'timesheet' in client?.value && 'R' in client?.value?.timesheet && client?.value?.timesheet?.R.length > 0) {
                        // Check if bio of client is present
                        if ('bio' in client?.value
                            && 'assignment' in client?.value?.bio
                            && 'clientContact' in client?.value?.bio
                            && 'invoicingInformation' in client?.value?.bio
                            && 'paymentTermInDays' in client?.value?.bio?.assignment
                            && 'vatPercentage' in client?.value?.bio?.assignment

                        ) {
                            // check of all necessary fields are present in bio.assignment
                            if (client?.value?.bio?.assignment?.vatPercentage !== ''
                                && client?.value?.bio?.assignment?.paymentTermInDays !== '') {

                                filteredClients.push(client)
                            }



                        }
                    }

                    // let r = client?.value?.timesheet?.R
                    // let bio = client?.value?.bio
                    // const assignement = bio.assignement
                    // const clientContact = bio.clientContact
                    // const invoicingInformation = bio.invoicingInformation

                    // if (bio.assignment?.ChargeForPreperation) {

                    // }


                }
            }

        }
        return filteredClients
    }
    function selectClient(data) {
        const client = coachContext.clients.filter((client) => {
            return client.id === data
        })[0]
        // const client = CoachContext.clients[0]
        setCurrentClient(client)

    }

    function cancel() {
        setEditedInvoicing(coachContext.getInvoicing())
        setEditing(false)
    }

    function save() {
        setEditing(false)
        coachContext.saveInvoicing(editedInvoicing)
    }

    function toggleCreating() {
        open()
    }

    return (
        <>
            <Paper
                p={'md'}
                shadow={'lg'}
                withBorder
                sx={{
                    position: 'sticky',
                    top: 69,
                    padding: '20px',
                    zIndex: 2,
                    transition: 'all 0.3s ease',
                }}
            >
                <PageMenu sectionType={'Coach'} name={'Invoicing'} editing={editing} toggleEditing={toggleEditing} save={save} cancel={cancel} />
            </Paper>
            <Divider my={'lg'} />

            {/* <Title order={4} my={'md'}>Invoicing Information</Title> */}
            <SimpleGrid cols={2} spacing={'md'}>
                <Paper p={'md'} shadow={'lg'} withBorder>
                    <Title order={5} >Company General Information </Title>
                    <Stack spacing={'md'} my='md'>
                        <Group position='apart'>
                            <Text fw={'bold'}>
                                Company Name:
                            </Text>
                            <BItem editing={editing}>
                                <p color={'gray'} order={6} tt={'uppercase'}>
                                    {editedInvoicing?.companyName}
                                </p>
                                <TextInput value={editedInvoicing?.companyName} onChange={(e) => handleChange(e.target.value, 'companyName')} />
                            </BItem>
                        </Group>
                        <Group position='apart'>
                            <Text fw={'bold'}>
                                Company Site:
                            </Text>
                            <BItem editing={editing}>
                                <p color={'gray'} order={6} tt={'uppercase'}>
                                    {editedInvoicing?.companySite}
                                </p>
                                <TextInput value={editedInvoicing?.companySite} onChange={(e) => handleChange(e.target.value, 'companySite')} />
                            </BItem>
                        </Group>
                        <Group position='apart'>
                            <Text fw={'bold'}>
                                Chamber of Commerce:
                            </Text>
                            <BItem editing={editing}>
                                <p color={'gray'} order={6} tt={'uppercase'}>
                                    {editedInvoicing?.chamberOfCommerce}
                                </p>
                                <TextInput value={editedInvoicing?.chamberOfCommerce} onChange={(e) => handleChange(e.target.value, 'chamberOfCommerce')} />
                            </BItem>
                        </Group>
                        <Group position='apart'>
                            <Text fw={'bold'}>
                                VAT:
                            </Text>
                            <BItem editing={editing}>
                                <p color={'gray'} order={6} tt={'uppercase'}>
                                    {editedInvoicing?.VAT}
                                </p>
                                <TextInput value={editedInvoicing?.VAT} onChange={(e) => handleChange(e.target.value, 'VAT')} />
                            </BItem>
                        </Group>


                    </Stack>
                </Paper>
                <Paper p={'md'} shadow={'lg'} withBorder>
                    <Title order={5} >Company Address Information </Title>
                    <Stack spacing={'md'} my='md'>
                        <Group position='apart'>
                            <Text fw={'bold'}>
                                Street:
                            </Text>
                            <BItem editing={editing}>
                                <p color={'gray'} order={6} tt={'uppercase'}>
                                    {editedInvoicing?.street}
                                </p>
                                <TextInput value={editedInvoicing?.street} onChange={(e) => handleChange(e.target.value, 'street')} />
                            </BItem>
                        </Group>
                        <Group position='apart'>
                            <Text fw={'bold'}>
                                Number:
                            </Text>
                            <BItem editing={editing}>
                                <p color={'gray'} order={6} tt={'uppercase'}>
                                    {editedInvoicing?.number}
                                </p>
                                <TextInput value={editedInvoicing?.number} onChange={(e) => handleChange(e.target.value, 'number')} />
                            </BItem>
                        </Group>
                        <Group position='apart'>
                            <Text fw={'bold'}>
                                Zipcode:
                            </Text>
                            <BItem editing={editing}>
                                <p color={'gray'} order={6} tt={'uppercase'}>
                                    {editedInvoicing?.zipcode}
                                </p>
                                <TextInput value={editedInvoicing?.zipcode} onChange={(e) => handleChange(e.target.value, 'zipcode')} />
                            </BItem>
                        </Group>
                        <Group position='apart'>
                            <Text fw={'bold'}>
                                City:
                            </Text>
                            <BItem editing={editing}>
                                <p color={'gray'} order={6} tt={'uppercase'}>
                                    {editedInvoicing?.city}
                                </p>
                                <TextInput value={editedInvoicing?.city} onChange={(e) => handleChange(e.target.value, 'city')} />
                            </BItem>
                        </Group>
                        <Group position='apart'>
                            <Text fw={'bold'}>
                                Country:
                            </Text>
                            <BItem editing={editing}>
                                <p color={'gray'} order={6} tt={'uppercase'}>
                                    {editedInvoicing?.country}
                                </p>
                                <TextInput value={editedInvoicing?.country} onChange={(e) => handleChange(e.target.value, 'country')} />
                            </BItem>
                        </Group>
                    </Stack>
                </Paper>
                <Paper p={'md'} shadow={'lg'} withBorder>
                    <Title order={5} >Company Contact Information </Title>
                    <Stack spacing={'md'} my='md'>
                        <Group position='apart'>
                            <Text fw={'bold'}>
                                Name:
                            </Text>
                            <BItem editing={editing}>
                                <p color={'gray'} order={6} tt={'uppercase'}>
                                    {editedInvoicing?.name}
                                </p>
                                <TextInput value={editedInvoicing?.name} onChange={(e) => handleChange(e.target.value, 'website')} />
                            </BItem>
                        </Group>
                        <Group position='apart'>
                            <Text fw={'bold'}>
                                Phone Number:
                            </Text>
                            <BItem editing={editing}>
                                <p color={'gray'} order={6} tt={'uppercase'}>
                                    {editedInvoicing?.phoneNumber}
                                </p>
                                <TextInput value={editedInvoicing?.phoneNumber} onChange={(e) => handleChange(e.target.value, 'phoneNumber')} />
                            </BItem>
                        </Group>
                        <Group position='apart'>
                            <Text fw={'bold'}>
                                Email:
                            </Text>
                            <BItem editing={editing}>
                                <p color={'gray'} order={6} tt={'uppercase'}>
                                    {editedInvoicing?.email}
                                </p>
                                <TextInput value={editedInvoicing?.email} onChange={(e) => handleChange(e.target.value, 'email')} />
                            </BItem>
                        </Group>

                    </Stack>
                </Paper>
                <Paper p={'md'} shadow={'lg'} withBorder>
                    <Title order={5} >Company Bank Information </Title>
                    <Stack spacing={'md'} my='md'>
                        <Group position='apart'>
                            <Text fw={'bold'}>
                                Bank Name:
                            </Text>
                            <BItem editing={editing}>
                                <p color={'gray'} order={6} tt={'uppercase'}>
                                    {editedInvoicing?.nameBank}
                                </p>
                                <TextInput value={editedInvoicing?.nameBank} onChange={(e) => handleChange(e.target.value, 'nameBank')} />
                            </BItem>
                        </Group>
                        <Group position='apart'>
                            <Text fw={'bold'}>
                                IBAN:
                            </Text>
                            <BItem editing={editing}>
                                <p color={'gray'} order={6} tt={'uppercase'}>
                                    {editedInvoicing?.iban}
                                </p>
                                <TextInput value={editedInvoicing?.iban} onChange={(e) => handleChange(e.target.value, 'iban')} />
                            </BItem>
                        </Group>
                        <Group position='apart'>
                            <Text fw={'bold'}>
                                SWIFT:
                            </Text>
                            <BItem editing={editing}>
                                <p color={'gray'} order={6} tt={'uppercase'}>
                                    {editedInvoicing?.swift}
                                </p>
                                <TextInput value={editedInvoicing?.swift} onChange={(e) => handleChange(e.target.value, 'swift')} />
                            </BItem>
                        </Group>
                        <Group position='apart'>
                            <Text fw={'bold'}>
                                Bank Address:
                            </Text>
                            <BItem editing={editing}>
                                <p color={'gray'} order={6} tt={'uppercase'}>
                                    {editedInvoicing?.bankAddress}
                                </p>
                                <TextInput value={editedInvoicing?.bankAddress} onChange={(e) => handleChange(e.target.value, 'bankAddress')} />
                            </BItem>
                        </Group>
                    </Stack>
                </Paper>

            </SimpleGrid>
            <Divider my={'lg'} />
            <Paper p={'md'} shadow={'lg'} withBorder>
                <Title mb={'md'} order={4}>Invoicing Logo</Title>
                <ImageUpload name={'Invoice Logo'} defaultLogo={defaultLogo} getImageUrl={coachContext.getInvoiceImageUrl} resetImage={coachContext.resetInvoiceImage} addImage={coachContext.addInvoiceImage}  ></ImageUpload>

            </Paper>
            <Divider my={'lg'} />
            <Paper p={'md'} shadow={'lg'} withBorder>
                <Title mb={'md'} order={4}>Create Invoice</Title>
                <Group>
                    <Select miw={'50%'}
                        placeholder='Select Client'

                        data={filterClients(coachContext?.clients).map((client) => {
                            return {
                                key: client.id,
                                label: `${client.name} (${client.email})`,
                                value: client.id,
                                text: client.id
                            }
                        })}
                        value={currentClient?.id ? currentClient.id : '-'}

                        onChange={(event) => selectClient(event)}
                    />

                    {currentClient && <Button my={'md'} onClick={open}>Create Invoice</Button>}
                </Group>
            </Paper>
            <Modal centered size={'100vw'} h={100} closeOnClickOutside={false} opened={creating} title={
                <Title order={4}>Create Invoice</Title>
            } onClose={close} >
                <InvoiceCreator client={currentClient} />
            </Modal>
        </>

        //     <div className={"topbar " + (scrollTop > height ? "scrolled" : '')} ref={sticky}>
        //         <div className="left">
        //             <Header as={'h2'}>Invoicing</Header>
        //             {!editing ?
        //                 <EditButton click={toggleEditing} /> :
        //                 <>
        //                     <SaveButton click={save} />
        //                     <CancelButton click={cancel} />
        //                 </>
        //             }
        //         </div>
        //     </div>
        //     <div className="coach-info">
        //         <Header as='h3'>Coach Information</Header>
        //         <div className="coach-info-content">
        //             <div className="content-section">
        //                 <Header as={'h4'}>Company Information:</Header>
        //                 <div className='field'>
        //                     <span>Company Name:</span>
        //                     <BItem editing={editing}>
        //                         <div>{editedInvoicing?.companyName}</div>
        //                         <Form>
        //                             <Input type='text' onChange={(e) => handleChange(e.target.value, 'companyName')} value={editedInvoicing['companyName']} >
        //                             </Input>
        //                         </Form> :
        //                     </BItem>
        //                 </div>
        //                 <div className='field'>
        //                     <span>Company Site:</span>
        //                     <BItem editing={editing}>
        //                         <div>{editedInvoicing.companySite}</div>
        //                         <Form>
        //                             <Input type='text' onChange={(e) => handleChange(e.target.value, 'companySite')} value={editedInvoicing['companySite']} >
        //                             </Input>
        //                         </Form> :
        //                     </BItem>
        //                 </div>
        //                 <div className='field'>
        //                     <span>Chamber of commerce#:</span>
        //                     <BItem editing={editing}>
        //                         <div>{editedInvoicing.chamberOfCommerce}</div>
        //                         <Form>
        //                             <Input type='text' onChange={(e) => handleChange(e.target.value, 'chamberOfCommerce')} value={editedInvoicing['chamberOfCommerce']} >
        //                             </Input>
        //                         </Form> :
        //                     </BItem>
        //                 </div>
        //                 <div className='field'>
        //                     <span>VAT:</span>
        //                     <BItem editing={editing}>
        //                         <div>{editedInvoicing.VAT}</div>
        //                         <Form>
        //                             <Input type='text' onChange={(e) => handleChange(e.target.value, 'VAT')} value={editedInvoicing['VAT']} >
        //                             </Input>
        //                         </Form> :
        //                     </BItem>
        //                 </div>
        //             </div>
        //             <div className="content-section">
        //                 <Header as={'h4'}>Company Adress:</Header>
        //                 <div className='field'>
        //                     <span>Street:</span>
        //                     <BItem editing={editing}>
        //                         <div>{editedInvoicing.street}</div>
        //                         <Form>
        //                             <Input type='text' onChange={(e) => handleChange(e.target.value, 'street')} value={editedInvoicing['street']} >
        //                             </Input>
        //                         </Form> :
        //                     </BItem>
        //                 </div>
        //                 <div className='field'>
        //                     <span>Number:</span>
        //                     <BItem editing={editing}>
        //                         <div>{editedInvoicing.number}</div>
        //                         <Form>
        //                             <Input type='text' onChange={(e) => handleChange(e.target.value, 'number')} value={editedInvoicing['number']} >
        //                             </Input>
        //                         </Form> :
        //                     </BItem>
        //                 </div>
        //                 <div className='field'>
        //                     <span>Zip code:</span>
        //                     <BItem editing={editing}>
        //                         <div>{editedInvoicing.zipCode}</div>
        //                         <Form>
        //                             <Input type='text' onChange={(e) => handleChange(e.target.value, 'zipCode')} value={editedInvoicing['zipCode']} >
        //                             </Input>
        //                         </Form> :
        //                     </BItem>
        //                 </div>
        //                 <div className='field'>
        //                     <span>City:</span>
        //                     <BItem editing={editing}>
        //                         <div>{editedInvoicing.city}</div>
        //                         <Form>
        //                             <Input type='text' onChange={(e) => handleChange(e.target.value, 'city')} value={editedInvoicing['city']} >
        //                             </Input>
        //                         </Form> :
        //                     </BItem>
        //                 </div>
        //                 <div className='field'>
        //                     <span>Country:</span>
        //                     <BItem editing={editing}>
        //                         <div>{editedInvoicing.country}</div>
        //                         <Form>
        //                             <Input type='text' onChange={(e) => handleChange(e.target.value, 'country')} value={editedInvoicing['country']} >
        //                             </Input>
        //                         </Form> :
        //                     </BItem>
        //                 </div>
        //             </div>
        //             <div className="content-section">
        //                 <Header as={'h4'}>Company Contact:</Header>
        //                 <div className='field'>
        //                     <span>Name:</span>
        //                     <BItem editing={editing}>
        //                         <div>{editedInvoicing.name}</div>
        //                         <Form>
        //                             <Input type='text' onChange={(e) => handleChange(e.target.value, 'name')} value={editedInvoicing['name']} >
        //                             </Input>
        //                         </Form> :
        //                     </BItem>
        //                 </div>
        //                 <div className='field'>
        //                     <span>Email:</span>
        //                     <BItem editing={editing}>
        //                         <div>{editedInvoicing.email}</div>
        //                         <Form>
        //                             <Input type='text' onChange={(e) => handleChange(e.target.value, 'email')} value={editedInvoicing['email']} >
        //                             </Input>
        //                         </Form> :
        //                     </BItem>
        //                 </div>
        //                 <div className='field'>
        //                     <span>Phone:</span>
        //                     <BItem editing={editing}>
        //                         <div>{editedInvoicing.phone}</div>
        //                         <Form>
        //                             <Input type='text' onChange={(e) => handleChange(e.target.value, 'phone')} value={editedInvoicing['phone']} >
        //                             </Input>
        //                         </Form> :
        //                     </BItem>
        //                 </div>
        //             </div>
        //             <div className="content-section">
        //                 <Header as={'h4'}>Company Bank:</Header>
        //                 <div className='field'>
        //                     <span>Name bank:</span>
        //                     <BItem editing={editing}>
        //                         <div>{editedInvoicing.nameBank}</div>
        //                         <Form>
        //                             <Input type='text' onChange={(e) => handleChange(e.target.value, 'nameBank')} value={editedInvoicing['nameBank']} >
        //                             </Input>
        //                         </Form> :
        //                     </BItem>
        //                 </div>
        //                 <div className='field'>
        //                     <span>IBAN:</span>
        //                     <BItem editing={editing}>
        //                         <div>{editedInvoicing.iban}</div>
        //                         <Form>
        //                             <Input type='text' onChange={(e) => handleChange(e.target.value, 'iban')} value={editedInvoicing['iban']} >
        //                             </Input>
        //                         </Form> :
        //                     </BItem>
        //                 </div>
        //                 <div className='field'>
        //                     <span>SWIFT:</span>
        //                     <BItem editing={editing}>
        //                         <div>{editedInvoicing.swift}</div>
        //                         <Form>
        //                             <Input type='text' onChange={(e) => handleChange(e.target.value, 'swift')} value={editedInvoicing['swift']} >
        //                             </Input>
        //                         </Form> :
        //                     </BItem>
        //                 </div>
        //                 <div className='field'>
        //                     <span>Address:</span>
        //                     <BItem editing={editing}>
        //                         <div>{editedInvoicing.bankAddress}</div>
        //                         <Form>
        //                             <Input type='text' onChange={(e) => handleChange(e.target.value, 'bankAddress')} value={editedInvoicing['bankAddress']} >
        //                             </Input>
        //                         </Form> :
        //                     </BItem>
        //                 </div>
        //             </div>


        //         </div>

        //         <Header as='h3'>Invoice Image</Header>
        //         <ImageUpload name={'Invoice Logo'} defaultLogo={defaultLogo} getImageUrl={coachContext.getInvoiceImageUrl} resetImage={coachContext.resetInvoiceImage} addImage={coachContext.addInvoiceImage}  ></ImageUpload>

        //         <Header as={'h3'}>Client {currentClient && currentClient.name}</Header>


        //         <div className='row'>
        //             <Dropdown
        //                 placeholder='Select Client'
        //                 fluid
        //                 selection
        //                 options={filterClients(coachContext.clients).map((client) => {
        //                     return {
        //                         key: client.id,
        //                         text: `${client.name} (${client.email})`,
        //                         value: client.id
        //                     }
        //                 })}
        //                 value={currentClient?.id ? currentClient.id : '-'}

        //                 onChange={(e, data) => selectClient(e, data)}
        //             />

        //             {currentClient && <Button onClick={() => toggleCreating()}>Create Invoice</Button>}
        //         </div>


        //     </div>
        //     {creating && <InvoiceCreator stop={() => toggleCreating()} client={currentClient} />}
        // </div>
        // <div className="InvoicingMobile">
        //     <Header as={'h4'}>                This page is not available on mobile.
        //     </Header>
        // </div> */}
        //</>
    )
}
const invoiceDefaults = InvoiceSchema

Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

function getPayableUntil(date, paymentTermInDays) {
    if (typeof date === 'string') {
        date = new Date(date)
    }
    return formattedDate(date.addDays(parseInt(paymentTermInDays)))
}

const blobToBase64 = blob => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise(resolve => {
        reader.onloadend = () => {
            resolve(reader.result);
        };
    });
};
export function InvoiceCreator({ client, stop }) {

    const coachContext = useCoachContext()
    const [invoice, setInvoice] = useState(fillInputs(""))
    const [invoiceRecords, setInvoiceRecords] = useState(client.value.timesheet.R)
    const [records, setRecords] = useState([])
    const [step, setStep] = useState(0)
    const [steps, setSteps] = useState([
        {
            text: "Enter invoice details",
            index: 0,
            complete: false,
        },
        {
            text: "Select invoice records",
            index: 1,
            complete: false,
        },
        {
            text: "Check and export to pdf",
            index: 2,
            complete: false,
        }
    ])
    const [pdfInput, setPdfInput] = useState(invoiceDefaults.inputs)
    const [opened, { open, close }] = useDisclosure(false)


    function markComplete() {
        const newSteps = steps.map((s) => {
            if (s.index === step) {
                return { ...s, complete: true }
            } return s
        })
        setSteps(newSteps)
    }
    function markNotComplete() {
        const newSteps = steps.map((s) => {
            if (s.index === step) {
                return { ...s, complete: false }
            } return s
        })
        setSteps(newSteps)
    }
    function markReady(bool) {
        const newSteps = steps.map((s) => {
            if (s.index === step) {
                return { ...s, ready: bool }
            } return s
        })
        setSteps(newSteps)
    }

    function nextStep() {

        if (step + 1 === steps.length) {
        } else {
            setStep(step + 1)
        }
    }
    function previousStep() {
        if (step === 0) {
        } else {
            setStep(step - 1)
        }
    }

    // function exit(event) {
    //     event.preventDefault()
    //     toggleModal()
    // }
    function parseVatPercentage(vat) {
        if (vat.includes('%')) {
            return vat
        } else {
            return vat + "%"
        }
    }
    function fillInputs() {
        const coachInvoicing = coachContext.getInvoicing()
        const clientInvoicingInformation = client?.value?.bio?.invoicingInformation
        const clientInvoicingAssignment = client?.value?.bio?.assignment
        const invoiceDetails = {
            date: formattedDate(new Date()),
            id: "0",
            period: "1 Jan - 31 Jan"
        }
        let newInputs = invoiceDefaults.inputs[0]
        newInputs.CoachUID = ""
        newInputs.Coachcompany = coachInvoicing.companyName
        newInputs.CoachstreetAndNumber = coachInvoicing.street + " " + coachInvoicing.number
        newInputs.CoachzipCodeAndCityAndCountry = coachInvoicing.zipCode + " " + coachInvoicing.city + " " + coachInvoicing.country
        newInputs.Companyname = clientInvoicingInformation.company
        newInputs.CompanystreetAndNumber = clientInvoicingInformation.streetAndNr
        newInputs.ClientzipAndCity = clientInvoicingInformation.postcode + " " + clientInvoicingInformation.city
        newInputs.Clientcountry = clientInvoicingInformation.country
        newInputs.Companycontact = client.name
        newInputs.CompanyAccountNrTitle = "Account Nr:"
        newInputs.CompanyAccountNr = clientInvoicingInformation.accountNr ? clientInvoicingInformation.accountNr : "-"
        newInputs.CompanyAccountPONumberTitle = "PO Number:"
        newInputs.CompanyAccountPONumber = clientInvoicingInformation.PONumber ? clientInvoicingInformation.PONumber : "-"
        newInputs.Invoiceperiod = invoiceDetails.period
        newInputs.Invoiceid = invoiceDetails.id
        newInputs.Coachlogo =
            newInputs.Invoicedate = invoiceDetails.date
        newInputs.Coachname = coachInvoicing.name
        newInputs.ClientpaymentTerms = clientInvoicingAssignment.paymentTermInDays
        newInputs.InvoicepayableUntil = getPayableUntil(invoiceDetails.date, clientInvoicingAssignment.paymentTermInDays)
        newInputs.Invoicecurrency = clientInvoicingAssignment.currency
        newInputs.Invoicecurrency2 = clientInvoicingAssignment.currency
        newInputs.Invoicecurrency3 = clientInvoicingAssignment.currency
        newInputs.InvoicevatPercentage = parseVatPercentage(clientInvoicingAssignment.vatPercentage)
        newInputs.Additionalinfo = `${coachInvoicing.companyName} | CoC: ${coachInvoicing.chamberOfCommerce} | VAT ID Nr.: ${coachInvoicing.VAT}`
        newInputs.CoachBankDetails = `${coachInvoicing.companyName}\n${coachInvoicing.street} ${coachInvoicing.number}\n${coachInvoicing.zipCode} ${coachInvoicing.city}\n${coachInvoicing.country}\nIBAN: ${coachInvoicing.iban}\nSWIFT/BIC: ${coachInvoicing.swift}\nName bank: ${coachInvoicing.nameBank}`     // newInputs.companyName = cu
        return newInputs
    }


    useEffect(() => {

    }, [records, invoice])


    // TODO


    async function generateInvoice() {
        const inputs = [invoice]

        inputs[0].ClientpaymentTerms = `Within ${inputs[0].ClientpaymentTerms} days`
        coachContext.getInvoiceBlob().then((blob) => {
            blobToBase64(blob).then((base64) => {
                inputs[0].Coachlogo = base64
            })
        })
        const template = invoiceDefaults.templateGeneral
        const pdf = await generate({ template, inputs })
        const blob = new Blob([pdf.buffer], { type: 'application/pdf' });
        const shortURl = URL.createObjectURL(blob)
        window.open(shortURl);

    }
    return (
        <Stack justify={'space-between'} style={{ 'height': 'calc(80vh - 4rem' }}>
            <div>
                {step === 0
                    ? <InvoiceDetails input={invoice} markComplete={markComplete} markNotComplete={markNotComplete} setInvoice={setInvoice} />
                    : step === 1
                        ? <InvoiceRecords input={invoiceRecords} completeInput={records} invoice={invoice} setInvoice={setInvoice} setRecords={setRecords} markComplete={markComplete} markNotComplete={markNotComplete} />
                        : <InvoiceFinal invoice={invoice} client={client} records={records} generateInvoice={generateInvoice} />

                }
            </div>
            <Group position='apart'>
                <div>
                    {step > 0 && <Button onClick={() => previousStep()}>Back</Button>}

                </div>
                <div>
                    {steps[step].complete && <Button onClick={() => nextStep()}>Next</Button>}

                </div>
            </Group>
        </Stack>
        // <div className="InvoiceCreator" onClick={(event) => exit(event)}>
        /* <Modal open={openModal} onClose={() => toggleModal()} >
                <Modal.Header>
                    {"Are you sure?"}
                </Modal.Header>
                <Modal.Content>
                    <Button onClick={() => stop()}>{"Confirm"}</Button>
                </Modal.Content>
            </Modal>
            <Container onClick={(event) => event.stopPropagation()}>

                <div className="top">
                    <Header as={'h1'}>Invoice Generator</Header>
                    <WarningButton click={() => stop()} iconName="window close" buttonText={'Exit'} ></WarningButton>
                </div>
                <div className='content'>
                    <>
                        {step === 0
                            ? <InvoiceDetails input={invoice} markComplete={markComplete} markNotComplete={markNotComplete} setInvoice={setInvoice} />
                            : step === 1
                                ? <InvoiceRecords input={invoiceRecords} completeInput={records} invoice={invoice} setInvoice={setInvoice} setRecords={setRecords} markComplete={markComplete} markNotComplete={markNotComplete} />
                                : <InvoiceFinal invoice={invoice} client={client} records={records} generateInvoice={generateInvoice} />

                        }
                    </>
                    < className="creator-buttons">
                        <div>
                            {step > 0 && <Button onClick={() => previousStep()}>Back</Button>}

                        </div>
                        {/* <div>
                            {step === 0 && <Button onClick={() => markComplete()}>Submit</Button>}

                        </div> */
        /* <div>
                                    {steps[step].complete && <Button onClick={() => nextStep()}>Next</Button>}
        
                                </div>
        
        
                            </div>
                        </div>
                    </Container>  */

        // </div>
    )
}


export function InvoiceDetails({ input, setInvoice, markComplete, markNotComplete }) {
    const [editedInvoice, setEditedInvoice] = useState(input)
    const [editing, setEditing] = useState(false)
    const coachContext = useCoachContext()
    const [image, setImage] = useState("")
    useEffect(() => {
        coachContext.getInvoiceImageUrl().then((url) => {
            setImage(url)
        }).catch((error) => {
        })

        markComplete()

    }, [])

    const [scrollTop, setScrollTop] = useState(0)
    const [height, setHeight] = useState(0)
    const stickyDetails = createRef()

    function handleScroll(event) {
        const scrollTopNew = event.target.scrollTop
        try {

            setScrollTop(scrollTopNew)
        } catch {

        }
    }





    function handleChange(data, key) {
        setEditedInvoice((prevState) => ({
            ...prevState,
            [key]: data
        }))
        markNotComplete()
        setEditedInvoice((prevState) => {
            return ({
                ...prevState,
                'InvoicepayableUntil': getPayableUntil(prevState.Invoicedate, prevState.ClientpaymentTerms)
            })
        }
        )
    }



    function cancel() {
        setEditedInvoice(input)
        markComplete()
        setEditing(false)
    }

    function save() {
        setEditing(false)
        markComplete()
        setInvoice(editedInvoice)
    }

    function toggleEditing() {
        setEditing(!editing)
    }
    function handleCurrency(e) {
        handleChange(e.target.value, 'Invoicecurrency')
        handleChange(e.target.value, 'Invoicecurrency2')
        handleChange(e.target.value, 'Invoicecurrency3')
    }

    function handleDate(e) {
        handleChange(e, 'Invoicedate')
    }

    function handleDays(e) {
        handleChange(e.toString(), 'ClientpaymentTerms')
    }


    return (
        <>
            <PageMenu sectionType={'Coach'} name={'Check invoice details'} editing={editing} toggleEditing={toggleEditing} save={save} cancel={cancel} />
            <SimpleGrid cols={3} spacing={'lg'} p={'md'}>
                <Paper >
                    <Title order={5} >Coach Information</Title>
                    <Stack spacing={'md'} my='md'>
                        <Group position='apart'>
                            <Text fw={'bold'}>
                                Company Name:
                            </Text>
                            <BItem editing={editing}>
                                <p color={'gray'} order={6} tt={'uppercase'}>
                                    {editedInvoice?.Coachcompany}
                                </p>
                                <TextInput value={editedInvoice.Coachcompany} onChange={(e) => handleChange(e.target.value, 'Coachcompany')} />
                            </BItem>
                        </Group>
                        <Group position='apart'>
                            <Text fw={'bold'}>
                                Name:
                            </Text>
                            <BItem editing={editing}>
                                <p color={'gray'} order={6} tt={'uppercase'}>
                                    {editedInvoice?.Coachname}
                                </p>
                                <TextInput value={editedInvoice.Coachname} onChange={(e) => handleChange(e.target.value, 'Coachname')} />
                            </BItem>
                        </Group>
                        <Group position='apart'>
                            <Text fw={'bold'}>
                                Address:
                            </Text>
                            <BItem editing={editing}>
                                <p color={'gray'} order={6} tt={'uppercase'}>
                                    {editedInvoice?.CoachstreetAndNumber}
                                </p>
                                <TextInput value={editedInvoice.CoachstreetAndNumber} onChange={(e) => handleChange(e.target.value, 'CoachstreetAndNumber')} />
                            </BItem>
                        </Group>
                        <Group position='apart'>
                            <Text fw={'bold'}>
                                VAT:
                            </Text>
                            <BItem editing={editing}>
                                <p color={'gray'} order={6} tt={'uppercase'}>
                                    {coachContext.getInvoicing().VAT
                                    }
                                </p>
                                <TextInput value={editedInvoice.CoachUID} onChange={(e) => handleChange(e.target.value, 'CoachUID')} />
                            </BItem>
                        </Group>
                        <Group position='apart'>
                            <Text fw={'bold'}>
                                Zipcode, City and Country:
                            </Text>
                            <BItem editing={editing}>
                                <p color={'gray'} order={6} tt={'uppercase'}>
                                    {editedInvoice?.CoachzipCodeAndCityAndCountry}
                                </p>
                                <TextInput value={editedInvoice.CoachzipCodeAndCityAndCountry} onChange={(e) => handleChange(e.target.value, 'CoachzipCodeAndCityAndCountry')} />
                            </BItem>
                        </Group>

                    </Stack>
                </Paper>
                <Paper >
                    <Title order={5} >Bank details</Title>
                    <Textarea my='md' minRows={8} value={editedInvoice.CoachBankDetails} onChange={(e) => handleChange(e.target.value, 'CoachBankDetails')} />
                </Paper>
                <Paper >
                    <Title order={5} >Logo:</Title>
                    <Image maw={'90%'} my={'md'} src={image} />
                </Paper>
                <Paper >
                    <Title order={5} >Invoice Information: </Title>
                    <Stack spacing={'md'} my='md'>
                        <Group position='apart'>
                            <Text fw={'bold'}>
                                Invoice ID:
                            </Text>
                            <BItem editing={editing}>
                                <p color={'gray'} order={6} tt={'uppercase'}>
                                    {editedInvoice?.Invoiceid}
                                </p>
                                <TextInput value={editedInvoice.Invoiceid} onChange={(e) => handleChange(e.target.value, 'Invoiceid')} />
                            </BItem>
                        </Group>
                        <Group position='apart'>
                            <Text fw={'bold'}>
                                Currency:
                            </Text>
                            <BItem editing={editing}>
                                <p color={'gray'} order={6} tt={'uppercase'}>
                                    {editedInvoice?.Invoicecurrency}
                                </p>
                                <TextInput value={editedInvoice.Invoicecurrency} onChange={handleCurrency} />
                            </BItem>
                        </Group>
                        <Group position='apart'>
                            <Text fw={'bold'}>
                                VAT percentage:
                            </Text>
                            <BItem editing={editing}>
                                <p color={'gray'} order={6} tt={'uppercase'}>
                                    {editedInvoice.InvoicevatPercentage}
                                </p>
                                <TextInput value={editedInvoice.InvoicevatPercentage} onChange={(e) => handleChange(e.target.value, 'InvoicevatPercentage')} />
                            </BItem>
                        </Group>
                        <Group position='apart'>
                            <Text fw={'bold'}>
                                Invoice date:
                            </Text>
                            <BItem editing={editing}>
                                <p color={'gray'} order={6} tt={'uppercase'}>
                                    {editedInvoice.Invoicedate}
                                </p>
                                <SafeDateInput date={editedInvoice.Invoicedate} handleChange={handleDate} />
                                {/* <TextInput value={editedInvoice.Invoicedate} onChange={(e) => handleDate(e.target.value, 'Invoicedate')} /> */}
                            </BItem>
                        </Group>
                        <Group position='apart'>
                            <Text fw={'bold'}>
                                Payment term in days:
                            </Text>
                            <BItem editing={editing}>
                                <p color={'gray'} order={6} tt={'uppercase'}>
                                    Within {editedInvoice.ClientpaymentTerms} days
                                </p>
                                <NumberInput value={parseInt(editedInvoice.ClientpaymentTerms)} onChange={(e) => handleDays(e)} />
                            </BItem>
                        </Group>
                        <Group position='apart'>
                            <Text fw={'bold'}>
                                Payable until:
                            </Text>
                            <p color={'gray'} order={6} tt={'uppercase'}>
                                {editedInvoice.InvoicepayableUntil}
                            </p>
                        </Group>

                    </Stack>

                </Paper>
                <Paper >
                    <Title order={5} >Client Information: </Title>
                    <Stack spacing={'md'} my='md'>
                        <Group position='apart'>
                            <Text fw={'bold'}>
                                Company Name:
                            </Text>
                            <BItem editing={editing}>
                                <p color={'gray'} order={6} tt={'uppercase'}>
                                    {editedInvoice?.Companyname}
                                </p>
                                <TextInput value={editedInvoice.Companyname} onChange={(e) => handleChange(e.target.value, 'Companyname')} />
                            </BItem>
                        </Group>
                        <Group position='apart'>
                            <Text fw={'bold'}>
                                Contact name:
                            </Text>
                            <BItem editing={editing}>
                                <p color={'gray'} order={6} tt={'uppercase'}>
                                    {editedInvoice?.Companycontact}
                                </p>
                                <TextInput value={editedInvoice.Companycontact} onChange={(e) => handleChange(e.target.value, 'Companycontact')} />
                            </BItem>
                        </Group>
                        <Group position='apart'>
                            <Text fw={'bold'}>
                                Address:
                            </Text>
                            <BItem editing={editing}>
                                <p color={'gray'} order={6} tt={'uppercase'}>
                                    {editedInvoice?.CompanystreetAndNumber}
                                </p>
                                <TextInput value={editedInvoice.CompanystreetAndNumber} onChange={(e) => handleChange(e.target.value, 'CompanystreetAndNumber')} />
                            </BItem>
                        </Group>
                        <Group position='apart'>
                            <Text fw={'bold'}>
                                Zipcode and city:
                            </Text>
                            <BItem editing={editing}>
                                <p color={'gray'} order={6} tt={'uppercase'}>
                                    {editedInvoice?.ClientzipAndCity}
                                </p>
                                <TextInput value={editedInvoice.ClientzipAndCity} onChange={(e) => handleChange(e.target.value, 'ClientzipAndCity')} />
                            </BItem>
                        </Group>
                        <Group position='apart'>
                            <Text fw={'bold'}>
                                Country:
                            </Text>
                            <BItem editing={editing}>
                                <p color={'gray'} order={6} tt={'uppercase'}>
                                    {editedInvoice?.Clientcountry}
                                </p>
                                <TextInput value={editedInvoice.Clientcountry} onChange={(e) => handleChange(e.target.value, 'Clientcountry')} />
                            </BItem>
                        </Group>
                    </Stack>
                </Paper>

            </SimpleGrid>
        </>

        /* //     <div className={"topbar scrolled "} ref={stickyDetails}>
        //         <div className="left">
        //             <Header as={'h2'}>Check details</Header>

        //             {!editing ?

        //                 <EditButton click={toggleEditing} /> :
        //                 <>
        //                     <SaveButton click={save} />
        //                     <CancelButton click={cancel} />
        //                 </>
        //             }
        //         </div>
        //     </div>
        //     <div className="invoiceDetails-FormGrid">
        //         <div className="coach">
        //             <Header as={'h4'}>Coach:</Header>
        //             <div className='field'>
        //                 <span>Company:</span>
        //                 <BItem editing={editing}>
        //                     <div>{editedInvoice.Coachcompany}</div>
        //                     <Form>
        //                         <Input type='text' onChange={(e) => handleChange(e.target.value, 'Coachcompany')} value={editedInvoice['Coachcompany']} >
        //                         </Input>
        //                     </Form> :
        //                 </BItem>
        //             </div>
        //             <div className='field'>
        //                 <span>Name:</span>
        //                 <BItem editing={editing}>
        //                     <div>{editedInvoice.Coachname}</div>
        //                     <Form>
        //                         <Input type='text' onChange={(e) => handleChange(e.target.value, 'Coachname')} value={editedInvoice['Coachname']} >
        //                         </Input>
        //                     </Form> :
        //                 </BItem>
        //             </div>
        //             <div className='field'>
        //                 <span>Address:</span>
        //                 <BItem editing={editing}>
        //                     <div>{editedInvoice.CoachstreetAndNumber}</div>
        //                     <Form>
        //                         <Input type='text' onChange={(e) => handleChange(e.target.value, 'CoachstreetAndNumber')} value={editedInvoice['CoachstreetAndNumber']} >
        //                         </Input>
        //                     </Form> :
        //                 </BItem>
        //             </div>
        //             <div className='field'>
        //                 <span>VAT:</span>
        //                 <BItem editing={editing}>
        //                     <div>{coachContext.getInvoicing().VAT
        //                     }</div>
        //                     <Form>
        //                         <Input type='text' onChange={(e) => handleChange(e.target.value, 'CoachUID')} value={editedInvoice['CoachUID']} >
        //                         </Input>
        //                     </Form> :
        //                 </BItem>
        //             </div>

        //             <div className='field'>
        //                 <span>Zipcode, City and Country:</span>
        //                 <BItem editing={editing}>
        //                     <div>{editedInvoice.CoachzipCodeAndCityAndCountry}</div>
        //                     <Form>
        //                         <Input type='text' onChange={(e) => handleChange(e.target.value, 'CoachzipCodeAndCityAndCountry')} value={editedInvoice['CoachzipCodeAndCityAndCountry']} >
        //                         </Input>
        //                     </Form> :
        //                 </BItem>
        //             </div>

        //         </div>
        //         <div className="bankDetails">
        //             <Header as={'h4'}>Bank details:</Header>
        //             <BItem editing={editing}>
        //                 <div style={{ whiteSpace: 'pre-line' }}>{editedInvoice.CoachBankDetails}</div>
        //                 <Form>
        //                     <TextArea rows={6} className='fullspace' type='text' onChange={(e) => handleChange(e.target.value, 'CoachBankDetails')} value={editedInvoice['CoachBankDetails']} >
        //                     </TextArea>
        //                 </Form> :
        //             </BItem>

        //         </div>
        //         <div className="logo">
        //             <Header as={'h4'}>Logo:</Header>
        //             <img src={image} alt="" />
        //         </div>
        //         <div className="invoice">
        //             <Header as={'h4'}>Invoice:</Header>
        //             <div className='field'>
        //                 <span>Id:</span>
        //                 <BItem editing={editing}>
        //                     <div>{editedInvoice.Invoiceid}</div>
        //                     <Form>
        //                         <Input type='text' onChange={(e) => handleChange(e.target.value, 'Invoiceid')} value={editedInvoice['Invoiceid']} >
        //                         </Input>
        //                     </Form> :
        //                 </BItem>
        //             </div>
        //             <div className='field'>
        //                 <span>Currency</span>
        //                 <BItem editing={editing}>
        //                     <div>{editedInvoice.Invoicecurrency}</div>
        //                     <Form>
        //                         <Input type='text' onChange={(e) => handleCurrency(e)} value={editedInvoice['Invoicecurrency']} >
        //                         </Input>
        //                     </Form> :
        //                 </BItem>
        //             </div>
        //             <div className='field'>
        //                 <span>VAT percentage</span>
        //                 <BItem editing={editing}>
        //                     <div>{editedInvoice.InvoicevatPercentage}</div>
        //                     <Form>
        //                         <Input type='text' onChange={(e) => handleChange(e.target.value, 'InvoicevatPercentage')} value={editedInvoice['InvoicevatPercentage']} >
        //                         </Input>
        //                     </Form> :
        //                 </BItem>
        //             </div>
        //             <div className='field'>
        //                 <span>Payment term in days</span>
        //                 <BItem editing={editing}>
        //                     <div>Within {editedInvoice.ClientpaymentTerms} days</div>
        //                     <Form>
        //                         <Input type='number' onChange={(e) => handleDays(e.target.value)} value={editedInvoice['ClientpaymentTerms']} >
        //                         </Input>
        //                     </Form> :
        //                 </BItem>
        //             </div>
        //             <div className='field'>
        //                 <span>Date</span>
        //                 <BItem editing={editing}>
        //                     <div>{editedInvoice.Invoicedate instanceof Date ? editedInvoice.Invoicedate.toDateString : editedInvoice.Invoicedate}</div>
        //                     <Form>
        //                         <Input type='date' onChange={(e) => handleDate(e.target.value)} value={editedInvoice['Invoicedate']} >
        //                         </Input>
        //                     </Form> :
        //                 </BItem>
        //             </div>
        //             <div className='field'>
        //                 <span>Payable until</span>
        //                 <div>{editedInvoice.InvoicepayableUntil}</div>

        //             </div>
        //             <div className='field'>
        //                 <span>Period</span>
        //                 <BItem editing={editing}>
        //                     <div>{editedInvoice.Invoiceperiod}</div>
        //                     <Form>
        //                         <Input type='text' onChange={(e) => handleChange(e.target.value, 'Invoiceperiod')} value={editedInvoice['Invoiceperiod']} >
        //                         </Input>
        //                     </Form> :
        //                 </BItem>
        //             </div>
        //         </div>
        //         <div className="client">
        //             <Header as={'h4'}>Company:</Header>
        //             <div className='field'>
        //                 <span>Name:</span>
        //                 <BItem editing={editing}>
        //                     <div>{editedInvoice.Companyname}</div>
        //                     <Form>
        //                         <Input type='text' onChange={(e) => handleChange(e.target.value, 'Companyname')} value={editedInvoice['Companyname']} >
        //                         </Input>
        //                     </Form> :
        //                 </BItem>
        //             </div>
        //             <div className='field'>
        //                 <span>Contact:</span>
        //                 <BItem editing={editing}>
        //                     <div>{editedInvoice.Companycontact}</div>
        //                     <Form>
        //                         <Input type='text' onChange={(e) => handleChange(e.target.value, 'Companycontact')} value={editedInvoice['Companycontact']} >
        //                         </Input>
        //                     </Form> :
        //                 </BItem>
        //             </div>
        //             <div className='field'>
        //                 <span>Address:</span>
        //                 <BItem editing={editing}>
        //                     <div>{editedInvoice.CompanystreetAndNumber}</div>
        //                     <Form>
        //                         <Input type='text' onChange={(e) => handleChange(e.target.value, 'CompanystreetAndNumber')} value={editedInvoice['CompanystreetAndNumber']} >
        //                         </Input>
        //                     </Form> :
        //                 </BItem>
        //             </div>
        //             <div className='field'>
        //                 <span>Zip and city:</span>
        //                 <BItem editing={editing}>
        //                     <div>{editedInvoice.ClientzipAndCity}</div>
        //                     <Form>
        //                         <Input type='text' onChange={(e) => handleChange(e.target.value, 'ClientzipAndCity')} value={editedInvoice['ClientzipAndCity']} >
        //                         </Input>
        //                     </Form> :
        //                 </BItem>
        //             </div>
        //             <div className='field'>
        //                 <span>Country:</span>
        //                 <BItem editing={editing}>
        //                     <div>{editedInvoice.Clientcountry}</div>
        //                     <Form>
        //                         <Input type='text' onChange={(e) => handleChange(e.target.value, 'Clientcountry')} value={editedInvoice['Clientcountry']} >
        //                         </Input>
        //                     </Form> :
        //                 </BItem>
        //             </div>
        //         </div>
        //     </div>
        // </div > */

    )
}


export function InvoiceRecords({ input, completeInput, setRecords, setInvoice, markComplete, invoice, markNotComplete }) {
    const [editedInvoice, setEditedInvoice] = useState(input)
    const [ready, setReady] = useState(false)

    function filterSelected(arr) {
        return arr.filter((k) => {
            return k.selected
        })

    }
    function getTotalAmountSelected(arr) {
        return filterSelected(arr).reduce((acc, record) => {
            const duration = calculateDuration(record.startTime, record.endTime)
            const amount = record.singleCharge ? record.hourlyRate : calculateTotalAmount(record.hourlyRate, duration)
            return acc + parseInt(amount)
        }, 0)
    }

    function submitRecords() {
        const InvoicetotalEXVAT = parseFloat(getTotalAmountSelected(editedInvoice)).toFixed(2)
        const InvoicetotalINCVAT = (getTotalAmountSelected(editedInvoice) * (1 + parseFloat(invoice.InvoicevatPercentage.split("\%")[0]) / 100)).toFixed(2)
        const InvoiceVAT = (InvoicetotalINCVAT - InvoicetotalEXVAT).toFixed(2).toString()
        setInvoice((prev) => ({
            ...prev, InvoicetotalEXVAT: InvoicetotalEXVAT, InvoicetotalINCVAT: InvoicetotalINCVAT
            , InvoiceVAT: InvoiceVAT
        }))
        setRecords(filterSelected(editedInvoice))
        markComplete()
    }
    useEffect(() => {

        let newInput = editedInvoice.map((record) => {
            if (completeInput) {
                for (const r of completeInput) {
                    if (r.id === record.id) {
                        return { ...record, selected: true }
                    }
                }
                return record
            }
        })
        setEditedInvoice(newInput)
    }, [])

    function handleChange(data, key) {
        markNotComplete()
        let found = false
        let new_records = editedInvoice.map((record) => {
            if (record.id === key) {
                if (!record.selected) {
                    found = true
                }
                return { ...record, selected: data }
            } else {
                if (record.selected) {
                    found = true
                }
            }
            return record
        })
        setReady(found)
        setEditedInvoice(new_records)
    }

    function selectedSort(records) {
        const start = []
        const end = []
        for (const record of records) {
            if (record.selected) {
                start.push(record)
            } else {
                end.push(record)
            }
        }


        return [...start, ...end]
    }


    return (
        <>
            <Title py={"md"} order={3} tt={"uppercase"}>
                Check Records
            </Title>
            <Table striped highlightOnHover withBorder my={'md'}>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Description</th>
                        <th>Duration</th>
                        <th>Total</th>
                        <th>Invoiced</th>
                        <th>Add</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        editedInvoice.map((record, i) => {
                            const duration = calculateDuration(record.startTime, record.endTime)
                            return (
                                <tr key={record.id}>
                                    <td>{formattedDate(record.date)}</td>
                                    <td>{record.description}</td>
                                    <td>{duration}</td>
                                    <td>{record.singleCharge ? record.hourlyRate : formatMoney(record.hourlyRate * duration)}</td>
                                    <td>{record.invoiced !== '-' ? "yes" : "no"}</td>
                                    <td >
                                        <Switch checked={record.selected} onChange={(event) => {
                                            if (event.currentTarget.checked) {
                                                return handleChange(true, record.id)
                                            } else {
                                                return handleChange(false, record.id)
                                            }
                                        }} />
                                    </td>
                                </tr>)
                        })
                    }

                </tbody>
                <tfoot style={{
                    marginTop: '1rem',
                    backgroundColor: '#f9fafb'
                }}>
                    <tr valign='center' >
                        <td colSpan={6}>
                            <Stack>
                                <Divider />
                                <Group spacing={'lg'} p={'md'} position={'apart'}>
                                    <Group>

                                        <span color={'gray'} order={6} tt={'uppercase'}>
                                            Total amount ex. VAT: {getTotalAmountSelected(editedInvoice)} {invoice.Invoicecurrency}</span>
                                        <span color={'gray'} order={6} tt={'uppercase'}>
                                            Total inc. VAT: {(getTotalAmountSelected(editedInvoice) * (1 + parseFloat(parseVatPercentage(invoice.InvoicevatPercentage).split("\%")[0]) / 100)).toFixed(2)} {invoice.Invoicecurrency}
                                        </span>
                                        <span color={'gray'} order={6} tt={'uppercase'}>
                                            Selected {filterSelected(editedInvoice).length}
                                        </span>
                                    </Group>
                                    {ready && <Button className='inverted' onClick={() => submitRecords()}>Submit</Button>}
                                </Group>
                            </Stack>
                        </td>

                    </tr>
                </tfoot>

            </Table>
            {/* <Header as={'h2'}>Check Records</Header>

            <div className="invoiceRecords">

                <Table striped>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Date</Table.HeaderCell>
                            <Table.HeaderCell>Description</Table.HeaderCell>
                            <Table.HeaderCell>Duration</Table.HeaderCell>
                            <Table.HeaderCell>Total</Table.HeaderCell>
                            <Table.HeaderCell>Invoiced</Table.HeaderCell>
                            <Table.HeaderCell>Add</Table.HeaderCell>

                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {
                            editedInvoice.map((record, i) => {

                                const duration = calculateDuration(record.startTime, record.endTime)
                                return (
                                    <Table.Row key={record.id}>
                                        <Table.Cell>{record.date}</Table.Cell>
                                        <Table.Cell>{record.description}</Table.Cell>
                                        <Table.Cell>{duration}</Table.Cell>
                                        <Table.Cell>{record.singleCharge ? record.hourlyRate : formatMoney(record.hourlyRate, duration)}</Table.Cell>
                                        <Table.Cell>{record.invoiced !== '-' ? "yes" : "no"}</Table.Cell>
                                        <Table.Cell collapsing>
                                            <Checkbox toggle checked={record.selected} onChange={(event, data) => {
                                                if (data.checked) {
                                                    return handleChange(true, record.id)
                                                } else {
                                                    return handleChange(false, record.id)
                                                }
                                            }} />
                                        </Table.Cell>
                                    </Table.Row>)
                            })
                        }

                    </Table.Body>
                </Table>
                <div className="totals">
                    <span>Total amount ex. VAT: {getTotalAmountSelected(editedInvoice)} {invoice.Invoicecurrency}</span>
                    <span>Total inc. VAT: {(getTotalAmountSelected(editedInvoice) * (1 + parseFloat(parseVatPercentage(invoice.InvoicevatPercentage).split("\%")[0]) / 100)).toFixed(2)} {invoice.Invoicecurrency}</span>
                    <span>Selected {filterSelected(editedInvoice).length}</span>
                    <span>{ready && <Button className='inverted' onClick={() => submitRecords()}>Submit</Button>}</span>
                </div>
            </div>

            <div className='submit'>

            </div> */}
        </>
    )
}
function parseVatPercentage(vat) {
    if (vat.includes('%')) {
        return vat
    } else {
        return vat + "%"
    }
}

function* chunks(arr, n) {
    for (let i = 0; i < arr.length; i += n) {
        yield arr.slice(i, i + n);
    }
}

export function InvoiceFinal({ generateInvoice, invoice, records, client }) {
    const coachContext = useCoachContext()
    const [generalPDF, setGeneralPDF] = useState()
    const [detailsPDF, setDetailsPDF] = useState()

    useEffect(() => {
        showGeneralPDF().then((x) => {
            setGeneralPDF(x)
        })
        showRecordsPDF().then((x) => {
            setDetailsPDF(x)
        })
    }, [records])

    async function fillInGeneral(inputs, template) {
        const blob = await coachContext.getInvoiceBlob()
        template = resizeImage(blob, template)
        const base64 = await blobToBase64(blob)
        inputs = inputs[0]
        inputs.Coachlogo = base64

        return { inputs: [inputs], template, template }
    }

    async function fillInRecords(inputs, template) {
        const coachInvoicing = coachContext.getInvoicing()
        const blob = await coachContext.getInvoiceBlob()
        // template = resizeImage(blob, template)
        const base64 = await blobToBase64(blob)
        inputs = inputs[0]
        // inputs.Coachcompany = coachInvoicing.companyName
        // inputs.CoachstreetAndNumber = coachInvoicing.street + " " + coachInvoicing.number
        // inputs.CoachzipCodeAndCityAndCountry = coachInvoicing.zipCode + " " + coachInvoicing.city + " " + coachInvoicing.country
        // inputs.CoachUID = ""
        // inputs.Coachlogo = base64
        return { inputs: [inputs], template: template }

    }
    async function resizeImage(blob, template) {
        let result = template
        const bmp = await createImageBitmap(blob)
        const { width, height } = bmp;
        const ratio = width / height
        let imageHeight = 30
        let imageWidth = ratio * imageHeight
        const maxWidth = 108
        if (imageWidth > maxWidth) {
            imageWidth = maxWidth
            imageHeight = imageWidth / ratio
        }
        const imageX = 196 - imageWidth
        const imageY = 10
        result.schemas[0].Coachlogo.position.x = imageX
        result.schemas[0].Coachlogo.position.y = imageY
        result.schemas[0].Coachlogo.width = imageWidth
        result.schemas[0].Coachlogo.height = imageHeight
        return template
    }

    async function showRecordsPDF() {
        let inputs = invoiceDefaults.recordInputs
        let template = invoiceDefaults.templateRecords
        let pdfData = await fillInRecords(inputs, template)
        inputs = pdfData.inputs
        template = await pdfData.template
        return {
            template: template,
            inputs: inputs
        }
    }

    async function showGeneralPDF() {
        let inputs = [invoice]
        let template = invoiceDefaults.templateGeneral
        let pdfData = await fillInGeneral(inputs, template)
        const blob = await coachContext.getInvoiceBlob()
        inputs = pdfData.inputs
        template = await pdfData.template
        const domContainer = document.getElementById('overview');
        const view = new Viewer({ domContainer, template, inputs })
        return {
            template: template,
            inputs: inputs
        }
    }

    function markAsInvoiced() {

        let newTimesheet = client?.value?.timesheet
        let recordIds = records.map((record) => record.id)
        const today = new Date().toISOString()

        for (const record of newTimesheet.R) {
            if (!record.invoiced) {
                record.invoiced = today
            } else {

                record.invoiced = recordIds.includes(record.id) ? today : record.invoiced
            }
        }
        coachContext.markCompleted(client.id, newTimesheet)
    }


    async function generateAll() {
        // calculate record pages 16 records fit on one page
        const pages = Math.ceil(records.length / 16) + 1
        const recordChunks = [...chunks(records, 16)]
        const pdfOverview = await generateOverview(pages)
        const pdfDoc = await PDFDocument.load(pdfOverview)
        const pdfRecords = []
        for (let page = 2; page < pages + 1; page++) {
            const pdf = await generateRecords(page, pages, recordChunks)
            pdfRecords.push(pdf)

        }
        for (const rec of pdfRecords) {
            const pdf = await PDFDocument.load(rec)
            const copiedPages = await pdfDoc.copyPages(pdf, pdf.getPageIndices());
            copiedPages.forEach((page) => pdfDoc.addPage(page))
        }
        const pdfBytes = await pdfDoc.save()
        const blob = new Blob([pdfBytes], { type: 'application/pdf' });
        const shortURl = URL.createObjectURL(blob)
        window.open(shortURl);

    }




    async function generateOverview(pages) {
        let inputs = generalPDF.inputs
        let template = generalPDF.template
        inputs[0].ClientpaymentTerms = inputs[0].ClientpaymentTerms.includes("Within") ? inputs[0].ClientpaymentTerms : `Within ${inputs[0].ClientpaymentTerms} days`
        inputs[0].Pages = `1/${pages}`
        const pdfOverview = generate({ template, inputs })
        return pdfOverview
    }

    async function generateRecords(page, pages, records) {
        const coachInvoicing = coachContext.getInvoicing()
        let inputs = detailsPDF.inputs
        let template = detailsPDF.template
        template.schemas[0] = (({ Date, Time, Duration, Description, SingleCharge, Rate, Total, Pages, Additionalinfo }) => ({ Date, Time, Duration, Description, SingleCharge, Rate, Total, Pages, Additionalinfo }))(template.schemas[0])
        records[page - 2].forEach((record, i) => {
            const duration = calculateDuration(record.startTime, record.endTime)
            if (i === 0) {
                inputs[0].Date = `${formattedDate(record.date)}`
                inputs[0].Time = `${record.startTime} - ${record.endTime} `
                inputs[0].Duration = duration + " hours"
                inputs[0].Description = record.description.toString()
                inputs[0].SingleCharge = record.singleCharge ? 'yes' : 'no'
                inputs[0].Rate = record.hourlyRate.toString()
                inputs[0].Total = record.singleCharge ? record.hourlyRate.toString() : formatMoney(record.hourlyRate * duration).toString()
                inputs[0].Pages = `${page} /${pages}`
                inputs[0].Additionalinfo = `${coachInvoicing.companyName} | CoC: ${coachInvoicing.chamberOfCommerce} | VAT ID Nr.: ${coachInvoicing.VAT}`
            } else {
                template.schemas[0][`Date${i}`] = structuredClone(template.schemas[0].Date)
                template.schemas[0][`Date${i}`].position.y = template.schemas[0].Date.position.y + (i * 15)
                template.schemas[0][`Time${i}`] = structuredClone(template.schemas[0].Time)
                template.schemas[0][`Time${i}`].position.y = template.schemas[0].Time.position.y + (i * 15)
                template.schemas[0][`Duration${i}`] = structuredClone(template.schemas[0].Duration)
                template.schemas[0][`Duration${i}`].position.y = template.schemas[0].Duration.position.y + (i * 15)
                template.schemas[0][`Description${i}`] = structuredClone(template.schemas[0].Description)
                template.schemas[0][`Description${i}`].position.y = template.schemas[0].Description.position.y + (i * 15)
                template.schemas[0][`SingleCharge${i}`] = structuredClone(template.schemas[0].SingleCharge)
                template.schemas[0][`SingleCharge${i}`].position.y = template.schemas[0].SingleCharge.position.y + (i * 15)
                template.schemas[0][`Rate${i}`] = structuredClone(template.schemas[0].Rate)
                template.schemas[0][`Rate${i}`].position.y = template.schemas[0].Rate.position.y + (i * 15)
                template.schemas[0][`Total${i}`] = structuredClone(template.schemas[0].Total)
                template.schemas[0][`Total${i}`].position.y = template.schemas[0].Total.position.y + (i * 15)
                inputs[0][`Date${i}`] = `${formattedDate(record.date)}`
                inputs[0][`Time${i}`] = `${record.startTime} - ${record.endTime} `
                inputs[0][`Duration${i}`] = duration + " hours"
                inputs[0][`Description${i}`] = record.description.replace(/(\r\n|\n|\r)/gm, "");
                inputs[0][`SingleCharge${i}`] = record.singleCharge ? 'yes' : 'no'
                inputs[0][`Rate${i}`] = record.hourlyRate.toString()
                inputs[0][`Total${i}`] = record.singleCharge ? record.hourlyRate.toString() : formatMoney(record.hourlyRate * duration).toString()
            }
        })

        const pdfDetails = generate({ template, inputs })
        return pdfDetails
    }
    function isoToEU(s) {
        const [year, month, day] = s.split("-")
        return `${day}-${month}-${year}`
    }
    return (
        <div >
            <Title py={'md'} order={3} tt={'uppercase'}>Final Check</Title>
            <Grid align={'center'} >
                <Grid.Col span={6}>
                    <div style={{ 'maxHeight': '600px' }} id="overview">

                    </div>
                </Grid.Col>
                <Grid.Col span={6}>
                    <Stack justify={'center'} spacing={'md'}>
                        <Button onClick={() => generateAll()}>Export</Button>
                        <Button onClick={() => markAsInvoiced()}>Mark as Invoiced</Button>
                    </Stack>
                </Grid.Col>

            </Grid>

        </div>
    )
}
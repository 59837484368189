import React, { useContext } from 'react'
import { useState, useRef } from 'react'
import { useEffect } from 'react'
import { Divider, Header, Form, Input, Icon, Modal, Button } from 'semantic-ui-react'
import { useAlertsContext } from '../../../providers/Alerts/AlertsProvider'
import { BrandingContext, useBrandingContext } from '../../../providers/BrandingProvider/BrandingProvider'
import { CoachContext, useCoachContext } from '../../../providers/CoachProvider/CoachProvider'
import BItem from '../../utils/BItem'
import { CancelButton, EditButton, ResetButton, ResetButtonWarning, SaveButton } from '../../utils/buttons/buttons'
import './Branding.scss'
import brandingState from './brandingState'
import { ColorPicker, toColor, useColor } from 'react-color-palette'
import "react-color-palette/lib/css/styles.css"
import OptionalModal from '../../utils/optionalModal'
import defaultLogo from "../../../assets/logo.png"
import ImageUpload from 'components/utils/ImageU/ImageUpload'
import { Paper, SimpleGrid, TextInput, Title, ColorInput } from '@mantine/core'
import { formattedDate, PageMenu, SafeDateInput, ImageUpload as ImageUploadNew } from 'components/utils/MantineUtils'
import { useSelector, useDispatch } from 'react-redux'
import { fetchLogoAsync, fetchBrandingAsync } from '../../../redux/branding/brandingSlice'
import { brandingService } from 'services/brandingService'
import { useUserContext } from 'App'
import { useUserDataContext } from 'providers/UserData/UserDataProvider'

export default function BrandingComponent() {
    const userDetails = useSelector(state => state.authUser.userDetails)
    const { role, coach } = useSelector(state => state.firestoreUser.userData)
    const alertsProvider = useAlertsContext()

    const logo = useSelector(state => state.branding.logo)
    const branding = useSelector(state => state.branding.branding)
    const [editedBranding, setEditedBranding] = useState(branding)
    const [editing, setEditing] = useState(false)
    const dispatch = useDispatch()

    function getLogo() {
        dispatch(fetchLogoAsync(coach, role, userDetails.uid))
    }

    function getBranding() {
        dispatch(fetchBrandingAsync(coach, role, userDetails.uid))
    }

    function addLogo(file) {
        brandingService.addLogo(file, userDetails.uid).then(() => {
            getLogo()
        }).catch((error) => {
            console.log(error)
        })
    }

    function resetLogo() {
        brandingService.deleteLogo(userDetails.uid).then(() => {
            getLogo()
        }).catch((error) => {
            console.log(error)
        })
    }

    function save() {
        brandingService.saveBranding(coach, role, userDetails.uid, editedBranding).then(() => {
            getBranding()
            alertsProvider.addAlert({ content: "Saved Branding!", timeout: 2000, variation: 'positive' })
            toggleEditing()
        })
    }

    function toggleEditing() {
        setEditing(!editing)
    }

    function cancel() {
        setEditedBranding(branding)
        setEditing(false)
    }

    return (
        <>
            <Paper
                p={'md'}
                shadow={'lg'}
                withBorder
                sx={{
                    position: 'sticky',
                    top: 69,
                    padding: '20px',
                    zIndex: 2,
                    transition: 'all 0.3s ease',
                }}
            >
                <PageMenu sectionType={'Coach'} name={'Branding'} editing={editing} toggleEditing={toggleEditing} save={save} cancel={cancel} />
            </Paper>
            <Divider my={'lg'} />
            <SimpleGrid cols={2}
                breakpoints={
                    [
                        { maxWidth: '36rem', cols: 1, spacing: 'sm' },
                    ]
                }
                spacing={'md'}>
                <Paper p={'md'} shadow={'lg'} withBorder>
                    <Title order={5} >Company Logo</Title>
                    <ImageUploadNew name={'Branding Logo'} image={logo} resetImage={resetLogo} addImage={addLogo} />
                </Paper>
                <Paper p={'md'} shadow={'lg'} withBorder>
                    <Title order={5} >Colors</Title>
                    <ColorInput disabled={!editing} onChange={(value) => setEditedBranding((prev) => {
                        return { ...prev, primaryColor: value }
                    })} value={editedBranding.primaryColor} placeholder="Pick color" label="Your favorite color" />                </Paper>
            </SimpleGrid>

        </>
        // <div className="Branding">
        //     <div className="Coach-topbar">
        //         <Header as='h2'>Branding</Header>
        //         <div className="bar-segment">

        //             <Button icon="save" content="Apply branding" onClick={() => save()}  ></Button>
        //             <ResetButtonWarning text={'Reset branding'} click={() => reset()} />

        //         </div >
        //     </div >
        //     <Divider />
        //     <div className="logo-topbar">
        //         <Header as={'h3'}>Logo</Header>
        //         <div className="bar-segment">
        //             <ImageUpload name={'Branding Logo'} defaultLogo={defaultLogo} getImageUrl={brandingContext.getLogoUrl} resetImage={brandingContext.resetLogo} addImage={brandingContext.addLogo} />
        //             {/* <input type="file" ref={hiddenFileInput} style={{ display: 'none' }} onChange={(e) => onFileChange(e)} />

        //             <Button onClick={() => addDocuments()}>Upload Logo</Button>
        //             <ResetButtonWarning text={'Reset logo'} click={() => resetLogo()} /> */}

        //         </div>
        //     </div>

        //     {/* <div className="logo-container">
        //         <img src={logo || defaultLogo} alt="Logo" />

        //     </div> */}
        //     <Divider />
        //     <Header as={'h3'}>Colors</Header>

        //     {
        //         <div className="card">
        //             {/* <ColorComponent handleChange={handleColor} editedBranding={editedBranding} name='primary' editing={editing} /> */}
        //             {Object.keys(brandingState.colors).map((k, v) =>
        //                 <ColorComponent key={k} name={k} editedBranding={editedBranding} handleChange={handleColor} />

        //             )}
        //             {/* <ColorComponent name='primary' editedBranding={editedBranding} handleChange={handleColor} />
        //             <ColorComponent name='secondary' editedBranding={editedBranding} handleChange={handleColor} /> */}

        //         </div>
        //     }

        // </div >
    )
}

// function ColorComponent({ handleChange, editedBranding, name, editing }) {
//     const [color, setColor] = useColor("hex", "#121212");

//     useEffect(() => {
//         handleChange(color.hex, name)
//     }, [color])
//     return (
//         <>
//             {editedBranding && editedBranding.colors &&
//                 <BItem editing={editing}>
//                     <div className='color'>
//                         <label>
//                             {name} color:
//                         </label>
//                         <div className="color-value">
//                             {editedBranding?.colors[name] || ''}
//                         </div>
//                         <div className='circle' style={{ backgroundColor: editedBranding?.colors[name] || '' }}></div>
//                     </div>
//                     <div className='color-edit'>
//                         {/* <label>{name} color</label>
//                         <Input label='#' maxLength='6' onChange={(e) => handleChange(e.target.value, name)} value={editedBranding.colors[name] || ''} >
//                         </Input> */}
//                         <Header as={'h4'}>
//                             {name} color:
//                         </Header>
//                         <ColorPicker width={400} height={300} color={color} onChange={setColor} hideHSV hideRGB dark />
//                     </div>
//                 </BItem>
//             }
//         </>

//     )
// }
function ColorComponent({ name, editedBranding, handleChange }) {
    const [color, setColor] = useColor("hex", editedBranding.colors ? editedBranding.colors[name] : '#000000');
    const [editing, setEditing] = useState(false)
    useEffect(() => {
        if (editedBranding.colors && name in editedBranding.colors) {
            updateColor()
        }

        // setColor(useColor('hex', editedBranding.colors ? editedBranding.colors[name] : '#000000'))
    }, [editedBranding])
    function updateColor() {
        setColor(toColor("hex", editedBranding.colors[name]))
    }
    function toggleEditing() {
        setEditing(!editing)
    }
    function cancel() {
        setEditing(false)

    }
    function confirm() {
        handleChange(color.hex, name)
        setEditing(false)

    }
    function getMatchingTextColor(bgColor) {
        var color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
        var r = parseInt(color.substring(0, 2), 16); // hexToR
        var g = parseInt(color.substring(2, 4), 16); // hexToG
        var b = parseInt(color.substring(4, 6), 16); // hexToB
        return (((r * 0.299) + (g * 0.587) + (b * 0.114)) > 186) ?
            "#000000" : "#FFFFFF";
    }


    return (<div className='colorContainer'>
        <div className='colorComponent' style={{ "backgroundColor": color.hex, "border": "1px solid " + getMatchingTextColor(color.hex) }}>
            <h4 style={{ "color": getMatchingTextColor(color.hex) }}>
                {name[0].toUpperCase() + name.slice(1)} color
            </h4>

            {editing ?
                <div className='buttons' style={{ "color": getMatchingTextColor(color.hex) }}>
                    <Icon name='save' />
                    <Icon name='cancel' />
                </div> :
                <div className='buttons' style={{ "color": getMatchingTextColor(color.hex) }}>
                    <Icon name='edit' onClick={() => toggleEditing()} />
                </div>

            }
            <h3 style={{ "color": getMatchingTextColor(color.hex) }}>
                color: {color.hex} {editing}
            </h3>
        </div>
        <Modal className='colorModal' open={editing} onClose={() => cancel()}  >
            <Modal.Header style={{ "backgroundColor": color.hex, "color": getMatchingTextColor(color.hex) }}>
                <div>Editing {name[0].toUpperCase() + name.slice(1)} color</div>
                <Icon name='cancel' onClick={() => cancel()} />
            </Modal.Header>
            <Modal.Content style={{ "backgroundColor": color.hex, "color": getMatchingTextColor(color.hex) }}>
                <ColorPicker width={400} height={300} color={color} onChange={setColor} hideHSV hideRGB dark />

                <Button onClick={() => confirm()}>save</Button>
            </Modal.Content>
        </Modal>


    </div >)
}
export const noteInput = {
    id: -1,
    session: "1",
    date: "",
    topic: "-",
    notes: "",
    actions: []
}
export const actionInput = {
    id: -1,
    title: "",
    notes: '',
    status: "Opened"
}
export const sortTypes = {
    none: 0,
    up: 1,
    down: 2
}
export function getIcon(sort) {
    let icon = ''
    switch (sort) {
        case 0:
            icon = 'sort'
            break;
        case 1:
            icon = "sort down"
            break;
        case 2:
            icon = 'sort up'
            break;
        default:
            icon = 'sort'
            break;
    }
    return icon
}
import "./roadMap.scss";
import roadMapState from "./roadMapState";
import {
    roadMapInput,
    priorityTypes,
    directionTypes,
    statusTypes,
} from "./roadMapUtils";
import React, { useEffect, useRef, useState, createRef } from "react";

import CoachItem from "../../utils/CoachItem";
import {
    EditButton,
    SaveButton,
    CancelButton,
} from "../../utils/buttons/buttons";
import { useClientContext } from "../../../providers/ClientProvider/ClientProvider";
import ClientService from "../ClientService";
import BItem from "../../utils/BItem";
import { Group, Paper, Title, Button, Box, Select, TextInput, Divider, Textarea, Text, Table } from "@mantine/core";
import { NormalButton, DeleteButton, PageMenu } from "components/utils/MantineUtils";
import { IconArrowBackUp, IconDeviceFloppy, IconEdit, IconPlus } from "@tabler/icons-react";
import { statusColors } from "components/clients/roadMap/roadMapUtils";
import { useMediaQuery } from "@mantine/hooks";

export default function RoadMapComponent() {
    const cContext = useClientContext();
    const matching = useMediaQuery("(max-width: 800px)");
    const [scrollTop, setScrollTop] = useState(0);
    const [search, setSearch] = useState("")
    const endRoadMapRef = useRef(null);

    const topics =
        cContext.clientData.initialProposal &&
            cContext.clientData.initialProposal["T"]
            ? cContext.clientData.initialProposal["T"]
            : [];
    const roadMap = cContext.clientData.roadMap;
    const [editing, setEditing] = useState(false);
    const [editedRoadMap, setEditedRoadMap] = useState(
        roadMap === undefined ? {} : JSON.parse(JSON.stringify(roadMap))
    );
    const lastRef = useRef(null);

    let cs = new ClientService(setEditedRoadMap, setScrollTop);

    const topicOptions = cContext.clientData.initialProposal
        ? cContext.clientData.initialProposal["T"].map((o, i) => {
            return {
                key: i,
                text: o.name,
                value: o.name,
                label: o.name,
            };
        })
        : [];



    useEffect(() => {
        if (roadMap === undefined) {
            cContext.saveClientRoadMap(roadMapState);
        }
    }, [roadMap, editedRoadMap]);

    useEffect(() => {
        if (endRoadMapRef.current) {
            endRoadMapRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [editedRoadMap]);




    function customFilter(note) {

        const searchU = search.toUpperCase()

        for (let key of Object.keys(note)) {

            if (note[key].toString().toUpperCase().includes(searchU)) {
                return true
            }
        }
        return false
    }

    function cancel() {
        setEditedRoadMap(cContext.clientData.roadMap);
        setEditing(false);
    }

    function save() {
        setEditing(false);
        cContext.saveClientRoadMap(editedRoadMap);
    }
    function deleteInput(cat, id) {
        cs.deleteInput(editedRoadMap, cat, id);
    }
    function handleChange(data, cat, id, key) {
        cs.handleChange(editedRoadMap, data, cat, id, key);
    }

    function toggleEditing() {
        setEditing(!editing);
    }

    function initializeRoadMap() {
        topics.forEach((topic) => {
            if (topic.name && !alreadyIn(topic)) {
                let template = roadMapInput;
                template.topic = topic.name;

                cs.addInput(editedRoadMap, "R", template);
            }
        });
        save();
    }
    function addInput() {
        let template = roadMapInput;
        cs.addInput(editedRoadMap, "R", template);

    }
    function alreadyIn(topic) {
        if (editedRoadMap["R"]) {
            let found = false;
            editedRoadMap["R"].some((r) => {
                if (r.topic === topic.name) {
                    found = true;
                }
            });
            return found;
        }
        return false;
    }


    return (
        <>

            <Paper p={"md"} shadow="lg" withBorder
                sx={
                    {
                        position: 'sticky',
                        top: 69,
                        padding: '20px',
                        zIndex: 2,
                        transition: 'all 0.3s ease',
                        // borderBottom: '1px solid #ddd',

                    }
                } >
                <PageMenu sectionType={'All'} name={'roadmap'} editing={editing} toggleEditing={toggleEditing} save={save} cancel={cancel} search={true} searchFunction={setSearch}>

                    {editing &&
                        <CoachItem>
                            <Group>
                                <Button
                                    variant="default"
                                    leftIcon={<IconPlus stroke={"3"} size={20} />}
                                    onClick={() => {
                                        addInput();
                                    }}
                                >
                                    <Title order={6}>Roadmap entry</Title>
                                </Button>
                            </Group>
                        </CoachItem>
                    }
                </PageMenu>

            </Paper>
            <Divider my={'lg'} />
            {editedRoadMap["R"] &&
                // matching ? (
                editedRoadMap["R"].filter(record => customFilter(record)).map((record, i) => {
                    return (
                        <RoadmapCard
                            matching={matching}

                            topics={topicOptions}
                            record={record}
                            deleteInput={deleteInput}
                            editing={editing}
                            handleChange={handleChange}
                            key={i}
                        />
                    );
                })}
            <Box ref={endRoadMapRef} />
        </>
        // <div className="Roadmap">
        //     <div
        //         className={
        //             "Roadmap-topbar " + (scrollTop > height ? "scrolled" : "")
        //         }
        //         ref={sticky}
        //     >
        //         <div className="left">
        //             <Header as={"h2"}>Road Map</Header>
        //             {!editing ? (
        //                 <EditButton click={toggleEditing} />
        //             ) : (
        //                 <>
        //                     <SaveButton click={save} />
        //                     <CancelButton click={cancel} />
        //                     <Button
        //                         animated
        //                         onClick={() => {
        //                             addInput();
        //                             scrollToLast(lastRef);
        //                         }}
        //                     >
        //                         <Button.Content visible>
        //                             Add Entry
        //                         </Button.Content>
        //                         <Button.Content hidden>
        //                             <Icon name="add"></Icon>
        //                         </Button.Content>
        //                     </Button>
        //                 </>
        //             )}
        //         </div>
        //     </div>

        //     <Divider />
        //     <div className="cards">
        //         {editedRoadMap["R"] &&
        //             editedRoadMap["R"].map((record, i) => {
        //                 return (
        //                     <RoadmapCard
        //                         topics={topicOptions}
        //                         record={record}
        //                         deleteInput={deleteInput}
        //                         editing={editing}
        //                         handleChange={handleChange}
        //                         key={i}
        //                     />
        //                 );
        //             })}
        //     </div>
        // </div>
    );
}
function RoadmapCard(props) {
    const record = props.record;

    const statusOptions = Object.keys(statusTypes).map((o, i) => {
        return {
            key: i,
            text: o,
            value: o,
            label: o,
        };
    });
    const priorityOptions = Object.keys(priorityTypes).map((o, i) => {
        return {
            key: i,
            text: o,
            value: o,
            label: o,
        };
    });

    return (
        <>
            <Paper withBorder shadow={'lg'} my='md' p={'md'}
                sx={() => ({
                    position: 'relative',
                })}
            >
                {
                    props.editing &&
                    <Group position="right" py={'md'} >
                        <DeleteButton click={() => props.deleteInput("R", record.id)} />
                    </Group>
                }

                <Box sx={
                    (theme) => ({
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        height: '100%',
                        width: '32px',
                        backgroundColor: theme.colors[statusColors[record.status]]
                    })
                }></Box>
                <Box ml={'32px'}>
                    <Group position="apart" grow>
                        <BItem
                            editing={props.editing}
                        >
                            <Title order={6} tt={"uppercase"}>
                                {record.topic || "-"}
                            </Title>
                            <Select data={props.topics} value={record.topic} onChange={(event) => props.handleChange(event, "R",
                                record.id,
                                "topic")} />

                        </BItem>
                        <BItem
                            editing={props.editing}
                        >
                            <Title order={6} tt={"uppercase"} ta={'right'}>
                                {record.status || "-"}
                            </Title>
                            <Select data={statusOptions} value={record.status} onChange={(event) => props.handleChange(event, "R",
                                record.id,
                                "status")} />

                        </BItem>

                    </Group>
                    <Group grow position="apart" my={'md'}>
                        <BItem editing={props.editing}>
                            <Title order={6} tt={"uppercase"}>
                                {record.priority || "-"}
                            </Title>
                            <Select data={priorityOptions} value={record.priority} onChange={(event) => props.handleChange(event, "R",
                                record.id,
                                "priority")} />
                        </BItem>
                        <BItem editing={props.editing}>
                            <Title order={6} tt={"uppercase"} ta={'right'}>
                                {record.byWhen || "-"}
                            </Title>
                            <TextInput value={record.byWhen} onChange={(event) => props.handleChange(event.target.value, "R",
                                record.id,
                                "byWhen")} />
                        </BItem>
                    </Group>
                    <Divider my={'md'} />
                    <Title mb={'md'} order={6} tt={"uppercase"}>
                        Objective in the client's words
                    </Title>
                    <BItem editing={props.editing}>
                        <Text>
                            {record.objective || "-"}
                        </Text>
                        <Textarea autosize maxRows={6} value={record.objective} onChange={(event) => props.handleChange(event.currentTarget.value, "R",
                            record.id,
                            "objective")} />

                    </BItem>
                    <Divider my={'md'} />
                    <Title mb={'md'} order={6} tt={"uppercase"}>
                        Impact
                    </Title>
                    <BItem editing={props.editing}>
                        <Text>
                            {record.impact || "-"}
                        </Text>
                        <Textarea autosize maxRows={6} value={record.impact} onChange={(event) => props.handleChange(event.currentTarget.value, "R",
                            record.id,
                            "impact")} />

                    </BItem>
                    <Divider my={'md'} />
                    <Title mb={'md'} order={6} tt={"uppercase"}>
                        Actions
                    </Title>
                    <BItem editing={props.editing}>
                        <Text>
                            {record.actions || "-"}
                        </Text>
                        <Textarea autosize maxRows={6} value={record.actions} onChange={(event) => props.handleChange(event.currentTarget.value, "R",
                            record.id,
                            "actions")} />

                    </BItem>
                    <Divider my={'md'} />
                    <Title mb={'md'} order={6} tt={"uppercase"}>
                        When is the client succesful? (KPIs)
                    </Title>
                    <BItem editing={props.editing}>
                        <Text>
                            {record.kpi || "-"}
                        </Text>
                        <Textarea autosize maxRows={6} value={record.kpi} onChange={(event) => props.handleChange(event.currentTarget.value, "R",
                            record.id,
                            "kpi")} />

                    </BItem>

                </Box>
            </Paper>

        </>

        // <Message className="roadmapCard">
        //     <div className={"strip " + record.status.replace(/\s+/g, "")} />
        //     <div className="roadmapCard-content">
        //         <div className="topic">
        //             <BItem editing={props.editing}>
        //                 <Header as={"h3"} className="newLines">
        //                     {record.topic || "-"}
        //                 </Header>
        //                 <Dropdown
        //                     fluid
        //                     selection
        //                     placeholder={record.topic}
        //                     options={props.topics}
        //                     value={record.topic}
        //                     onChange={(event, data) => 
        //                         props.handleChange(
        //                             data.value,
        //                             "R",
        //                             record.id,
        //                             "topic"
        //                         )
        //                     }
        //                 ></Dropdown>
        //             </BItem>
        //         </div>
        //         <div className="byWhen">
        //             <BItem editing={props.editing}>
        //                 <span>
        //                     Target completion date: {record.byWhen || "-"}
        //                 </span>
        //                 <Form>
        //                     <Input
        //                         type="text"
        //                         value={record.byWhen}
        //                         onChange={(e) => 
        //                             props.handleChange(
        //                                 e.target.value,
        //                                 "R",
        //                                 record.id,
        //                                 "byWhen"
        //                             )
        //                         }
        //                     ></Input>
        //                 </Form>
        //             </BItem>
        //         </div>
        //         <div className="priority">
        //             <BItem editing={props.editing}>
        //                 <p>priority: {record.priority || "-"}</p>
        //                 <Dropdown
        //                     placeholder="-"
        //                     fluid
        //                     selection
        //                     options={priorityOptions}
        //                     value={record.priority}
        //                     onChange={(event, data) => 
        //                         props.handleChange(
        //                             data.value,
        //                             "R",
        //                             record.id,
        //                             "priority"
        //                         )
        //                     }
        //                 ></Dropdown>
        //             </BItem>
        //         </div>
        //         <div className="status">
        //             <BItem editing={props.editing}>
        //                 <span>Status: {record.status || "-"}</span>
        //                 <Dropdown
        //                     placeholder="-"
        //                     fluid
        //                     selection
        //                     options={statusOptions}
        //                     value={record.status}
        //                     onChange={(event, data) => 
        //                         props.handleChange(
        //                             data.value,
        //                             "R",
        //                             record.id,
        //                             "status"
        //                         )
        //                     }
        //                 ></Dropdown>
        //             </BItem>
        //         </div>
        //         <div className="objective">
        //             <Header as={"h3"}>
        //                 Objective in the client's own words
        //             </Header>
        //             <BItem editing={props.editing}>
        //                 <span className="newLines">
        //                     {record.objective || "-"}
        //                 </span>
        //                 <Form>
        //                     <TextArea
        //                         type="text"
        //                         value={record.objective}
        //                         onChange={(e) => 
        //                             props.handleChange(
        //                                 e.target.value,
        //                                 "R",
        //                                 record.id,
        //                                 "objective"
        //                             )
        //                         }
        //                     ></TextArea>
        //                 </Form>
        //             </BItem>
        //         </div>
        //         <div className="impact">
        //             <Header as={"h3"}>Impact</Header>

        //             <BItem editing={props.editing}>
        //                 <span className="newLines">{record.impact || "-"}</span>
        //                 <Form>
        //                     <TextArea
        //                         type="text"
        //                         value={record.impact}
        //                         onChange={(e) =>
        //                             props.handleChange(
        //                                 e.target.value,
        //                                 "R",
        //                                 record.id,
        //                                 "impact"
        //                             )
        //                         }
        //                     ></TextArea>
        //                 </Form>
        //             </BItem>
        //         </div>
        //         <div className="actions">
        //             <Header as={"h3"}>Key actions</Header>

        //             <BItem editing={props.editing}>
        //                 <span className="newLines">
        //                     {record.actions || "-"}
        //                 </span>
        //                 <Form>
        //                     <TextArea
        //                         type="text"
        //                         value={record.actions}
        //                         onChange={(e) =>
        //                             props.handleChange(
        //                                 e.target.value,
        //                                 "R",
        //                                 record.id,
        //                                 "actions"
        //                             )
        //                         }
        //                     ></TextArea>
        //                 </Form>
        //             </BItem>
        //         </div>
        //         <div className="kpi">
        //             <Header as={"h3"}>When client is succesful (KPI)</Header>

        //             <BItem editing={props.editing}>
        //                 <span className="newLines">{record.kpi || "-"}</span>
        //                 <Form>
        //                     <TextArea
        //                         type="text"
        //                         value={record.kpi}
        //                         onChange={(e) =>
        //                             props.handleChange(
        //                                 e.target.value,
        //                                 "R",
        //                                 record.id,
        //                                 "kpi"
        //                             )
        //                         }
        //                     ></TextArea>
        //                 </Form>
        //             </BItem>
        //         </div>
        //     </div>
        // </Message>
    );
}

import { getStorage, ref, listAll, getDownloadURL, uploadBytes, deleteObject, uploadString, getMetadata } from 'firebase/storage'
import { doc, getDocs, query, collection, where, getDoc, updateDoc, setDoc } from 'firebase/firestore'
import { db } from '../firebase'
import { initialBrandingState } from '../redux/branding/brandingSlice'

export class brandingService {
    static async fetchLogo(coach, role, uid) {
        let coachId = coach
        if (role === 'coach') {
            coachId = uid
        }
        const storage = getStorage()
        const path = `${coachId}/logo.png`
        let url = ''
        if (coachId !== '') {
            try {
                url = await getDownloadURL(ref(storage, path))
                return url;
            } catch (error) {
                throw Error(error)
            }
        }
    }
    static async getBranding(coach, role, uid) {
        const coachId = role === 'coach' ? uid : coach;
        const ref = doc(db, 'branding', coachId);
        const snap = await getDoc(ref);

        if (snap.exists()) {
            const data = snap.data();
            if (data && 'branding' in data && 'primaryColor' in data.branding) {
                return data.branding;
            }
        } else {
            return initialBrandingState;
        }
    }

    static async saveBranding(coach, role, uid, branding) {
        const coachId = role === 'coach' ? uid : coach;
        const ref = doc(db, 'branding', coachId);
        try {
            await updateDoc(ref, { "branding": branding });
        } catch (error) {
            try {
                console.log(error)
                await setDoc(ref, { "branding": branding });
            }
            catch (error) {
                console.log(error)
                throw Error(error)
            }
            // throw Error(error);
        }
    }

    static async addLogo(file, uid) {
        const coachId = uid
        const storage = getStorage()
        const path = `${coachId}/logo.png`
        try {
            await uploadBytes(ref(storage, path), file)
        }
        catch (error) {
            throw Error(error)
        }
    }

    static async deleteLogo(uid) {
        const coachId = uid
        const storage = getStorage()
        const path = `${coachId}/logo.png`
        try {
            await deleteObject(ref(storage, path))
        }
        catch (error) {
            throw Error(error)
        }
    }
}
import React, { useState, useRef, useEffect, createRef } from "react";

import BItem from "../../utils/BItem";
import CoachItem from "../../utils/CoachItem";
import "./timesheet.scss";
import timesheetState from "./timesheetState";
import {
    timesheetRecord,
    calculateTotalAmount,
    formatMoney,
    calculateDuration,
} from "./timesheetUtils";
import { useClientContext } from "../../../providers/ClientProvider/ClientProvider";
import ClientService from "../ClientService";

import OptionalModal from "../../utils/optionalModal";
import { sortTypes, getIcon } from "../sessionNotes/sessionNotesUtils";
import TimesheetRecord from "./timesheetRecord";
import { Box, Button, Divider, Group, Paper, Table, Text, Title } from "@mantine/core";
import { NormalButton, PageMenu, DeleteButton } from "components/utils/MantineUtils";
import { IconArrowBackUp, IconDeviceFloppy, IconEdit, IconPlus } from "@tabler/icons-react";
import { useMediaQuery } from "@mantine/hooks";

export default function TimesheetComponent() {
    const cContext = useClientContext();
    const timesheet = cContext.clientData.timesheet;
    const matching = useMediaQuery("(max-width: 768px)")
    const [height, setHeight] = useState(0);
    const [scrollTop, setScrollTop] = useState(0);
    const [editing, setEditing] = useState(false);
    const [editedTimesheet, setEditedTimesheet] = useState(
        timesheet === undefined ? {} : JSON.parse(JSON.stringify(timesheet))
    );
    const [sort, setSort] = useState(sortTypes.none);
    const [search, setSearch] = useState("");
    const timesheetRef = useRef(null);
    let cs = new ClientService(setEditedTimesheet, setScrollTop);

    const currency =
        cContext.clientData.bio && cContext.clientData.bio.assignment.currency
            ? cContext.clientData.bio.assignment.currency
            : "";

    useEffect(() => {
        if (timesheet === undefined) {
            setEditedTimesheet(timesheetState);
            save();
        }
    }, [timesheet, editedTimesheet]);

    useEffect(() => {
        if (timesheetRef.current) {
            timesheetRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [editedTimesheet]);


    function customFilter(note) {

        const searchU = search.toUpperCase()

        for (let key of Object.keys(note)) {

            if (note[key].toString().toUpperCase().includes(searchU)) {
                return true
            }
        }
        return false
    }

    function dateSort(records) {
        if (sort === sortTypes.none || editing) {
            return records;
        }
        const sorted = records.sort(function (a, b) {
            if (sort === sortTypes.down) {
                return new Date(a.date) - new Date(b.date);
            } else {
                return new Date(b.date) - new Date(a.date);
            }
        });

        return [...sorted];
    }

    function changeSort() {
        const amount = Object.keys(sortTypes).length;
        setSort((prevSort) => {
            if (prevSort + 1 === amount) {
                return 0;
            }
            return prevSort + 1;
        });
    }

    function cancel() {
        setEditedTimesheet(timesheet);
        setEditing(false);
    }

    function save() {
        setEditing(false);
        cContext.saveClientTimesheet(editedTimesheet);
    }
    function deleteInput(cat, id) {
        cs.deleteInput(editedTimesheet, cat, id);
    }
    function addInput(cat) {
        let template = timesheetRecord;
        template.date = new Date().toISOString();
        cs.addInput(editedTimesheet, cat, template);
    }
    function handleChange(data, cat, id, key) {
        cs.handleChange(editedTimesheet, data, cat, id, key);
    }
    function toggleEditing() {
        setEditing(!editing);
    }

    function calculateMoney() {
        let res = 0;
        if (editedTimesheet["R"] === undefined || editedTimesheet["R"].length === 0) {
            return res;
        }
        editedTimesheet["R"].forEach((record) => {
            const duration = calculateDuration(
                record.startTime,
                record.endTime
            );
            if (record.singleCharge) {
                res += parseInt(record.hourlyRate);
            } else {
                const amount = parseInt(
                    calculateTotalAmount(record.hourlyRate, duration)
                );
                res += amount && amount > 0 ? amount : 0;
            }
        });
        return res;
    }
    function getTotalInvoiced() {
        let res = 0;
        if (editedTimesheet["R"] === undefined || editedTimesheet["R"].length === 0) {
            return res
        }
        const filtered = editedTimesheet["R"].filter((record) => {
            return !(record.invoiced === "-" || !record.invoiced);
        });
        filtered.forEach((x) => {
            const amount = calculateTotalAmount(
                x.hourlyRate,
                calculateDuration(x.startTime, x.endTime)
            );
            if (x.singleCharge) {
                res += parseInt(x.hourlyRate);
            } else {
                res += amount && amount > 0 ? amount : 0;
            }
        });
        return res;
    }
    function getTotalPaid() {
        let res = 0;
        if (editedTimesheet["R"] === undefined || editedTimesheet["R"].length === 0) {
            return res
        }
        const filtered = editedTimesheet["R"].filter((record) => {
            const now = new Date();
            const date = new Date(record.paid);
            return record.invoiced !== "-" && now > date;
        });
        filtered.forEach((x) => {
            const amount = calculateTotalAmount(
                x.hourlyRate,
                calculateDuration(x.startTime, x.endTime)
            );
            if (x.singleCharge) {
                res += parseInt(x.hourlyRate);
            } else {
                res += amount && amount > 0 ? amount : 0;
            }
        });
        return res;
    }
    return (
        <>
            <Paper p={'md'} shadow={'lg'} withBorder sx={
                {
                    position: 'sticky',
                    top: 69,
                    padding: '20px',
                    zIndex: 2,
                    transition: 'all 0.3s ease',
                }
            }>
                <PageMenu sectionType={'Coach'} name={'Timesheet'} editing={editing} toggleEditing={toggleEditing} save={save} cancel={cancel} search searchFunction={setSearch}>


                    {editing && (
                        < Group >
                            <Button
                                variant="default"
                                leftIcon={<IconPlus stroke={"3"} size={20} />}
                                onClick={() => {
                                    addInput("R");

                                }}
                            >
                                <Title order={6}>Records</Title>
                            </Button>
                        </Group>)
                    }


                </PageMenu>

            </Paper>
            <Divider my={'lg'} />
            {
                editedTimesheet["R"] &&
                    editedTimesheet["R"].length !== 0 &&
                    matching ? (
                    <Paper p={'md'} shadow={'lg'} withBorder>
                        <Title order={6} tt={'uppercase'}>
                            Timetable is not available on mobile
                        </Title>
                    </Paper>
                ) : (
                    <>
                        <Paper p={'md'} shadow={'lg'} withBorder>
                            <Table striped withBorder highlightOnHover my={'md'}>
                                <thead>
                                    <tr>
                                        <th>
                                            <Title order={6} tt={'uppercase'}>
                                                Total amount to date

                                            </Title>
                                        </th>
                                        <th>
                                            <Title order={6} tt={'uppercase'}>
                                                Total invoiced
                                            </Title>
                                        </th>
                                        <th>
                                            <Title order={6} tt={'uppercase'}>
                                                Total paid
                                            </Title>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <Text>
                                                {formatMoney(
                                                    calculateMoney()
                                                )}{" "}
                                                {currency}
                                            </Text>
                                        </td>
                                        <td>
                                            <Text>
                                                {formatMoney(
                                                    getTotalInvoiced()
                                                )}{" "}
                                                {currency}
                                            </Text>
                                        </td>
                                        <td>
                                            <Text>
                                                {formatMoney(
                                                    getTotalPaid()
                                                )}{" "}
                                                {currency}
                                            </Text>
                                        </td>

                                    </tr>
                                </tbody>
                            </Table>
                        </Paper>
                        <Divider my={'lg'} />
                        <Paper p={'md'} shadow={'lg'} withBorder>
                            <Table striped withBorder highlightOnHover my={'md'}>
                                <thead>
                                    <tr>
                                        <th>
                                            <Title order={6} tt={'uppercase'}>
                                                Date
                                            </Title>
                                        </th>
                                        <th>
                                            <Title order={6} tt={'uppercase'}>
                                                time
                                            </Title>
                                        </th>
                                        <th>
                                            <Title order={6} tt={'uppercase'}>
                                                duration
                                            </Title>
                                        </th>
                                        <th>
                                            <Title order={6} tt={'uppercase'}>
                                                description
                                            </Title>
                                        </th>
                                        <th>
                                            <Title order={6} tt={'uppercase'}>
                                                Single charge
                                            </Title>
                                        </th>
                                        <th>
                                            <Title order={6} tt={'uppercase'}>
                                                Hourly rate
                                            </Title>
                                        </th>
                                        <th>
                                            <Title order={6} tt={'uppercase'}>
                                                Amount (ex. VAT)
                                            </Title>
                                        </th>
                                        <th>
                                            <Title order={6} tt={'uppercase'}>
                                                Invoiced
                                            </Title>
                                        </th>
                                        <th>
                                            <Title order={6} tt={'uppercase'}>
                                                Paid
                                            </Title>
                                        </th>
                                        {editing && (
                                            <th>
                                            </th>
                                        )
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {editedTimesheet["R"] !== undefined && editedTimesheet["R"].length > 0 && dateSort(
                                        editedTimesheet["R"]
                                    ).filter(record => customFilter(record)).map((record, i) => {
                                        return (
                                            <TimesheetRecord
                                                recordData={record}
                                                calculateTotalAmount={
                                                    calculateTotalAmount
                                                }
                                                deleteInput={
                                                    deleteInput
                                                }
                                                editing={editing}
                                                handleChange={
                                                    handleChange
                                                }
                                                key={i}
                                            />
                                        );
                                    })}
                                </tbody>
                            </Table>
                        </Paper>
                        <Box ref={timesheetRef} />
                    </>
                )
            }
            {/* <div className={"Timesheet"}>
                <div className={editing && "overlay"}>
                    <div className="container">
                        <div
                            className={
                                "Timesheet-topbar " +
                                (scrollTop > 0 ? "scrolled" : "")
                            }
                            ref={sticky}
                        >
                            <div className="left">
                                <Header as={"h2"}>Timesheet</Header>
                                <CoachItem>
                                    {!editing ? (
                                        <EditButton click={toggleEditing} />
                                    ) : (
                                        <>
                                            <SaveButton click={save} />
                                            <CancelButton click={cancel} />
                                        </>
                                    )}
                                </CoachItem>
                                <Button icon onClick={() => changeSort()}>
                                    <Icon name={getIcon(sort)}></Icon>
                                </Button>
                            </div>

                            {editing && (
                                <div className="adders">
                                    <Button
                                        onClick={() => {
                                            addInput("R");
                                            cs.scrollToLast(lastRef);
                                        }}
                                    >
                                        <Button.Content visible>
                                            Add record
                                        </Button.Content>
                                    </Button>
                                </div>
                            )}
                        </div>
                        <Divider />
                        <div className="mb-only">
                            This page is only available on desktop
                        </div>
                        <div className={"Records"}>
                            {editedTimesheet["R"] &&
                                editedTimesheet["R"].length !== 0 && (
                                    <>
                                        <Table compact>
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell>
                                                        Total amount to date:
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell>
                                                        Total invoiced
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell>
                                                        Total paid
                                                    </Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>
                                            <Table.Body>
                                                <Table.Row>
                                                    <Table.Cell>
                                                        {formatMoney(
                                                            calculateMoney()
                                                        )}{" "}
                                                        {currency}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {formatMoney(
                                                            getTotalInvoiced()
                                                        )}{" "}
                                                        {currency}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {formatMoney(
                                                            getTotalPaid()
                                                        )}{" "}
                                                        {currency}
                                                    </Table.Cell>
                                                </Table.Row>
                                            </Table.Body>
                                        </Table>
                                        <Table compact>
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell>
                                                        Date
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell>
                                                        Time
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell>
                                                        Duration
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell>
                                                        Description
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell>
                                                        Single charge
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell>
                                                        (Hourly) Rate
                                                    </Table.HeaderCell>

                                                    <Table.HeaderCell>
                                                        Amount ex. VAT
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell>
                                                        Invoiced
                                                    </Table.HeaderCell>
                                                    <Table.HeaderCell>
                                                        Paid
                                                    </Table.HeaderCell>
                                                    {editing && (
                                                        <Table.HeaderCell></Table.HeaderCell>
                                                    )}
                                                </Table.Row>
                                            </Table.Header>
                                            <Table.Body>
                                                {dateSort(
                                                    editedTimesheet["R"]
                                                ).map((record, i) => {
                                                    return (
                                                        <TimesheetRecord
                                                            recordData={record}
                                                            calculateTotalAmount={
                                                                calculateTotalAmount
                                                            }
                                                            deleteInput={
                                                                deleteInput
                                                            }
                                                            editing={editing}
                                                            handleChange={
                                                                handleChange
                                                            }
                                                            key={i}
                                                        />
                                                    );
                                                })}
                                            </Table.Body>
                                            <div ref={lastRef}></div>
                                        </Table>
                                    </>
                                )}
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    );
}

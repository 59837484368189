import { doc, getDoc, where, collection, getDocs, query, updateDoc } from 'firebase/firestore'
import { db } from '../../firebase'


export async function saveLogs(client_id, { lastEdited, lastLogin }) {
    // const ref = doc(db, 'users', client_id)
    // let result = {}
    // const logs = await getLogs(client_id)
    // if (logs) {
    //     result = logs
    // }
    // if (lastEdited) {
    //     result.lastEdited = lastEdited
    // }
    // if (lastLogin) {
    //     result.lastLogin = lastLogin
    // }
    // await updateDoc(ref, { "logs": result })
}
export async function getLogs(client_id) {
    // const docRef = doc(db, 'users', client_id)
    // const docSnap = await getDoc(docRef)
    // if (docSnap.exists()) {
    //     const data = docSnap.data()
    //     const logs = data['logs']
    //     return logs
    // }
}
import { BLANK_PDF } from "@pdfme/generator";

import basePDFs from "./basePDFs";
const inputs = [
    {
        Coachcompany: "",
        CoachstreetAndNumber: "",
        CoachzipCodeAndCityAndCountry: "",
        CoachUID: "",
        Coachlogo: "",
        Companyname: "",
        CompanystreetAndNumber: "",
        ClientzipAndCity: "",
        Clientcountry: "",
        Companycontact: "",
        CompanyAccountNrTitle: "Account Number",
        CompanyAccountNr: "",
        CompanyAccountPONumberTitle: "PO Number",
        CompanyAccountPONumber: "",
        Invoiceperiod: "",
        Invoiceid: "",
        Invoicedate: "",
        Coachname: "",
        ClientpaymentTerms: "",
        InvoicepayableUntil: "",
        InvoicetotalEXVAT: "1000.00",
        InvoicevatPercentage: "7.7%",
        InvoiceVAT: "77.00",
        InvoicetotalINCVAT: "1077.00",
        Invoicecurrency: "",
        Invoicecurrency2: "",
        Invoicecurrency3: "",
        CoachBankDetails: "",
        Additionalinfo: "asfdasdfasdfasdf",
    }
];

const templateGeneral = {
    schemas: [
        {
            Coachcompany: {
                type: "text",
                position: {
                    x: 15,
                    y: 10
                },
                width: 90,
                height: 5,
                alignment: "left",
                fontSize: 10,
                characterSpacing: 0,
                lineHeight: 1
            },
            CoachstreetAndNumber: {
                type: "text",
                position: {
                    x: 15,
                    y: 15
                },
                width: 90,
                height: 5,
                alignment: "left",
                fontSize: 10,
                characterSpacing: 0,
                lineHeight: 1
            },
            CoachzipCodeAndCityAndCountry: {
                type: "text",
                position: {
                    x: 15,
                    y: 20
                },
                width: 90,
                height: 5,
                alignment: "left",
                fontSize: 10,
                characterSpacing: 0,
                lineHeight: 1
            },
            CoachUID: {
                type: "text",
                position: {
                    x: 15,
                    y: 25
                },
                width: 90,
                height: 5,
                alignment: "left",
                fontSize: 10,
                characterSpacing: 0,
                lineHeight: 1,
                fontColor: "#000"
            },
            Coachlogo: {
                type: "image",
                position: {
                    x: 114,
                    y: 10
                },
                width: 82,
                height: 30,
                alignment: "left",
                fontSize: 13,
                characterSpacing: 0,
                lineHeight: 1
            },
            Companyname: {
                type: "text",
                position: {
                    x: 65,
                    y: 52
                },
                width: 55,
                height: 10,
                alignment: "left",
                fontSize: 10,
                characterSpacing: 0,
                lineHeight: 1,
                fontColor: "#000"
            },
            CompanystreetAndNumber: {
                type: "text",
                position: {
                    x: 65,
                    y: 62
                },
                width: 55,
                height: 5,
                alignment: "left",
                fontSize: 10,
                characterSpacing: 0,
                lineHeight: 1,
                fontColor: "#000"
            },
            ClientzipAndCity: {
                type: "text",
                position: {
                    x: 65,
                    y: 67
                },
                width: 55,
                height: 5,
                alignment: "left",
                fontSize: 10,
                characterSpacing: 0,
                lineHeight: 1,
                fontColor: "#000"
            },
            Clientcountry: {
                type: "text",
                position: {
                    x: 65,
                    y: 72
                },
                width: 55,
                height: 5,
                alignment: "left",
                fontSize: 10,
                characterSpacing: 0,
                lineHeight: 1,
                fontColor: "#000"
            },
            Companycontact: {
                type: "text",
                position: {
                    x: 122.00,
                    y: 52
                },
                width: 55,
                height: 5,
                alignment: "left",
                fontSize: 10,
                characterSpacing: 0,
                lineHeight: 1,
                fontColor: "#000"
            },
            CompanyAccountNrTitle: {
                type: "text",
                position: {
                    x: 122.00,
                    y: 57
                },
                width: 30,
                height: 5,
                alignment: "left",
                fontSize: 10,
                characterSpacing: 0,
                lineHeight: 1,
                fontColor: "#000"
            },
            CompanyAccountNr: {
                type: "text",
                position: {
                    x: 152.00,
                    y: 57
                },
                width: 46,
                height: 5,
                alignment: "left",
                fontSize: 10,
                characterSpacing: 0,
                lineHeight: 1,
                fontColor: "#000"
            },
            CompanyAccountPONumberTitle: {
                type: "text",
                position: {
                    x: 122.00,
                    y: 62
                },
                width: 30,
                height: 5,
                alignment: "left",
                fontSize: 10,
                characterSpacing: 0,
                lineHeight: 1,
                fontColor: "#000"
            },
            CompanyAccountPONumber: {
                type: "text",
                position: {
                    x: 152.00,
                    y: 62
                },
                width: 46,
                height: 5,
                alignment: "left",
                fontSize: 10,
                characterSpacing: 0,
                lineHeight: 1,
                fontColor: "#000"
            },
            Invoiceperiod: {
                type: "text",
                position: {
                    x: 65,
                    y: 78
                },
                width: 55,
                height: 5,
                alignment: "left",
                fontSize: 10,
                characterSpacing: 0,
                lineHeight: 1,
                fontColor: "#000"
            },
            Invoiceid: {
                type: "text",
                position: {
                    x: 65,
                    y: 87.09
                },
                width: 55,
                height: 5,
                alignment: "left",
                fontSize: 10,
                characterSpacing: 0,
                lineHeight: 1,
                fontColor: "#000"
            },
            Invoicedate: {
                type: "text",
                position: {
                    x: 65,
                    y: 94.97
                },
                width: 55,
                height: 5,
                alignment: "left",
                fontSize: 10,
                characterSpacing: 0,
                lineHeight: 1,
                fontColor: "#000"
            },
            Coachname: {
                type: "text",
                position: {
                    x: 65,
                    y: 103.12
                },
                width: 55,
                height: 5,
                alignment: "left",
                fontSize: 10,
                characterSpacing: 0,
                lineHeight: 1,
                fontColor: "#000"
            },
            ClientpaymentTerms: {
                type: "text",
                position: {
                    x: 65,
                    y: 111
                },
                width: 55,
                height: 5,
                alignment: "left",
                fontSize: 10,
                characterSpacing: 0,
                lineHeight: 1,
                fontColor: "#000"
            },
            InvoicepayableUntil: {
                type: "text",
                position: {
                    x: 152.59,
                    y: 111.47
                },
                width: 42.83,
                height: 5,
                alignment: "left",
                fontSize: 10,
                characterSpacing: 0,
                lineHeight: 1,
                fontColor: "#000"
            },
            InvoicetotalEXVAT: {
                type: "text",
                position: {
                    x: 155,
                    y: 128
                },
                width: 40,
                height: 4.99,
                alignment: "right",
                fontSize: 10,
                characterSpacing: 0,
                lineHeight: 1,
                fontColor: "#000"
            },
            InvoicevatPercentage: {
                type: "text",
                position: {
                    x: 104.25,
                    y: 132.5
                },
                width: 15,
                height: 5,
                alignment: "left",
                fontSize: 10,
                characterSpacing: 0,
                lineHeight: 1,
                fontColor: "#000"
            },
            InvoiceVAT: {
                type: "text",
                position: {
                    x: 155,
                    y: 132.75
                },
                width: 40,
                height: 4.99,
                alignment: "right",
                fontSize: 10,
                characterSpacing: 0,
                lineHeight: 1,
                fontColor: "#000"
            },
            InvoicetotalINCVAT: {
                type: "text",
                position: {
                    x: 155.21,
                    y: 137.72
                },
                width: 40,
                height: 4.99,
                alignment: "right",
                fontSize: 10,
                characterSpacing: 0,
                lineHeight: 1,
                fontColor: "#000"
            },
            Invoicecurrency: {
                type: "text",
                position: {
                    x: 140,
                    y: 128
                },
                width: 15,
                height: 5,
                alignment: "left",
                fontSize: 10,
                characterSpacing: 0,
                lineHeight: 1,
                fontColor: "#000"
            },
            Invoicecurrency2: {
                type: "text",
                position: {
                    x: 140,
                    y: 133
                },
                width: 15,
                height: 5,
                alignment: "left",
                fontSize: 10,
                characterSpacing: 0,
                lineHeight: 1,
                fontColor: "#000"
            },
            Invoicecurrency3: {
                type: "text",
                position: {
                    x: 140,
                    y: 138
                },
                width: 15,
                height: 5,
                alignment: "left",
                fontSize: 10,
                characterSpacing: 0,
                lineHeight: 1,
                fontColor: "#000"
            },
            CoachBankDetails: {
                type: "text",
                position: {
                    x: 65,
                    y: 170
                },
                width: 80,
                height: 40,
                alignment: "left",
                fontSize: 10,
                characterSpacing: 0,
                lineHeight: 2,
                fontColor: "#000"
            },
            Pages: {
                type: "text",
                position: {
                    x: 186.00,
                    y: 284.00
                },
                width: 12.00,
                height: 6.12,
                fontSize: 10
            },
            Additionalinfo: {
                type: "text",
                position: {
                    x: 12.75,
                    y: 284.00,
                },
                width: 185.00,
                height: 5.00,
                fontSize: 10,
                alignment: 'center'
            }
        }
    ],
    basePdf: basePDFs.InvoiceGeneralBase
};
const templateRecords = {
    schemas: [
        {
            Date: {
                type: "text",
                position: {
                    x: 13,
                    y: 28
                },
                width: 22,
                height: 30,
                alignment: "left",
                fontSize: 10,
                characterSpacing: 0,
                lineHeight: 1.5
            },
            Time: {
                type: "text",
                position: {
                    x: 36,
                    y: 28
                },
                width: 21,
                height: 30,
                alignment: "left",
                fontSize: 10,
                characterSpacing: 0,
                lineHeight: 1.2
            },
            Duration: {
                type: "text",
                position: {
                    x: 59,
                    y: 28
                },
                width: 21,
                height: 30,
                alignment: "left",
                fontSize: 10,
                characterSpacing: 0,
                lineHeight: 1.2
            },
            Description: {
                type: "text",
                position: {
                    x: 78,
                    y: 28
                },
                width: 50,
                height: 30,
                alignment: "left",
                fontSize: 10,
                characterSpacing: 0,
                lineHeight: 1.2
            },
            SingleCharge: {
                type: "text",
                position: {
                    x: 129,
                    y: 28
                },
                width: 22,
                height: 30,
                alignment: "left",
                fontSize: 11,
                characterSpacing: 0,
                lineHeight: 1.5
            },
            Rate: {
                type: "text",
                position: {
                    x: 151,
                    y: 28
                },
                width: 22,
                height: 30,
                alignment: "right",
                fontSize: 10,
                characterSpacing: 0,
                lineHeight: 1.5
            },
            Total: {
                type: "text",
                position: {
                    x: 174.00,
                    y: 28
                },
                width: 23,
                height: 30,
                alignment: "right",
                fontSize: 10,
                characterSpacing: 0,
                lineHeight: 1.5
            },
            Pages: {
                type: "text",
                position: {
                    x: 186.00,
                    y: 284.00
                },
                width: 12.00,
                height: 6.12,
                fontSize: 10
            },
            Additionalinfo: {
                type: "text",
                position: {
                    x: 12.75,
                    y: 284.00,
                },
                width: 185.00,
                height: 5.00,
                fontSize: 10,
                alignment: 'center'
            }
        }
    ],
    basePdf: basePDFs.InvoiceRecordsBase
}
const recordInputs = [
    {
        Date: "13 OCT 22",
        Time: "18:00 - 20:00",
        Duration: "4 hours",
        Description: "Various Coaching sessions by Bjorn Schigt",
        SingleCharge: "No",
        Rate: "250",
        Total: "250",
        Additionalinfo: "dafasdfasdfasdf",
        Pages: ""

    }
]
export default {
    inputs: inputs,
    templateGeneral: templateGeneral,
    recordInputs: recordInputs,
    templateRecords: templateRecords,
}


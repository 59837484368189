import "./resetPassword.scss"
import React, { useContext, useRef } from 'react'
import { useState } from 'react'
import { useNavigate, useSearchParams, useParams, Navigate } from 'react-router-dom';
import { passwordService } from "../../services/passwordService";
import { useAlertsContext } from "../../providers/Alerts/AlertsProvider";
import { AppShell, BackgroundImage, Box, Center, Container, PasswordInput, Stack, TextInput, Header, Title, Button } from "@mantine/core";
import { Form, useForm } from "@mantine/form";
import CustomHeader from "components/header/header";
import { useSelector } from "react-redux";
export default function ResetPasswordPage() {
    const alertsProvider = useAlertsContext()
    const nav = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams();
    const primaryColor = useSelector(state => state.branding.branding.primaryColor)
    const code = searchParams.get('oobCode')

    function addAlert(errorCode, errorMessage) {
        alertsProvider.addAlert({ header: errorCode, content: errorMessage, timeout: 2000, variation: 'error' })
    }

    const onSubmit = async (password, confirmPassword) => {
        if (code) {
            if (password === confirmPassword) {
                passwordService.resetPassword(code, password, addAlert).then((x) => {
                    alertsProvider.addAlert({ content: 'Reset password succesfully!', timeout: 2000, variation: 'positive' })
                    nav('/')
                }).catch((error) => {
                    console.log(error)
                })
            } else {
                alertsProvider.addAlert({ content: 'Passwords do not match', timeout: 2000, variation: 'error' })

            }
        } else {
            alertsProvider.addAlert({ content: 'Wrong url!', timeout: 2000, variation: 'error' })
        }
    }


    const form = useForm({
        initialvalues: {
            password: '',
            confirmPassword: '',
        }
    })

    console.log(primaryColor)



    return (
        <AppShell
            padding={0}
            sx={{
                backgroundColor: primaryColor,
            }}
            header={

                <Header
                    height={80}
                    sx={(theme) => ({
                        backgroundColor: primaryColor,
                    })}
                >
                    <CustomHeader />
                </Header>
            }
            styles={(theme) => ({
                main: {
                    backgroundColor:
                        theme.colorScheme === "dark"
                            ? theme.colors.dark[8]
                            : theme.colors.gray[0],
                },
            })}
        >
            <BackgroundImage src={require("../../assets/background-3.jpg")}  >
                <Box p={'xl'} sx={{
                    height: 'calc(100vh - 70px)',
                    backgroundColor: 'rgba(0,0,0,0.25)',
                }}>
                    <Container px={'lg'}>
                        <Center px='lg' width={500} >
                            <Stack>
                                <Title tt={'uppercase'} color={'white'} order={1} align={'left'}>(Re)set your password</Title>
                                <form onSubmit={form.onSubmit(({ password, confirmPassword }) => onSubmit(password, confirmPassword))} style={{ width: '400px' }}>
                                    <PasswordInput
                                        label={<Title order={6} color={'white'}>Password</Title>}
                                        placeholder="your@email.com"
                                        {...form.getInputProps('password')}
                                        mb={'md'}
                                    />
                                    <PasswordInput
                                        label={<Title order={6} color={'white'}>Confirm Password</Title>}
                                        placeholder="Firstname Lastname"
                                        {...form.getInputProps('confirmPassword')}
                                        mb={'md'}
                                    />


                                    <Button type="submit" my={'md'} >Change password</Button>
                                </form>
                            </Stack>
                        </Center>
                    </Container>
                </Box>
            </BackgroundImage>
        </AppShell >
    )
}

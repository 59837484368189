import React, { useEffect, useState, createRef, useRef } from 'react'


import { useClientContext } from '../../../providers/ClientProvider/ClientProvider'
import ClientService from '../ClientService'
import './documentStorage.scss'
import { useFileContext } from '../../../providers/FileProvider/FileProvider'
import { CancelButton, DeleteFButton, SaveButton } from '../../utils/buttons/buttons'
import { Group, Paper, Title, Button, LoadingOverlay, Table, Grid, Box, Modal, ActionIcon, TextInput, ScrollArea, Tooltip } from '@mantine/core'
import { DeleteButton, NormalButton, PageMenu } from 'components/utils/MantineUtils'
import { IconArrowBackUp, IconDeviceFloppy, IconDownload, IconEdit, IconFile, IconFolder, IconFolderCancel, IconFolderOpen, IconPlus, IconTrash } from '@tabler/icons-react'
import CoachItem from 'components/utils/CoachItem'
import { useSelector } from 'react-redux'
// import { Dropzone } from '@mantine/dropzone'
export default function DocumentStorageComponent(props) {


    const fProvider = useFileContext()

    const cContext = useClientContext()

    const [editing, setEditing] = useState(true)
    const [addingFolder, setAddingFolder] = useState(false)
    const [newFolderName, setNewFolderName] = useState("")
    const [update, setUpdate] = useState(false)
    const [dS, setDS] = useState({ client: [], coach: [], shared: [] })
    const [selectedFile, setSelectedFile] = useState(null)
    const [height, setHeight] = useState(0)
    const [scrollTop, setScrollTop] = useState(0)
    const [currentFolder, setCurrentFolder] = useState(null)
    // const sticky = createRef()
    const hiddenFileInput = useRef(null)
    const hiddenDownload = useRef(null)
    const drop = useRef(null)
    let cs = new ClientService(null, setScrollTop)
    const { role, name } = useSelector((state) => state.firestoreUser.userData)





    useEffect(() => {
        window.addEventListener('scroll', (e) => handleScroll(e), true)
        fProvider.getAllDocuments().then((data) => {
            setDS(data)
        })
        drop.current.addEventListener('dragover', handleDragOver);
        drop.current.addEventListener('drop', handleDrop);
    }, [])



    useEffect(() => {
        if (update) {

            cContext.setLoading(true)

            fProvider.getAllDocuments().then((data) => {
                setDS(data)
                cContext.setLoading(false)
                setUpdate(false)
            })
        }
    }, [update])

    function handleDragOver(e) {
        e.preventDefault()
        e.stopPropagation()
    }

    function selectFolder(newfolder) {
        setCurrentFolder(newfolder)
        cancel()
    }
    function handleChange(data) {
        setNewFolderName(data.value)
    }
    function cancel() {
        setAddingFolder(false)
        setNewFolderName('')
    }
    function save() {
        if (newFolderName !== null) {
            addFolder(newFolderName)

        }
        cancel()
    }
    async function handleDrop(e) {
        e.preventDefault()
        e.stopPropagation()
        const { files } = e.dataTransfer
        if (files && files.length && currentFolder && currentFolder.path) {
            fProvider.addDocuments(files, currentFolder).then(() => {
                setUpdate(true)
            })
        }
    }

    function handleScroll(event) {
        cs.handleScroll(event, setScrollTop)
    }

    async function getDocument(file, role) {
        fProvider.getDocument(file, role)
    }
    function changeEditing(role) {
        const old = editing[role]
        setEditing((prevState) => ({ ...prevState, [role]: !old })
        )
    }

    function addDocuments() {
        hiddenFileInput.current.click()
    }
    function addFolder(name) {
        if (currentFolder) {
            fProvider.addFolder(currentFolder, name).then(() => {
                setUpdate(true)
            })
        }
    }
    function tryAddFolder() {
        if (currentFolder.path === 'shared' || currentFolder.path === role || currentFolder.path.split("/")[1] === 'shared' || currentFolder.path.split("/")[1] === role) {
            setAddingFolder(true)
        }
    }
    async function deleteFolder(folder, role) {
        folder.delete(role).then(() => {
            setUpdate(true)
        })
    }
    async function deleteDocument(file, role) {
        file.delete(role).then(() => {
            setUpdate(true)

        })
    }

    async function onFileChange(event) {
        const files = event.target.files
        if (files && currentFolder) {
            fProvider.addDocuments(files, currentFolder).then(() => {
                setUpdate(true)
            })
        }
    }
    return (
        <>
            <Modal opened={addingFolder} onClose={() => cancel()}
                centered
                title={
                    <Group position='apart'>
                        <Title
                            order={5}
                            color={"gray.9"}
                            tt={"uppercase"}
                        // py={0}
                        // my={"xs"}

                        >
                            Add a folder to {currentFolder ? currentFolder.name : ""}
                        </Title>
                        <Group>
                            <NormalButton fn={save} color={'green'} >
                                <IconDeviceFloppy />

                            </NormalButton>
                            <NormalButton fn={cancel} color={'orange'} >
                                <IconArrowBackUp />

                            </NormalButton>
                        </Group>
                    </Group>
                }
            >
                <TextInput
                    label="Folder name"
                    placeholder="Folder name"
                    value={newFolderName}
                    onChange={(e) => handleChange(e.target)}
                />
            </Modal>
            <Paper p={'md'} shadow='lg' withBorder sx={
                {
                    position: 'sticky',
                    top: 69,
                    padding: '20px',
                    zIndex: 2,
                    transition: 'all 0.3s ease',
                }
            }>
                <PageMenu sectionType={'All'} name={'document storage'}  >

                    {currentFolder && (currentFolder.path === 'shared' || currentFolder.path === role || currentFolder.path.split("/")[1] === 'shared' || currentFolder.path.split("/")[1] === role) &&
                        <>
                            <Button
                                variant="default"
                                leftIcon={<IconPlus stroke={"3"} size={20} />}
                                onClick={() => {
                                    addDocuments()
                                }}
                            >
                                <Title order={6}>Document</Title>
                            </Button>
                            <Button
                                variant="default"
                                leftIcon={<IconPlus stroke={"3"} size={20} />}
                                onClick={() => {
                                    tryAddFolder()
                                }}
                            >
                                <Title order={6}>Folder</Title>
                            </Button></>
                    }
                </PageMenu>
                {/* <Group position='apart'>
                    <Title py={"md"} order={3} tt={"uppercase"}>
                        Document storage
                    </Title>
                    {
                        currentFolder && (currentFolder.path === 'shared' || currentFolder.path === role || currentFolder.path.split("/")[1] === 'shared' || currentFolder.path.split("/")[1] === role) &&
                        < Group >
                            <Button
                                variant="default"
                                leftIcon={<IconPlus stroke={"3"} size={20} />}
                                onClick={() => {
                                    addDocuments()
                                }}
                            >
                                <Title order={6}>Document</Title>
                            </Button>
                            <Button
                                variant="default"
                                leftIcon={<IconPlus stroke={"3"} size={20} />}
                                onClick={() => {
                                    tryAddFolder()
                                }}
                            >
                                <Title order={6}>Folder</Title>
                            </Button>
                        </Group>

                    }

                </Group> */}

            </Paper>
            {
                dS !== undefined &&
                (
                    <Paper p={'md'} my={'md'} shadow='lg' withBorder ref={drop} >
                        <input type="file" ref={hiddenFileInput} style={{ display: 'none' }} multiple onChange={(e) => onFileChange(e)} />
                        <a href="abc" ref={hiddenDownload} download="filename" ></a>
                        <LoadingOverlay visible={update} overlayBlur={3} />
                        {
                            dS.client?.length === 0 && dS.shared?.length === 0 && dS.coach?.length === 0 ?
                                <Title order={5} >No documents</Title>
                                :
                                <Grid grow gutter={0}>
                                    <Grid.Col span={currentFolder == null ? 12 : 6}>

                                        <Table
                                            h={'100%'}
                                            striped
                                            highlightOnHover withBorder p={'md'}
                                        >
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <Title order={5} >FOLDERS</Title>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <ScrollArea h={'50vh'} type={'auto'}>
                                                <tbody>
                                                    <FolderStructure data={dS} selectFolder={(folder) => selectFolder(folder)} deleteFolder={(folder, role) => deleteFolder(folder, role)} />
                                                </tbody>
                                            </ScrollArea>

                                        </Table>
                                    </Grid.Col>
                                    {currentFolder !== null &&
                                        <Grid.Col span={5}>
                                            <Table
                                                h={'100%'}
                                                // striped
                                                highlightOnHover withBorder
                                            >
                                                <thead

                                                >
                                                    <tr >
                                                        <th >
                                                            <Title order={5} tt='uppercase' >{currentFolder.name}</Title>

                                                        </th>
                                                    </tr>
                                                </thead>
                                                <ScrollArea h={'50vh'} type={'auto'}>
                                                    <tbody>
                                                        <File key={currentFolder} folder={currentFolder} deleteFile={(file, role) => deleteDocument(file, role)} />

                                                    </tbody>

                                                </ScrollArea>
                                            </Table>
                                        </Grid.Col>
                                    }

                                </Grid>

                        }
                    </Paper>
                )
            }

        </>
        // <div className='DocumentStorage'>
        //     <div className={"DocumentStorage-topbar " + (scrollTop > height ? 'scrolled' : '')} ref={sticky}>
        //         <div className="left">
        //             <Header as={'h2'}>Document Storage</Header>
        //         </div>

        //         {editing &&
        //             <div className="adders">
        //                 <Button color='green' animated onClick={() => { addDocuments() }}>

        //                     <Button.Content visible >Add Document </Button.Content> <Button.Content hidden>
        //                         <Icon name='plus' />
        //                     </Button.Content>

        //                 </Button>
        //                 <Button color='green' animated onClick={() => tryAddFolder()}>

        //                     <Button.Content visible >Add Folder </Button.Content> <Button.Content hidden>
        //                         <Icon name='plus' />
        //                     </Button.Content>

        //                 </Button>
        //                 {addingFolder &&
        //                     <>
        //                         <Form>
        //                             <Input type='text' value={newFolderName} onChange={(e) => handleChange(e.target)}></Input>
        //                         </Form>
        //                         <SaveButton click={save} />
        //                         <CancelButton click={cancel} />
        //                     </>
        //                 }

        //             </div>

        //         }
        //     </div>
        //     <Divider />
        //     <div className="DocumentStorage-content DragAndDrop__area" ref={drop}>
        //         {dS !== undefined &&
        //             <>
        //                 <input type="file" ref={hiddenFileInput} style={{ display: 'none' }} multiple onChange={(e) => onFileChange(e)} />
        //                 <a href="abc" ref={hiddenDownload} download="filename" ></a>

        //                 {update ? <Segment>
        //                     <Dimmer active inverted>
        //                         <Loader>Loading</Loader>
        //                     </Dimmer>

        //                     <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
        //                     <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
        //                 </Segment>
        //                     : dS.client?.length === 0 && dS.coach?.length === 0 ?
        //                         <Segment placeholder>
        //                             <Header icon>
        //                                 <Icon name='pdf file outline' />
        //                                 No documents are shared yet. <br />
        //                                 Drag and drop files to upload!
        //                             </Header>
        //                         </Segment> :
        //                         <div className={`panes${currentFolder == null ? '-fullsize' : ''}`}>
        //                             <div>
        //                                 <Table>
        //                                     <Table.Header>
        //                                         <Table.Row>
        //                                             <Table.HeaderCell colSpan='1'>Folders </Table.HeaderCell>
        //                                         </Table.Row>
        //                                     </Table.Header>
        //                                 </Table>
        //                                 <FolderStructure data={dS} selectFolder={(folder) => selectFolder(folder)} deleteFolder={(folder, role) => deleteFolder(folder, role)} />
        //                                 {/* <Folder folder={dS.client} selectFolder={(folder) => selectFolder(folder)} />

        //                                 <Folder folder={dS.coach} selectFolder={(folder) => selectFolder(folder)} /> */}
        //                             </div>




        //                             {currentFolder &&
        //                                 <File key={currentFolder} folder={currentFolder} deleteFile={(file, role) => deleteDocument(file, role)} />}

        //                         </div>
        //                 }</>}
        //     </div>
        // </div >
    )
}
function File({ folder, deleteFile }) {
    const { getSharedFile } = useFileContext()
    const primaryColor = useSelector((state) => state.branding.branding.primaryColor)
    return (
        <>
            {folder && folder.files.filter((f) => f.name !== '.ghostfile').map((file, i) =>
                <tr  >
                    <td>
                        <Box
                            // onClick={() => openFolder(data.client)}
                            p='md'
                            sx={(theme) => ({
                                cursor: 'pointer',
                                '&:hover': {
                                    color: primaryColor
                                }
                            })}
                        >

                            <Group>
                                <IconFile />
                                <Box w={200}>
                                    <Tooltip label={file.name} placement='top'>
                                        <Title truncate tt={'uppercase'} order={6} >
                                            {file.name}
                                        </Title>
                                    </Tooltip>
                                </Box>
                                <Tooltip label={'Download file'} placement='top'>
                                    <IconDownload onClick={(() => file.download())} />
                                </Tooltip>
                                <Tooltip label={'Delete file'} placement='top'>
                                    <DeleteButton click={() => deleteFile(file, file.path.split("/")[1])} message={'This will delete the file'} />

                                </ Tooltip>
                            </Group>
                        </Box>
                    </td>
                </tr>
            )}
        </>
        // <Table className='filec'>
        //     <Table.Header>
        //         <Table.Row>
        //             <Table.HeaderCell colSpan='1'>Files in {folder.name} </Table.HeaderCell>
        //         </Table.Row>
        //     </Table.Header>
        //     <Table.Body>
        //         {folder && folder.files.filter((f) => f.name !== '.ghostfile').map((file, i) =>

        //             <Table.Row key={i} className={'file'} >
        //                 <Table.Cell>
        //                     <div>
        //                         <Icon name='file' />{file.name}
        //                         <Icon name='download' onClick={() => file.download()} />
        //                         <DeleteFButton click={() => deleteFile(file, file.path.split("/")[1])} />
        //                     </div>
        //                 </Table.Cell>
        //             </Table.Row>
        //         )}

        //     </Table.Body>

        // </Table>
    )
}
function FolderStructure({ data, selectFolder, deleteFolder }) {
    const [folderStructure, setFolderStructure] = useState({})
    const primaryColor = useSelector((state) => state.branding.branding.primaryColor)
    useEffect(() => {
        if (data) {
            const newFolderStructure = {}
            if (data.client) {
                data.client.folders.map((f, j) => {
                    newFolderStructure[f.path] = false
                }
                )

            }
            if (data.coach) {
                data.coach.folders.map((f, j) => {
                    newFolderStructure[f.path] = false
                }
                )

            }
            if (data.shared) {

                data.shared.folders.map((f, j) => {
                    newFolderStructure[f.path] = false
                }
                )
            }
            setFolderStructure(newFolderStructure)

        }
    }, [])


    function openFolder(folder) {
        const path = folder.path

        const newValue = !folderStructure[path]
        const newValues = {}
        newValues[path] = newValue

        if (!newValue) {
            selectFolder(null)
            for (const [key, value] of Object.entries(folderStructure)) {
                if (key.includes(path) && key !== path) {
                    newValues[key] = false;
                }
            }
        }
        else {
            selectFolder(folder)
            for (const [key, value] of Object.entries(folderStructure)) {
                if (!path.includes(key) && key !== path) {
                    newValues[key] = false
                }
            }
        }
        setFolderStructure(prevState => (
            { ...prevState, ...newValues }
        ))
    }

    return (
        <>

            <tr style={{ 'height': '40px' }} >
                <td

                >
                    <Box
                        onClick={() => openFolder(data.client)}
                        p='md'
                        sx={(theme) => ({
                            cursor: 'pointer',
                            color: folderStructure['client'] ? primaryColor : 'black',
                            '&:hover': {
                                color: primaryColor
                            }
                        })}
                    >
                        <Group>
                            {
                                folderStructure['client'] ?
                                    <IconFolderOpen /> :
                                    <IconFolder />
                            }
                            <Title tt={'uppercase'} order={6} >
                                {data.client.name}
                            </Title>
                        </Group>
                    </Box>
                </td>
            </tr>
            {folderStructure['client'] && data.client.folders.map((f, j) =>
                <Folder deleteFolder={deleteFolder} folder={f} openFolder={openFolder} structure={folderStructure} key={f.name} depth={1} />
            )}
            <tr  >
                <td
                    style={{ 'height': '40px' }}
                >
                    <Box
                        onClick={() => openFolder(data.coach)}
                        p='md'
                        sx={(theme) => ({
                            cursor: 'pointer',
                            color: folderStructure['coach'] ? primaryColor : 'black',
                            '&:hover': {
                                color: primaryColor

                            }
                        })}
                    >

                        <Group>
                            {
                                folderStructure['coach'] ?
                                    <IconFolderOpen /> :
                                    <IconFolder />
                            }
                            <Title tt={'uppercase'} order={6} >
                                {data.coach.name}
                            </Title>
                        </Group>
                    </Box>
                </td>
            </tr   >
            {folderStructure['coach'] && data.coach.folders.map((f, j) =>
                <Folder deleteFolder={deleteFolder} folder={f} openFolder={openFolder} structure={folderStructure} key={f.name} depth={1} />
            )}

            <tr  >
                <td
                    style={{ 'height': '40px' }}
                >
                    <Box
                        onClick={() => openFolder(data.shared)}
                        p='md'
                        sx={(theme) => ({
                            cursor: 'pointer',
                            color: folderStructure['shared'] ? primaryColor : 'black',
                            '&:hover': {
                                color: primaryColor
                            }
                        })}
                    >
                        <Group>
                            {
                                folderStructure['shared'] ?
                                    <IconFolderOpen /> :
                                    <IconFolder />
                            }
                            <Title tt={'uppercase'} order={6} >
                                {data.shared.name}
                            </Title>
                        </Group>
                    </Box>
                </td>
            </tr>
            {folderStructure['shared'] && data.shared.folders.map((f, j) =>
                <Folder deleteFolder={deleteFolder} folder={f} openFolder={openFolder} structure={folderStructure} key={f.name} depth={1} />
            )}
        </>
        // <Table celled className='folder'>
        //     <Table.Body>
        //         <Table.Row className='folder' onClick={() => openFolder(data.client)}>
        //             <Table.Cell className={`${folderStructure['client'] ? 'selectedFolder' : ''}`}>
        //                 {folderStructure['client'] ? <Icon name='folder open' /> : <Icon name='folder' />}
        //                 {data.client.name}
        //             </Table.Cell>
        //         </Table.Row>
        //         {folderStructure['client'] && data.client.folders.map((f, j) =>
        //             <Folder deleteFolder={deleteFolder} folder={f} openFolder={openFolder} structure={folderStructure} key={f.name} depth={1} />
        //         )}
        //         <Table.Row className='folder' onClick={() => openFolder(data.coach)}>
        //             <Table.Cell className={`${folderStructure['coach'] ? 'selectedFolder' : ''}`}>
        //                 {folderStructure['coach'] ? <Icon name='folder open' /> : <Icon name='folder' />}
        //                 {data.coach.name}
        //             </Table.Cell>
        //         </Table.Row>
        //         {folderStructure['coach'] && data.coach.folders.map((f, j) =>
        //             <Folder deleteFolder={deleteFolder} folder={f} openFolder={openFolder} structure={folderStructure} key={f.name} depth={1} />
        //         )}
        //         <Table.Row className='folder' onClick={() => openFolder(data.shared)}>
        //             <Table.Cell className={`${folderStructure['shared'] ? 'selectedFolder' : ''}`}>
        //                 {folderStructure['shared'] ? <Icon name='folder open' /> : <Icon name='folder' />}
        //                 {data.shared.name}
        //             </Table.Cell>
        //         </Table.Row>
        //         {folderStructure['shared'] && data.shared.folders.map((f, j) =>
        //             <Folder deleteFolder={deleteFolder} folder={f} openFolder={openFolder} structure={folderStructure} key={f.name} depth={1} />
        //         )}
        //     </Table.Body>
        // </Table>
    )


}
function Folder({ folder, openFolder, depth, structure, deleteFolder }) {
    const primaryColor = useSelector((state) => state.branding.branding.primaryColor)
    const indent = `${depth * 1}rem`


    function del() {
        deleteFolder(folder, folder.path.split("/")[1])
    }

    return (
        <>
            <tr  >
                <td>
                    <Box p='md' onClick={() => openFolder(folder)} sx={(theme) => ({
                        cursor: 'pointer',
                        color: structure[folder.path] ? primaryColor : 'black',
                        marginLeft: indent,
                        '&:hover': {
                            color: primaryColor
                        }

                    })}>
                        <Group>
                            {structure[folder.path] ?
                                <IconFolderOpen /> :
                                <IconFolder />}
                            <Title tt={'uppercase'} order={6} >
                                {folder.name}
                            </Title>
                            <Tooltip label={'Delete folder'} placement='top'>
                                <DeleteButton click={() => del()} message={'This will delete the folder'} />
                            </Tooltip>
                        </Group>

                    </Box>
                </td>
            </tr>
            {structure[folder.path] && folder.folders.map((f, j) =>
                <Folder folder={f} openFolder={openFolder} deleteFolder={deleteFolder} structure={structure} depth={depth + 1} key={j} />
            )}
        </>)

}

import React, { useState, useContext, useEffect } from 'react'
import { auth, db } from '../../firebase'
import { doc, where, collection, getDocs, query } from 'firebase/firestore'
import clientsInput from './clientsInput'
import { saveLogs } from '../../components/overview/clientLogging';
import { useLoggingContext } from 'providers/LoggingProvider/LoggingProvider'
import { firestore } from '../../redux/logging/loggingSlice'
import dayjs from 'dayjs'
import { useDispatch, useSelector } from 'react-redux'
import { setUnseenTabs, toOverview, toClient } from '../../redux/viewer/viewerSlice'
export function ClientProvider(props) {
    const dispatch = useDispatch()
    const { role } = useSelector(state => state.firestoreUser.userData)
    const [clientData, setClientData] = useState({})
    const [loading, setLoading] = useState(true)
    const viewer = useSelector(state => state.viewer)
    const firestoreUser = useSelector(state => state.firestoreUser)

    useEffect(() => {
        if (viewer.currentClient) {
            getClientData()
            // if (role === 'client') {
            //     saveLastLogin()
            // }
        }
    }, [viewer.currentClient])
    /**
     * Client and tab functionality
     *
     */
    useEffect(() => {
        if (viewer.currentClient) {
            getUnseenTabs()
        }
    }, [clientData])

    function getUnseenTabs() {
        let res = []
        if (clientData && clientData.logs) {
            if (role === 'client') {
                res = clientData?.logs?.coach
            } else {
                res = clientData?.logs?.client
            }
        }
        dispatch(setUnseenTabs(res))
    }

    function getCurrentClient() {
        return viewer.currentClient
    }

    async function getClientData() {
        // console.log('getClientData')
        if (role === 'client' && viewer.view === 'overview') {
            return
        }
        if (!viewer.currentClient) return
        console.log(viewer.currentClient.id)
        const ref = doc(db, 'clients', viewer.currentClient.id)
        const snap = await dispatch(firestore.getDoc(ref));
        if (snap.exists()) {
            setLoading(false)
            const data = snap.data()
            setClientData(data)
        }
        else {
            await dispatch(firestore.setDoc(doc(db, 'clients', viewer.currentClient.id), clientsInput))
        }
    }

    async function saveClientBio(bio) {
        await saveLastEdited("Client")
        const ref = doc(db, 'clients', viewer.currentClient.id)
        console.log('here', bio);
        dispatch(firestore.updateDoc(ref, { "bio": bio })).then(() => {
            setClientData((prevstate) => ({ ...prevstate, bio }))
        })
    }

    async function saveClientIntake(intake) {
        await saveLastEdited("Program Proposal")
        const ref = doc(db, 'clients', viewer.currentClient.id)
        firestore.updateDoc(ref, { "intake": intake })(dispatch).then(() => {
            setClientData((prevstate) => ({ ...prevstate, intake }))
        })
    }

    async function saveClientInitialProposal(iP) {
        await saveLastEdited("Program Proposal")
        const ref = doc(db, 'clients', viewer.currentClient.id)
        dispatch(firestore.updateDoc(ref, { "initialProposal": iP })).then(() => {
            setClientData((prevstate) => ({ ...prevstate, initialProposal: iP }))
        })
    }

    async function saveClientSessionNotes(sN) {
        await saveLastEdited("Session Notes")
        const ref = doc(db, 'clients', viewer.currentClient.id)
        dispatch(firestore.updateDoc(ref, { "sessionNotes": sN })).then(() => {
            setClientData((prevstate) => ({ ...prevstate, sessionNotes: sN }))
        })
    }

    async function saveClientTimesheet(timesheet) {
        await saveLastEdited("Timesheet")

        const ref = doc(db, 'clients', viewer.currentClient.id)
        dispatch(firestore.updateDoc(ref, { "timesheet": timesheet })).then(() => {
            setClientData((prevstate) => ({ ...prevstate, timesheet: timesheet }))
        })
    }

    async function saveClientRoadMap(roadMap) {
        await saveLastEdited("Road Map")
        const ref = doc(db, 'clients', viewer.currentClient.id)
        dispatch(firestore.updateDoc(ref, { "roadMap": roadMap })).then(() => {
            setClientData((prevstate) => ({ ...prevstate, roadMap: roadMap }))
        })
    }


    async function saveClientWhiteboard(whiteboard) {
        await saveLastEdited("Whiteboard")
        const ref = doc(db, 'clients', viewer.currentClient.id)
        dispatch(firestore.updateDoc(ref, { "whiteboard": whiteboard })).then(() => {
            setClientData((prevstate) => ({ ...prevstate, whiteboard: whiteboard }))
        })
    }

    async function saveClientActions(actions, id) {
        await saveLastEdited("Actions")
        const sN = clientData.sessionNotes
        sN['N'][getNoteIndexById(id)]['actions'] = actions
        const ref = doc(db, 'clients', viewer.currentClient.id)
        dispatch(firestore.updateDoc(ref, { "sessionNotes": sN })).then(() => {
            setClientData((prevstate) => ({ ...prevstate, sessionNotes: sN }))
        })
    }

    function listActions(notes) {
        let result = []
        if (!notes) {
            return result
        }
        notes.forEach(element => {
            result = [...result, ...element.actions]
        });
        return result
    }

    function getNoteIndexById(id) {
        const index = clientData.sessionNotes['N'].findIndex((note) => {
            return note.id === id
        })
        return index
    }


    async function saveLastLogin() {

        const ref = doc(db, 'clients', viewer.currentClient.id)
        firestore.updateDoc(ref, { "lastLogin": dayjs().format('YYYY-MMM-DD HH:mm:ss') })(dispatch).then(() => {
        }
        )
    }




    async function saveLastEdited(tab) {

        const ref = doc(db, 'clients', viewer.currentClient.id)
        let tabName = tab
        if (tab === 'Whiteboard') {
            tabName = 'Notes'
        } else if (tab === 'Initial Proposal') {
            tabName = 'Program Proposal'
        }
        if (tab === 'Bio') {
            tabName = 'Client'
        }

        let logs = {
            coach: [],
            client: []
        }

        const snap = await dispatch(firestore.getDoc(ref));
        if (snap.exists()) {
            const data = snap.data()
            if ('logs' in data) {
                logs = data.logs
            }
        }

        const newLog = {
            tab: tabName,
            date: dayjs().format('YYYY-MMM-DD HH:mm:ss'),
            seen: false,
        }

        if (role === 'coach') {
            logs.coach = logs.coach.filter((log) => log.tab !== tabName)
            logs.coach.push(newLog)
        } else {
            logs.client = logs.client.filter((log) => log.tab !== tabName)
            logs.client.push(newLog)
        }

        dispatch(firestore.updateDoc(ref, { "logs": logs })).then(() => {
            setClientData((prevstate) => ({ ...prevstate, logs }))
        })
    }



    return (

        <ClientContext.Provider value={{ loading, setLoading, clientData, saveLastEdited, saveClientActions, setClientData, saveClientBio, saveClientSessionNotes, saveClientIntake, saveClientInitialProposal, listActions, saveClientTimesheet, saveClientWhiteboard, saveClientRoadMap, getCurrentClient }}>
            {props.children}
        </ClientContext.Provider>
    )
}


export const ClientContext = React.createContext([])

export const useClientContext = () => React.useContext(ClientContext)
export const timesheetRecord = {
    id: '-1',
    date: '',
    participants: '',
    timeStart: '',
    timeEnd: '',
    duration: '',
    description: '',
    item: '',
    hourlyRate: 0,
    amount: '',
    invoiced: '-',
    paid: '-'
}

export function calculateTotalAmount(hourlyRate, duration) {
    const amount = hourlyRate * duration
    return (amount && amount > 0) ? amount : 0
}

export function formatMoney(amount) {
    if (amount) {
        return parseInt(amount).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return 0
}

export function calculateDuration(timeStart, timeEnd) {
    if (timeStart && timeEnd) {
        const start = timeStart.split(":");
        const end = timeEnd.split(":");
        const startDate = new Date(0, 0, 0, start[0], start[1], 0);
        const endDate = new Date(0, 0, 0, end[0], end[1], 0);

        let diff = endDate.getTime() - startDate.getTime();
        const hours = Math.floor(diff / 1000 / 60 / 60);
        diff -= hours * 1000 * 60 * 60;
        const minutes = Math.floor(diff / 1000 / 60);

        return parseInt(hours) + "." + (minutes / 60).toFixed(2).split(".")[1];
    }
    return 0
}


export default {
    companyName: "",
    companySite: "",
    chamberOfCommerce: "",
    VAT: "",
    street: "",
    zipCode: "",
    city: "",
    country: "",
    name: "",
    email: "",
    phone: "",
    nameBank: "",
    iban: "",
    swift: "",
    bankAddress: "",
}
import React, { useEffect, useState, createRef, useRef } from 'react'
import { Radio, Header, Form, Icon, TextArea, List, Input, Message, Dropdown } from 'semantic-ui-react'
import { useClientContext } from '../../../providers/ClientProvider/ClientProvider'
import { DeleteButton, EditButton, CancelButton, SaveButton } from '../../utils/buttons/buttons'
import { actionsASInput } from './actionsUtils'
import BItem from '../../utils/BItem'
import CItem from '../../utils/CItem'
import actionsState from './actionsState'
import './actions.scss'
import CoachItem from '../../utils/CoachItem'
import ClientService from '../ClientService'
import { noteInput, sortTypes, getIcon, actionNoteInput } from '../sessionNotes/sessionNotesUtils'
import sessionNotesState from '../sessionNotes/sessionNotesState'
import { Action } from '../sessionNotes/sessionNotes'
import { setValuesByKey } from '../ClientService'
import { useMediaQuery } from '@mantine/hooks'
import actionTypes from '../../utils/actionTypes'
import { Group, Paper, Select, Title, Button, Divider, Table, Text, Textarea, Box } from '@mantine/core'
import { NormalButton, PageMenu } from 'components/utils/MantineUtils'
import { IconArrowBackUp, IconDeviceFloppy, IconEdit } from '@tabler/icons-react'
import { statusColors } from '../../utils/actionTypes'
export default function ActionsComponent(props) {
    const cContext = useClientContext()
    const sN = cContext.clientData.sessionNotes
    const matching = useMediaQuery('(max-width: 62em)')

    const [height, setHeight] = useState(0)
    const [scrollTop, setScrollTop] = useState(0)
    const [editing, setEditing] = useState(false)
    const [editedSN, setEditedSN] = useState(sN === undefined ? {} : JSON.parse(JSON.stringify(sN)))
    const [sort, setSort] = useState(sortTypes.none)
    const [search, setSearch] = useState("")
    const [status, setStatus] = useState("All")
    const [sortedData, setSortedData] = useState([])


    const statusTypes = {
        ...actionTypes,
        All: 1


    }
    const sRef = useRef(null)

    let cs = new ClientService(setEditedSN, setScrollTop)

    useEffect(() => {
        if (sN === undefined) {
            setEditedSN(sessionNotesState)
            save()
        }
    }, [sN])


    useEffect(() => {
        if (editedSN['N'] !== undefined) {

            setSortedData(dateSort(editedSN['N']))
        }
    }, [editedSN])



    const statusOptions = Object.keys(statusTypes).map((o, i) => {
        return {
            key: i,
            text: o,
            value: o
        }
    })

    function customFilter(action) {
        const searchU = search.toUpperCase()

        const status = action.status.toUpperCase()
        const title = action.title.toUpperCase()
        const notes = action.notes.toUpperCase()

        if (status.includes(searchU) || title.includes(searchU) || notes.includes(searchU)) {
            return true
        }
        return false
    }
    function statusFilter(action) {
        const searchU = status.toUpperCase()
        if (searchU === "ALL") {
            return true
        }
        const st = action.status.toUpperCase()
        if (st === searchU) {
            return true
        }
        return false
    }

    function dateSort(notes) {
        let actions = []
        for (let note of notes) {
            if (note.actions) {
                let noteActions = note.actions
                noteActions.map(a => { a.noteid = note.id })
                noteActions.map(a => { a.session = note.session })
                actions = [...actions, ...note.actions]
            }
        }
        actions = actions.filter(action => statusFilter(action)).filter(action => customFilter(action))
        if (sort === sortTypes.none) {
            return actions
        }
        const sorted = actions.sort(function (a, b) {
            if (sort === sortTypes.down) {
                return new Date(a.date) - new Date(b.date)
            } else {
                return new Date(b.date) - new Date(a.date)
            }

        })

        return [...sorted]
    }




    function changeSort() {
        const amount = Object.keys(sortTypes).length;
        setSort((prevSort) => {
            if ((prevSort + 1) === amount) {
                return 0
            }
            return prevSort + 1
        })
    }

    function toggleEditing() {
        setEditing(!editing)
    }

    function cancel() {
        setEditedSN(sN)
        setEditing(false)
    }

    function save() {
        setEditing(false)

        cContext.saveClientSessionNotes(editedSN)
    }
    function handleChange(data, noteId, actionId, key) {
        cs.handleChangeAction(editedSN, data, 'N', noteId, actionId, key)
    }



    return (
        <>
            <Paper
                p={"md"} shadow="lg" withBorder
                sx={
                    {
                        position: 'sticky',
                        top: 69,
                        padding: '20px',
                        zIndex: 2,
                        transition: 'all 0.3s ease',
                        // borderBottom: '1px solid #ddd',

                    }
                }

            >
                <PageMenu sectionType={'Client'} name={'actions'} editing={editing} toggleEditing={toggleEditing} save={save} cancel={cancel} sortType={'date'} setData={setSortedData} data={[...sortedData]} unsorted={dateSort((editedSN && editedSN.N && editedSN.N.length > 0) ? [...editedSN['N']] : [])} search={true} sort searchFunction={setSearch}>
                    <Select data={Object.keys(statusTypes).map((status) => {
                        return {
                            value: status,
                            label: status,
                        }
                    })
                    } value={status} onChange={(e) => setStatus(e)} placeholder={"Filter on status"} />
                </PageMenu>

            </Paper>
            <Divider my={"lg"} />
            {
                editedSN && editedSN["N"] &&
                <Paper p={"md"} shadow="lg" withBorder>
                    {matching ?
                        (sortedData.filter(note => customFilter(note)).filter(note => statusFilter(note)).map((action, j) =>
                            <FullAction matching={matching} cs={cs} key={j} note={action.note} action={action} editing={editing} handleChange={handleChange}></FullAction>
                        )) :


                        (<Table
                            striped
                            highlightOnHover withBorder my={'md'}
                        >
                            <thead>
                                <tr>
                                    <th width={"120px"}>
                                        <Title order={6} >SESSION NO.</Title>
                                    </th>
                                    <th width={"140px"}>
                                        <Title order={6} >CREATION DATE</Title>
                                    </th>
                                    <th width={'360px'}>
                                        <Title order={6} >
                                            ACTION
                                        </Title>
                                    </th>
                                    <th>
                                        <Title order={6} >COMMENT</Title>
                                    </th>
                                    <th width={'140px'}>
                                        <Title order={6} >STATUS</Title>
                                    </th>


                                </tr>
                            </thead>
                            <tbody>
                                {sortedData.filter(note => customFilter(note)).filter(note => statusFilter(note)).map((action, j) =>
                                    <FullAction matching={matching} cs={cs} key={j} note={action.note} action={action} editing={editing} handleChange={handleChange}></FullAction>
                                )}
                            </tbody>
                        </Table>)
                    }

                </Paper>
            }
        </>
        // <div className="SessionNotes">
        //     <div className={"SessionNotes-topbar " + (scrollTop > height ? 'scrolled' : '')} ref={sticky}>
        //         <div className="left">
        //             <Header as={'h2'}>Actions</Header>
        //             <CItem>
        //                 <></>
        //                 <BItem editing={editing}>
        //                     <EditButton click={toggleEditing} />
        //                     <>
        //                         <SaveButton click={save} />
        //                         <CancelButton click={cancel} /></>
        //                 </BItem>
        //             </CItem>
        //             <Button icon onClick={() => changeSort()} >
        //                 <Icon name={getIcon(sort)}></Icon>
        //             </Button>
        //             <Input type='text' placeholder={'search'} value={search} onChange={(e) => setSearch(e.target.value)}></Input>
        //             <div className="statusFilter">
        //                 <Dropdown placeholder='-' fluid selection options={statusOptions} value={status} onChange={(event, data) => setStatus(data.value)}></Dropdown>
        //             </div>

        //         </div>
        //         <div className="session-header">
        //             <div className="session-header-actions">
        //                 <span>Session</span>
        //                 <span>Created</span>
        //                 <span>Action</span>
        //                 <span>Comment</span>
        //                 <span >Status</span>
        //             </div>
        //         </div>
        //     </div>

        //     <Divider />
        //     {editedSN !== undefined &&
        //         <div className="SessionNotes-content">
        //             {
        //                 editedSN && editedSN["N"] && dateSort(editedSN["N"]).map((action, j) =>
        //                     <FullAction cs={cs} key={j} note={action.note} action={action} editing={editing} handleChange={handleChange}></FullAction>

        //                 )


        //             }
        //         </div>
        //     }
        //     <div ref={sRef}></div>

        // </div >
    )
}
function FullAction(props) {
    const action = props.action
    const note = props.note


    const statusOptions = Object.keys(actionTypes).map((o, i) => {
        return {
            key: i,
            text: o,
            label: o,
            value: o,
        }
    })




    function padTo2Digits(num) {
        return num.toString().padStart(2, '0');
    }

    function formatDate(date) {
        const day = new Date(date)

        const yyyy = day.getFullYear();
        let mm = day.getMonth() + 1; // Months start at 0!
        let dd = day.getDate();

        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;

        return dd + '-' + mm + '-' + yyyy;
    }

    return (
        <>
            {
                props.matching ?
                    <Paper my={'md'} p={'md'} shadow={'lg'} withBorder
                        sx={(theme) => ({
                            position: 'relative',
                        })}
                    >
                        <Box sx={
                            (theme) => ({
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                height: '100%',
                                width: '32px',
                                backgroundColor: theme.colors[statusColors[action.status]]
                            })
                        }></Box>
                        <Box ml={'32px'}>
                            <Group position="apart">
                                <Title color={'gray'} order={6} tt={'uppercase'}>
                                    Session no.: {action.session}
                                </Title>
                                <BItem editing={props.editing}>
                                    <Title color={'gray'} order={6} tt={'uppercase'} my={'md'}>
                                        {action.status}
                                    </Title>
                                    <Select data={statusOptions} value={action.status} onChange={(event) => props.handleChange(event, action.noteid, action.id, "status")} />
                                </BItem>
                                <Title color={'gray'} order={6} tt={'uppercase'}>
                                    Creation date: {props.cs.formatDate(action.date)}
                                </Title>
                            </Group>
                            <Text fw={'700'} my={'md'}>
                                {action.title}
                            </Text>
                            <Box my={'md'}>
                                <BItem editing={props.editing}>
                                    <Text sx={{
                                        whiteSpace: 'pre-line'
                                    }} my={'md'}>
                                        {action.notes}
                                    </Text>
                                    <Textarea autosize placeholder="Comment" value={action.notes} onChange={(e) => props.handleChange(e.target.value, action.noteid, action.id, 'notes')}></Textarea>
                                </BItem>

                            </Box>
                            <Box my={'md'}>

                            </Box>
                        </Box>
                    </Paper> :
                    <tr valign='top' >
                        <td>
                            <Text>
                                {action.session}
                            </Text>
                        </td>
                        <td>
                            <Text>
                                {props.cs.formatDate(action.date)}
                            </Text>
                        </td>
                        <td>
                            <Text fw={'700'}>
                                {action.title}
                            </Text>
                        </td>
                        <td>
                            <BItem editing={props.editing}>
                                <Text sx={{
                                    whiteSpace: 'pre-line'
                                }}>
                                    {action.notes}
                                </Text>
                                <Textarea autosize minRows={1} placeholder="Comment" value={action.notes} onChange={(e) => props.handleChange(e.target.value, action.noteid, action.id, 'notes')}></Textarea>
                            </BItem>
                        </td>
                        <td style={{ 'position': 'relative' }}>
                            <Box mr={'32px'}>
                                <BItem editing={props.editing}>
                                    <Text>
                                        {action.status}
                                    </Text>
                                    <Select w={'150px'} data={statusOptions} value={action.status} onChange={(event) => props.handleChange(event, action.noteid, action.id, "status")} />
                                </BItem>
                            </Box>
                            <Box sx={
                                (theme) => ({
                                    position: 'absolute',
                                    top: '0',
                                    right: '0',
                                    height: '100%',
                                    width: '32px',
                                    backgroundColor: theme.colors[statusColors[action.status]]
                                })
                            }></Box>
                        </td>
                    </tr>
            }
        </>
        // <Message className="FullAction">
        //     <div className={'strip ' + action.status.replace(/\s+/g, '')}> </div>
        //     <div className="FullAction-content">
        //         <span as={'h3'}>                <span className="mobile-only">Session: </span>
        //             {action.session}</span>
        //         <span>                <span className="mobile-only">Date: </span>
        //             {action.date ? props.cs.formatDate(action.date) : ''}</span>
        //         <span as={'h3'}>{action.title}</span>
        //         <BItem editing={props.editing}>
        //             <span className={'newLines'} as={'h3'}>{action.notes}</span>
        //             <Form>
        //                 <TextArea type='text' value={action.notes} onChange={(e) => props.handleChange(e.target.value, action.noteid, action.id, 'notes')}></TextArea>
        //             </Form>
        //         </BItem>
        //         <BItem editing={props.editing}>
        //             <span>{action.status}</span>
        //             <Dropdown placeholder={action.status} fluid selection options={statusOptions} value={action.status} onChange={(event, data) => props.handleChange(data.value, action.noteid, action.id, "status")}></Dropdown>

        //         </BItem>





        //         {/* <Icon name='circle outline' className={action.status.replace(/\s+/g, '')}>
        //                 </Icon> */}
        //     </div>
        // </Message >
    )
}



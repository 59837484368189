import React, { useEffect, useState, createRef, useRef } from "react";
import intakeState from "../intake/intakeState";
import { useClientContext } from "../../../providers/ClientProvider/ClientProvider";
import initialProposalState from "./initialProposalState";
import { topicInput, meetingInput } from "./initialProposalUtils";
import BItem from "../../utils/BItem";
import "./initialProposal.scss";
import CoachItem from "../../utils/CoachItem";
import { MD } from "../intake/intake";

import { DeleteButton, generateId, PageMenu, SafeDateInput } from "../../utils/MantineUtils";
import {
    Badge,
    Box,
    Button,
    Center,
    Divider,
    Flex,
    Grid,
    Group,
    MultiSelect,
    Paper,
    ScrollArea,
    SimpleGrid,
    Stack,
    Switch,
    Table,
    Text,
    Textarea,
    TextInput,
    Title,
} from "@mantine/core";
import {
    IconArrowBackUp,
    IconDeviceFloppy,
    IconEdit,
    IconPlus,
} from "@tabler/icons-react";
import { intakeASInput } from "../intake/intakeUtils";
import { useScrollIntoView, useMediaQuery } from "@mantine/hooks";
import dayjs from "dayjs";
import { DateInput, TimeInput } from "@mantine/dates";
export default function InitialProposalComponent(props) {
    const matching = useMediaQuery("(min-width: 800px)");
    const cContext = useClientContext();
    let iP = cContext.clientData.initialProposal;
    let intake = cContext.clientData.intake;
    const [editedIntake, setEditedIntake] = useState(
        intake === undefined ? {} : JSON.parse(JSON.stringify(intake))
    );
    const [height, setHeight] = useState(0);
    const [scrollTop, setScrollTop] = useState(0);
    const [editing, setEditing] = useState(false);
    const [editedIP, setEditedIP] = useState(
        iP === undefined ? {} : JSON.parse(JSON.stringify(iP))
    );

    const endMeetingsRef = useRef(null)
    const endIdentifiedTopicsRef = useRef(null)
    const endAssessmentRef = useRef(null)



    useEffect(() => {
        if (intake === undefined) {
            setEditedIntake(intakeState);
            saveIntake();
        }
    }, [intake, editedIntake]);

    useEffect(() => {
        if (cContext.clientData.initialProposal === undefined) {
            setEditedIP(initialProposalState);
            saveInitialProposal();
        }
    }, [iP, editedIP]);



    function cancel() {
        setEditedIP(iP);
        setEditedIntake(intake);
        setEditing(false);
    }
    function save() {
        setEditing(false);
        cContext.saveClientInitialProposal(editedIP);
        cContext.saveClientIntake(editedIntake);
    }
    function saveInitialProposal() {
        setEditing(false);
        cContext.saveClientInitialProposal(editedIP);
    }
    function saveIntake() {
        setEditing(false);
        cContext.saveClientIntake(editedIntake);
    }

    function deleteInput(cat, id) {
        let old = Object.assign([], editedIP[cat]);
        const filtered = old.filter((item) => item.id !== id);
        setEditedIP((prevState) => ({
            ...prevState,
            [cat]: filtered,
        }));
    }
    function deleteIntakeInput(cat, id) {
        let old = Object.assign([], editedIntake[cat]);
        const filtered = old.filter((item) => item.id !== id);
        setEditedIntake((prevState) => ({
            ...prevState,
            [cat]: filtered,
        }));
    }

    function findIndexById(editedData, cat, id) {
        return editedData[cat].findIndex((ic) => ic.id === id);
    }

    function handleChangeMD(data, key) {
        let old = Object.assign({}, editedIP["MD"]);
        old[key] = data;
        setEditedIP((prevState) => ({
            ...prevState,
            ["MD"]: old,
        }));
    }

    function handleChange(data, cat, id, key) {
        let old = Object.assign([], editedIP[cat])
        old[findIndexById(editedIP, cat, id)][key] = data
        setEditedIP((prevState) => ({
            ...prevState, [cat]: old
        }))
    }
    function handleChangeIntake(data, cat, id, key) {
        let old = Object.assign([], editedIntake[cat])
        old[findIndexById(editedIntake, cat, id)][key] = data
        setEditedIntake((prevState) => ({
            ...prevState, [cat]: old
        }))
    }

    function handleIntakeNotes(data) {
        setEditedIP((prevState) => ({
            ...prevState,
            ["IntakeNotes"]: data,
        }));
    }

    function handleTopic(data, id, key) {
        let old = Object.assign([], editedIP["T"]);
        old[findIndexById(editedIP, "T", id)][key] = data;
        setEditedIP((prevState) => ({
            ...prevState,
            ["T"]: old,
        }));
    }

    function handleTopicAreas(id, data) {
        let old = Object.assign([], editedIP["T"]);
        for (const x of ["consulting", "coaching", "training"]) {
            if (data.includes(x)) {
                old[findIndexById(editedIP, "T", id)][x] = true;
            } else {
                old[findIndexById(editedIP, "T", id)][x] = false;
            }
        }
        setEditedIP((prevState) => ({
            ...prevState,
            ["T"]: old,
        }));
    }

    function addInput(cat) {
        let template;
        let data = editedIP
        let setData = setEditedIP
        switch (cat) {
            case "T":
                template = topicInput;
                endIdentifiedTopicsRef?.current?.scrollIntoView({ behavior: 'smooth' })
                break;
            case "M":
                template = meetingInput;
                endMeetingsRef.current.scrollIntoView({ behavior: 'smooth' })
                break;
            case "AS":
                template = intakeASInput;
                data = editedIntake
                setData = setEditedIntake
                endAssessmentRef.current.scrollIntoView({ behavior: 'smooth' })
                break;
            default:
                template = topicInput;
                break;
        }
        addInputCat(data, setData, cat, template);
    }
    function addInputCat(editedData, setData, cat, template) {
        const newInput = Object.assign({}, template)
        newInput.id = generateId(editedData, cat)
        const cur = Object.assign([], editedData[cat])
        cur.push(newInput)
        setData((prevState) => ({
            ...prevState,
            [cat]: cur
        }))
    }
    function toggleEditing() {
        setEditing(!editing);
    }
    return (
        <>
            <Paper p={"md"} shadow="lg" withBorder
                sx={
                    {
                        position: 'sticky',
                        top: 69,
                        padding: '20px',
                        zIndex: 2,
                        transition: 'all 0.3s ease',
                        // borderBottom: '1px solid #ddd',

                    }
                }>

                <PageMenu sectionType={'Coach'} name={'Program Proposal'} editing={editing} toggleEditing={toggleEditing} save={save} cancel={cancel}>
                    <BItem editing={editing}>
                        <></>

                        <CoachItem>
                            <Group>
                                <Button
                                    variant="default"
                                    leftIcon={<IconPlus stroke={"3"} size={20} />}
                                    onClick={() => {
                                        addInput("T");
                                    }}
                                >
                                    <Title order={6}>Identified topic</Title>
                                </Button>
                                <Button
                                    variant="default"
                                    leftIcon={<IconPlus stroke={"3"} size={20} />}
                                    onClick={() => {
                                        addInput("M");
                                    }}
                                >
                                    <Title order={6}>Meeting</Title>
                                </Button>
                                <Button
                                    variant="default"
                                    leftIcon={<IconPlus stroke={"3"} size={20} />}
                                    onClick={() => {
                                        addInput("AS");
                                    }}
                                >
                                    <Title order={6}>Assessment</Title>
                                </Button>
                            </Group>
                        </CoachItem>
                    </BItem>
                </PageMenu>
            </Paper>
            <Divider my={"lg"} />
            {iP !== undefined && (
                <ScrollArea>
                    <Title order={6} my={"lg"} tt={"uppercase"}>
                        Initial proposal appointment
                    </Title>
                    <Paper p={"md"} shadow="lg" withBorder>
                        <Flex gap={'md'} direction={matching ? 'row' : 'column'}>
                            <MD
                                handleChange={handleChangeMD}
                                MD={{
                                    key: "date",
                                    value: editedIP["MD"]["date"],
                                    type: "date",
                                }}
                                editing={editing}
                            />
                            <MD
                                handleChange={handleChangeMD}
                                MD={{
                                    key: "time",
                                    value: editedIP["MD"]["time"],
                                    type: "time",
                                }}
                                editing={editing}
                            />
                            <MD
                                handleChange={handleChangeMD}
                                MD={{
                                    key: "location",
                                    value: editedIP["MD"]["location"],
                                    type: "location",
                                }}
                                editing={editing}
                            />
                        </Flex>
                    </Paper>
                    <Divider my={"lg"} />
                    <Title order={6} my={"lg"} tt={"uppercase"}>
                        Intake Notes
                    </Title>

                    <Paper p={"md"} shadow="lg" withBorder>
                        <BItem editing={editing}>
                            <Text sx={{
                                whiteSpace: 'pre-line'
                            }}>
                                {editedIP["IntakeNotes"]
                                    ? editedIP["IntakeNotes"]
                                    : ""}
                            </Text>
                            <Textarea
                                value={
                                    editedIP["IntakeNotes"]
                                        ? editedIP["IntakeNotes"]
                                        : ""
                                }
                                onChange={(e) =>
                                    handleIntakeNotes(e.target.value)
                                }
                                autosize
                            />
                        </BItem>
                    </Paper>
                    <Divider my={"lg"} />
                    <Title order={6} my={"lg"} tt={"uppercase"}>
                        Identified topics
                    </Title>
                    {editedIP["T"] && editedIP["T"].map((topic, i) => (
                        <Topic
                            handleTopic={handleTopic}
                            // handleChange={handleChange}
                            handleTopicAreas={handleTopicAreas}
                            deleteInput={deleteInput}
                            topic={topic}
                            key={i}
                            editing={editing}
                        ></Topic>
                    ))}
                    <Divider my={"lg"} />
                    <Box ref={endIdentifiedTopicsRef} ></Box>

                    <Title order={6} my={"lg"} tt={"uppercase"}>
                        Proposed program structure
                    </Title>
                    <Paper p={"md"} shadow="lg" withBorder>
                        <Table striped
                            highlightOnHover withBorder my={'md'}>
                            <thead>
                                <tr>
                                    <th><Title order={6} tt={'uppercase'}>
                                        Description
                                    </Title></th>
                                    <th width={'100px'}><Title order={6} tt={'uppercase'}>
                                        Date
                                    </Title></th>
                                    <th><Title order={6} tt={'uppercase'}>
                                        Time
                                    </Title></th>
                                    {editing &&
                                        <th width={'100px'}><Title order={6} tt={'uppercase'}>
                                            Remove
                                        </Title></th>
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {editedIP["M"] && editedIP["M"].map((meeting, i) => (
                                    <Meeting
                                        handleChange={handleChange}
                                        deleteInput={deleteInput}
                                        meeting={meeting}
                                        key={i}
                                        editing={editing}
                                    />
                                ))}
                            </tbody>
                        </Table>
                        <Box ref={endMeetingsRef} ></Box>

                    </Paper>
                    <Divider my={"lg"} />
                    <Title order={6} my={"lg"} tt={"uppercase"}>
                        Assessment(s)
                    </Title>
                    <Paper p={"md"} shadow="lg" withBorder>
                        {editedIntake && editedIntake['AS'] &&
                            editedIntake["AS"].map(
                                (option, i) => (
                                    <AS
                                        handleChange={handleChangeIntake}
                                        deleteInput={deleteIntakeInput}
                                        AS={option}
                                        key={option.id}
                                        editing={editing}

                                    />
                                )
                            )}

                    </Paper>
                    <Box mt={'xl'} ref={endAssessmentRef} ></Box>
                </ScrollArea>
            )}
        </>
    );
}
function Topic(props) {
    const matching = useMediaQuery("(min-width: 768px)");
    const areas = [
        {
            value: "coaching",
            label: "Coaching",
        },
        {
            value: "consulting",
            label: "Consulting",
        },
        {
            value: "training",
            label: "Training",
        },
    ];

    const topic = props.topic;
    const [multiSelect, setMultiSelect] = useState(topicToValues);
    function deleteInput() {
        props.deleteInput("T", topic.id);
    }

    function topicToValues() {
        let values = [];
        if (topic.coaching) {
            values.push("coaching");
        }
        if (topic.consulting) {
            values.push("consulting");
        }
        if (topic.training) {
            values.push("training");
        }
        return values;
    }

    useEffect(() => {
        setMultiSelect(topicToValues());
    }, [topic]);

    function handleChecked(checked) {
        props.handleTopicAreas(topic.id, checked);
        setMultiSelect(checked);
    }

    return (
        <Paper p={"md"} shadow="lg" withBorder my={'md'}>
            <Flex
                direction={matching ? "row" : "column-reverse"}
                justify={matching ? "space-between" : "flex-start"}
                gap={matching ? "md" : "sm"}
                align={matching ? "flex-start" : "flex-end"}
                wrap={"nowrap"}
            >
                <Grid
                    // sx={(theme) => ({ miw: "1000px" })}
                    // gutter={12}
                    miw={matching ? "100%" : '100%'}
                    justify={matching ? "center" : "space-between"}
                    align={matching ? "center" : "stretch"}
                >
                    <Grid.Col span={matching ? 3 : 12} >
                        <BItem editing={props.editing} >
                            <Title order={4} tt={"uppercase"} sx={{
                                wordBreak: 'break-word'
                            }} >
                                {topic.name}
                            </Title>
                            <TextInput
                                value={topic.name}
                                onChange={(event) =>
                                    props.handleTopic(
                                        event.target.value,
                                        topic.id,
                                        "name"
                                    )
                                }
                                disabled={!props.editing}
                            />
                        </BItem>
                    </Grid.Col>
                    <Grid.Col span={matching ? 3 : 12}>
                        <BItem editing={props.editing}>
                            <>
                                {topic.coaching && <Badge>{"Coaching"}</Badge>}
                                {topic.training && <Badge>{"Training"}</Badge>}
                                {topic.consulting && (
                                    <Badge>{"Consulting"}</Badge>
                                )}
                            </>
                            <MultiSelect
                                data={areas}
                                value={multiSelect}
                                onChange={(event) => handleChecked(event)}
                            />
                        </BItem>
                    </Grid.Col>
                    <Grid.Col span={matching ? 6 : 12}>
                        <BItem editing={props.editing}>
                            <Text>{topic.proposal}</Text>
                            <Textarea
                                miw={"100%"}
                                autosize
                                minRows={1}
                                value={topic.proposal}
                                onChange={(event) =>
                                    props.handleTopic(
                                        event.target.value,
                                        topic.id,
                                        "proposal"
                                    )
                                }
                                disabled={!props.editing}
                            />
                        </BItem>
                    </Grid.Col>
                </Grid>
                <Box sx={(theme) => ({ textAlign: "right" })}>
                    {props.editing && (
                        <DeleteButton
                            click={deleteInput}
                            message={
                                "This will delete the Identified topic permanently."
                            }
                        />
                    )}
                </Box>
            </Flex>
        </Paper>
    );
}

function Meeting(props) {
    const meeting = props.meeting;

    function padTo2Digits(num) {
        return num.toString().padStart(2, "0");
    }

    function formatDate(date) {
        const newDate = new Date(date);
        return [
            padTo2Digits(newDate.getDate()),
            padTo2Digits(newDate.getMonth() + 1),
            newDate.getFullYear(),
        ].join("-");
    }

    function handleDate(e) {
        let formattedDate = dayjs(e).format("DD-MMM-YYYY").toString();

        props.handleChange(formattedDate, "M", meeting.id, "date");
    }

    function deleteInput() {
        props.deleteInput("M", meeting.id);
    }
    return (
        <tr>
            <td>
                <BItem editing={props.editing}>
                    <Text>{meeting.name}</Text>
                    <Textarea value={meeting.name} onChange={(e) => props.handleChange(e.target.value, "M", meeting.id, "name")} autosize />
                </BItem>
            </td>
            <td >
                <BItem editing={props.editing}>
                    <Text>{meeting.date}</Text>
                    <SafeDateInput
                        date={meeting.date}
                        handleChange={props.handleChange}
                        params={["M", meeting.id, "date"]}
                    />
                </BItem>
            </td>
            <td>
                <BItem editing={props.editing}>
                    <Text>{meeting.time}</Text>
                    <TimeInput
                        value={meeting.time}
                        onChange={(e) => props.handleChange(e.target.value, "M", meeting.id, "time")}
                    />

                </BItem>
            </td>
            {props.editing && <td>
                <DeleteButton click={deleteInput} message={"This will detele the Identified topic permanently."} />
            </td>
            }
        </tr>
    );
}
export function AS(props) {
    const AS = props.AS;
    const [checked, setChecked] = useState(AS.value);
    function deleteInput() {
        props.deleteInput("AS", AS.id);
    }
    useEffect(() => {
        props.handleChange(checked, "AS", AS.id, "value");
    }, [checked]);

    return (
        <Group position={"apart"} my={'md'}>
            <Group>
                <Switch checked={checked} onChange={(e) => { props.editing && setChecked(e.target.checked) }} />
                <BItem editing={props.editing}>
                    <Text>{AS.name}</Text>
                    <TextInput value={AS.name} onChange={(e) => props.handleChange(e.target.value, "AS", AS.id, "name")} />
                </BItem>
            </Group>
            {props.editing && <DeleteButton click={deleteInput} message={"This will detele the Assessment permanently."} />}
        </Group>
    );
}
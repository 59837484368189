import React, { useContext, useState } from 'react'
import { Modal, Container, Header, Button, Divider, Table, Input, Menu, Segment, Icon } from 'semantic-ui-react'
import { auth } from '../../firebase'
import { useNavigate } from 'react-router-dom'
import { useBrandingContext } from '../../providers/BrandingProvider/BrandingProvider'
import { useUserContext } from '../../App'
import { useAlertsContext } from '../../providers/Alerts/AlertsProvider'
import "./overview.scss"
import { useOverviewContext } from 'providers/OverviewProvider/OverviewProvider'
import { useEffect } from 'react'
import { saveLogs, getLogs } from './clientLogging'
import { useClientContext } from 'providers/ClientProvider/ClientProvider'
import { ResetButtonWarning } from 'components/utils/buttons/buttons'
import AdminSection from './InsightsSection'
import ClientSection from './clientSection'
import { Space } from '@mantine/core'
import InsightsSection from './InsightsSection'
import ModulesSection from './modulesSection'
import { useSelector } from 'react-redux'
export function OverviewComponent(props) {
    // const clients = useSelector(state => state.firestoreUser.clients)
    const [search, setSearch] = useState('')
    const navigate = useNavigate()
    // const [modal, setModal] = useState(false)
    // const [logs, setLogs] = useState({})
    // const brandingProvider = useBrandingContext()
    // const [client, setClient] = useState(null)
    // const [clientsData, setClientsData] = useState({})
    // const [coachSummary, setCoachSummary] = useState({})
    // const { getTotal, resetPassword, getTotalHours } = useOverviewContext()








    // function openModal(c) {
    //     setClient(c)
    //     setModal(true)
    // }
    // function closeModal() {
    //     setModal(false)
    //     setClient(false)
    // }

    // function addNewUser() {
    //     navigate('/addUser')
    // }

    // function customFilter(client) {
    //     if (client.name.includes(search) || client.companyName.includes(search) || client.email.includes(search)) {
    //         return true
    //     }
    //     return false
    // }

    return (
        <>

            <ClientSection />
            <Space h='lg' />
            <InsightsSection />
            <Space h='lg' />
            <ModulesSection />
        </>

    )
}



export const intakeIQInput = {
    id: '-1',
    question: ''
}
export const intakeASInput = {
    id: '-1',
    name: '',
    value: false
}
export const intakeICInput = {
    id: '-1',
    title: "",
    description: "",
    solution: ""

}
import React, { useRef } from 'react'
import Alert from '../../components/alert/alert'
import './AlertsProvider.scss'
import { useState } from 'react'
import { v4 as uuid } from 'uuid'
import { notifications, Notifications } from '@mantine/notifications'
import { Title } from '@mantine/core'
const AlertContext = React.createContext({})


export function AlertsProvider(props) {

    function getColorByVariation(variation) {
        switch (variation) {
            case 'error':
                return 'red'
            case 'info':
                return 'blue'
            case 'warning':
                return 'yellow'
            case 'positive':
                return 'green'
            default:
                return 'blue'
        }
    }

    function addAlert({ header, timeout, content, variation }) {
        notifications.show({
            title: <Title order={6}>{header}</Title>,
            message: content,
            autoClose: timeout,
            color: getColorByVariation(variation),
        })
    }
    return (
        <AlertContext.Provider value={{ addAlert: addAlert }}>
            <Notifications />
            {props.children}

        </AlertContext.Provider>
    )
}

export const useAlertsContext = () => React.useContext(AlertContext)
import { createSlice, current } from "@reduxjs/toolkit";
import coachTabs from "./coachTabs";
import clientTabs from "./clientTabs";
import { viewerService } from "services/viewerService";

const coachStates = {
    overview: 'overview',
    admin: 'admin',
    client: 'client'
}

export const initialViewerState = {
    navbarItems: [],
    navbarTitle: '',
    navbarOn: false,
    activeTab: 'Client',
    view: coachStates.overview,
    currentClient: null,
    unseenTabs: [],
}



export const viewerSlice = createSlice({
    name: "viewer",
    initialState: initialViewerState,
    reducers: {
        resetViewer: (state) => {
            state = initialViewerState;
        },
        setNavbarItems: (state, action) => {
            state.navbarItems = action.payload;
        },
        setNavbarTitle: (state, action) => {
            state.navbarTitle = action.payload;
        },
        setNavbarOn: (state, action) => {
            state.navbarOn = action.payload;
        },
        setView(state, action) {
            state.view = action.payload;
        },
        setActiveTab(state, action) {
            state.activeTab = action.payload;
        },
        setUnseenTabs(state, action) {
            state.unseenTabs = action.payload;
        },
        setCurrentClient(state, action) {
            state.currentClient = action.payload;
        }
    },
});


export const toOverview = () => async (dispatch) => {
    try {
        // console.log('to overview')
        await dispatch(setNavbarOn(false));
        await dispatch(setView(coachStates.overview));
    } catch (error) {
        console.log(error)
    }
}


export const toAdmin = () => async (dispatch) => {
    try {
        // console.log('to admin')
        await dispatch(setNavbarOn(true));
        await dispatch(setView(coachStates.admin));
        await dispatch(setNavbarTitle('Coaching Modules'));
        await dispatch(setNavbarItems(coachTabs));
        await dispatch(setActiveTab(coachTabs[0]));
    } catch (error) {
        console.log(error)
    }
}

export const toClient = (client) => async (dispatch) => {
    try {
        // console.log(client)
        await dispatch(setNavbarOn(true));
        await dispatch(setView('client'));
        await dispatch(setNavbarTitle(client.name));
        await dispatch(setNavbarItems(clientTabs));
        await dispatch(setActiveTab(clientTabs[0]));
        await dispatch(setCurrentClient(client))
    } catch (error) {
        console.log(error)
    }
}


export const seeTab = (currentClient, role, tab) => async (dispatch) => {
    try {
        // console.log(currentClient)
        const unseenTabs = await viewerService.seeTab(currentClient.id, role, tab, dispatch);
        // console.log(unseenTabs)
        dispatch(setUnseenTabs(unseenTabs));
    } catch (error) {
        console.log(error)
    }
}


export const { setNavbarItems, setNavbarTitle, setNavbarOn, setView, setActiveTab, setUnseenTabs, setCurrentClient, resetViewer } = viewerSlice.actions;

export default viewerSlice.reducer;
export const topicInput = {
    id: -1,
    name: "",
    coaching: false,
    training: false,
    consulting: false,
    proposal: "",
    level: 0,
}
export const meetingInput = {
    id: -1,
    name: "",
    date: "",
    time: ""
}

import React, { useState } from 'react'

import { Segment, Dimmer, Loader, Placeholder, Button, Icon, Menu } from 'semantic-ui-react'
import { useCoachContext } from '../../providers/CoachProvider/CoachProvider'

import BrandingComponent from './branding/Branding'
import InvoicingComponent from './invoicing/Invoicing'
import CoachItem from '../utils/CoachItem'
import coachTabs from '../utils/coachTabs'
import ClientSettingsComponent from './clientSettings/clientSettings'
import { useDispatch, useSelector } from 'react-redux'
import { setActiveTab } from '../../redux/viewer/viewerSlice'
export default function CoachComponent(props) {
    const { loading, coachData } = useCoachContext()
    const dispatch = useDispatch()
    const { role, name } = useSelector(state => state.firestoreUser.userData)
    const { activeTab } = useSelector(state => state.viewer)


    function handleItemClick(e, { name }) {
        dispatch(setActiveTab(name))
    }

    function changeTab() {
        let tab = activeTab
        if (activeTab) {
            tab = tab.replace(/\s/g, '')
        }
        switch (tab) {
            case "Branding":
                return <BrandingComponent />
            case "Invoicing":
                return <InvoicingComponent />
            case "ClientSettings":
                return <ClientSettingsComponent />
            default:
                return <InvoicingComponent />
        }
    }

    return (
        <>{loading ?
            <div>
                <Segment>
                    <Dimmer active inverted>
                        <Loader inverted>Loading</Loader>
                    </Dimmer>

                    <Placeholder >
                        <Placeholder.Paragraph>
                            <Placeholder.Line />
                            <Placeholder.Line />
                            <Placeholder.Line />
                            <Placeholder.Line />
                            <Placeholder.Line />
                        </Placeholder.Paragraph>
                        <Placeholder.Paragraph>
                            <Placeholder.Line />
                            <Placeholder.Line />
                            <Placeholder.Line />
                        </Placeholder.Paragraph>
                    </Placeholder>
                </Segment>
            </div> : <div>

                {changeTab()}

            </div>
        }</>
    )
}

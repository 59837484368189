export default {
    colors: {
        primary: '#122f53',
        primaryHover: '#1d477b',
        primaryText: '#000000',
        secondary: '#a79d96',
        secondaryHover: '#c7c2bc',
        secondaryText: '#ffffff',
        mainBackground: '#f3f1ee',
        containerBackground: '#ffffff',
        cardText: "#000000",
        cardHeader: "#122f53",
        cardBackground: '#f3f1ee',
        border: '#d4d4d5',
        tabBackground: '#ffffff'
    },
    logo: ''
}
import './App.scss';
import Login from './pages/login/login';
import React, { useEffect, useState } from 'react';
import { LoadingOverlay, MantineProvider } from '@mantine/core';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Dashboard from './pages/home/home';
import Register from './pages/register/register';
import { auth, functions } from './firebase'
import { AlertsProvider } from './providers/Alerts/AlertsProvider'
import ResetPasswordPage from './pages/resetPassword/resetPassword';
import { saveLogs } from './components/overview/clientLogging';
import { CustomFonts } from 'fonts/CustomFonts';
import { useDispatch, useSelector } from 'react-redux';
import { resetAuthUser, setUserDetails } from './redux/authUser/authUserSlice';
import { loadUserData, resetFirestoreUser } from './redux/firestoreUser/firestoreUserSlice';
import GDPR from 'components/gdpr/gdpr';
import { toOverview, toClient, resetViewer } from './redux/viewer/viewerSlice';
import { fetchTokensAsync } from './redux/tokens/tokenSlice';
import { fetchBrandingAsync, fetchLogoAsync, restoreBranding, restoreLogo } from './redux/branding/brandingSlice';
import Debugger from 'components/utils/debugger/Debugger';
import AddCredits from 'pages/addCredits/addCredits';
import { fetchProducts, fetchStripe } from './redux/stripe/stripeSlice';
import Resources from 'pages/resources/resources';
import { setLoading } from './redux/loader/loaderSlice';
const UserContext = React.createContext({});

function App() {
  const firestoreUser = useSelector(state => state.firestoreUser)
  const authUser = useSelector(state => state.authUser)
  const logging = useSelector(state => state.logging)
  const viewer = useSelector(state => state.viewer)
  const { loading } = useSelector(state => state.loader)
  const dispatch = useDispatch()
  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        // console.log('auth state changed')
        const newDetails = {
          uid: user.uid,
          email: user.email,
        }
        dispatch(setUserDetails(newDetails))
        saveLogs(user.uid, { lastLogin: new Date().formatMonth() })
      } else {
        dispatch(setLoading(false));
        // console.log('no user')
        dispatch(resetAuthUser())
      }
    })
  }, [])

  useEffect(() => {
    // console.log(authUser)
    if (authUser.loaded) {
      dispatch(loadUserData(authUser.userDetails.uid))
      dispatch(fetchStripe(authUser.userDetails.uid))
    } else {
      // console.log('resetting firestore user')
      dispatch(resetFirestoreUser())
    }
  }, [authUser])

  useEffect(() => {
    // console.log(firestoreUser)
    if (firestoreUser.loaded) {
      const { role, coach } = firestoreUser.userData
      const uid = authUser.userDetails.uid
      dispatch(fetchLogoAsync(coach, role, uid))
      dispatch(fetchBrandingAsync(coach, role, uid))
      if (role === 'client') {
        // console.log('to client')
        dispatch(toClient({ id: authUser.userDetails.uid, name: 'You', role: firestoreUser.userData.role }))
      } else {
        dispatch(toOverview())
        dispatch(fetchTokensAsync(uid))
      }
      dispatch(setLoading(false));
    } else {
      // console.log('resetting viewer')
      dispatch(resetViewer())
      dispatch(restoreLogo())
      dispatch(restoreBranding())
    }
  }, [firestoreUser])


  useEffect(() => {
    // console.log('viewer', viewer)
  }, [viewer])

  useEffect(() => {
    // console.log('logging', logging)
  }, [logging])



  const isAuthenticated = () => {
    return !!authUser.loaded
  }






  return (
    <MantineProvider withGlobalStyles withNormalizeCSS theme={theme}
    ><CustomFonts />
      <GDPR />
      <AlertsProvider className="App">
        <BrowserRouter>
          <LoadingOverlay visible={loading} color='red' overlayOpacity={1}
            overlayColor="#fff"
          />
          {/* <Navbar /> */}
          <div className='main'>
            <Routes>
              <Route path="/" element={authUser.loaded ? <Dashboard /> : <Login />} />
              <Route path="/addCredits" element={authUser.loaded ? <AddCredits /> : <Login />} />
              <Route path="/addUser" element={authUser.loaded ? <Register /> : <Login />} />
              <Route path="/resources" element={authUser.loaded ? <Resources /> : <Login />} />
              <Route path="/reset-password/action" element={!authUser.loaded ? <ResetPasswordPage /> : <Login />} />
              <Route
                path="*"
                element={
                  <main style={{ padding: "1rem" }}>
                    <p>There's nothing here!</p>
                  </main>
                }
              />

            </Routes>
            {/* {process.env.REACT_APP_DEVELOPMENT === 'true' && <Debugger />} */}
          </div>
        </BrowserRouter>
      </AlertsProvider>
    </MantineProvider >
  );
}


export const useUserContext = () => React.useContext(UserContext)
export default App;

Date.prototype.formatMonth = function () {

  const monthNames = ["Jan", "Feb", "Mar", "Apr",
    "May", "Jun", "Jul", "Aug",
    "Sep", "Oct", "Nov", "Dec"];

  const day = this.getDate();

  const monthIndex = this.getMonth();
  const monthName = monthNames[monthIndex];

  const year = this.getFullYear();

  return `${day}-${monthName}-${year}`;
}

const theme = {
  colorScheme: 'light',
  fontFamily: 'Quicksand, sans-serif',
  headings: {
    fontWeight: 700,
    fontFamily: 'Montserrat, sans-serif',
  },
  primaryColorText: '#fff',
  white: '#fff',
  black: '#000',
  colors: {
    'red': [


      '#ffe1e8',
      '#ffb1bb',
      '#ff7f8e',
      '#ff4c63',
      '#ff1a36',
      '#e6001c',
      '#b40015',
      '#81000f',
      '#500006',
      '#210000',

    ],


    'beige': [
      "#c7c2bc",
      "#D5D3D1",
      "#C3BFBC",
      "#B4ADA9",
      "#A79D96",
      "#978D87",
      "#887F79",
      "#79736E",
      "#6C6764",
      "#605D5B",
      "#565452"
    ],
    'blue': [
      "#4A5869",
      "#404F62",
      "#36485D",
      "#2D4159",
      "#243A56",
      "#1B3454",
      "#122F53",
      "#162B45",
      "#18273A",
      "#192431",
      "#18202A",
      "#181D24",
      "#161A1F"
    ]
  },
  primaryColor: 'red',
  primaryShade: 8,
  components: {
    Container: {
      defaultProps: {
        sizes: {
          xs: 540,
          sm: 720,
          md: 960,
          lg: 1140,
          xl: 1320,
        },
      },
    },
  },
}



import React from 'react'
import { Button, CommentContent, Icon } from 'semantic-ui-react'
import OptionalModal from '../optionalModal'
import { useState } from 'react'
import { Modal } from 'semantic-ui-react'
import { useEffect } from 'react'
import { useHover } from '@mantine/hooks'
import { ActionIcon, Button as MantineButton, Title } from '@mantine/core'
export function DeleteButton(props) {
    const [open_modal, setOpenModal] = useState(false)
    function click() {
        setOpenModal(!open_modal)
    }
    function confirm() {
        click()
        props.click()
    }
    return (
        <>
            <Modal open={open_modal} onClose={() => click()} >
                <Modal.Header>
                    Are you sure?
                </Modal.Header>
                <Modal.Content>
                    <Button onClick={() => confirm()}>Confirm</Button>
                </Modal.Content>
            </Modal>
            <Button className='red' onClick={() => click()} icon >
                <Icon name='trash' />
            </Button>
        </>

    )
}

export function WarningButton({ click, modalHeaderText, confirmText, iconName, buttonText }) {
    const [open_modal, setOpenModal] = useState(false)

    useEffect(() => {
        if (!modalHeaderText) {
            modalHeaderText = "Are you sure?"
        }
        if (!confirmText) {
            confirmText = "Confirm"
        }
        if (!iconName) {
            iconName = "undo"
        }

    }
        , [])


    function toggleModal() {
        setOpenModal(!open_modal)
    }
    function confirm() {
        toggleModal()
        click()
    }
    return (
        <div>
            <Modal open={open_modal} onClose={() => toggleModal()} >
                <Modal.Header>
                    {modalHeaderText || "Are you sure?"}
                </Modal.Header>
                <Modal.Content>
                    <Button onClick={() => confirm()}>{confirmText || "Confirm"}</Button>
                </Modal.Content>
            </Modal>
            <Button onClick={() => toggleModal()} icon >
                <Icon name={iconName} /> {buttonText}
            </Button>

        </div>
    )

}


export function ResetButtonWarning(props) {
    const [open_modal, setOpenModal] = useState(false)

    function click(e) {
        e.stopPropagation();

        setOpenModal(!open_modal)
    }
    function confirm(e) {
        click(e)
        props.click(e)
    }
    return (
        <>
            <Modal open={open_modal} onClose={(e) => click(e)} >
                <Modal.Header>
                    Are you sure?
                </Modal.Header>
                <Modal.Content>
                    <Button onClick={(e) => confirm(e)}>Confirm</Button>
                </Modal.Content>
            </Modal>
            <Button onClick={(e) => click(e)} icon >
                <Icon name='undo' /> {props?.text}
            </Button>
        </>)
}

export function DeleteFButton(props) {
    const [open_modal, setOpenModal] = useState(false)
    function click() {
        setOpenModal(!open_modal)
    }
    function confirm() {
        click()
        props.click()
    }
    return (
        <>
            <Modal open={open_modal} onClose={() => click()} >
                <Modal.Header>
                    Are you sure?
                </Modal.Header>
                <Modal.Content>
                    <Button onClick={() => confirm()}>Confirm</Button>
                </Modal.Content>
            </Modal>
            <Icon name='delete' onClick={() => click()} />

        </>

    )
}
export function SaveButton(props) {
    return (
        <Button className='green' onClick={() => props.click()} icon >
            <Icon name='save' />
        </Button>
    )
}
export function EditButton(props) {
    return (<div className='editButton'>
        <Button icon primary onClick={() => props.click()}>
            <Icon name='edit' />
        </Button>
    </div>)
}
export function CancelButton(props) {
    return (
        <Button icon onClick={() => props.click()}>
            <Icon name='undo' />
        </Button>)
}

export function ResetButton(props) {
    return (
        <Button icon onClick={() => props.click()}>
            <Icon name='reset' />
        </Button>)
}
export function ProgrezrButton (props) {
    const {hovered, ref} = useHover()
    return (
        <MantineButton  ref={ref} icon onClick={() => props.click()}>
            <Title order={5}>{props.text}</Title>
        </MantineButton>)
}
export function ProgrezrButtonPrimaryIcon (props) {
    return(
    <ActionIcon variant='transparent' color='white' variant={'transparent'}  size='md' onClick={() => props.click()}>
        {props.children}
    </ActionIcon>)
}

import React, { useState, createRef, useEffect } from 'react'
import { useClientContext } from '../../../providers/ClientProvider/ClientProvider'
import topicLevels from '../../utils/topicLevels'
import './progress.scss'
import actionTypes, { statusColors } from 'components/utils/actionTypes'
import { Center, Divider, Group, Paper, rem, RingProgress, SimpleGrid, Stack, Text, Title, Tooltip, Button, Grid, Progress, Box, ThemeIcon } from '@mantine/core'
import { NormalButton, PageMenu, PrimaryTextUpperCase } from 'components/utils/MantineUtils'
import { IconArrowBackUp, IconArrowBadgeUpFilled, IconArrowRotaryLastLeft, IconArrowUpBar, IconCircle0Filled, IconCircleNumber0, IconDatabase, IconNumber0, IconSquareRoundedNumber0Filled } from '@tabler/icons-react'
import { useMediaQuery } from '@mantine/hooks'
import { useAlertsContext } from 'providers/Alerts/AlertsProvider'
import { useSelector } from 'react-redux'
export function progressBarColor(value, total) {
    if (value === total && value > 0) {
        return 'green'
    } else if (value > 0 && value < total) {
        return 'red'
    } else {
        return 'gray.3'
    }
}

export default function ProgressComponent(props) {
    const matches = useMediaQuery('(min-width: 768px)')

    const cContext = useClientContext()
    const topics = cContext.clientData.initialProposal ? cContext.clientData.initialProposal['T'] : []
    const notes = cContext.clientData.sessionNotes ? cContext.clientData.sessionNotes['N'] : []
    const timesheetRecords = cContext.clientData.timesheet ? cContext.clientData.timesheet['R'] : []


    const assignment = cContext.clientData.bio.assignment
    const actions = cContext.listActions(notes)
    const finished = actions.filter(x => x.status === 'Completed').length
    const total = actions.filter(x => x.status !== 'Cancelled').length
    const percentage = total >= 1 && finished / total * 100
    const budget = Number.isInteger(Number.parseInt(assignment.totalBudget)) ? Number.parseInt(assignment.totalBudget) : 1
    const amountTotalSessions = Number.isInteger(Number.parseInt(assignment.totalAmountSessions)) ? Number.parseInt(assignment.totalAmountSessions) : 1
    const amountSessions = getCurrentAmountSessions()
    // const budgetSpent = getBudgetSpent()
    const percentageBudget = budget >= 1 && getTotalAmount() / budget * 100
    const percentageSessions = amountTotalSessions >= 1 && amountSessions / amountTotalSessions * 100
    const [height, setHeight] = useState(0)
    const [scrollTop, setScrollTop] = useState(0)
    const [editing, setEditing] = useState(false)
    const [editedTopics, setEditedTopics] = useState(topics === undefined ? [] : JSON.parse(JSON.stringify(topics)))

    const sticky = createRef()


    function getCurrentAmountSessions() {
        let max = 0
        if (notes === undefined || notes.length === 0) {
            return 0
        }
        notes.forEach(note => {
            let session = Number.parseInt(note.session)
            if (Number.isInteger(session) && session > max) {
                max = session
            }
        })
        return max
    }
    function ringSize() {
        if (matches) {
            return 100
        } else {
            return 50
        }
    }
    function strokeSize() {
        if (matches) {
            return 20
        } else {
            return 15
        }
    }

    function getBudgetSpent() {
        let ratePerHour = Number.parseInt(assignment.ratePerHour)
        let sessionDuration = Number.parseInt(assignment.sessionDuration)
        if (Number.isInteger(ratePerHour) &&
            Number.isInteger(sessionDuration)) {
            const sessionDurationInHours = sessionDuration / 60
            const res = (sessionDurationInHours * ratePerHour * amountSessions)
            return res

        }
        return 0
    }

    useEffect(() => {
        if (topics === undefined) {
            setEditedTopics([])
            // save()
        }
    }, [topics, editedTopics])


    function save() {
        const old = Object.assign({}, cContext.clientData.initialProposal)
        old['T'] = editedTopics
        cContext.saveClientInitialProposal(old)
    }


    function handleChange(i, value) {
        let old = Object.assign([], editedTopics)
        const now = new Date()
        old[i].date = formatDate(now)
        old[i].level = value
        setEditedTopics(old)
        save()
    }

    function formatDate(day) {
        const yyyy = day.getFullYear();
        let mm = day.getMonth() + 1; // Months start at 0!
        let dd = day.getDate();

        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;

        return dd + '-' + mm + '-' + yyyy;
    }

    function getTopicActions(topicName, notes) {
        let newActions = []
        if (notes === undefined || notes.length === 0) {
            return []
        }
        notes.forEach(element => {
            if (element.topic === topicName) {
                newActions = [...newActions, ...element.actions]
            }
        })
        return newActions
    }

    function totalAmount(hourlyRate, duration) {
        return hourlyRate * duration
    }

    function calculateDuration(st, et) {
        if (st && et) {
            let start = st
            let end = et
            start = start.split(":");
            end = end.split(":");
            const startDate = new Date(0, 0, 0, start[0], start[1], 0);
            const endDate = new Date(0, 0, 0, end[0], end[1], 0);
            let diff = endDate.getTime() - startDate.getTime();
            const hours = Math.floor(diff / 1000 / 60 / 60);
            diff -= hours * 1000 * 60 * 60;
            const minutes = Math.floor(diff / 1000 / 60);
            return hours + Math.ceil(minutes / 60);

            // return (hours < 9 ? "0" : "") + hours + ":" + (minutes < 9 ? "0" : "") + minutes;
        }

        return 0
    }




    function getTotalAmount() {
        let res = 0
        timesheetRecords.forEach(record => {
            const duration = calculateDuration(record.startTime, record.endTime)

            if (record.singleCharge) {
                res += parseInt(record.hourlyRate)
            } else {
                const amount = parseInt(totalAmount(record.hourlyRate, duration))
                res += (amount && amount > 0) ? amount : 0
            }
        })
        return res

    }

    return (
        <>
            <Paper p={'md'} shadow={'lg'} withBorder sx={
                {
                    position: 'sticky',
                    top: 69,
                    padding: '20px',
                    zIndex: 2,
                    transition: 'all 0.3s ease',
                }
            }>
                <PageMenu sectionType={'None'} name={'Progress'} />
                {/* <Group position='apart'>
                    <Title py={"md"} order={3} tt={"uppercase"}>
                        Progress
                    </Title>


                </Group> */}

            </Paper>
            <Divider my={'lg'} />
            <Grid cols={3} gutter={'xs'}  >
                <Grid.Col span={matches ? 4 : 12}>
                    <Paper h={'100%'} p={"md"} shadow="lg" withBorder>
                        <Title order={6} tt={"uppercase"}>
                            Sessions
                        </Title>
                        <Title color={'gray'} order={6} tt={"uppercase"}>
                            Completed
                        </Title>
                        <Center>
                            <RingProgress size={200} thickness={strokeSize()} sections={[
                                { value: (amountSessions / amountTotalSessions * 100), color: progressBarColor(amountSessions, amountTotalSessions) },
                            ]}
                                label={
                                    <Center>
                                        <Title order={6} color={progressBarColor(amountSessions, amountTotalSessions)} tt={"uppercase"}>
                                            {(amountSessions / amountTotalSessions * 100).toFixed(0)}%
                                        </Title>
                                    </Center>
                                }
                            />
                        </Center>
                        <Title color={'gray'} ta={'center'} order={6} tt={"uppercase"}>
                            {amountSessions}/{amountTotalSessions}
                        </Title>
                    </Paper>

                </Grid.Col>
                <Grid.Col span={matches ? 4 : 12}>
                    <Paper p={"md"} shadow="lg" withBorder h={'100%'}>
                        <Title order={5} tt={"uppercase"}>
                            Budget
                        </Title>
                        <Title color={'gray'} order={6} tt={"uppercase"}>
                            Spent
                        </Title>
                        <Center>
                            <RingProgress
                                size={200} thickness={strokeSize()} sections={[
                                    { value: (getTotalAmount() / budget * 100), color: progressBarColor(getTotalAmount(), budget) },
                                ]}
                                label={
                                    <Center>
                                        <Title order={6} tt={"uppercase"} color={progressBarColor(getTotalAmount(), budget)}>
                                            {(getTotalAmount() / budget * 100).toFixed(0)}%
                                        </Title>
                                    </Center>
                                }
                            />
                        </Center>
                        <Title color={'gray'} ta={'center'} order={6} tt={"uppercase"}>
                            {getTotalAmount()}/{budget}
                        </Title>
                    </Paper>

                </Grid.Col>
                <Grid.Col span={matches ? 4 : 12}>
                    <Paper p={"md"} shadow="lg" withBorder >
                        <Title order={5} tt={"uppercase"}>
                            Actions (All)
                        </Title>

                        {Object.keys(actionTypes).map((actionType, index) => {
                            return (
                                <Box key={index}
                                    my={'md'}

                                >
                                    <Title color={'gray'} order={6} tt={"uppercase"}>
                                        {actionType} ({actions.filter(action => action.status === actionType).length})
                                    </Title>
                                    <Box>
                                        <Progress size={'lg'} value={actions.filter(action => action.status === actionType).length / actions.length * 100} color={statusColors[actionType]} />
                                    </Box>
                                </Box>
                            )
                        })}
                    </Paper>
                </Grid.Col>
            </Grid>
            <Divider my={'lg'} />
            <SimpleGrid cols={matches ? 2 : 1}>
                {(notes && notes.length > 0) && editedTopics.map((topic, i) => {

                    return (
                        <P key={i} P={topic} ringSize={ringSize} strokeSize={strokeSize} actions={getTopicActions(topic.name, notes)} handleChange={(value) => handleChange(i, value)} />
                    )
                })}
            </SimpleGrid>
        </>
    )
}

export function P(props) {
    const P = props.P
    const actions = props.actions
    const visibleLvl = parseInt(P.level) + parseInt(1)
    const finished = actions.filter(x => x.status === 'Completed').length
    const total = actions.filter(x => x.status !== 'Cancelled').length
    const percentage = total >= 1 && finished / total * 100
    const { addAlert } = useAlertsContext()
    const matches = useMediaQuery('(min-width:768px)')

    function handleClick(level) {
        if (level > P.level) {
            addAlert({
                header: 'Level up!',
                content: `Congratulations, you have leveled up to ${topicLevels[level]} !`,
                variation: 'positive',
                timeout: 4000
            })
        }
        props.handleChange(level)
    }

    return (
        <Paper p={"md"} my={'md'} shadow="lg" withBorder>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'stretch',
                    justifyContent: 'space-between',
                    '@media screen and (max-width: 768px)': {
                        flexDirection: 'column',
                        alignItems: 'stretch',
                        justifyContent: 'center',
                    },
                }}

            >
                <Box w={matches ? '50%' : '100%'} mih={'150px'} justify={'space-between'} spacing={'xs'}>
                    <Title order={5} tt={"uppercase"} sx={{
                        wordBreak: 'break-all'
                    }}>
                        {P.name}:
                    </Title>
                    <Title order={6} color={'gray'}> {topicLevels[P.level]}</Title>
                    <Center mt={'lg'} h={'100%'}>
                        <Group>
                            {topicLevels.map((level, i) => {
                                return (
                                    <Tooltip label={i < P.level ? `Level down to ${topicLevels[i]}` : i === P.level ? `You are now on level: ${topicLevels[i]}` : `Level up to ${topicLevels[i]}`} >
                                        <ThemeIcon
                                            sx={{
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => handleClick(i)}
                                            key={i} name={P.icon} size={'xl'} variant={i <= P.level ? 'filled' : 'outline'} color={i <= P.level ? 'green' : 'red'} />
                                    </Tooltip>
                                )
                            })
                            }
                        </Group>
                    </Center>
                    {/* <Group position='apart'>
                        {
                        visibleLvl >
                        1 && <NormalButton fn={() => props.handleChange(parseInt(P.level) - 1)} color={'red'}>
                            <Tooltip label="Go back to previous level" placement="top">
                                <IconArrowBackUp />
                            </Tooltip>
                        </NormalButton>
                    }

                    </Group> */}

                </Box>
                <Box w={matches ? '50%' : '100%'}>
                    {Object.keys(actionTypes).map((actionType, index) => {
                        return (
                            <Box key={index}
                                my={'md'}
                            // sx={{
                            //     display: 'flex',
                            //     flexDirection: 'column',
                            //     alignItems: 'center',
                            //     justifyContent: 'center',
                            // }}
                            >
                                <Title color={'gray'} order={6} tt={"uppercase"}>
                                    {actionType} ({actions.filter(action => action.status === actionType).length})
                                </Title>
                                <Box>
                                    <Progress size={'lg'} value={actions.filter(action => action.status === actionType).length / actions.length * 100} color={statusColors[actionType]} />
                                </Box>
                            </Box>
                        )
                    })}
                </Box>
            </Box>
            {/* <Group position='apart' noWrap mt={'md'}>
                <Stack >

                    <Center>
                        <RingProgress size={props.ringSize()} thickness={props.strokeSize()} sections={[
                            { value: ((visibleLvl) / 4 * 100), color: progressBarColor(visibleLvl, 4) },
                        ]} />

                    </Center>
                    <Title order={6} tt={'uppercase'} ta={'center'} color={progressBarColor(visibleLvl, 4)}>
                        Level {visibleLvl}
                    </Title>
                </Stack>
                <Stack>

                    {
                        visibleLvl <= 3 ? <>
                            <Center>
                                <Title order={6} color={progressBarColor((P.level + 1), 4)} tt={'uppercase'} ta={'center'} >
                                    Next level is {topicLevels[P.level + 1]}
                                </Title>
                            </Center>
                            <Grid align={'center'}>
                                <Grid.Col span={2}>
                                    <Title order={6} color={progressBarColor((P.level + 1), 4)} tt={'uppercase'} ta={'right'} >
                                        {P.level + 1}
                                    </Title>
                                </Grid.Col>
                                <Grid.Col span={8}>
                                    <Progress value={50} progress='ratio' />
                                </Grid.Col>
                                <Grid.Col span={2}>
                                    <Title order={6} color={progressBarColor((P.level + 2), 4)} tt={'uppercase'} ta={'left'} >
                                        {P.level + 2}
                                    </Title>
                                </Grid.Col>
                            </Grid>
                            <Center>
                                <Button
                                    onClick={() => {
                                        props.handleChange(parseInt(P.level) + 1);
                                    }}
                                    leftIcon={<IconArrowBadgeUpFilled size="2rem" />}>
                                    <Title order={6} tt={'uppercase'} ta={'center'} >
                                        Next level
                                    </Title>
                                </Button>


                            </Center>

                        </> :
                            <Title order={6} tt={'uppercase'} ta={'center'} color={'green'}>Congratulations, you have gained {topicLevels[P.level]} for the topic of <Text fw={'bolder'} fs={'italic'} span> {P.name}
                            </Text> </Title>

                    }
                </Stack>
                <Stack>

                    <Center>
                        <RingProgress size={props.ringSize()} thickness={props.strokeSize()} sections={[
                            { value: ((finished) / total * 100), color: progressBarColor(finished, total) },
                        ]} />

                    </Center>
                    <Title order={6} tt={'uppercase'} ta={'center'} color={progressBarColor(finished, total)}>
                        {(finished)}/{total} actions completed
                    </Title>
                </Stack>
            </Group> */}

        </Paper >
        // <Message className="P">
        //     <div className="row-grid">
        //         <div className="data">
        //             <Header as={'h4'}>{P.name}</Header>
        //             <Form>
        //                 <Form.Field>
        //                     <label className='mobile-hidden'>Self assessment: </label>
        //                 </Form.Field>
        //             </Form>
        //             {/* <span>{P.date ? P.date : 'Not started'}</span> */}
        //         </div>
        //         <div className={"level level" + (visibleLvl > 1 ? visibleLvl > 2 ? visibleLvl > 3 ? '-level3' : '-level2' : '-level1' : '-level0')}>

        //             {/* <div className={"level"}> */}
        //             <Form>
        //                 <Form.Input
        //                     label={topicLevels[P.level] + ' (level: ' + visibleLvl + ')'}
        //                     min={0}
        //                     max={3}
        //                     name='level'
        //                     onChange={(e, { name, value }) => props.handleChange(value)}
        //                     step={1}
        //                     type='range'
        //                     value={P.level}
        //                     className={UContext.role == 'coach' ? "disabled" : ""}
        //                 />
        //             </Form>
        //         </div>
        //     </div>
        //     <div className={"row-grid row-grid-bar row-grid-bar" + (percentage > 25 ? percentage > 50 ? percentage > 75 ? '-level3' : '-level2' : '-level1' : '-level0')}>
        //         <div></div>
        //         <Progress value={finished} total={total} progress='ratio'   >
        //             Actions complete
        //         </Progress>
        //     </div>

        // </Message >
    )
}
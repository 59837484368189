import React, { useEffect, useState, createRef } from "react";

import { useClientContext } from "../../../providers/ClientProvider/ClientProvider";
// import "./bio.scss";
import BItem from "../../utils/BItem";
import bioState from "./bioState";
import CoachItem from "../../utils/CoachItem";
import ClientService from "../ClientService";
import { typeOptions, currencyOptions } from "./bioUtils";

import { Link } from "react-router-dom";
import {
    Divider,
    Group,
    Paper,
    Select,
    SimpleGrid,
    Stack,
    Text,
    TextInput,
    Textarea,
    Title,
    Box,
    ScrollArea,
    LoadingOverlay,
} from "@mantine/core";
import { ErrorIcon, NormalButton, PageMenu, SafeDateInput, WarningIcon } from "components/utils/MantineUtils";
import { DateInput } from "@mantine/dates";
import dayjs from "dayjs";
import { useMediaQuery } from "@mantine/hooks";

export default function BioComponent(props) {
    const cContext = useClientContext();
    let bio = cContext.clientData.bio;
    const matches = useMediaQuery('(max-width: 768px)');
    const [height, setHeight] = useState(0);
    const [scrollTop, setScrollTop] = useState(0);
    const [editing, setEditing] = useState(false);
    const [editedBio, setEditedBio] = useState(
        bio === undefined ? {} : JSON.parse(JSON.stringify(bio))
    );


    let cs = new ClientService(setEditedBio, setScrollTop);

    useEffect(() => {
        if (bio === undefined) {
            setEditedBio(bioState);
            save();
        }
    }, [bio, editedBio]);



    function cancel() {
        setEditedBio(bio);
        setEditing(false);
    }

    function save() {
        setEditing(false);
        cContext.saveClientBio(editedBio);
    }
    function handleChange(data, first, second) {
        setEditedBio((prevState) => ({
            ...prevState,
            [first]: {
                ...prevState[first],
                [second]: data,
            },
        }));
    }
    function toggleEditing() {
        setEditing(!editing);
    }

    return (
        <>
            <Paper p={"md"} shadow="lg" withBorder
                sx={
                    {
                        position: 'sticky',
                        top: 69,
                        padding: '20px',
                        zIndex: 2,
                        transition: 'all 0.3s ease',
                        // borderBottom: '1px solid #ddd',

                    }
                }>
                <PageMenu sectionType={'Coach'} name={'Client Info'} editing={editing} toggleEditing={toggleEditing} save={save} cancel={cancel} />
                {/* <Group


                    className={(scrollTop > height ? "scrolled" : '')} >
                    <Title py={"md"} order={3} tt={"uppercase"}>
                        General Info
                    </Title>
                    <CoachItem>
                        {!editing ? (
                            <NormalButton fn={toggleEditing}>
                                <IconEdit color="orange"

                                />
                            </NormalButton>
                        ) : (
                            <Group>
                                <NormalButton fn={save}>
                                    <IconDeviceFloppy color="green" />
                                </NormalButton>
                                <NormalButton fn={cancel}>
                                    <IconArrowBackUp color="red" />
                                </NormalButton>
                            </Group>
                        )}
                    </CoachItem>
                </Group> */}
            </Paper>
            <Divider my={'lg'} />
            <LoadingOverlay visible={bio === undefined} overlayBlur={2} />
            {bio === undefined ? <></> :
                <SimpleGrid cols={matches ? 1 : 3} spacing={"md"}>
                    <Paper p={"md"} shadow="lg" withBorder>
                        <Title order={4} tt={"uppercase"}>
                            Client Contact
                        </Title>
                        <Divider size={"xs"} my={"md"} />
                        <Stack spacing={"xs"}>
                            <ClientTextInput
                                editedBio={editedBio}
                                editing={editing}
                                label={"clientContact"}
                                name={"name"}
                                handleChange={handleChange}
                                error
                            />
                            <ClientTextInput
                                editedBio={editedBio}
                                editing={editing}
                                label={"clientContact"}
                                name={"streetAndNr"}
                                handleChange={handleChange}
                                error
                            ></ClientTextInput>
                            <ClientTextInput
                                editedBio={editedBio}
                                editing={editing}
                                label={"clientContact"}
                                name={"postcode"}
                                handleChange={handleChange}
                                error
                            ></ClientTextInput>
                            <ClientTextInput
                                editedBio={editedBio}
                                editing={editing}
                                label={"clientContact"}
                                name={"city"}
                                handleChange={handleChange}
                                error
                            ></ClientTextInput>
                            <ClientTextInput
                                editedBio={editedBio}
                                editing={editing}
                                label={"clientContact"}
                                name={"country"}
                                handleChange={handleChange}
                                error
                            ></ClientTextInput>
                            <ClientTextInput
                                editedBio={editedBio}
                                editing={editing}
                                label={"clientContact"}
                                name={"email"}
                                handleChange={handleChange}
                                error
                            ></ClientTextInput>
                            <ClientTextInput
                                editedBio={editedBio}
                                editing={editing}
                                label={"clientContact"}
                                name={"telephone"}
                                handleChange={handleChange}
                                error
                            ></ClientTextInput>
                            <ClientTextInput
                                editedBio={editedBio}
                                editing={editing}
                                label={"clientContact"}
                                name={"linkedIn"}
                                override="LinkedIn"
                                link
                                column
                                handleChange={handleChange}
                            ></ClientTextInput>
                        </Stack>
                    </Paper>
                    <Paper p={"md"} shadow="lg" withBorder>
                        <Title order={4} tt={"uppercase"}>
                            Client Experience
                        </Title>

                        <Divider size={"xs"} my={"md"} />
                        <Stack spacing={"xs"}>
                            <ClientTextInput
                                editedBio={editedBio}
                                editing={editing}
                                label={"clientExperience"}
                                name={"company"}
                                handleChange={handleChange}
                            ></ClientTextInput>
                            <ClientTextInput
                                editedBio={editedBio}
                                editing={editing}
                                label={"clientExperience"}
                                name={"jobTitle"}
                                handleChange={handleChange}
                            ></ClientTextInput>
                            <ClientTextInput
                                editedBio={editedBio}
                                editing={editing}
                                label={"clientExperience"}
                                name={"function"}
                                handleChange={handleChange}
                            ></ClientTextInput>
                            <ClientTextInput
                                editedBio={editedBio}
                                editing={editing}
                                label={"clientExperience"}
                                name={"department"}
                                handleChange={handleChange}
                            ></ClientTextInput>
                            <ClientTextInput
                                editedBio={editedBio}
                                editing={editing}
                                label={"clientExperience"}
                                name={"managementLevel"}
                                handleChange={handleChange}
                            ></ClientTextInput>
                            <ClientTextInput
                                editedBio={editedBio}
                                editing={editing}
                                label={"clientExperience"}
                                name={"yearsInCurrentRole"}
                                handleChange={handleChange}
                            ></ClientTextInput>
                            <ClientTextInput
                                editedBio={editedBio}
                                editing={editing}
                                label={"clientExperience"}
                                date
                                name={"dateOfBirth"}
                                handleChange={handleChange}
                            ></ClientTextInput>
                            <ClientTextInput
                                editedBio={editedBio}
                                editing={editing}
                                label={"clientExperience"}
                                name={"companySite"}
                                link
                                column
                                handleChange={handleChange}
                            ></ClientTextInput>
                            <ClientTextInput
                                editedBio={editedBio}
                                editing={editing}
                                label={"clientExperience"}
                                name={"additionalNotes"}
                                column
                                freetext
                                handleChange={handleChange}
                            ></ClientTextInput>
                        </Stack>
                    </Paper>
                    <Paper p={"md"} shadow="lg" withBorder>
                        <Title order={4} tt={"uppercase"}>
                            Invoice Information{" "}
                        </Title>
                        <Divider size={"xs"} my={"md"} />
                        <Stack spacing={"xs"}>
                            <ClientTextInput
                                editedBio={editedBio}
                                editing={editing}
                                label={"invoicingInformation"}
                                name={"company"}
                                handleChange={handleChange}
                                error
                            ></ClientTextInput>
                            <ClientTextInput
                                editedBio={editedBio}
                                editing={editing}
                                label={"invoicingInformation"}
                                name={"department"}
                                handleChange={handleChange}
                                warning
                            ></ClientTextInput>
                            <ClientTextInput
                                editedBio={editedBio}
                                editing={editing}
                                label={"invoicingInformation"}
                                name={"streetAndNr"}
                                handleChange={handleChange}
                                error
                            ></ClientTextInput>
                            <ClientTextInput
                                editedBio={editedBio}
                                editing={editing}
                                label={"invoicingInformation"}
                                name={"postcode"}
                                handleChange={handleChange}
                                error
                            ></ClientTextInput>
                            <ClientTextInput
                                editedBio={editedBio}
                                editing={editing}
                                label={"invoicingInformation"}
                                name={"city"}
                                handleChange={handleChange}
                                error
                            ></ClientTextInput>
                            <ClientTextInput
                                editedBio={editedBio}
                                editing={editing}
                                label={"invoicingInformation"}
                                name={"country"}
                                handleChange={handleChange}
                                error
                            ></ClientTextInput>
                            <ClientTextInput
                                editedBio={editedBio}
                                editing={editing}
                                label={"invoicingInformation"}
                                name={"vatNr"}
                                handleChange={handleChange}
                                warning
                            ></ClientTextInput>
                            <ClientTextInput
                                editedBio={editedBio}
                                editing={editing}
                                label={"invoicingInformation"}
                                name={"accountNr"}
                                handleChange={handleChange}
                                warning
                            ></ClientTextInput>

                            <ClientTextInput
                                editedBio={editedBio}
                                editing={editing}
                                label={"invoicingInformation"}
                                name={"PONumber"}
                                override={"PO number"}
                                handleChange={handleChange}
                                warning
                            ></ClientTextInput>
                            <ClientTextInput
                                editedBio={editedBio}
                                editing={editing}
                                label={"invoicingInformation"}
                                name={"additionalNotes"}
                                column
                                freetext
                                handleChange={handleChange}

                            ></ClientTextInput>
                        </Stack>
                    </Paper>
                    <Paper p={"md"} shadow="lg" withBorder>
                        <Title order={4} tt={"uppercase"}>
                            Assignment
                        </Title>
                        <Divider size={"xs"} my={"md"} />
                        <Stack spacing={"xs"}>
                            <Group position={"apart"}>
                                <Text fw={'bold'}>Sponsored</Text>
                                <BItem editing={editing}>
                                    <Text>
                                        {editedBio?.assignment?.sponsored || "-"}
                                    </Text>
                                    <Select
                                        maw={"140px"}
                                        data={typeOptions}
                                        value={editedBio?.assignment?.sponsored}
                                        onChange={(event) =>
                                            handleChange(
                                                event,
                                                "assignment",
                                                "sponsored"
                                            )
                                        }
                                    ></Select>
                                </BItem>
                            </Group>
                            <ClientTextInput
                                editedBio={editedBio}
                                editing={editing}
                                label={"assignment"}
                                name={"duration"}
                                override={"Duration in months"}
                                handleChange={handleChange}
                            ></ClientTextInput>
                            <ClientTextInput
                                editedBio={editedBio}
                                editing={editing}
                                label={"assignment"}
                                name={"sessionFrequency"}
                                override={"Session frequency p/m"}
                                handleChange={handleChange}
                            ></ClientTextInput>
                            <ClientTextInput
                                editedBio={editedBio}
                                editing={editing}
                                label={"assignment"}
                                name={"sessionDuration"}
                                override={"Session duration in min"}
                                handleChange={handleChange}
                            ></ClientTextInput>
                            <ClientTextInput
                                editedBio={editedBio}
                                editing={editing}
                                label={"assignment"}
                                name={"totalAmountSessions"}
                                override={"Total nr of sessions"}
                                handleChange={handleChange}
                            ></ClientTextInput>
                            <ClientTextInput
                                editedBio={editedBio}
                                editing={editing}
                                label={"assignment"}
                                name={"totalBudget"}
                                override={"Total budget (ex. VAT)"}
                                handleChange={handleChange}
                            ></ClientTextInput>
                            <ClientTextInput
                                editedBio={editedBio}
                                editing={editing}
                                label={"assignment"}
                                name={"ratePerHour"}
                                override={"Rate per hour"}
                                handleChange={handleChange}
                            ></ClientTextInput>
                            <ClientTextInput
                                editedBio={editedBio}
                                editing={editing}
                                label={"assignment"}
                                name={"chargeForCommutingPerSession"}
                                override={"Session travel charge"}
                                handleChange={handleChange}
                            ></ClientTextInput>
                            <ClientTextInput
                                editedBio={editedBio}
                                editing={editing}
                                label={"assignment"}
                                name={"ChargeForPreperation"}
                                override={"Preperation charge"}
                                handleChange={handleChange}
                            ></ClientTextInput>
                            <ClientTextInput
                                editedBio={editedBio}
                                editing={editing}
                                label={"assignment"}
                                name={"chargeForFollowUp"}
                                override={"Follow up charge"}
                                handleChange={handleChange}
                            ></ClientTextInput>
                            <ClientTextInput
                                editedBio={editedBio}
                                editing={editing}
                                label={"assignment"}
                                name={"additionalCharges"}
                                handleChange={handleChange}
                            ></ClientTextInput>
                            <Group position={"apart"}>
                                <Group>
                                    <Text fw={'bold'}>Currency</Text>
                                    <CoachItem>
                                        <ErrorIcon error={!editedBio.assignment?.currency} errorText={`Currency needs to be filled in for Invoicing to work correctly`} />
                                    </CoachItem>
                                </Group>
                                <BItem editing={editing}>
                                    <Text >
                                        {editedBio.assignment?.currency || "-"}
                                    </Text>
                                    <Select
                                        maw={"140px"}
                                        data={currencyOptions}
                                        value={editedBio.assignment?.currency}
                                        onChange={(event) =>
                                            handleChange(
                                                event,
                                                "assignment",
                                                "currency"
                                            )
                                        }
                                    ></Select>
                                </BItem>
                            </Group>
                            <ClientTextInput
                                editedBio={editedBio}
                                editing={editing}
                                label={"assignment"}
                                name={"vatPercentage"}
                                override={"VAT %"}
                                error={true}
                                handleChange={handleChange}

                            ></ClientTextInput>
                            <ClientTextInput
                                editedBio={editedBio}
                                editing={editing}
                                label={"assignment"}
                                name={"paymentTermInDays"}
                                override={"Payment term in days"}
                                handleChange={handleChange}
                                error={true}
                            ></ClientTextInput>

                            <ClientTextInput
                                editedBio={editedBio}
                                editing={editing}
                                label={"assignment"}
                                name={"additionalNotes"}
                                freetext
                                column
                                handleChange={handleChange}
                            ></ClientTextInput>
                        </Stack>
                    </Paper>
                    {editedBio?.assignment?.sponsored === "Yes" && (
                        <Paper p={"md"} shadow="lg" withBorder>
                            <Title order={4} tt={"uppercase"}>
                                Sponsor
                            </Title>
                            <Divider size={"xs"} my={"md"} />
                            <Stack spacing={"xs"}>
                                <ClientTextInput
                                    editedBio={editedBio}
                                    editing={editing}
                                    label={"sponsor"}
                                    name={"company"}
                                    handleChange={handleChange}
                                ></ClientTextInput>
                                <ClientTextInput
                                    editedBio={editedBio}
                                    editing={editing}
                                    label={"sponsor"}
                                    name={"managerName"}
                                    handleChange={handleChange}
                                ></ClientTextInput>
                                <ClientTextInput
                                    editedBio={editedBio}
                                    editing={editing}
                                    label={"sponsor"}
                                    name={"managerEmail"}
                                    handleChange={handleChange}
                                ></ClientTextInput>
                                <ClientTextInput
                                    editedBio={editedBio}
                                    editing={editing}
                                    label={"sponsor"}
                                    name={"hrName"}
                                    override="HR name"
                                    handleChange={handleChange}
                                ></ClientTextInput>
                                <ClientTextInput
                                    editedBio={editedBio}
                                    editing={editing}
                                    label={"sponsor"}
                                    name={"hrEmail"}
                                    override="HR email"
                                    handleChange={handleChange}
                                ></ClientTextInput>
                                <ClientTextInput
                                    editedBio={editedBio}
                                    editing={editing}
                                    label={"sponsor"}
                                    name={"streetAndNr"}
                                    handleChange={handleChange}
                                ></ClientTextInput>
                                <ClientTextInput
                                    editedBio={editedBio}
                                    editing={editing}
                                    label={"sponsor"}
                                    name={"postcode"}
                                    handleChange={handleChange}
                                ></ClientTextInput>
                                <ClientTextInput
                                    editedBio={editedBio}
                                    editing={editing}
                                    label={"sponsor"}
                                    name={"city"}
                                    handleChange={handleChange}
                                ></ClientTextInput>
                                <ClientTextInput
                                    editedBio={editedBio}
                                    editing={editing}
                                    label={"sponsor"}
                                    name={"country"}
                                    handleChange={handleChange}
                                ></ClientTextInput>
                                <ClientTextInput
                                    editedBio={editedBio}
                                    editing={editing}
                                    label={"sponsor"}
                                    name={"companySite"}
                                    link
                                    column
                                    handleChange={handleChange}
                                ></ClientTextInput>
                                <ClientTextInput
                                    editedBio={editedBio}
                                    editing={editing}
                                    label={"sponsor"}
                                    name={"industry"}
                                    handleChange={handleChange}
                                ></ClientTextInput>
                                <ClientTextInput
                                    editedBio={editedBio}
                                    editing={editing}
                                    label={"sponsor"}
                                    name={"turnover"}
                                    handleChange={handleChange}
                                ></ClientTextInput>
                                <ClientTextInput
                                    editedBio={editedBio}
                                    editing={editing}
                                    label={"sponsor"}
                                    name={"employees"}
                                    handleChange={handleChange}
                                ></ClientTextInput>
                                <ClientTextInput
                                    editedBio={editedBio}
                                    editing={editing}
                                    label={"sponsor"}
                                    name={"additionalNotes"}
                                    column
                                    freetext
                                    handleChange={handleChange}
                                ></ClientTextInput>
                            </Stack>
                        </Paper>
                    )}
                    <Paper p={"md"} shadow="lg" withBorder>
                        <Title order={4} tt={"uppercase"}>
                            Notes
                        </Title>
                        <Divider size={"xs"} my={"md"} />
                        <Stack spacing={"xs"}>
                            <BItem editing={editing}>
                                <Text>{editedBio?.notes?.notes}</Text>

                                <Textarea
                                    onChange={(e) =>
                                        handleChange(
                                            e.target.value,
                                            "notes",
                                            "notes"
                                        )
                                    }
                                    value={editedBio?.notes?.notes}
                                    w={"100%"}
                                />
                                {/* <ClientTextInput override={'\n'} column freetext editedBio={editedBio} editing={editing} label={'notes'} name={'notes'} handleChange={handleChange}></ClientTextInput> */}
                            </BItem>
                        </Stack>
                    </Paper>
                </SimpleGrid>
            }

        </ >
        // <div className='Bio'>
        //     <div className={"Bio-topbar " + (scrollTop > height ? "scrolled" : '')} ref={sticky}>
        //         <div className="left">
        //             <Header as={'h2'}>General Info</Header>
        //             <CoachItem>{!editing ?
        //                 <EditButton click={toggleEditing} /> :
        //                 <>
        //                     <SaveButton click={save} />
        //                     <CancelButton click={cancel} />
        //                 </>
        //             }</CoachItem>
        //         </div>
        //     </div>
        //     {bio === undefined ? <></> : <div className="Bio-content">
        //         <div className="card">
        //             <Header as={'h3'}>Client Contact</Header>
        //             <ClientTextInput editedBio={editedBio} editing={editing} label={'clientContact'} name={'name'} handleChange={handleChange}></ClientTextInput>
        //             <ClientTextInput editedBio={editedBio} editing={editing} label={'clientContact'} name={'streetAndNr'} handleChange={handleChange}></ClientTextInput>
        //             <ClientTextInput editedBio={editedBio} editing={editing} label={'clientContact'} name={'postcode'} handleChange={handleChange}></ClientTextInput>
        //             <ClientTextInput editedBio={editedBio} editing={editing} label={'clientContact'} name={'city'} handleChange={handleChange}></ClientTextInput>
        //             <ClientTextInput editedBio={editedBio} editing={editing} label={'clientContact'} name={'country'} handleChange={handleChange}></ClientTextInput>
        //             <ClientTextInput editedBio={editedBio} editing={editing} label={'clientContact'} name={'email'} handleChange={handleChange}></ClientTextInput>
        //             <ClientTextInput editedBio={editedBio} editing={editing} label={'clientContact'} name={'telephone'} handleChange={handleChange}></ClientTextInput>
        //             <ClientTextInput editedBio={editedBio} editing={editing} label={'clientContact'} name={'linkedIn'} override="LinkedIn" link column handleChange={handleChange}></ClientTextInput>
        //         </div>
        //         <div className="card">
        //             <Header as={'h3'}>Client Experience</Header>
        //             <ClientTextInput editedBio={editedBio} editing={editing} label={'clientExperience'} name={'company'} handleChange={handleChange}></ClientTextInput>
        //             <ClientTextInput editedBio={editedBio} editing={editing} label={'clientExperience'} name={'jobTitle'} handleChange={handleChange}></ClientTextInput>
        //             <ClientTextInput editedBio={editedBio} editing={editing} label={'clientExperience'} name={'function'} handleChange={handleChange}></ClientTextInput>
        //             <ClientTextInput editedBio={editedBio} editing={editing} label={'clientExperience'} name={'department'} handleChange={handleChange}></ClientTextInput>
        //             <ClientTextInput editedBio={editedBio} editing={editing} label={'clientExperience'} name={'managementLevel'} handleChange={handleChange}></ClientTextInput>
        //             <ClientTextInput editedBio={editedBio} editing={editing} label={'clientExperience'} name={'yearsInCurrentRole'} handleChange={handleChange}></ClientTextInput>
        //             <ClientTextInput editedBio={editedBio} editing={editing} label={'clientExperience'} date name={'dateOfBirth'} handleChange={handleChange}></ClientTextInput>
        //             <ClientTextInput editedBio={editedBio} editing={editing} label={'clientExperience'} name={'companySite'} link column handleChange={handleChange}></ClientTextInput>
        //             <ClientTextInput editedBio={editedBio} editing={editing} label={'clientExperience'} name={'additionalNotes'} column freetext handleChange={handleChange}></ClientTextInput>

        //         </div>
        //         <div className="card">
        //             <Header as={'h3'}>Invoice Information</Header>

        //         </div>
        //         <div className="card">
        //             <Header as={'h3'}>Assignment</Header>

        //             <Form.Field className='ClientTextInput'>
        //                 <label >Sponsored</label>
        //                 <BItem editing={editing}>
        //                     <p>{editedBio.assignment.sponsored}</p>
        //                     <Dropdown placeholder='-' fluid selection options={typeOptions} value={editedBio.assignment.sponsored} onChange={(event, data) => handleChange(data, "assignment", "sponsored")}></Dropdown>
        //                 </BItem>
        //             </Form.Field>
        //             <ClientTextInput editedBio={editedBio} editing={editing} label={'assignment'} name={'duration'} override={'Duration (months)'} handleChange={handleChange}></ClientTextInput>
        //             <ClientTextInput editedBio={editedBio} editing={editing} label={'assignment'} name={'sessionFrequency'} override={'Session frequency per month'} handleChange={handleChange}></ClientTextInput>
        //             <ClientTextInput editedBio={editedBio} editing={editing} label={'assignment'} name={'sessionDuration'} override={'Session duration in min'} handleChange={handleChange}></ClientTextInput>
        //             <ClientTextInput editedBio={editedBio} editing={editing} label={'assignment'} name={'totalAmountSessions'} override={'Total nr of sessions'} handleChange={handleChange}></ClientTextInput>
        //             <ClientTextInput editedBio={editedBio} editing={editing} label={'assignment'} name={'totalBudget'} override={'Total budget (ex. VAT)'} handleChange={handleChange}></ClientTextInput>
        //             <ClientTextInput editedBio={editedBio} editing={editing} label={'assignment'} name={'ratePerHour'} override={'Rate per Hour'} handleChange={handleChange}></ClientTextInput>
        //             <ClientTextInput editedBio={editedBio} editing={editing} label={'assignment'} name={'chargeForCommutingPerSession'} handleChange={handleChange}></ClientTextInput>
        //             <ClientTextInput editedBio={editedBio} editing={editing} label={'assignment'} name={'ChargeForPreperation'} handleChange={handleChange}></ClientTextInput>
        //             <ClientTextInput editedBio={editedBio} editing={editing} label={'assignment'} name={'chargeForFollowUp'} handleChange={handleChange}></ClientTextInput>
        //             <ClientTextInput editedBio={editedBio} editing={editing} label={'assignment'} name={'additionalCharges'} handleChange={handleChange}></ClientTextInput>
        //             <Form.Field className='ClientTextInput'>
        //                 <label >Currency</label>
        //                 <BItem editing={editing}>
        //                     <p>{editedBio.assignment.currency || '-'}</p>
        //                     <Dropdown placeholder='-' fluid selection options={currencyOptions} value={editedBio.assignment.currency} onChange={(event, data) => handleChange(data, "assignment", "currency")}></Dropdown>
        //                 </BItem>
        //             </Form.Field>
        //             <ClientTextInput editedBio={editedBio} editing={editing} label={'assignment'} name={'vatPercentage'} override={'VAT %'} handleChange={handleChange}></ClientTextInput>
        //             <ClientTextInput editedBio={editedBio} editing={editing} label={'assignment'} name={'paymentTermInDays'} override={'Payment term (days)'} handleChange={handleChange}></ClientTextInput>

        //             <ClientTextInput editedBio={editedBio} editing={editing} label={'assignment'} name={'additionalNotes'} freetext column handleChange={handleChange}></ClientTextInput>
        //         </div>
        //         {editedBio.assignment.sponsored === 'Yes' && <div className="card">
        //             <Header as={'h3'}>Sponsor</Header>

        //         </div>}
        //         <div className="card">

        //         </div>
        //     </div>}
        // </div>
    );
}

function ClientTextInput({
    editedBio,
    link,
    editing,
    label,
    date,
    name,
    handleChange,
    freetext,
    override,
    column,
    warning,
    error
}) {
    const cs = new ClientService(null, null);
    function parseLabel(str) {
        if (override) {
            return override;
        }
        return (
            str
                // insert a space between lower & upper
                .replace(/([a-z])([A-Z])/g, "$1 $2")
                // space before last upper in a sequence followed by lower
                .replace(/\b([A-Z]+)([A-Z])([a-z])/, "$1 $2$3")
                .toLowerCase()
                // uppercase the first character
                .replace(/^./, function (str) {
                    return str.toUpperCase();
                })
        );
    }

    function isValidUrl(urlString) {
        let url;
        try {
            url = new URL(urlString);
        } catch (e) {
            return false;
        }
        return url.protocol === "http:" || url.protocol === "https:";
    }
    function handleDate(e) {
        let formattedDate = dayjs(e).format("DD-MMM-YYYY").toString();

        handleChange(formattedDate, label, name);
    }
    return (
        <>
            <Group spacing={"md"} position="apart">
                <Group>
                    <Text fw={'bold'}>{label !== "notes" && parseLabel(name)} </Text>
                    {error && <CoachItem>
                        <ErrorIcon error={editedBio[label][name] === '' || editedBio[label][name] === undefined} errorText={`This field is required for the invoicing module`} />
                    </CoachItem>}
                    {warning && <CoachItem>
                        <WarningIcon warning={editedBio[label][name] === '' || editedBio[label][name] === undefined} warningText={`recommended for corporate clients`} />
                    </CoachItem>}
                </Group>
                <BItem editing={editing}>
                    {link && isValidUrl(editedBio[label][name]) ? (
                        <a
                            href={editedBio[label][name]}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {editedBio[label][name]}
                        </a>
                    ) : (
                        <p className={freetext ? "newLines" : ""}>
                            {date
                                ? cs.formatDate(editedBio[label][name])
                                : editedBio[label][name]}
                        </p>
                    )}
                    {freetext ? (
                        <Textarea
                            onChange={(e) =>
                                handleChange(e.target.value, label, name)
                            }
                            value={editedBio[label][name]}
                            w={"100%"}
                        />
                    ) : date ? (
                        <SafeDateInput
                            date={editedBio[label][name]}
                            handleChange={handleChange}
                            params={[label, name]}
                        />
                    ) : (
                        <TextInput
                            maw={"140px"}
                            onChange={(e) =>
                                handleChange(e.target.value, label, name)
                            }
                            value={editedBio[label][name]}
                        />
                    )}
                </BItem>
            </Group>
            {/* <Form.Field
                className={"ClientTextInput " + (column ? "column" : "")}
            >
                <label>{parseLabel(name)}</label>
                <BItem editing={editing}>
                    {link && isValidUrl(editedBio[label][name]) ? (
                        <a
                            href={editedBio[label][name]}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {editedBio[label][name]}
                        </a>
                    ) : (
                        <p className={freetext ? "newLines" : ""}>
                            {date
                                ? cs.formatDate(editedBio[label][name])
                                : editedBio[label][name]}
                        </p>
                    )}

                    {freetext ? (
                        <Form>
                            <TextArea
                                type="text"
                                onChange={(e) =>
                                    handleChange(e.target, label, name)
                                }
                                value={editedBio[label][name]}
                            ></TextArea>
                        </Form>
                    ) : date ? (
                        <Form>
                            <Input
                                type="date"
                                value={editedBio[label][name]}
                                onChange={(e) =>
                                    handleChange(e.target, label, name)
                                }
                            ></Input>
                        </Form>
                    ) : (
                        <Input
                            type="text"
                            onChange={(e) =>
                                handleChange(e.target, label, name)
                            }
                            value={editedBio[label][name]}
                        ></Input>
                    )}
                </BItem>
            </Form.Field> */}
        </>
    );
}

// date ? cs.formatDate(bio[label][name]) :

import React, { useEffect, useState } from 'react'
import RoadMapComponent from './roadMap/roadMap'
import TimesheetComponent from './timesheet/timesheet'
import clientTabs from '../utils/clientTabs'
import { Dimmer, Loader, Placeholder, Image, Button, Divider, Table, Input, Menu, Segment, Icon, Header, Popup } from 'semantic-ui-react'
import './clients.scss'
import CoachItem from '../utils/CoachItem'
import BioComponent from './bio/bio'
import IntakeComponent from './intake/intake'
import InitialProposalComponent from './initialProposal/initialProposal'
import SessionNotesComponent from './sessionNotes/sessionNotes'
import DocumentStorageComponent from './documentStorage/documentStorage'
import ActionsComponent from './actions/actions'
import ProgressComponent from './progress/progress'
import WhiteboardComponent from './whiteboard/whiteboard'
import { useClientContext } from '../../providers/ClientProvider/ClientProvider'
import { ClientProvider } from '../../providers/ClientProvider/ClientProvider'
import { useDispatch, useSelector } from 'react-redux'
import { setActiveTab } from '../../redux/viewer/viewerSlice'
import { Box, LoadingOverlay } from '@mantine/core'
export default function ClientComponent(props) {
    const { seenLastEdited, loading, lastEdited } = useClientContext()
    const activeTab = useSelector(state => state.viewer.activeTab)
    const unseenTabs = useSelector(state => state.viewer.unseenTabs);
    const dispatch = useDispatch()
    function handleItemClick(e, { name }) {
        if (lastEdited && lastEdited.client && lastEdited.client.tab === activeTab) {
            seenLastEdited().then(() => dispatch(setActiveTab(name)))
        } else if (lastEdited && lastEdited.coach && lastEdited.coach.tab === activeTab) {
            seenLastEdited().then(() => dispatch(setActiveTab(name)))
        } else {
            dispatch(setActiveTab(name))
        }

    }
    useEffect(() => {
        changeTab()
    }, [activeTab])



    function changeTab() {
        let tab = activeTab
        tab = tab.replace(/\s/g, '')
        if (tab === "Client") {
            tab = "Bio"
            return <BioComponent />
        }
        switch (tab) {
            case "Intake":
                return <IntakeComponent />
            case "ProgramProposal":
                return <InitialProposalComponent />
            case "SessionNotes":
                return <SessionNotesComponent />
            case "Actions":
                return <ActionsComponent />
            case "RoadMap":
                return <RoadMapComponent />
            case "Notes":
                return <WhiteboardComponent />
            case "Timesheet":
                return <TimesheetComponent />
            case "DocumentStorage":
                return <DocumentStorageComponent />
            case "Progress":
                return <ProgressComponent />
            default:
                return <BioComponent />
        }
    }



    return (
        <>
            {loading ? <div>
                <Box maw={400} pos="relative">
                    <LoadingOverlay visible={true} overlayBlur={2} />
                    {/* ...other content */}
                </Box>
            </div> : <><div>

                {changeTab()}
            </div>

            </>
            }
        </>

    )
}


export function LastEdited({ tab, role }) {
    const cContext = useClientContext()
    let lastEdited = cContext.clientData.lastEdited

    useEffect(() => {
        if (lastEdited === undefined) {
            lastEdited = {}
            lastEdited.coach = {}
            lastEdited.client = {}
        }
    }, [lastEdited])

    if (role === 'coach' && lastEdited && lastEdited.client && tab === lastEdited.client.tab) {
        return (<div className='indicator'><Popup offset={[-17, 0]} content={'Edited last on: ' + lastEdited.client.date} header={lastEdited.client.name} trigger={<Icon className='alert-indicator' size='mini' name='circle'></Icon>} /></div>)
    }
    if (role === 'client' && lastEdited && lastEdited.coach && tab === lastEdited.coach.tab) {
        return (<div className='indicator'><Popup offset={[-17, 0]} content={'Edited last on: ' + lastEdited.coach.date} header={lastEdited.coach.name} trigger={<Icon className='alert-indicator' size='mini' name='circle'></Icon>} /></div>)

    }
}

Date.prototype.formatMonth = function () {

    const monthNames = ["Jan", "Feb", "Mar", "Apr",
        "May", "Jun", "Jul", "Aug",
        "Sep", "Oct", "Nov", "Dec"];

    const day = this.getDate();

    const monthIndex = this.getMonth();
    const monthName = monthNames[monthIndex];

    const year = this.getFullYear();

    return `${day}-${monthName}-${year}`;
}
import { db, db2 } from "../firebase";
import { collection, doc, query, where, onSnapshot, setDoc, addDoc } from "firebase/firestore";
import { firestore } from "../redux/logging/loggingSlice";

export class stripeService {

    static async fetchPayments(uid, dispatch) {
        // console.log('fetching payments')
        let q = query(collection(db, "customers", uid, "payments"), where("status", "==", "succeeded"));
        const res = []
        const payments = await dispatch(firestore.getDocs(q))
        payments.forEach(async (paymentDoc) => {
            // console.log(paymentDoc.id, ' => ', paymentDoc.data());
            const data = paymentDoc.data()
            res[paymentDoc.id] = {
                id: paymentDoc.id,
                amountTokens: data.items[0].description.split(' ')[0],
                package: data.items[0].price.product,
                status: data.status,
            }
        });
        // console.log('res', res)
        return res
    }

    static async fetchProducts(dispatch) {
        // console.log('fetching products')
        let q = query(collection(db, "tokenPackages"), where("active", "==", true));
        const res = []
        const products = await dispatch(firestore.getDocs(q))
        products.forEach(async (productDoc) => {
            // console.log(productDoc.id, ' => ', productDoc.data());
            res[productDoc.id] = productDoc.data()
            const priceSnap = await dispatch(firestore.getDocs(collection(db, 'tokenPackages', productDoc.id, 'prices')));
            priceSnap.forEach((priceDoc) => {
                // console.log(priceDoc.id, ' => ', priceDoc.data());
                res[productDoc.id].prices = {
                    priceData: priceDoc.data(),
                    priceId: priceDoc.id
                }
            });
        });
        return res
    }
    static async loadCheckout(uid, priceId, setLoading) {
        const checkoutSessionRef = await addDoc(
            // currentUser is provided by firebase, via getAuth().currentUser
            collection(db, `customers/${uid}/checkout_sessions`),
            {
                price: priceId,
                success_url: window.location.origin,
                cancel_url: window.location.origin,
                mode: 'payment',
            }
        );
        onSnapshot(checkoutSessionRef, (snap) => {
            const data = snap.data();
            const { error, sessionId, url } = data;
            if (error) {
                console.log(error)
            }
            if (sessionId) {
                // console.log('sessionId', sessionId)
                // dispatch(firestore.deleteDoc(doc(db, 'customers', uid, 'checkout_sessions', sessionId)))
                // window.location.href = `/checkout?sessionId=${sessionId}`
            }
            if (url) {
                window.location.assign(url)
            }
        });
    }
}
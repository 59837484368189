import React, { useState, useContext, useEffect } from 'react'
import { useAlertsContext } from '../Alerts/AlertsProvider';
import { db } from '../../firebase'
import { doc, where, collection, getDocs, query, updateDoc } from 'firebase/firestore'
import { getStorage, ref, listAll, getDownloadURL, uploadBytes, deleteObject, uploadString } from 'firebase/storage'
import { useLoggingContext } from 'providers/LoggingProvider/LoggingProvider';
import { useSelector } from 'react-redux';



export function FileProvider(props) {
    const { getDoc, setDoc } = useLoggingContext()

    const alertsProvider = useAlertsContext()
    const { role } = useSelector(state => state.firestoreUser.userData)


    useEffect(() => {
    }, [])

    class File {
        constructor(name, path) {
            this.name = name
            this.path = path
        }
        download() {
            getSharedFile(this.path)
        }
        async delete(roledos) {
            deleteDocument(this.path, roledos)
        }


    }

    class Folder {
        constructor(name, path) {
            this.path = path
            this.name = name
            this.open = false
            this.files = []
            this.folders = []
        }
        async delete(role) {
            for (const f of this.folders) {
                f.delete(role)
            }
            for (const f of this.files) {
                deleteDocument(f.path, role)
            }

        }
    }
    async function getSharedDocument(sharedFiles, path) {
        const folder = new Folder(getFileName(path), path)
        if (sharedFiles.items) {
            sharedFiles.items.forEach((item) => {
                folder.files.push(new File(getFileName(item._location.path), item._location.path))
            })
        }
        if (sharedFiles.prefixes) {
            sharedFiles.prefixes.forEach((folderRef) => {
                listAll(folderRef).then((newFolders) => {
                    getSharedDocument(newFolders, folderRef._location.path).then((f) => {
                        folder.folders.push(f)
                    })
                })

            })
        }
        return folder
    }
    function getFileName(str) {
        return /[^/]*$/.exec(str)[0]
    }

    async function deleteDocument(path, roledos) {
        const storage = getStorage();
        if (role !== roledos && roledos !== 'shared') {
            alertsProvider.addAlert({ header: 'Not permitted', content: `You do not have permission to delete a file from the ${roledos} folder`, variation: 'error', timeout: 4000 })
            return
        }
        const fileRef = ref(storage, path)
        deleteObject(fileRef).then(() => {
            alertsProvider.addAlert({ header: 'Success', content: `deleted document`, variation: 'positive', timeout: 2000 })

        }).catch((error) => {
            // Uh-oh, an error occurred!
        });
    }

    async function getDocument(file, role) {
        const storage = getStorage();
        const path = `${props.currentClient.id}/${role}/${file}`
        getDownloadURL(ref(storage, path))
            .then((url) => {
                var link = document.createElement('a');
                link.download = 'data:,' + file;
                link.href = url;
                window.open(
                    link.href,
                    '_blank'
                );
            })
            .catch((error) => {
                // Handle any errors
            });

    }
    async function deleteFolder(path, roledos) {


    }
    async function getSharedFile(path) {
        const storage = getStorage();
        getDownloadURL(ref(storage, path))
            .then((url) => {
                var link = document.createElement('a');
                link.download = 'data:,' + path;
                link.href = url;
                window.open(
                    link.href,
                    '_blank'
                );
            })
            .catch((error) => {
                // Handle any errors
            });
    }
    async function addFolder(folder, name) {

        if (folder.path && (folder.path.split('/')[1] == role || folder.path == role || folder.path == 'shared' || folder.path.split("/")[1] == 'shared')) {
            const storage = getStorage()
            let folder_path = folder.path
            if (!folder_path.includes('/')) {
                folder_path = props.currentClient.id + '/' + folder_path
                folder_path = folder_path + "/"

            }
            else {
                folder_path += '/'
            }
            const listRefFolder = ref(storage, folder_path)
            const files = await listAll(listRefFolder)
            const folderName = name
            const path = folder_path + folderName + "/"
            const newDir = ref(storage, path)
            const ghostFile = ref(newDir, '.ghostfile')
            await uploadString(ghostFile, '')
        }
    }

    async function getAllDocuments() {
        const storage = getStorage();
        // Create a reference under which you want to list
        const listRefClient = ref(storage, props.currentClient.id + '/client');
        const listRefCoach = ref(storage, props.currentClient.id + '/coach');
        const listRefShared = ref(storage, props.currentClient.id + '/shared')
        let coach = []
        let client = []
        let shared = []
        const coachFiles = await listAll(listRefCoach)
        const clientFiles = await listAll(listRefClient)
        const sharedFiles = await listAll(listRefShared)
        // coachFiles.items.forEach((item) => {
        //     coach.push(getFileName(item._location.path))
        // })
        coach = await getSharedDocument(coachFiles, "coach")
        // clientFiles.items.forEach((item) => {
        //     client.push(getFileName(item._location.path))
        // })
        client = await getSharedDocument(clientFiles, 'client')
        // sharedFiles.items.forEach((item) => {
        //     shared.push(getFileName(item._location.path))
        // })
        shared = await getSharedDocument(sharedFiles, 'shared')
        return {
            client: client,
            coach: coach,
            shared: shared
        }
    }

    async function addDocuments(files, folder) {
        const storage = getStorage()
        if (role === folder.path || role === folder.path.split("/")[1] || 'shared' === folder.path.split("/")[1] || folder.path == 'shared') {
            if (!folder.path.includes("/")) {
                folder.path = props.currentClient.id + "/" + folder.path
            }
            if (files.length > 1) {
                await Promise.all(Object.keys(files).map(async (index) => {
                    const file = files[index]
                    const name = file.name
                    const filePath = `${folder.path}/${name}`
                    const fileRef = ref(storage, filePath)
                    const snapshot = await uploadBytes(fileRef, file)
                }))
            } else {
                const file = files[0]
                const name = file.name
                const filePath = `${folder.path}/${name}`
                const fileRef = ref(storage, filePath)
                await uploadBytes(fileRef, file)
            }
        } else {
            alertsProvider.addAlert({ header: 'Unauthorized', content: `You do not have permission add file(s) to the ${folder} folder`, variation: 'error', timeout: 2000 })
        }

    }

    return (

        <FileContext.Provider value={{ addDocuments, getSharedDocument, getFileName, deleteDocument, getDocument, deleteFolder, getSharedFile, addFolder, getAllDocuments }}>
            {props.children}
        </FileContext.Provider>
    )
}


export const FileContext = React.createContext([])

export const useFileContext = () => React.useContext(FileContext)
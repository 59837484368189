import React, { useState, useEffect } from "react";
import "./overview.scss";
import { useOverviewContext } from '../../providers/OverviewProvider/OverviewProvider'
import { Paper, Text, Title, Table, Group, ActionIcon, Badge, Modal } from "@mantine/core";
import { IconSquareArrowRight } from "@tabler/icons-react";
import { NormalButton } from "components/utils/MantineUtils";
import { toAdmin } from "../../redux/viewer/viewerSlice";
import { useDispatch } from "react-redux";
export default function ModulesSection() {
    const { coachSummary } = useOverviewContext()
    const dispatch = useDispatch()
    return (
        <Paper shadow="lg" p='md' withBorder>
            <Group position={'apart'}>
                <Title order={2}>COACHING MODULES</Title>
                <NormalButton fn={() => dispatch(toAdmin())} >
                    <IconSquareArrowRight />
                </NormalButton>
            </Group>
            <Table my={'md'} withBorder
                striped
                highlightOnHover>
                <thead >
                    <tr>
                        <th><Title order={6} tt={'uppercase'} fz={'md'}>Module</Title ></th>
                        <th><Title order={6} tt={'uppercase'} fz={'md'} >Status</Title ></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Personal Branding</td>
                        <td>
                            <Badge color={'green.7'}><Title order={6}>Active</Title></Badge>
                        </td>

                    </tr>
                    <tr>
                        <td>Invoicing</td>
                        <td>
                            <Badge color={'green.7'}><Title order={6}>ACTIVE</Title></Badge>
                        </td>

                    </tr>
                    <tr>
                        <td>Client Management</td>
                        <td>
                            <Badge color={'green.7'}><Title order={6}>ACTIVE</Title></Badge>
                        </td>

                    </tr>
                </tbody>
            </Table>
        </Paper>
    );
}


import whiteboardState from "./whiteboardState";
import { whiteboardInput } from "./whiteboardUtils";
import "./whiteboard.scss";

import CoachItem from "../../utils/CoachItem";
import {
    EditButton,
    SaveButton,
    CancelButton,
} from "../../utils/buttons/buttons";
import ClientService from "../ClientService";
import { useClientContext } from "../../../providers/ClientProvider/ClientProvider";
import React, { useState, useRef, useEffect, createRef } from "react";
import BItem from "../../utils/BItem";
import { Group, Paper, Title, Text, Button, Divider, Textarea } from "@mantine/core";
import { IconArrowBackUp, IconDeviceFloppy, IconEdit, IconPlus } from "@tabler/icons-react";
import { DeleteButton, NormalButton, PageMenu } from "components/utils/MantineUtils";

export default function WhiteboardComponent(props) {
    const cContext = useClientContext();
    const whiteboard = cContext.clientData.whiteboard;

    const [height, setHeight] = useState(0);
    const [scrollTop, setScrollTop] = useState(0);
    const [editing, setEditing] = useState(false);
    const [editedWhiteboard, setEditedWhiteboard] = useState(
        whiteboard === undefined ? {} : JSON.parse(JSON.stringify(whiteboard))
    );



    let cs = new ClientService(setEditedWhiteboard, setScrollTop);

    useEffect(() => {
        if (whiteboard === undefined) {
            setEditedWhiteboard(whiteboardState);
            save();
        }
    }, [whiteboard, setEditedWhiteboard]);





    function cancel() {
        setEditedWhiteboard(whiteboard);
        setEditing(false);
    }

    function save() {
        setEditing(false);
        cContext.saveClientWhiteboard(editedWhiteboard);
    }
    function deleteInput(cat, id) {
        cs.deleteInput(editedWhiteboard, cat, id);
    }
    function addInput(cat) {
        let template;
        switch (cat) {
            case "N":
                template = whiteboardInput;
                break;
            default:
                template = whiteboardInput;
                break;
        }
        cs.addInput(editedWhiteboard, cat, template);
    }
    function handleChange(data, cat, id, key) {
        cs.handleChange(editedWhiteboard, data, cat, id, key);
    }

    function toggleEditing() {
        setEditing(!editing);
    }

    return (
        <>
            <Paper p={'md'} shadow={'lg'} withBorder sx={
                {
                    position: 'sticky',
                    top: 69,
                    padding: '20px',
                    zIndex: 2,
                    transition: 'all 0.3s ease',
                }
            }>
                <PageMenu sectionType={'All'} name={'notes'} editing={editing} toggleEditing={toggleEditing} save={save} cancel={cancel}>

                    {editing &&

                        < Group >
                            <Button
                                variant="default"
                                leftIcon={<IconPlus stroke={"3"} size={20} />}
                                onClick={() => {
                                    addInput("N")
                                }}
                            >
                                <Title order={6}>Note</Title>
                            </Button>
                        </Group>}
                </PageMenu>
                {/* <Group position='apart'>
                    <Group>
                        <Title py={"md"} order={3} tt={"uppercase"}>
                            Notes
                        </Title>
                        <Group position="apart">
                            {!editing ? (
                                <NormalButton fn={toggleEditing}>
                                    <IconEdit color="orange" />
                                </NormalButton>
                            ) : (
                                <Group>
                                    <NormalButton fn={save}>
                                        <IconDeviceFloppy color="green" />
                                    </NormalButton>
                                    <NormalButton fn={cancel}>
                                        <IconArrowBackUp color="red" />
                                    </NormalButton>
                                </Group>
                            )}
                        </Group>
                    </Group>
                    {editing && (
                        < Group >
                            <Button
                                variant="default"
                                leftIcon={<IconPlus stroke={"3"} size={20} />}
                                onClick={() => {
                                    addInput("N")
                                }}
                            >
                                <Title order={6}>Note</Title>
                            </Button>
                        </Group>)
                    }


                </Group> */}

            </Paper>
            <Divider my={'lg'} />
            {editedWhiteboard["N"] &&
                editedWhiteboard["N"].map((note, i) => (
                    <WBNote
                        handleChange={handleChange}
                        deleteInput={deleteInput}
                        note={note}
                        key={i}
                        editing={editing}
                    />
                ))}
        </>
        // <div className="Whiteboard">
        //     <div
        //         className={
        //             "Whiteboard-topbar " +
        //             (scrollTop > height ? "scrolled" : "")
        //         }
        //         ref={sticky}
        //     >
        //         <div className="left">
        //             <Header as={"h2"}>Whiteboard</Header>
        //             {!editing ? (
        //                 <EditButton click={toggleEditing} />
        //             ) : (
        //                 <>
        //                     <SaveButton click={save} />
        //                     <CancelButton click={cancel} />
        //                 </>
        //             )}
        //         </div>

        //         {editing && (
        //             <div className="adders">
        //                 <Button
        //                     onClick={() => {
        //                         addInput("N");
        //                         cs.scrollToLast(lastRef);
        //                     }}
        //                 >
        //                     <Button.Content visible>Add note</Button.Content>
        //                 </Button>
        //             </div>
        //         )}
        //     </div>
        //     <Divider />
        //     <div className="notes">
        //         {editedWhiteboard["N"] &&
        //             editedWhiteboard["N"].map((note, i) => (
        //                 <WBNote
        //                     handleChange={handleChange}
        //                     deleteInput={deleteInput}
        //                     note={note}
        //                     key={i}
        //                     editing={editing}
        //                 />
        //             ))}
        //     </div>
        //     <div ref={lastRef}></div>
        // </div>
    );
}

function WBNote(props) {
    const note = props.note;

    function deleteInput() {
        props.deleteInput("N", note.id);
    }

    return (
        <Paper
            p={'md'}
            shadow={'lg'}
            withBorder
            my={'md'}

        >
            <BItem editing={props.editing}>
                <Text sx={{
                    whiteSpace: 'pre-line'
                }}>
                    {note.text || "-"}
                </Text>
                <>
                    <Group position="apart">
                        <Textarea autosize w={'90%'} value={note.text} onChange={(e) => props.handleChange(e.target.value, "N", note.id, "text")} />
                        <DeleteButton click={deleteInput} />
                    </Group>
                </>
            </BItem>


        </Paper>

    );
}

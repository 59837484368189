import { Global } from "@mantine/core";
import montserrat from "./Montserrat-VariableFont_wght.ttf";
import ebgaramond from "./EBGaramond-VariableFont_wght.ttf";
import quicksand from "./Quicksand-VariableFont_wght.ttf"
export function CustomFonts() {
    return (
        <Global
            styles={[
                {
                    "@font-face": {
                        fontFamily: "Montserrat",
                        src: `url"('${montserrat}') format('truetype')`,
                        fontWeight: 700,
                        fontStyle: "normal",
                    },
                },
                {
                    "@font-face": {
                        fontFamily: "EB Garamond",
                        src: `url"('${ebgaramond}') format('truetype')`,
                        fontWeight: 700,
                        fontStyle: "normal",
                                    },
                },
                {
                    "@font-face": {
                        fontFamily: "Quicksand",
                        src: `url"('${quicksand}') format('truetype')`,
                        fontWeight: 700,
                        fontStyle: "normal",
                                    },
                },
            ]}
        />
    );
}

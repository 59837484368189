import { doc, getDocs, query, collection, where } from 'firebase/firestore'
import { firestore } from '../redux/logging/loggingSlice';
import { db } from '../firebase'

export class tokenService {
    static async fetchTokens(uid, dispatch) {
        const ref = doc(db, 'tokens', uid);
        const snap = await dispatch(firestore.getDoc(ref));
        if (snap.exists()) {
            const data = snap.data();
            if (data && 'balance' in data) {
                return data.balance;
            }
        }
        else {
            await dispatch(firestore.setDoc(ref, { balance: 0 }))
            const balance = await this.fetchTokens(uid, dispatch)
            return balance
        }
    }
    static async getAllClients(uid) {
        const q = query(collection(db, "users"), where("role", "==", "client"), where("coach", "==", uid));
        const querySnapshot = await getDocs(q);
        const clients = []
        querySnapshot.forEach((doc) => {
            clients.push(doc.data())
        });
        return clients
    }
}
import { collection, doc, getDoc, setDoc, updateDoc, query, where, getDocs } from 'firebase/firestore'
import { db } from '../../firebase'
import React, { useState, useEffect } from 'react'
import { getStorage, uploadBytes, getBlob, ref, deleteObject, getDownloadURL } from 'firebase/storage'
import brandingState from '../../components/coach/branding/brandingState'
import invoicingState from 'components/coach/invoicing/invoicingState'
import { useBrandingContext } from '../BrandingProvider/BrandingProvider'
import { useSelector } from 'react-redux'
export default function CoachProvider(props) {
    const [loading, setLoading] = useState(true)
    const [coachData, setCoachData] = useState({})
    const [clients, setClients] = useState([])
    const [refresh, setRefresh] = useState(false)
    const userDetails = useSelector(state => state.authUser.userDetails)
    const brandingContext = useBrandingContext()
    useEffect(() => {
        getCoachData()
        getClients()
    }, [])

    useEffect(() => {
        if (refresh) {
            getClients()
            getCoachData()
            setRefresh(false)
        }
    }, [refresh])



    useEffect(() => {
        setLoading(false)
    }, [coachData])


    async function addInvoiceImage(file) {
        const coachId = userDetails.uid
        const storage = getStorage()
        const path = `${coachId}/Invoicelogo.png`
        await uploadBytes(ref(storage, path), file)
    }

    async function getClients() {
        const q = query(collection(db, 'users'), where("role", '==', 'client'), where('coach', '==', userDetails.uid))
        const snap = await getDocs(q)
        const users = []
        const tempUsers = []
        snap.forEach((docu) => {
            const userData = docu.data()
            const user = {
                id: docu.id,
                name: userData.name,
                email: userData.email,
                text: userData.name + " " + userData.email,
                key: docu.id,
                status: userData.status,
                value: {}
            }
            tempUsers.push(user)
        })
        for (const user of tempUsers) {
            const ref = doc(db, 'clients', user.id)
            const clientInfo = await getDoc(ref)
            user.value = clientInfo.data()
            users.push(user)
        }
        setClients(users)
    }


    async function resetInvoiceImage() {
        const coachId = userDetails.uid
        const storage = getStorage()
        const path = `${coachId}/Invoicelogo.png`
        await deleteObject(ref(storage, path))
    }
    async function getInvoiceImageUrl() {
        const coachId = userDetails.uid
        const storage = getStorage()
        const path = `${coachId}/Invoicelogo.png`
        let url = ''
        try {
            url = await getDownloadURL(ref(storage, path))
        } catch {

            url = await getDownloadURL(ref(storage, 'GLOBALS/INVOICE_LOGO.png'))
        }
        return url
    }
    async function getInvoiceBlob() {
        const coachId = userDetails.uid
        const storage = getStorage()
        const path = `${coachId}/Invoicelogo.png`
        let blob = ''
        try {
            blob = await getBlob(ref(storage, path))
        } catch (err) {
            blob = await getBlob(ref(storage, 'GLOBALS/INVOICE_LOGO.png'))
        }
        return blob
    }

    async function getCoachData() {
        const ref = doc(db, 'coaches', userDetails.uid)
        const snap = await getDoc(ref);
        if (snap.exists()) {
            const data = snap.data()
            setCoachData(data)
            if (!data.branding) {
                saveBranding(brandingState)
            }
            if (!data.invoicing) {
                saveInvoicing(invoicingState)
            }
        } else {
            setDoc(doc(db, 'coaches', userDetails.uid), {}).then(() => {

                getCoachData()
            })
        }
    }
    function getBranding() {
        return coachData.branding
    }

    function getInvoicing() {
        return coachData.invoicing
    }

    function saveInvoicing(invoicing) {
        const ref = doc(db, 'coaches', userDetails.uid)
        updateDoc(ref, { "invoicing": invoicing }).then(() => {
            setCoachData((prevstate) => ({ ...prevstate, invoicing: invoicing }))
        })
    }

    async function saveBranding(branding) {
        const ref = doc(db, 'coaches', userDetails.uid)
        updateDoc(ref, { "branding": branding }).then(() => {
            setCoachData((prevstate) => ({ ...prevstate, branding: branding }))
            brandingContext.setBrandingStyle(branding.colors)
            brandingContext.updateStyle()

        })
    }
    async function markCompleted(clientId, timesheet) {
        const ref = doc(db, 'clients', clientId)
        updateDoc(ref, {
            'timesheet': timesheet
        }).then(() => {
            getClients()
        }).catch((error) => {
        })
    }


    return (
        <CoachContext.Provider value={{ markCompleted, getClients, clients, loading, saveBranding, getBranding, saveInvoicing, getInvoicing, coachData, getInvoiceImageUrl, getInvoiceBlob, addInvoiceImage, resetInvoiceImage, clients }}>
            {props.children}
        </CoachContext.Provider>
    )
}

export const CoachContext = React.createContext([])

export const useCoachContext = () => React.useContext(CoachContext)
import { db } from "../firebase";
import { collection, doc, query, where } from "firebase/firestore";
import { firestore } from "../redux/logging/loggingSlice";
import { initialFirestoreUserState } from "../redux/firestoreUser/firestoreUserSlice";
export class firestoreUserService {
    static async fetchUser(uid, dispatch) {
        const ref = doc(db, 'users', uid);
        const snap = await dispatch(firestore.getDoc(ref));
        if (snap.exists()) {
            const data = snap.data();
            const gdpr = data['gdpr'];
            const status = data['status'];
            const role = data['role'];
            const license = data['license'];
            let updateData = {
            };
            if(license === undefined){
                updateData['license'] = initialFirestoreUserState.userData.license;
            }
            if (gdpr === undefined) {
                updateData['gdpr'] = false;
            }
            if (role === 'client' && status === undefined) {
                updateData['status'] = initialFirestoreUserState.userData.status;
            }
            await dispatch(firestore.updateDoc(ref, updateData));
            if (data) {
                return data;
            }
        }
        else {
            console.log('no user')
            return null
        }
    }

    static async acceptGDPR(uid, dispatch) {
        try {
            const ref = doc(db, 'users', uid);
            await dispatch(firestore.updateDoc(ref, { gdpr: true }));
        }
        catch (error) {
            console.log(error);
        }
    }

    static async getClients(uid, role, dispatch) {
        try {
            let q = query(collection(db, 'users'), where('role', '==', 'client'), where('coach', '==', uid))
            if (role === 'client') {
                q = query(collection(db, 'users'), where('role', '==', 'client'), where('__name__', '==', uid))
            }
            const querySnapshot = await dispatch(firestore.getDocs(q))
            // console.log(querySnapshot, 'querySnapshot')
            let clients = []
            const additionalInfoPromises = [];

            querySnapshot.forEach((doc) => {
                const data = doc.data();
                data.id = doc.id;
                if (!data.status || data.status === undefined) {
                    dispatch(firestore.updateDoc(doc.ref, { status: initialFirestoreUserState.userData.status }))
                }
                let status = data.status
                let valueIsMissing = false;
                for (let key in initialFirestoreUserState.userData.status) {
                    if (!(key in status)) {
                        status[key] = initialFirestoreUserState.userData.status[key]
                        valueIsMissing = true;
                    }
                }
                if (valueIsMissing) {
                    dispatch(firestore.updateDoc(doc.ref, { status: status }))
                }

                const infoPromise = this.getAdditionalInfo(doc.id, dispatch)
                    .then((info) => {
                        Object.assign(data, info);
                        return data;
                    })
                    .catch((error) => {
                        console.error('Error fetching additional info:', error);
                        return data;
                    });

                additionalInfoPromises.push(infoPromise);
            });

            const resolvedData = await Promise.all(additionalInfoPromises);
            clients.push(...resolvedData);

            return clients;
        }
        catch (error) {
            console.log(error);
        }
    }

    static async getAdditionalInfo(uid, dispatch) {
        const ref = doc(db, 'clients', uid)
        const snap = await dispatch(firestore.getDoc(ref));
        if (snap.exists()) {
            if (snap.data().bio) {
                return {
                    city: snap.data().bio.clientContact.city,
                    telephone: snap.data().bio.clientContact.telephone,
                    dateOfBirth: snap.data().bio.clientExperience.dateOfBirth,
                    logs: snap.data().logs,
                    lastLogin: snap.data().lastLogin
                }
            }
        }
    }

}
import React from "react";

import {
    AppShell,
    Header,
    Box,
    TextInput,
    Button,
    PasswordInput,
    Title,
    BackgroundImage,
    SimpleGrid,
    Image,
    List,
    ThemeIcon,
    Center,
    Anchor,
    Flex,
    Container,
    Paper,
    Accordion,
} from "@mantine/core";
import { useAlertsContext } from "../../providers/Alerts/AlertsProvider";
import CustomHeader from "components/header/header";
import { useSelector } from "react-redux";
import { IconLink } from "@tabler/icons-react";

export default function Resources() {
    const alertsProvider = useAlertsContext();
    const primaryColor = useSelector(
        (state) => state.branding.branding.primaryColor
    );
    const { license, role } = useSelector(
        (state) => state.firestoreUser.userData
    );
    console.log(license);
    return (
        <AppShell
            padding={0}
            header={
                <Header
                    height={80}
                    sx={(theme) => ({
                        backgroundColor: primaryColor,
                    })}
                >
                    <CustomHeader />
                </Header>
            }
            styles={(theme) => ({
                main: {
                    backgroundColor:
                        theme.colorScheme === "dark"
                            ? theme.colors.dark[8]
                            : theme.colors.gray[0],
                },
            })}
        >
            <BackgroundImage src={require("../../assets/background-4.jpg")}>
                <Box
                    p={"xl"}
                    sx={{
                        height: "calc(100vh - 70px)",
                        backgroundColor: "rgba(0,0,0,0.25)",
                        overflowY: "scroll",
                    }}
                >
                    <Container>
                        <Paper p={"md"} shadow="lg" withBorder>

                            {role === "coach" && license && license.type === "commercial"  &&
                                
                                <>  
                                <Title order={2}>
                                   Frequently Asked Questions - Coach:
                               </Title>
                               <CommercialFAQ />
                               </>
                            }
                            {role === "coach" && (!license || license.type !== "commercial")  &&
                            <>  
                             <Title order={2}>
                                Frequently Asked Questions - Coach:
                            </Title>
                            <StandardFAQ />
                            </>
                            }
                            <Title order={2} mt={'lg'}>
                                Frequently Asked Questions - Client:
                            </Title>
                            <ClientFAQ />
                            <Title order={2} mt={"xl"}>
                                Important documents
                            </Title>
                            <Anchor
                                href="privacy_policy.pdf"
                                target="_blank"
                                color="black"
                            >
                                <Flex align={"center"} mt={"md"}>
                                    <Title order={6} color={"black"}>
                                        PRIVACY POLICY{" "}
                                        <IconLink size={"1rem"} />
                                    </Title>
                                </Flex>
                            </Anchor>
                        </Paper>
                    </Container>
                </Box>
            </BackgroundImage>
        </AppShell>
    );
}

function StandardFAQ() {
    return (
        <Accordion defaultValue="" mt={"md"}>
            <Accordion.Item value="uno">
                <Accordion.Control>
                    <Title order={6} tt={"uppercase"} fz={"md"}>
                        1. How Do I Buy and Use PROGREZR Credits Under the
                        Standard License?
                    </Title>
                </Accordion.Control>
                <Accordion.Panel>
                    <p>
                        <strong>
                            Guide to Buying and Using PROGREZR Standard License
                            Credits
                        </strong>
                    </p>
                    <p>
                        Welcome to PROGREZR! We&apos;re excited to guide you
                        through purchasing and utilising credits under the
                        PROGREZR Standard License. Here&apos;s everything you
                        need to know:
                    </p>
                    <p>
                        <strong>Understanding Credits and License:</strong>
                    </p>
                    <p>
                        Under the PROGREZR Standard License, 1 credit equals the
                        ability to support 1 active client for a duration of 1
                        month. These credits are the currency of your client
                        support capabilities within the PROGREZR platform.
                    </p>
                    <p>
                        <strong>Purchasing Credits:</strong>
                    </p>
                    <p>
                        Navigate to the Add Credits Section under your account
                        information. Choose Your Credit Package: Select the
                        package that suits your needs best. Consider the number
                        of clients you intend to manage and the duration for
                        which you&apos;ll need the credits.
                    </p>
                    <p>
                        Complete the Purchase: Follow the prompts to complete
                        your purchase securely. Once the transaction is
                        successful, the credits will be added to your account.
                    </p>
                    <p>
                        <strong>Using Credits:</strong>
                    </p>
                    <p>
                        Assigning Credits: Each time you start your engagement
                        with a new client, a credit will be deducted from your
                        total. This process ensures that you have the necessary
                        resources to manage their needs effectively.
                    </p>
                    <p>
                        1 Credit allows you to support your client for a month.
                    </p>
                    <p>
                        Monitoring Credit Usage: Keep an eye on your credit
                        balance. The platform will notify you when your credit
                        balance is running low, allowing you to replenish as
                        needed.
                    </p>
                    <p>
                        Renewing Credits: If you continue supporting a client
                        beyond the initial month, automatically 1 credit will be
                        deducted for each month.
                    </p>
                    <p>
                        <strong>Example Scenario:</strong>
                    </p>
                    <p>
                        Let&apos;s say you&apos;re managing 30 clients with
                        various durations:
                    </p>
                    <p>Example 1: 2 Clients Over 4 Months</p>
                    <p>Total Credits Used:</p>
                    <p>2 clients &times; 1 credit x 2 months = 4 credits</p>
                    <p>&nbsp;</p>
                    <p>Example 2: 4 Clients Over 6 Months</p>
                    <p>Total Credits Used:</p>
                    <p>
                        4 clients &times; 1 credit &times; 6 months = 24 credits
                    </p>
                    <p>&nbsp;</p>
                    <p>Example 3: 3 Clients Over 9 Months</p>
                    <p>Total Credits Used:</p>
                    <p>
                        3 clients &times; 1 credit &times; 9 months = 27 credits
                    </p>
                    <p>&nbsp;</p>
                    <p>Example 4: 1 Client Over 11 Months</p>
                    <p>
                        1 client &times; 1 credit &times; 11 months = 11 credits
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        These totals represent the cumulative credits needed to
                        manage the specified number of clients over their
                        respective durations. Efficient allocation and renewal
                        of credits are crucial to maintaining uninterrupted
                        service for clients within the PROGREZR platform.
                    </p>
                    <p>
                        <strong>Conclusion:</strong>
                    </p>
                    <p>
                        Understanding the usage and allocation of credits under
                        the PROGREZR Standard License ensures smooth client
                        management within the platform. By following this guide,
                        you can efficiently purchase, allocate, and renew
                        credits, ensuring seamless operations and client
                        satisfaction.
                    </p>
                    <p>
                        Should you have any further questions or require
                        assistance, our support team is always available to
                        help.
                    </p>
                </Accordion.Panel>
            </Accordion.Item>

            <Accordion.Item value="dos">
                <Accordion.Control>
                    <Title order={6} tt={"uppercase"} fz={"md"}>
                        2. How Do I Manage Client Status in PROGREZR?
                    </Title>
                </Accordion.Control>
                <Accordion.Panel>
                    <p>
                        <strong>
                            {" "}
                            Guidance for Managing Client Status in PROGREZR:
                        </strong>
                    </p>
                    <p>
                        <strong>Turning Clients Inactive:</strong>
                    </p>
                    <p>
                        When taking a break from coaching a client or when you
                        have completed the coaching program, you have the option
                        to turn the client&apos;s status to
                        &quot;inactive&quot;.
                    </p>
                    <p>
                        Inactive status ensures that neither you nor the client
                        can make edits or write changes to the account.
                    </p>
                    <p>
                        Both you and the client retain &quot;read&quot; rights,
                        allowing access to historical data and information, but
                        you do not have any &ldquo;write/edit&rdquo; rights as
                        long as the client status is inactive.
                    </p>
                    <p>
                        <strong>Minimum Activation Period:</strong>
                    </p>
                    <p>
                        When reactivating a client, the activation will be for a
                        minimum duration of 1 month.
                    </p>
                    <p>
                        This ensures consistency and commitment in coaching
                        engagements, promoting effective progress and
                        communication.
                    </p>
                    <p>
                        <strong>Handling Non-Payment:</strong>
                    </p>
                    <p>
                        If a client fails to make payments, as a coach, you have
                        the authority to set the client to &quot;inactive&quot;.
                    </p>
                    <p>
                        Inactive status restricts access to editing or writing
                        rights until payments are resumed.
                    </p>
                    <p>
                        <strong>Rights and Access:</strong>
                    </p>
                    <p>
                        Both coaches and clients retain &quot;read&quot; rights
                        regardless of their status (active or inactive).
                    </p>
                    <p>
                        This allows transparency and access to past sessions,
                        documents, and progress reports.
                    </p>
                    <p>
                        <strong>Communication:</strong>
                    </p>
                    <p>
                        It&apos;s crucial to communicate clearly with clients
                        about status changes and expectations regarding
                        activation periods and payment terms.
                    </p>
                    <p>
                        Open communication fosters trust and understanding
                        between coach and client, ensuring smooth transitions
                        and engagements.
                    </p>
                    <p>
                        Managing client status in PROGREZR involves balancing
                        flexibility and accountability. By adhering to these
                        guidelines, coaches can maintain effective coaching
                        relationships while ensuring clarity and consistency in
                        client engagements.
                    </p>
                    <p>&nbsp;</p>
                </Accordion.Panel>
            </Accordion.Item>

            <Accordion.Item value="tres">
                <Accordion.Control>
                    <Title order={6} tt={"uppercase"} fz={"md"}>
                        3. What Is the Difference Between Fixed and Ongoing
                        Clients Status?
                    </Title>
                </Accordion.Control>
                <Accordion.Panel>
                    <p>
                        <strong>
                            Guide to Client Status Options in PROGREZR:
                        </strong>
                    </p>
                    <p>
                        <strong>Ongoing Status:</strong>
                    </p>
                    <p>
                        Definition: The ongoing status means that the usage of
                        PROGREZR for a single client is automatically extended
                        each month, with a maximum limit of 12 months.
                    </p>
                    <p>
                        Functionality: Clients under ongoing status remain
                        active until manually deactivated or until they reach
                        the 12-month limit.
                    </p>
                    <p>
                        Usage: Ideal for clients with indefinite coaching needs
                        or those requiring continuous support and engagement.
                    </p>
                    <p>
                        <strong>Fixed Status:</strong>
                    </p>
                    <p>
                        Definition: The fixed status allows setting a
                        predetermined duration of 1 or 6 months for the
                        client&apos;s active status.
                    </p>
                    <p>
                        Functionality: Clients under fixed status will
                        automatically be set to inactive after the chosen period
                        expires.
                    </p>
                    <p>
                        Usage: Suited for clients with specific short-term goals
                        or engagements requiring a defined timeline.
                    </p>
                    <p>
                        <strong>
                            Saving Credits with Fixed Status (6 months):
                        </strong>
                    </p>
                    <p>
                        Benefit: Opting for a fixed status of 6 months offers a
                        credit-saving advantage.
                    </p>
                    <p>
                        Credit Deduction: When selecting the fixed status for 6
                        months, only 15 credits per client will be deducted
                        instead of 18.
                    </p>
                    <p>
                        Savings: This credit-saving mechanism allows coaches to
                        save 3 credit per client over the 6-month period.
                    </p>
                    <p>
                        Economy: An economical choice for managing multiple
                        clients over an extended duration while optimizing
                        credit usage.
                    </p>
                    <p>
                        <strong>Manual Adjustments:</strong>
                    </p>
                    <p>
                        Flexibility: Coaches have the flexibility to manually
                        switch client statuses between ongoing and fixed as per
                        individual needs and preferences.
                    </p>
                    <p>
                        Control: Allows coaches to adapt to changing client
                        requirements and engagement dynamics seamlessly.
                    </p>
                    <p>
                        <strong>Conclusion:</strong>
                    </p>
                    <p>
                        Understanding and utilizing the different client status
                        options in PROGREZR empower coaches to tailor their
                        coaching services effectively. Whether it&apos;s
                        providing ongoing support or setting specific durations,
                        these features ensure flexibility, efficiency, and
                        cost-effectiveness in managing client engagements within
                        the platform.
                    </p>
                    <p>&nbsp;</p>
                </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value="quatro">
                <Accordion.Control>
                    <Title order={6} tt={"uppercase"} fz={"md"}>
                        4. How Can I Use PROGREZR In My Value Proposition
                        Towards Clients and Sponsors?
                    </Title>
                </Accordion.Control>
                <Accordion.Panel>
                    <p>
                        <strong>
                            Guide to Leveraging PROGREZR in Your Value
                            Proposition
                        </strong>
                    </p>
                    <p>
                        PROGREZR offers a comprehensive platform for coaches and
                        clients to facilitate coaching sessions effectively
                        while maximizing value. Integrating PROGREZR into your
                        value proposition enhances collaboration,
                        accountability, and ROI visibility for both clients and
                        companies.
                    </p>
                    <p>
                        <strong>Benefits for Clients and Companies:</strong>
                    </p>
                    <p>
                        <strong>Actionable Insights:</strong> PROGREZR provides
                        actionable insights derived from coaching sessions,
                        assessments, and progress tracking. Clients gain
                        valuable insights into their strengths, areas for
                        improvement, and actionable strategies for growth.
                    </p>
                    <p>
                        <strong>Progress Tracking:</strong> Clients can track
                        their progress over time, setting and achieving
                        milestones with clarity and confidence. Progress
                        tracking features empower clients to stay motivated and
                        focused on their goals.
                    </p>
                    <p>
                        <strong>Accountability for Actions:</strong> With
                        PROGREZR, clients and coaches can establish clear
                        accountability frameworks, ensuring that action plans
                        are implemented effectively. Accountability fosters
                        commitment and drives tangible results.
                    </p>
                    <p>
                        <strong>Transparent Collaboration:</strong> PROGREZR
                        promotes transparent collaboration between coaches and
                        clients, fostering open communication, trust, and
                        alignment towards shared objectives. Clients feel
                        empowered and engaged throughout their coaching journey.
                    </p>
                    <p>
                        <strong>Competency-Based ROI:</strong> PROGREZR offers
                        robust tools for measuring and demonstrating return on
                        investment (ROI) in competency development initiatives.
                        Companies can assess the impact of coaching
                        interventions on individual and organizational
                        competencies, enhancing workforce effectiveness and
                        performance.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <strong>Pricing Strategy:</strong>
                    </p>
                    <p>
                        <strong>Coaching Session Duration:</strong> Tailor
                        coaching session durations to meet client needs,
                        offering flexibility and value. Standard session
                        durations can be 90 minutes or 120 minutes.
                    </p>
                    <p>
                        <strong>Pricing Structure:</strong> Incorporate PROGREZR
                        usage into your pricing proposal by charging additional
                        time for preparation and follow-up activities.
                    </p>
                    <p>
                        <strong>&nbsp;</strong>
                    </p>
                    <p>
                        <strong>Pricing Guidelines:</strong>
                    </p>
                    <p>
                        For a 90-minute coaching session, charge an additional
                        30 minutes for preparation and follow-up.
                    </p>
                    <p>
                        For a 120-minute coaching session, charge an additional
                        60 minutes for preparation and follow-up.
                    </p>
                    <p>
                        <strong>&nbsp;</strong>
                    </p>
                    <p>
                        <strong>Transparent Communication:</strong> Clearly
                        communicate pricing structures and billing policies to
                        clients and companies. Transparency builds trust and
                        ensures alignment on expectations.
                    </p>
                    <p>
                        <strong>Conclusion:</strong>
                    </p>
                    <p>
                        Integrating PROGREZR into your value proposition
                        enhances coaching effectiveness, client satisfaction,
                        and business outcomes. By emphasizing actionable
                        insights, progress tracking, accountability, transparent
                        collaboration, and ROI visibility, you can position
                        PROGREZR as a valuable asset in coaching engagements.
                        Incorporating transparent pricing structures further
                        strengthens your value proposition, fostering trust and
                        long-term partnerships with clients and companies.
                    </p>
                    <p>&nbsp;</p>
                </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value="cinco">
                <Accordion.Control>
                    <Title order={6} tt={"uppercase"} fz={"md"}>
                        5. What Is the Theoretical Framework Used For Measuring
                        Progress?{" "}
                    </Title>
                </Accordion.Control>
                <Accordion.Panel>
                    <p>
                        <strong>
                            5. Guide to Utilizing the Competency Framework for
                            Tracking Progress
                        </strong>
                    </p>
                    <p>
                        The competency framework serves as a valuable tool for
                        tracking progress in coaching engagements, helping
                        clients move from unconscious incompetence to conscious
                        competence in various areas. By combining the competency
                        framework with completed actions per coaching topic,
                        coaches can effectively guide clients through their
                        developmental journey.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <strong>Understanding the Competency Framework:</strong>
                    </p>
                    <p>
                        <strong>Unconscious Incompetence:</strong> At this
                        stage, clients lack awareness of their deficiencies in
                        specific competencies or skills.
                    </p>
                    <p>
                        <strong>Conscious Incompetence:</strong> Clients become
                        aware of their deficiencies and recognize the need for
                        improvement in certain areas.
                    </p>
                    <p>
                        <strong>Conscious Competence:</strong> Through learning
                        and practice, clients develop competence in identified
                        areas, though it requires conscious effort and
                        concentration.
                    </p>
                    <p>
                        <strong>Unconscious Competence:</strong> Clients attain
                        mastery in specific competencies or skills, performing
                        tasks effortlessly and without conscious thought.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <strong>Incorporating Completed Actions:</strong>
                    </p>
                    <p>
                        <strong>Identify Coaching Topics:</strong> Break down
                        coaching engagements into specific topics or areas of
                        focus, aligning them with the competency framework.
                    </p>
                    <p>
                        <strong>Set Clear Objectives:</strong> Define clear
                        objectives for each coaching topic, outlining desired
                        outcomes and competency development milestones.
                    </p>
                    <p>
                        <strong>Track Completed Actions:</strong> Record
                        completed actions or tasks undertaken by clients to
                        address specific coaching topics. Each action should
                        contribute to competency development and align with the
                        competency framework.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <strong>Assess Progress:</strong> Regularly assess
                        client progress by evaluating their movement within the
                        competency framework. Monitor shifts from unconscious
                        incompetence to conscious competence across different
                        coaching topics.
                    </p>
                    <p>
                        <strong>Provide Feedback:</strong> Offer constructive
                        feedback based on clients&apos; progress within the
                        competency framework. Highlight areas of improvement and
                        celebrate achievements as clients move towards mastery.
                    </p>
                    <p>
                        <strong>Adjust Coaching Approach:</strong> Adapt
                        coaching strategies based on clients&apos; progress
                        within the competency framework. Tailor interventions to
                        address evolving needs and challenges encountered along
                        the developmental journey.
                    </p>
                    <p>
                        <strong>&nbsp;</strong>
                    </p>
                    <p>
                        <strong>Continuous Improvement and Reflection:</strong>
                    </p>
                    <p>
                        <strong>Encourage Reflection:</strong> Encourage clients
                        to reflect on their progress within the competency
                        framework, fostering self-awareness and accountability
                        for growth.
                    </p>
                    <p>
                        <strong>Iterative Approach:</strong> Embrace an
                        iterative approach to coaching, continuously refining
                        strategies and interventions based on feedback and
                        outcomes observed within the competency framework.
                    </p>
                    <p>
                        <strong>Celebrate Milestones:</strong> Celebrate
                        milestones achieved by clients as they progress through
                        the competency framework, acknowledging their dedication
                        and perseverance in pursuit of mastery.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <strong>Conclusion:</strong>
                    </p>
                    <p>
                        By leveraging the competency framework in conjunction
                        with completed actions per coaching topic, coaches can
                        effectively track client progress and facilitate
                        meaningful developmental experiences. Embrace a
                        collaborative approach that empowers clients to navigate
                        their developmental journey from unconscious
                        incompetence to conscious competence, ultimately
                        fostering personal and professional growth.
                    </p>
                </Accordion.Panel>
            </Accordion.Item>
        </Accordion>
    );
}
function ClientFAQ() {
    return (
        <Accordion defaultValue="" mt={"md"}>
            <Accordion.Item value="uno">
                <Accordion.Control>
                    <Title order={6} tt={"uppercase"} fz={"md"}>
                        1. Why Is It Important To Record My Actions After Each
                        Session?
                    </Title>
                </Accordion.Control>
                <Accordion.Panel>
                    <p>
                        <strong>
                            Guide for Clients: Taking Ownership of Your Progress
                            in Coaching
                        </strong>
                    </p>
                    <p>
                        Congratulations on taking the step to work with a coach!
                        As you embark on your coaching journey, it&apos;s
                        essential to understand the role you play in driving
                        your own progress. Here&apos;s why it&apos;s crucial to
                        write down 2-3 actions after each coaching session and
                        take responsibility for your development:
                    </p>
                    <p>
                        <strong>1. Clarifying Objectives:</strong>
                    </p>
                    <p>
                        Writing down 2-3 actions after each coaching session
                        helps clarify your objectives and focus areas. It serves
                        as a roadmap for your progress, ensuring that you remain
                        aligned with your goals between sessions.
                    </p>
                    <p>
                        <strong>2. Reinforcing Commitment:</strong>
                    </p>
                    <p>
                        By documenting specific actions, you reinforce your
                        commitment to your growth and development. It&apos;s a
                        tangible reminder of the steps you&apos;ve agreed to
                        take and the changes you&apos;re committed to making in
                        your life or career.
                    </p>
                    <p>
                        <strong>3. Enhancing Accountability:</strong>
                    </p>
                    <p>
                        Writing down actions holds you accountable for your
                        progress. When you review your actions regularly,
                        you&apos;re more likely to follow through and make
                        meaningful strides towards your goals.
                    </p>
                    <p>
                        <strong>4. Maximizing Session Impact:</strong>
                    </p>
                    <p>
                        The actions you jot down are directly tied to the
                        insights and breakthroughs you experience during
                        coaching sessions. They translate abstract concepts into
                        tangible steps you can take to effect positive change in
                        your life.
                    </p>
                    <p>
                        <strong>5. Empowering Self-Reflection:</strong>
                    </p>
                    <p>
                        Reviewing your actions allows for self-reflection and
                        introspection. You can assess what&apos;s working well,
                        what challenges you&apos;re facing, and where
                        adjustments may be necessary to stay on track.
                    </p>
                    <p>
                        <strong>6. Taking Ownership:</strong>
                    </p>
                    <p>
                        Ultimately, your progress in coaching is your
                        responsibility. While your coach serves as a guide and
                        facilitator, the real work happens between sessions as
                        you implement the actions you&apos;ve committed to.
                    </p>
                    <p>
                        <strong>Tips for Effective Action Writing:</strong>
                    </p>
                    <p>Be specific and measurable in defining your actions.</p>
                    <p>
                        Prioritize actions that align with your overarching
                        goals.
                    </p>
                    <p>Break larger goals into smaller, manageable steps.</p>
                    <p>
                        Set deadlines to create a sense of urgency and momentum.
                    </p>
                    <p>
                        Reflect on your actions during each session and adjust
                        as needed.
                    </p>
                    <p>
                        Your journey with your coach is a partnership, with both
                        of you working towards your success. By taking ownership
                        of your progress and actively engaging in the process,
                        you&apos;ll maximize the benefits of coaching and
                        realize your full potential. Remember, the power to
                        transform your life lies within you.
                    </p>
                </Accordion.Panel>
            </Accordion.Item>

            <Accordion.Item value="dos">
                <Accordion.Control>
                    <Title order={6} tt={"uppercase"} fz={"md"}>
                        2. What Is the Theoretical Framework Used For Measuring
                        Progress?
                    </Title>
                </Accordion.Control>
                <Accordion.Panel>
                    <p>
                        <strong>Understanding the Competency Framework:</strong>
                    </p>
                    <p>
                        <strong>Unconscious Incompetence:</strong> At this
                        stage, you may not be aware of your areas for
                        improvement or skills that need development.
                    </p>
                    <p>
                        <strong>Conscious Incompetence:</strong> As you
                        progress, you start recognizing where you need to grow
                        and improve in specific competencies.
                    </p>
                    <p>
                        <strong>Conscious Competence:</strong> Through learning
                        and practice, you develop competency in identified
                        areas, although it may require conscious effort.
                    </p>
                    <p>
                        <strong>Unconscious Competence:</strong> With continued
                        practice, you achieve mastery in certain competencies,
                        performing tasks effortlessly and without much thought.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <strong>Incorporating Completed Actions:</strong>
                    </p>
                    <p>
                        <strong>Identify Coaching Topics</strong>: Break down
                        your coaching journey into specific topics or areas you
                        want to focus on, aligning them with the competency
                        framework.
                    </p>
                    <p>
                        <strong>Set Clear Objectives:</strong> Define clear
                        objectives for each coaching topic, outlining the
                        outcomes you want to achieve and the milestones you want
                        to reach in terms of competency development.
                    </p>
                    <p>
                        <strong>Track Completed Actions:</strong> Keep a record
                        of the actions or tasks you complete to address each
                        coaching topic. Each action you take should contribute
                        to your competency development and align with the
                        competency framework.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <strong>Assessing Progress:</strong>
                    </p>
                    <p>
                        <strong>Regular Evaluation:</strong> Assess your
                        progress regularly together with your coach, by
                        evaluating how you&apos;re moving within the competency
                        framework. Notice shifts from unconscious incompetence
                        to conscious competence across different coaching
                        topics.
                    </p>
                    <p>
                        <strong>Feedback:</strong> Embrace feedback from your
                        coach based on your progress within the competency
                        framework. Celebrate your achievements and receive
                        guidance on areas where you can improve.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <strong>Adjusting Your Approach:</strong>
                    </p>
                    <p>
                        <strong>Reflection:</strong> Take time to reflect on
                        your progress within the competency framework. Foster
                        self-awareness and hold yourself accountable for your
                        growth.
                    </p>
                    <p>
                        <strong>Adaptation:</strong> Embrace an adaptive
                        approach to your coaching journey. Continuously refine
                        your strategies and actions based on feedback and
                        outcomes observed within the competency framework.
                    </p>
                    <p>
                        <strong>Celebration:</strong> Celebrate your milestones
                        as you progress through the competency framework.
                        Acknowledge your dedication and perseverance in pursuing
                        mastery in different competencies.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        By leveraging the competency framework and tracking your
                        progress through completed actions, you can navigate
                        your developmental journey effectively. Take ownership
                        of your growth, embrace feedback, and celebrate your
                        achievements along the way. With dedication and
                        reflection, you&apos;ll continue to move towards
                        conscious competence and achieve your personal and
                        professional goals.
                    </p>
                </Accordion.Panel>
            </Accordion.Item>
        </Accordion>
    );
}

function CommercialFAQ() {
    return (
        <Accordion defaultValue="" mt={"md"}>
            <Accordion.Item value="uno">
                <Accordion.Control>
                    <Title order={6} tt={"uppercase"} fz={"md"}>
                        1. How Do I Buy and Use PROGREZR Credits Under the
                        Commercial License?
                    </Title>
                </Accordion.Control>
                <Accordion.Panel>
                    <p>
                        <strong>
                            Guide to Buying and Using PROGREZR Standard License
                            Credits
                        </strong>
                    </p>
                    <p>
                        Welcome to PROGREZR! We&apos;re excited to guide you
                        through purchasing and utilising credits under the
                        PROGREZR Standard License. Here&apos;s everything you
                        need to know:
                    </p>
                    <p>
                        <strong>Understanding Credits and License:</strong>
                    </p>
                    <p>
                        Under the PROGREZR Commercial License, 3 credits equal
                        the ability to support 1 active client for a duration of
                        1 month. These credits are the currency of your client
                        support capabilities within the PROGREZR platform.
                    </p>
                    <p>
                        <strong>Purchasing Credits:</strong>
                    </p>
                    <p>
                        For Commercial License users, credits are usually
                        purchased through the Commercial License Holder who pays
                        for the credits, setup and support. In this case the Add
                        Credits Section is not visible or disabled.&nbsp;
                    </p>
                    <p>
                        In case you can purchase yourself, navigate to the Add
                        Credits Section under your account information. Choose
                        Your Credit Package: Select the package that suits your
                        needs best. Consider the number of clients you intend to
                        manage and the duration for which you&apos;ll need the
                        credits.
                    </p>
                    <p>
                        Complete the Purchase: Follow the prompts to complete
                        your purchase securely. Once the transaction is
                        successful, the credits will be added to your account.
                    </p>
                    <p>
                        <strong>Using Credits:</strong>
                    </p>
                    <p>
                        Assigning Credits: Each time you start your engagement
                        with a new client, 3 credits will be deducted from your
                        total. This process ensures that you have the necessary
                        resources to manage their needs effectively.&nbsp;
                    </p>
                    <p>
                        3 Credits allows you to support your client for a month.
                    </p>
                    <p>
                        Monitoring Credit Usage: Keep an eye on your credit
                        balance. The platform will notify you when your credit
                        balance is running low, allowing you to replenish as
                        needed.
                    </p>
                    <p>
                        Renewing Credits: If you continue supporting a client
                        beyond the initial month, automatically 3 credits will
                        be deducted for each month.&nbsp;
                    </p>
                    <p>
                        <strong>Example Scenario:</strong>
                    </p>
                    <p>
                        Let&apos;s say you&apos;re managing 30 clients with
                        various durations:
                    </p>
                    <p>Example 1: 2 Clients Over 4 Months</p>
                    <p>Total Credits Used:&nbsp;</p>
                    <p>2 clients &times; 3 credits x 2 months = 12 credits</p>
                    <p>&nbsp;</p>
                    <p>Example 2: 4 Clients Over 6 Months</p>
                    <p>Total Credits Used:&nbsp;</p>
                    <p>
                        4 clients &times; 3 credits &times; 6 months = 72
                        credits
                    </p>
                    <p>&nbsp;</p>
                    <p>Example 3: 3 Clients Over 9 Months</p>
                    <p>Total Credits Used:&nbsp;</p>
                    <p>
                        3 clients &times; 3 credits &times; 9 months = 81
                        credits
                    </p>
                    <p>&nbsp;</p>
                    <p>Example 4: 1 Client Over 11 Months</p>
                    <p>
                        1 client &times; 3 credits &times; 11 months = 99
                        credits
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        These totals represent the cumulative credits needed to
                        manage the specified number of clients over their
                        respective durations. Efficient allocation and renewal
                        of credits are crucial to maintaining uninterrupted
                        service for clients within the PROGREZR platform.
                    </p>
                    <p>
                        <strong>Conclusion:</strong>
                    </p>
                    <p>
                        Understanding the usage and allocation of credits under
                        the PROGREZR Standard License ensures smooth client
                        management within the platform. By following this guide,
                        you can efficiently purchase, allocate, and renew
                        credits, ensuring seamless operations and client
                        satisfaction.
                    </p>
                    <p>
                        Should you have any further questions or require
                        assistance, our support team is always available to
                        help.&nbsp;
                    </p>
                    <p>&nbsp;</p>
                </Accordion.Panel>
            </Accordion.Item>

            <Accordion.Item value="dos">
                <Accordion.Control>
                    <Title order={6} tt={"uppercase"} fz={"md"}>
                        2. How Do I Manage Client Status in PROGREZR?
                    </Title>
                </Accordion.Control>
                <Accordion.Panel>
                    <p>
                        <strong>
                            {" "}
                            Guidance for Managing Client Status in PROGREZR:
                        </strong>
                    </p>
                    <p>
                        <strong>Turning Clients Inactive:</strong>
                    </p>
                    <p>
                        When taking a break from coaching a client or when you
                        have completed the coaching program, you have the option
                        to turn the client&apos;s status to
                        &quot;inactive&quot;.
                    </p>
                    <p>
                        Inactive status ensures that neither you nor the client
                        can make edits or write changes to the account.
                    </p>
                    <p>
                        Both you and the client retain &quot;read&quot; rights,
                        allowing access to historical data and information, but
                        you do not have any &ldquo;write/edit&rdquo; rights as
                        long as the client status is inactive.
                    </p>
                    <p>
                        <strong>Minimum Activation Period:</strong>
                    </p>
                    <p>
                        When reactivating a client, the activation will be for a
                        minimum duration of 1 month.
                    </p>
                    <p>
                        This ensures consistency and commitment in coaching
                        engagements, promoting effective progress and
                        communication.
                    </p>
                    <p>
                        <strong>Handling Non-Payment:</strong>
                    </p>
                    <p>
                        If a client fails to make payments, as a coach, you have
                        the authority to set the client to &quot;inactive&quot;.
                    </p>
                    <p>
                        Inactive status restricts access to editing or writing
                        rights until payments are resumed.
                    </p>
                    <p>
                        <strong>Rights and Access:</strong>
                    </p>
                    <p>
                        Both coaches and clients retain &quot;read&quot; rights
                        regardless of their status (active or inactive).
                    </p>
                    <p>
                        This allows transparency and access to past sessions,
                        documents, and progress reports.
                    </p>
                    <p>
                        <strong>Communication:</strong>
                    </p>
                    <p>
                        It&apos;s crucial to communicate clearly with clients
                        about status changes and expectations regarding
                        activation periods and payment terms.
                    </p>
                    <p>
                        Open communication fosters trust and understanding
                        between coach and client, ensuring smooth transitions
                        and engagements.
                    </p>
                    <p>
                        Managing client status in PROGREZR involves balancing
                        flexibility and accountability. By adhering to these
                        guidelines, coaches can maintain effective coaching
                        relationships while ensuring clarity and consistency in
                        client engagements.
                    </p>
                    <p>&nbsp;</p>
                </Accordion.Panel>
            </Accordion.Item>

            <Accordion.Item value="tres">
                <Accordion.Control>
                    <Title order={6} tt={"uppercase"} fz={"md"}>
                        3. What Is the Difference Between Fixed and Ongoing
                        Clients Status?
                    </Title>
                </Accordion.Control>
                <Accordion.Panel>
                    <p>
                        <strong>
                            Guide to Client Status Options in PROGREZR:
                        </strong>
                    </p>
                    <p>
                        <strong>Ongoing Status:</strong>
                    </p>
                    <p>
                        Definition: The ongoing status means that the usage of
                        PROGREZR for a single client is automatically extended
                        each month, with a maximum limit of 12 months.
                    </p>
                    <p>
                        Functionality: Clients under ongoing status remain
                        active until manually deactivated or until they reach
                        the 12-month limit.
                    </p>
                    <p>
                        Usage: Ideal for clients with indefinite coaching needs
                        or those requiring continuous support and engagement.
                    </p>
                    <p>
                        <strong>Fixed Status:</strong>
                    </p>
                    <p>
                        Definition: The fixed status allows setting a
                        predetermined duration of 1 or 6 months for the
                        client&apos;s active status.
                    </p>
                    <p>
                        Functionality: Clients under fixed status will
                        automatically be set to inactive after the chosen period
                        expires.
                    </p>
                    <p>
                        Usage: Suited for clients with specific short-term goals
                        or engagements requiring a defined timeline.
                    </p>
                    <p>
                        <strong>
                            Saving Credits with Fixed Status (6 months):
                        </strong>
                    </p>
                    <p>
                        Benefit: Opting for a fixed status of 6 months offers a
                        credit-saving advantage.
                    </p>
                    <p>
                        Credit Deduction: When selecting the fixed status for 6
                        months, only 5 credits per client will be deducted
                        instead of 6.
                    </p>
                    <p>
                        Savings: This credit-saving mechanism allows coaches to
                        save 1 credit per client over the 6-month period.
                    </p>
                    <p>
                        Economy: An economical choice for managing multiple
                        clients over an extended duration while optimizing
                        credit usage.
                    </p>
                    <p>
                        <strong>Manual Adjustments:</strong>
                    </p>
                    <p>
                        Flexibility: Coaches have the flexibility to manually
                        switch client statuses between ongoing and fixed as per
                        individual needs and preferences.
                    </p>
                    <p>
                        Control: Allows coaches to adapt to changing client
                        requirements and engagement dynamics seamlessly.
                    </p>
                    <p>
                        <strong>Conclusion:</strong>
                    </p>
                    <p>
                        Understanding and utilizing the different client status
                        options in PROGREZR empower coaches to tailor their
                        coaching services effectively. Whether it&apos;s
                        providing ongoing support or setting specific durations,
                        these features ensure flexibility, efficiency, and
                        cost-effectiveness in managing client engagements within
                        the platform.
                    </p>
                    <p>&nbsp;</p>
                </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value="quatro">
                <Accordion.Control>
                    <Title order={6} tt={"uppercase"} fz={"md"}>
                        4. How Can I Use PROGREZR In My Value Proposition
                        Towards Clients and Sponsors?
                    </Title>
                </Accordion.Control>
                <Accordion.Panel>
                    <p>
                        <strong>
                            Guide to Leveraging PROGREZR in Your Value
                            Proposition
                        </strong>
                    </p>
                    <p>
                        PROGREZR offers a comprehensive platform for coaches and
                        clients to facilitate coaching sessions effectively
                        while maximizing value. Integrating PROGREZR into your
                        value proposition enhances collaboration,
                        accountability, and ROI visibility for both clients and
                        companies.
                    </p>
                    <p>
                        <strong>Benefits for Clients and Companies:</strong>
                    </p>
                    <p>
                        <strong>Actionable Insights:</strong> PROGREZR provides
                        actionable insights derived from coaching sessions,
                        assessments, and progress tracking. Clients gain
                        valuable insights into their strengths, areas for
                        improvement, and actionable strategies for growth.
                    </p>
                    <p>
                        <strong>Progress Tracking:</strong> Clients can track
                        their progress over time, setting and achieving
                        milestones with clarity and confidence. Progress
                        tracking features empower clients to stay motivated and
                        focused on their goals.
                    </p>
                    <p>
                        <strong>Accountability for Actions:</strong> With
                        PROGREZR, clients and coaches can establish clear
                        accountability frameworks, ensuring that action plans
                        are implemented effectively. Accountability fosters
                        commitment and drives tangible results.
                    </p>
                    <p>
                        <strong>Transparent Collaboration:</strong> PROGREZR
                        promotes transparent collaboration between coaches and
                        clients, fostering open communication, trust, and
                        alignment towards shared objectives. Clients feel
                        empowered and engaged throughout their coaching journey.
                    </p>
                    <p>
                        <strong>Competency-Based ROI:</strong> PROGREZR offers
                        robust tools for measuring and demonstrating return on
                        investment (ROI) in competency development initiatives.
                        Companies can assess the impact of coaching
                        interventions on individual and organizational
                        competencies, enhancing workforce effectiveness and
                        performance.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <strong>Pricing Strategy:</strong>
                    </p>
                    <p>
                        <strong>Coaching Session Duration:</strong> Tailor
                        coaching session durations to meet client needs,
                        offering flexibility and value. Standard session
                        durations can be 90 minutes or 120 minutes.
                    </p>
                    <p>
                        <strong>Pricing Structure:</strong> Incorporate PROGREZR
                        usage into your pricing proposal by charging additional
                        time for preparation and follow-up activities.
                    </p>
                    <p>
                        <strong>&nbsp;</strong>
                    </p>
                    <p>
                        <strong>Pricing Guidelines:</strong>
                    </p>
                    <p>
                        For a 90-minute coaching session, charge an additional
                        30 minutes for preparation and follow-up.
                    </p>
                    <p>
                        For a 120-minute coaching session, charge an additional
                        60 minutes for preparation and follow-up.
                    </p>
                    <p>
                        <strong>&nbsp;</strong>
                    </p>
                    <p>
                        <strong>Transparent Communication:</strong> Clearly
                        communicate pricing structures and billing policies to
                        clients and companies. Transparency builds trust and
                        ensures alignment on expectations.
                    </p>
                    <p>
                        <strong>Conclusion:</strong>
                    </p>
                    <p>
                        Integrating PROGREZR into your value proposition
                        enhances coaching effectiveness, client satisfaction,
                        and business outcomes. By emphasizing actionable
                        insights, progress tracking, accountability, transparent
                        collaboration, and ROI visibility, you can position
                        PROGREZR as a valuable asset in coaching engagements.
                        Incorporating transparent pricing structures further
                        strengthens your value proposition, fostering trust and
                        long-term partnerships with clients and companies.
                    </p>
                    <p>&nbsp;</p>
                </Accordion.Panel>
            </Accordion.Item>
            <Accordion.Item value="cinco">
                <Accordion.Control>
                    <Title order={6} tt={"uppercase"} fz={"md"}>
                        5. What Is the Theoretical Framework Used For Measuring
                        Progress?{" "}
                    </Title>
                </Accordion.Control>
                <Accordion.Panel>
                    <p>
                        <strong>
                            5. Guide to Utilizing the Competency Framework for
                            Tracking Progress
                        </strong>
                    </p>
                    <p>
                        The competency framework serves as a valuable tool for
                        tracking progress in coaching engagements, helping
                        clients move from unconscious incompetence to conscious
                        competence in various areas. By combining the competency
                        framework with completed actions per coaching topic,
                        coaches can effectively guide clients through their
                        developmental journey.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <strong>Understanding the Competency Framework:</strong>
                    </p>
                    <p>
                        <strong>Unconscious Incompetence:</strong> At this
                        stage, clients lack awareness of their deficiencies in
                        specific competencies or skills.
                    </p>
                    <p>
                        <strong>Conscious Incompetence:</strong> Clients become
                        aware of their deficiencies and recognize the need for
                        improvement in certain areas.
                    </p>
                    <p>
                        <strong>Conscious Competence:</strong> Through learning
                        and practice, clients develop competence in identified
                        areas, though it requires conscious effort and
                        concentration.
                    </p>
                    <p>
                        <strong>Unconscious Competence:</strong> Clients attain
                        mastery in specific competencies or skills, performing
                        tasks effortlessly and without conscious thought.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <strong>Incorporating Completed Actions:</strong>
                    </p>
                    <p>
                        <strong>Identify Coaching Topics:</strong> Break down
                        coaching engagements into specific topics or areas of
                        focus, aligning them with the competency framework.
                    </p>
                    <p>
                        <strong>Set Clear Objectives:</strong> Define clear
                        objectives for each coaching topic, outlining desired
                        outcomes and competency development milestones.
                    </p>
                    <p>
                        <strong>Track Completed Actions:</strong> Record
                        completed actions or tasks undertaken by clients to
                        address specific coaching topics. Each action should
                        contribute to competency development and align with the
                        competency framework.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <strong>Assess Progress:</strong> Regularly assess
                        client progress by evaluating their movement within the
                        competency framework. Monitor shifts from unconscious
                        incompetence to conscious competence across different
                        coaching topics.
                    </p>
                    <p>
                        <strong>Provide Feedback:</strong> Offer constructive
                        feedback based on clients&apos; progress within the
                        competency framework. Highlight areas of improvement and
                        celebrate achievements as clients move towards mastery.
                    </p>
                    <p>
                        <strong>Adjust Coaching Approach:</strong> Adapt
                        coaching strategies based on clients&apos; progress
                        within the competency framework. Tailor interventions to
                        address evolving needs and challenges encountered along
                        the developmental journey.
                    </p>
                    <p>
                        <strong>&nbsp;</strong>
                    </p>
                    <p>
                        <strong>Continuous Improvement and Reflection:</strong>
                    </p>
                    <p>
                        <strong>Encourage Reflection:</strong> Encourage clients
                        to reflect on their progress within the competency
                        framework, fostering self-awareness and accountability
                        for growth.
                    </p>
                    <p>
                        <strong>Iterative Approach:</strong> Embrace an
                        iterative approach to coaching, continuously refining
                        strategies and interventions based on feedback and
                        outcomes observed within the competency framework.
                    </p>
                    <p>
                        <strong>Celebrate Milestones:</strong> Celebrate
                        milestones achieved by clients as they progress through
                        the competency framework, acknowledging their dedication
                        and perseverance in pursuit of mastery.
                    </p>
                    <p>&nbsp;</p>
                    <p>
                        <strong>Conclusion:</strong>
                    </p>
                    <p>
                        By leveraging the competency framework in conjunction
                        with completed actions per coaching topic, coaches can
                        effectively track client progress and facilitate
                        meaningful developmental experiences. Embrace a
                        collaborative approach that empowers clients to navigate
                        their developmental journey from unconscious
                        incompetence to conscious competence, ultimately
                        fostering personal and professional growth.
                    </p>
                </Accordion.Panel>
            </Accordion.Item>
        </Accordion>
    );
}

import React from "react";
import BItem from "../../utils/BItem";
import { calculateDuration } from "./timesheetUtils";
import { DateInput, TimeInput } from "@mantine/dates";
import dayjs from "dayjs";
import { Group, Text, Textarea, Checkbox, NumberInput } from "@mantine/core";
import { formattedDate, SafeDateInput, DeleteButton } from "components/utils/MantineUtils";
import { formatDate } from "../ClientService";
export default function TimesheetRecord(props) {
    const R = props.recordData;

    function deleteInput() {
        props.deleteInput("R", R.id);
    }

    function filterNumber(value) {
        return value
            .replace(/[^0-9.]/g, "")
            .replace(/(\..*?)\..*/g, "$1")
            .replace(/^0[^.]/, "0");
    }

    function handleDate(e) {
        let formattedDate = dayjs(e).format("DD-MMM-YYYY").toString();
        props.handleChange(formattedDate, "R", R.id, "date");
    }

    return (
        <tr>
            <td width={'120px'}>
                <BItem editing={props.editing}>
                    <Text>
                        {formattedDate(R.date)}
                    </Text>
                    <SafeDateInput
                        date={R.date}
                        handleChange={props.handleChange}
                        params={["R", R.id, "date"]}
                    />
                </BItem>
            </td>
            <td width={'180px'}>
                <BItem editing={props.editing}>
                    <Text>
                        {R.startTime} - {R.endTime}
                    </Text>
                    <Group>
                        <TimeInput

                            placeholder={R.startTime}
                            value={R.startTime}
                            onChange={(e) =>
                                props.handleChange(
                                    e.target.value,
                                    "R",
                                    R.id,
                                    "startTime"
                                )
                            }
                        />
                        -
                        <TimeInput
                            placeholder={R.endTime}
                            value={R.endTime}
                            onChange={(e) =>
                                props.handleChange(
                                    e.target.value,
                                    "R",
                                    R.id,
                                    "endTime"
                                )
                            }
                        />
                    </Group>
                </BItem>
            </td>
            <td width={'60px'}>
                <Text>
                    {calculateDuration(R.startTime, R.endTime) || "-"}
                </Text>
            </td>
            <td>
                <BItem editing={props.editing}>
                    <Text>
                        {R.description || "-"}
                    </Text>
                    <Textarea minRows={2}
                        value={R.description}
                        onChange={(e) =>
                            props.handleChange(
                                e.target.value,
                                "R",
                                R.id,
                                "description"
                            )
                        }

                    />
                </BItem>
            </td>
            <td width={'130px'} >
                <Checkbox
                    disabled={!props.editing}
                    checked={R.singleCharge}
                    onChange={(e) =>
                        props.handleChange(
                            e.target.checked,
                            "R",
                            R.id,
                            "singleCharge"
                        )
                    }
                />
            </td>
            <td width={'130px'} >
                <BItem editing={props.editing}>
                    <Text>
                        {R.hourlyRate || "-"}
                    </Text>
                    <NumberInput
                        onChange={(e) =>
                            props.handleChange(
                                e,
                                "R",
                                R.id,
                                "hourlyRate"
                            )

                        }
                        value={parseInt(R.hourlyRate)}
                        placeholder={R.hourlyRate}
                        precision={2}
                        min={0}
                        max={9999}

                    />
                </BItem>
            </td>
            <td width={'160px'} >
                <Text>
                    {R.singleCharge
                        ? R.hourlyRate
                        : props.calculateTotalAmount(
                            R.hourlyRate,
                            calculateDuration(R.startTime, R.endTime)
                        ) || "-"}
                </Text>
            </td>
            <td>
                <BItem editing={props.editing}>
                    <Text>
                        {formattedDate(R.invoiced)}
                    </Text>
                    <SafeDateInput
                        date={R.invoiced}
                        handleChange={props.handleChange}
                        params={["R", R.id, "invoiced"]}
                    />
                </BItem>
            </td>
            <td>
                <BItem editing={props.editing}>
                    <Text>
                        {formattedDate(R.paid)}
                    </Text>
                    <Group>
                        <SafeDateInput
                            date={R.paid}
                            handleChange={props.handleChange}
                            params={["R", R.id, "paid"]}
                        />

                    </Group>
                </BItem>
            </td>
            {props.editing && (
                <td>
                    <DeleteButton click={deleteInput} />
                </td>
            )
            }

        </tr>
    );
}

import { Timestamp } from "firebase/firestore"

export default class ClientService {
    constructor(setEditedData, setScrollTop) {
        this.setEditedData = setEditedData
        this.setScrollTop = setScrollTop
    }
    /**
     * Helper function to find an index of an object in an array by id
     */
    findIndexById(editedData, cat, id) {
        return editedData[cat].findIndex(ic => ic.id === id
        )
    }
    /**
     * 
     * @param { EditedData state of current page } editedData 
     * @param { New data to be saved } data 
     * @param { Category within tree of page object } cat 
     * @param { id of input that needs to be deleted in Category } id 
     * @param { field of object that needs to be changed } key 
     */
    handleChange(editedData, data, cat, id, key) {
        let old = Object.assign([], editedData[cat])
        old[this.findIndexById(editedData, cat, id)][key] = data
        this.setEditedData((prevState) => ({
            ...prevState, [cat]: old
        }))
    }

    handleChangeAction(editedData, data, cat, noteId, actionId, key) {
        let old = Object.assign([], editedData[cat])

        const noteIndex = this.findIndexById(editedData, cat, noteId)

        const actionIndex = this.findIndexById(old[noteIndex], 'actions', actionId)
        let oldAction = Object.assign({}, old[noteIndex]['actions'][actionIndex])

        oldAction[key] = data

        old[noteIndex]['actions'][actionIndex] = oldAction

        this.setEditedData((prevState) => ({
            ...prevState,
            [cat]: old

        }))
    }
    /**
     * Helper function to generate a new id for an added input
     */
    generateId(editedData, cat) {
        if (editedData[cat] && editedData[cat].length > 0) {
            return Math.max(...editedData[cat].map(o => o?.id)) + 1
        }
        return 0
    }
    /**
     * 
     * @param { EditedData state of current page } editedData 
     * @param { Category within tree of page object } cat 
     * @param { DataInput template that is needed for a new input } template 
     */
    addInput(editedData, cat, template) {
        const newInput = Object.assign({}, template)
        newInput.id = this.generateId(editedData, cat)
        const cur = Object.assign([], editedData[cat])
        cur.push(newInput)
        this.setEditedData((prevState) => ({
            ...prevState,
            [cat]: cur
        }))
    }
    formatDate(date) {

        if (date && date != '-') {
            let day = new Date(date)
            if (date.seconds) {
                day = new Timestamp(date.seconds, date.nanoseconds).toDate()
            }
            const yyyy = day.getFullYear();
            let mm = day.getMonth() + 1; // Months start at 0!
            let dd = day.getDate();

            if (dd < 10) dd = '0' + dd;
            if (mm < 10) mm = '0' + mm;
            return dd + '-' + mm + '-' + yyyy;
        }
    }
    /**
     * 
     * @param { EditedData state of current page } editedData 
     * @param { Category within tree of page object } cat 
     * @param { id of input that needs to be deleted in Category } id 
     */
    deleteInput(editedData, cat, id) {
        let old = Object.assign([], editedData[cat])
        const filtered = old.filter((item) => item.id !== id)
        this.setEditedData((prevState) => ({
            ...prevState,
            [cat]: filtered
        }))
    }
    /**
     * 
     * @param { reference of element to be scrolled to } ref 
     */
    scrollToRef(ref) {
        ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
    /**
     * 
     * @param { keeps track of scroll top } event 
     */
    handleScroll(event) {
        const scrollTopNew = event.target.scrollTop
        try {
            this.setScrollTop(scrollTopNew)
        } catch {

        }
    }
}

const isObject = (value) => {
    return !!(value && typeof value === "object" && !Array.isArray(value));
};
/**
 * 
 * @param {For setting values by key and mapping either every seen to client or coach} object 
 * @param {*} keyToMatch 
 * @param {*} valueToSet 
 * @returns 
 */
export const setValuesByKey = (object = {}, keyToMatch = "", valueToSet = "") => {
    const output = []

    for (const k in object) {
        if (k === keyToMatch) {
            output.push(Object.assign({}, object))
            object[k] = valueToSet
        }
        else if (typeof object[k] === 'object')
            output.push(...setValuesByKey(object[k], keyToMatch, valueToSet))
    }

    return output;
}

export function formatDate(date) {

    if (date && date != '-') {
        let day = new Date(date)
        if (date.seconds) {
            day = new Timestamp(date.seconds, date.nanoseconds).toDate()
        }
        const yyyy = day.getFullYear();
        let mm = day.getMonth() + 1; // Months start at 0!
        let dd = day.getDate();

        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;
        return dd + '-' + mm + '-' + yyyy;
    }
}
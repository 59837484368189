import React, { useEffect, useState, createRef, useRef } from 'react'
import CoachItem from '../../utils/CoachItem'
import { useClientContext } from '../../../providers/ClientProvider/ClientProvider'
import sessionNotesState from './sessionNotesState'
import { actionInput, noteInput, sortTypes, getIcon } from './sessionNotesUtils'
import './sessionNotes.scss'
import BItem from '../../utils/BItem'
import CItem from '../../utils/CItem'
import actionTypes, { statusColors } from '../../utils/actionTypes'
import ClientService, { formatDate } from '../ClientService'
import { CancelButton, EditButton, SaveButton } from '../../utils/buttons/buttons'
import { Group, Select, Paper, Title, Button, Divider, Table, TextInput, Text, Tooltip, Textarea, Stack, Box } from '@mantine/core'
import { NormalButton, DeleteButton, SafeDateInput, PageMenu } from 'components/utils/MantineUtils'
import { DateInput } from '@mantine/dates'
import dayjs from 'dayjs'
import { IconArrowBackUp, IconDeviceFloppy, IconEdit, IconList, IconPlus, IconTrash } from '@tabler/icons-react'
import ClientItem from 'components/utils/ClientItem'
import { useMediaQuery } from '@mantine/hooks'
export default function SessionNotesComponent(props) {
    const matching = useMediaQuery('(max-width: 62em)')
    const cContext = useClientContext()
    let sN = cContext.clientData.sessionNotes

    const topics = (cContext.clientData.initialProposal && cContext.clientData.initialProposal['T'].length > 0) ? cContext.clientData.initialProposal['T'].map((o, i) => {
        return {
            key: i,
            text: o.name,
            value: o.name
        }
    }
    ) : []

    const [editing, setEditing] = useState(false)
    const [editedSN, setEditedSN] = useState(sN === undefined ? {} : JSON.parse(JSON.stringify(sN)))
    const [sort, setSort] = useState(sortTypes.none)
    const [search, setSearch] = useState("")
    const [sortedData, setSortedData] = useState([])
    const endNotesRef = useRef(null)



    useEffect(() => {
        if (sN === undefined) {
            setEditedSN(sessionNotesState)
            save()
        }
    }, [sN])

    useEffect(() => {
        if (editedSN['N'] !== undefined) {

            setSortedData(editedSN['N'])
        }
    }, [editedSN])



    function dateSort(notes) {
        if (sort === sortTypes.none || editing) {
            return notes
        }
        const sorted = notes.sort(function (a, b) {
            if (sort === sortTypes.down) {
                return new Date(a.date) - new Date(b.date)
            } else {
                return new Date(b.date) - new Date(a.date)
            }

        })

        return [...sorted]
    }

    function customFilter(note) {
        const searchU = search.toUpperCase()
        const topic = note.topic.toUpperCase()
        const notes = note.notes.toUpperCase()
        const session = note.session.toUpperCase()
        if (topic.includes(searchU) || notes.includes(searchU) || session.includes(searchU) || note.date.toString().includes(searchU)) {
            return true
        }
        return false
    }


    function changeSort() {
        const amount = Object.keys(sortTypes).length;
        setSort((prevSort) => {
            if ((prevSort + 1) === amount) {
                return 0
            }
            return prevSort + 1
        })
    }

    function cancel() {
        setEditedSN(sN)
        setEditing(false)
    }
    function save() {
        setEditing(false)
        cContext.saveClientSessionNotes(editedSN)
    }
    function deleteInput(cat, id) {
        let old = Object.assign([], editedSN[cat])
        const filtered = old.filter((item) => item.id !== id)
        setEditedSN((prevState) => ({
            ...prevState,
            [cat]: filtered
        }))
    }
    function generateId(cat) {
        if (editedSN[cat] && editedSN[cat].length > 0) {
            return Math.max(...editedSN[cat].map(o => o?.id)) + 1
        }
        return 0
    }

    function addInput(cat) {
        let template = noteInput
        const now = new Date()
        template.date = now
        const newInput = Object.assign({}, template)
        newInput.id = generateId(cat)
        const cur = Object.assign([], editedSN[cat])
        cur.push(newInput)
        setEditedSN((prevState) => ({
            ...prevState,
            [cat]: cur
        }))
        endNotesRef.current.scrollIntoView({ behavior: 'smooth' })
    }
    function findIndexById(cat, id) {
        return editedSN[cat].findIndex(ic => ic.id === id
        )
    }
    function handleChange(data, cat, id, key) {
        let old = Object.assign([], editedSN[cat])
        old[findIndexById(cat, id)][key] = data
        setEditedSN((prevState) => ({
            ...prevState, [cat]: old
        }))
    }

    function toggleEditing() {
        setEditing(!editing)
    }

    return (
        <>
            <Paper
                p={"md"} shadow="lg" withBorder
                sx={
                    {
                        position: 'sticky',
                        top: 69,
                        padding: '20px',
                        zIndex: 2,
                        transition: 'all 0.3s ease',
                        // borderBottom: '1px solid #ddd',

                    }
                }

            >
                <PageMenu sectionType={'Coach'} name={'session notes'} editing={editing} toggleEditing={toggleEditing} save={save} cancel={cancel} sortType={'date'} setData={setSortedData} data={(editedSN && editedSN.N && editedSN.N.length > 0) ? [...editedSN['N']] : []} unsorted={(editedSN && editedSN.N && editedSN.N.length > 0) ? [...editedSN['N']] : []} search={true} sort searchFunction={setSearch} >

                    {editing &&
                        <CoachItem>
                            <Button
                                variant="default"
                                leftIcon={<IconPlus stroke={"3"} size={20} />}
                                onClick={() => {
                                    addInput("N");
                                }}
                            >
                                <Title order={6}>Session Note</Title>
                            </Button>
                        </CoachItem>}
                </PageMenu>
            </Paper>
            <Divider my={"lg"} />
            {
                matching ?
                    sortedData.filter(note => customFilter(note)).map((note, i) =>
                        <>  <SessionNote matching={matching} editing={editing} deleteInput={deleteInput} handleChange={handleChange} note={note} id={note.id} key={'note' + i} editing={editing} topics={topics} />
                            <Box ref={endNotesRef} />

                        </>)
                    :
                    <Paper p={"md"} shadow="lg" withBorder>
                        <Table striped
                            highlightOnHover withBorder my={'md'}>
                            <thead>
                                <tr>
                                    <th width={"120px"}>
                                        <Title order={6} >SESSION NO.</Title>
                                    </th>
                                    <th width={"140px"}>
                                        <Title order={6} >DATE</Title>
                                    </th>
                                    <th width={'360px'}>
                                        <Title order={6} >
                                            TOPIC
                                        </Title>
                                    </th>
                                    <th>
                                        <Title order={6} >NOTES</Title>
                                    </th>
                                    <th width={'140px'}>
                                        <Title order={6} >ACTIONS</Title>
                                    </th>


                                </tr>
                            </thead>
                            <tbody>
                                {sortedData.filter(note => customFilter(note)).map((note, i) =>
                                    <SessionNote matching={matching} editing={editing} deleteInput={deleteInput} handleChange={handleChange} note={note} id={note.id} key={'note' + i} editing={editing} topics={topics} />)}
                            </tbody>
                        </Table>
                        <Box ref={endNotesRef} />
                    </Paper>
            }

        </>

    )
}
function SessionNote(props) {
    const id = props.id
    const cContext = useClientContext()

    const actionsEndRef = useRef(null)
    const note = props.note

    let actions = note.actions

    const [editedActions, setEditedActions] = useState(actions === undefined ? {} : JSON.parse(JSON.stringify(actions)))
    const [userEditing, setUserEditing] = useState(false)
    const [actionsStatus, setActionsStatus] = useState(false)

    function noteIndex() {
        const res = cContext.clientData.sessionNotes['N'].findIndex((note) => {
            return note.id === id
        })
        if (res === undefined) {
            return []
        }
        return res
    }
    function findIndexById(id) {
        const index = editedActions.findIndex((action) => {
            return action.id === id
        })
        return index
    }

    function handleChange(data, id, key) {
        let old = Object.assign([], editedActions)
        old[findIndexById(id)][key] = data
        setEditedActions(old)
    }

    useEffect(() => {
        if (actionsEndRef.current) {
            actionsEndRef.current.scrollIntoView({ behavior: 'smooth' })
        }
    }, [editedActions])

    function deleteAction(id) {
        let old = Object.assign([], editedActions)
        const filtered = old.filter((item) => item.id !== id)
        setEditedActions(filtered)
    }
    function toggleActions() {
        setEditedActions([...actions])
        setActionsStatus(!actionsStatus)
    }
    function toggleUserEditing() {
        setUserEditing(!userEditing)
    }
    function generateId() {
        if (editedActions.length > 0) {
            return Math.max(...editedActions.map(o => o?.id)) + 1
        }
        return 0
    }

    function addAction() {
        const old = [...editedActions]
        const newAction = { ...actionInput }
        newAction.id = generateId()
        newAction.date = new Date()
        old.push(newAction)
        setEditedActions(old)
    }

    function save() {
        setUserEditing(false)
        cContext.saveClientActions(editedActions, note.id)

    }
    function cancel() {
        setUserEditing(false)
        setEditedActions([...actions])
    }
    function deleteInput() {
        props.deleteInput('N', note.id)
    }

    function padTo2Digits(num) {
        return num.toString().padStart(2, '0');
    }
    function handleDate(e) {
        let formattedDate = dayjs(e).format("DD-MMM-YYYY").toString();


    }

    function getTopicsData() {
        const topics = props.topics
        const newTopics = topics.map((topic) => {
            const newTopic = { ...topic }
            newTopic.label = topic.text
            return newTopic
        })
        return newTopics
    }

    useEffect(() => {
        getTopicsData()
    }, [])


    return (
        <>

            {props.matching ?
                <Paper my={'md'} p={"md"} shadow="lg" withBorder>

                    <Group position='apart' my={'md'}>
                        <BItem editing={props.editing}>
                            <Title color={'gray'} order={6} tt={'uppercase'}>
                                Session no.: {note.session}
                            </Title>
                            <TextInput value={note.session} onChange={(e) => props.handleChange(e.target.value, 'N', note.id, 'session')} />
                        </BItem>
                        <BItem editing={props.editing}>
                            <Title color={'gray'} order={6} tt={'uppercase'}>
                                {note.date.hasOwnProperty('seconds') ? formatDate(note.date) : dayjs(note.date).isValid() ? dayjs(note.date).format("DD-MMM-YYYY").toString() : note.date}
                            </Title>

                            <SafeDateInput
                                date={note.date}
                                handleChange={props.handleChange}
                                params={['N', note.id, 'date']}
                            />
                        </BItem>
                    </Group>
                    <BItem editing={props.editing}>
                        <Title order={5} my={'sm'} tt={'uppercase'}>
                            {note.topic}
                        </Title>
                        <Select my={'md'} data={getTopicsData()} value={note.topic} onChange={(e) => props.handleChange(e, 'N', note.id, 'topic')} />
                    </BItem>
                    <BItem editing={props.editing}>
                        <Text sx={{
                            whiteSpace: 'pre-line'
                        }}>
                            {note.notes}
                        </Text>
                        <Textarea autosize my={'md'} minRows={5} value={note.notes} onChange={(e) => props.handleChange(e.target.value, 'N', note.id, 'notes')} />
                    </BItem>
                    <Group position='center' my={'md'}>
                        <Tooltip label="Actions" aria-label="Actions">
                            <Button miw={'100px'} mt={'md'} leftIcon={
                                <IconList />
                            }
                                color={editedActions.length <= 0 && 'gray.5'} onClick={editedActions.length > 0 && toggleActions} >
                                ({editedActions.length})

                            </Button>
                        </Tooltip>
                        {
                            props.editing &&
                            <DeleteButton click={deleteInput} />
                        }
                    </Group>
                    {actionsStatus &&
                        (<>
                            <Group position={'apart'}>
                                <Group>
                                    <Title order={6} my={"lg"} tt={"uppercase"}>
                                        Actions
                                    </Title>
                                    <ClientItem>
                                        {
                                            !userEditing ?
                                                <NormalButton fn={toggleUserEditing} >
                                                    <IconEdit color='orange' />
                                                </NormalButton> :
                                                <Group>
                                                    <NormalButton fn={save}>
                                                        <IconDeviceFloppy color="green" />
                                                    </NormalButton>
                                                    <NormalButton fn={cancel}>
                                                        <IconArrowBackUp color="red" />
                                                    </NormalButton>
                                                </Group>
                                        }
                                    </ClientItem>
                                </Group>
                                <ClientItem>
                                    <Button
                                        variant="default"
                                        leftIcon={<IconPlus stroke={"3"} size={20} />}
                                        onClick={addAction}
                                    >
                                        <Title order={6}>Action</Title>
                                    </Button>
                                </ClientItem>
                            </Group>
                            <Divider />
                            {
                                editedActions.map((action, index) => {
                                    return (<Action key={index} editing={userEditing} action={action} handleChange={handleChange} deleteAction={deleteAction} />)
                                })}
                        </>)}
                </Paper>
                :
                <tr valign="top" >
                    <td>
                        <BItem editing={props.editing}>
                            <Text>
                                {note.session}
                            </Text>
                            <TextInput value={note.session} onChange={(e) => props.handleChange(e.target.value, 'N', note.id, 'session')} />
                        </BItem>
                    </td>
                    <td>
                        <BItem editing={props.editing}>
                            <Text>
                                {note.date.hasOwnProperty('seconds') ? formatDate(note.date) : dayjs(note.date).isValid() ? dayjs(note.date).format("DD-MMM-YYYY").toString() : note.date}
                            </Text>

                            <SafeDateInput
                                date={note.date}
                                handleChange={props.handleChange}
                                params={['N', note.id, 'date']}
                            />
                        </BItem>

                    </td>
                    <td>
                        <BItem editing={props.editing}>
                            <Text>
                                {note.topic}
                            </Text>
                            <Select data={getTopicsData()} value={note.topic} onChange={(e) => props.handleChange(e, 'N', note.id, 'topic')} />
                            {/* <TextInput value={note.topic} onChange={(e) => props.handleChange(e.target.value, 'N', note.id, 'topic')} /> */}
                        </BItem>
                    </td>
                    <td>
                        <BItem editing={props.editing}>
                            <Text sx={{
                                whiteSpace: 'pre-line'
                            }}>
                                {note.notes}
                            </Text>
                            <Textarea autosize minRows={1} value={note.notes} onChange={(e) => props.handleChange(e.target.value, 'N', note.id, 'notes')} />
                        </BItem>
                    </td>
                    <td>
                        <Group>
                            <Tooltip label="Actions" aria-label="Actions">
                                <Button leftIcon={
                                    <IconList />
                                } color={editedActions.length <= 0 && 'gray.5'} onClick={toggleActions} >
                                    ({editedActions.length})
                                </Button>
                            </Tooltip>
                            {
                                props.editing &&
                                <DeleteButton click={deleteInput} />
                            }

                        </Group>

                    </td>

                </tr >
            }

            {
                actionsStatus && (
                    props.matching ?
                        (<>

                        </>) :
                        (
                            <tr>
                                <td colSpan={5}>
                                    <Group position={'apart'}>
                                        <Group>
                                            <Title order={6} my={"lg"} tt={"uppercase"}>
                                                Actions
                                            </Title>
                                            <ClientItem>
                                                {
                                                    !userEditing ?
                                                        <NormalButton fn={toggleUserEditing} >
                                                            <IconEdit color='orange' />
                                                        </NormalButton> :
                                                        <Group>
                                                            <NormalButton fn={save}>
                                                                <IconDeviceFloppy color="green" />
                                                            </NormalButton>
                                                            <NormalButton fn={cancel}>
                                                                <IconArrowBackUp color="red" />
                                                            </NormalButton>
                                                        </Group>
                                                }
                                            </ClientItem>
                                        </Group>
                                        <ClientItem>
                                            <Button
                                                variant="default"
                                                leftIcon={<IconPlus stroke={"3"} size={20} />}
                                                onClick={addAction}
                                            >
                                                <Title order={6}>Action</Title>
                                            </Button>
                                        </ClientItem>
                                    </Group>
                                    <Divider />
                                    <Box>
                                        {
                                            actionsStatus && editedActions.map((action, index) => {
                                                return (<Action key={index} editing={userEditing} action={action} handleChange={handleChange} deleteAction={deleteAction} />)
                                            })}
                                        <Box ref={actionsEndRef} />
                                    </Box>
                                </td>

                            </tr>))
            }
        </>
    )
}


export function Action(props) {
    const action = props.action
    const statusOptions = Object.keys(actionTypes).map((o, i) => {
        return {
            key: i,
            text: o,
            label: o,
            value: o
        }
    })
    function deleteAction() {
        props.deleteAction(action.id)
    }
    return (
        <Paper className="Action" p={'md'} my={'md'} withBorder
            sx={(theme) => ({
                position: 'relative',
            })}
        >
            <Box sx={
                (theme) => ({
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    height: '100%',
                    width: '32px',
                    backgroundColor: theme.colors[statusColors[action.status]]
                })
            }>

            </Box>
            <Group ml={'32px'} position='apart'>
                <BItem editing={props.editing}>
                    <Text sx={{
                        whiteSpace: 'pre-line'
                    }}>
                        {action.title}
                    </Text>
                    <Textarea miw={'400px'} autosize value={action.title} onChange={(e) => props.handleChange(e.target.value, action.id, 'title')} />
                </BItem>
                <Group>
                    <BItem editing={props.editing}>
                        <Text fw={'bold'}>
                            {action.status}
                        </Text>
                        <Select data={statusOptions} value={action.status} onChange={(event) => props.handleChange(event, action.id, "status")} />
                    </BItem>
                    {props.editing &&
                        <DeleteButton click={deleteAction} />
                    }
                </Group>
            </Group>
        </Paper>
    )
}

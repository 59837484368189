import { createSlice } from "@reduxjs/toolkit";

import { tokenService } from "services/tokenService";

export const initialTokensState = {
    balance: 0,
}

export const tokensSlice = createSlice({
    name: "tokens",
    initialState: initialTokensState,
    reducers: {
        topUpBalance: (state, action) => {
            state.balance = action.payload;
        },
        setBalance: (state, action) => {
            state.balance = action.payload;
        },
    },
});

export const fetchTokensAsync = (uid) => async (dispatch) => {
    try {
        const tokens = await tokenService.fetchTokens(uid, dispatch); // Assuming this returns the URL
        dispatch(setBalance(tokens));
    } catch (error) {
        console.log(error)
        dispatch(setBalance(initialTokensState));
    }
}

export const { setBalance, topUpBalance } = tokensSlice.actions;

export default tokensSlice.reducer;



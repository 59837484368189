import { createSlice } from "@reduxjs/toolkit";

import { tokenService } from "services/tokenService";

export const initialAuthUserState = {
    loaded: false,
    userDetails: null,
};

export const authUserSlice = createSlice({
    name: "authUser",
    initialState: initialAuthUserState,
    reducers: {
        resetAuthUser: (state, action) => {
            state.loaded = false;
            state = initialAuthUserState;
        },
        setUserDetails: (state, action) => {
            state.userDetails = action.payload;
            state.loaded = true;
        },
    },
});

export const { setUserDetails, resetAuthUser } = authUserSlice.actions;

export default authUserSlice.reducer;



import { doc, query, collection, where } from 'firebase/firestore'
import { firestore } from '../redux/logging/loggingSlice';
import { db } from '../firebase'

export class viewerService {
    static async seeTab(uid, role, activeTab, dispatch) {
        const ref = doc(db, 'clients', uid)
        let currentLog = {}
        const snap = await dispatch(firestore.getDoc(ref));
        // console.log(activeTab);
        // console.log(role)
        // console.log(uid);
        if (snap.exists()) {
            if (!snap.data().logs) {
                return
            }
            const data = snap.data()
            let logs = data.logs
            if (role === 'client') {
                const filteredLogs = logs.coach.filter((tab) => tab.tab === activeTab)
                if (filteredLogs.length > 0) {
                    currentLog = filteredLogs[0]

                    currentLog.seen = true
                    logs.coach = logs.coach.filter((tab) => tab.tab !== activeTab)
                    logs.coach.push(currentLog)
                } else {
                    // console.log('log not found')
                }

            } else {
                const filteredLogs = logs.client.filter((tab) => tab.tab === activeTab)
                if (filteredLogs.length > 0) {
                    currentLog = filteredLogs[0]
                    currentLog.seen = true
                    logs.client = logs.client.filter((tab) => tab.tab !== activeTab)

                    logs.client.push(currentLog)
                } else {
                    // console.log('log not found')
                }
            }
            await dispatch(firestore.updateDoc(ref, { logs: logs }))
            if (role === 'client') {
                return logs.coach
            } else {
                return logs.client
            }
        }

    }
}
import { useAlertsContext } from 'providers/Alerts/AlertsProvider'
import { useNavigate } from 'react-router-dom'
import { auth } from '../../firebase'
import React, { useState, useEffect } from 'react'
import './header.scss'
import { ActionIcon, Box, Group, Image, Text, Title, Tooltip, Menu, Button, Anchor } from '@mantine/core'
import { IconArrowsLeftRight, IconInfoCircle, IconLogout, IconMessageCircle, IconPhoto, IconPlus, IconSearch, IconSettings, IconTrash, IconUserCircle } from '@tabler/icons-react'
import { PrimaryButton } from 'components/utils/MantineUtils'
import { useMediaQuery } from '@mantine/hooks'
import { useSelector, useDispatch } from 'react-redux'
import { resetAuthUser } from '../../redux/authUser/authUserSlice'
export default function CustomHeader() {
  const logo = useSelector(state => state.branding.logo)
  const alertsProvider = useAlertsContext()
  const { role, coach, license } = useSelector(state => state.firestoreUser.userData)
  const clients = useSelector(state => state.firestoreUser.clients)
  const { loaded, userDetails } = useSelector(state => state.authUser)
  const dispatch = useDispatch()
  const nav = useNavigate()
  const primaryColor = useSelector(state => state.branding.branding.primaryColor)
  const balance = useSelector(state => state.tokens.balance)
  const matches = useMediaQuery('(min-width: 600px)');
  // eslint-disable-next-line no-undef
  const [profileOpened, setProfileOpened] = useState(false);
  const [textColor, setTextColor] = useState('#ffffff');


  useEffect(() => {
    const newColor = getMatchingTextColor(primaryColor)
    setTextColor(newColor)
  }, [primaryColor])

  async function logOut() {
    dispatch(resetAuthUser())
    auth.signOut().then(() => {
      alertsProvider.addAlert({ content: "Logged out succesfully!", timeout: 2000, variation: 'positive' })
      // window.location.reload()
    }
    ).catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      alertsProvider.addAlert({ header: errorCode, content: errorMessage, variation: 'error', timeout: 2000 })
    })
    nav('/')
  }

  function getMatchingTextColor(bgColor) {
    var color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
    var r = parseInt(color.substring(0, 2), 16); // hexToR
    var g = parseInt(color.substring(2, 4), 16); // hexToG
    var b = parseInt(color.substring(4, 6), 16); // hexToB
    return (((r * 0.299) + (g * 0.587) + (b * 0.114)) > 186) ?
      "#000000" : "#FFFFFF";
  }

  function getActiveClientsAmount() {
    // console.log(clients);
    if (clients && clients.length > 0) {
      const activeClients = clients.filter((client) => client.status.active);
      return activeClients.length;
    }
    return 0
  }
  function getInActiveClientsAmount() {
    // console.log(clients);
    if (clients && clients.length > 0) {
      const activeClients = clients.filter((client) => !client.status.active);
      return activeClients.length;
    }
    return 0
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingLeft: '1rem',
        paddingRight: '1rem',
        width: '100%',
        height: 70,
      }}>
      {(loaded && userDetails.uid) &&
        <Group spacing={'md'}>
          <Menu shadow='md' width={400} position='bottom' opened={profileOpened} onChange={setProfileOpened}
            zIndex={100}
          >
            <Menu.Target >
              {/* <PrimaryButton > */}
              <Box
                sx={
                  {
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center'

                  }
                }
              >

                <Tooltip label='Profile settings' position='bottom'>
                  <IconUserCircle color={textColor} />
                </Tooltip>
              </Box>
              {/* </PrimaryButton> */}
            </Menu.Target>
            <Menu.Dropdown
            >
              <>
                <Menu.Label>
                  <Title order={5} color='black'>Logged in as {userDetails?.email}</Title>

                </Menu.Label>
                <Menu.Label>
                  <Title order={5} color='black'>Role: {role}</Title>

                </Menu.Label>
              </>
              {(loaded && balance !== undefined && balance.toString()) && role === 'coach' &&
                <>
                  <Menu.Label>
                    <Title order={5} color='black'>
                      Balance: {balance} {(license && license.type && license.type === 'commercial') ? 'Crowns' : 'Credits'}
                    </Title>
                  </Menu.Label>
                  {
                    (loaded && balance !== undefined && balance.toString()) && role === 'coach' && (!license || !license.type || license.type !== 'commercial') && <Menu.Item icon={<IconPlus size={14} />} onClick={() => nav('/addCredits')}>
                      Add Credits
                    </Menu.Item>
                  }

                </>
              }

              <>
                {(loaded && balance !== undefined && balance.toString()) && role === 'coach' && <Menu.Label>
                  <Title order={5} color='black'> Active Clients: {getActiveClientsAmount()}
                  </Title>
                </Menu.Label>}
              </>
              <>
                {(loaded && balance !== undefined && balance.toString()) && role === 'coach' && <Menu.Label>
                  <Title order={5} color='black'> Inactive Clients: {getInActiveClientsAmount()}
                  </Title>
                </Menu.Label>}
              </>
              <>
                {(loaded && balance !== undefined && balance.toString()) && role === 'coach' && <Menu.Label>
                  <Title order={5} color='black'> License type: {(license && license.type) ? license.type.charAt(0).toUpperCase() + license.type.slice(1) : 'Standard'}
                  </Title>
                </Menu.Label>}
              </>

              {/* <Menu.Item icon={<IconMessageCircle size={14} />}>Messages</Menu.Item>
          <Menu.Item icon={<IconPhoto size={14} />}>Gallery</Menu.Item>
          <Menu.Item
            icon={<IconSearch size={14} />}
            rightSection={<Text size="xs" color="dimmed">⌘K</Text>}
          >
            Search
          </Menu.Item>

          <Menu.Divider />

          <Menu.Label>Danger zone</Menu.Label>
          <Menu.Item icon={<IconArrowsLeftRight size={14} />}>Transfer my data</Menu.Item>
          <Menu.Item color="red" icon={<IconTrash size={14} />}>Delete my account</Menu.Item> */}
            </Menu.Dropdown>
          </Menu>
          <Box
            sx={
              {
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center'

              }
            }
          >
            <Tooltip cursor={'pointer'} label='Additional resources' position='bottom'>
              <IconInfoCircle color={textColor} onClick={() => nav('/resources')} />
            </Tooltip>
          </Box>
        </Group>

      }
      <Box
        sx={{
          position: 'fixed',
          width: '50%',
          left: '25%',
          height: 70,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Image sx={{
          cursor: 'pointer',
          '@media screen and (max-width: 600px)': {
            maxWidth: 250,
          }
        }} height={60} fit={'contain'} src={logo} alt="Progrezr Logo" onClick={() => nav('/')} />
      </Box>
      {(loaded && userDetails.uid) ? <Group p={'md'} className="userInfo">
        <PrimaryButton fn={() => logOut()}>
          <Tooltip label={'Sign out'}>
            <IconLogout color={textColor} />
          </Tooltip>
        </PrimaryButton>
      </Group> :
        <div></div>}

    </Box>


  )
}

import { createSlice } from "@reduxjs/toolkit";
import { doc } from "firebase/firestore";
import { firestoreUserService } from "services/firestoreUserService";
import { firestore } from "../logging/loggingSlice";
import { tokenService } from "services/tokenService";

export const initialLoaderState = {
    loading: true,
}

export const loaderSlice = createSlice({
    name: "loader",
    initialState: initialLoaderState,
    reducers: {
        setLoading: (state, action) => {
            state.loading = action.payload;
        }

    },
});

export const { setLoading } = loaderSlice.actions;

export default loaderSlice.reducer;
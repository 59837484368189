import { doc, getDocs, query, collection, where } from 'firebase/firestore'
import { db } from '../../firebase'
import logo from "../../assets/logo.png"
import React, { useState, useEffect, createFactory } from 'react'
import { useUserDataContext } from '../UserData/UserDataProvider'
import { useUserContext } from '../../App'
import brandingState from '../../components/coach/branding/brandingState'
import { getStorage, ref, listAll, getDownloadURL, uploadBytes, deleteObject, uploadString, getMetadata } from 'firebase/storage'
import { useAlertsContext } from '../Alerts/AlertsProvider'
import { useLoggingContext } from 'providers/LoggingProvider/LoggingProvider'
export default function BrandingProvider(props) {

    const { role, coach } = useUserDataContext()
    const { userDetails } = useUserContext()
    const [update, setUpdate] = useState(false)
    const [style, setStyle] = useState({})
    const [branding, setBranding] = useState('')
    const { getDoc, setDoc, updateDoc } = useLoggingContext()

    const [logoUrl, setLogoUrl] = useState('')
    useEffect(() => {
        if (role && userDetails) {
            getBrandingStyle()

        }
    }, [userDetails, role])

    useEffect(() => {
        if (update) {
            getBrandingStyle()
            setUpdate(false)
        }
    }, [update])

    useEffect(() => {
        getLogoUrl()

    }, [role])

    function updateStyle() {
        setUpdate(true)
    }
    async function getBrandingStyle() {
        if (coach || role === 'coach') {
            const coachId = role === 'coach' ? userDetails.uid : coach
            const ref = doc(db, 'coaches', coachId)
            const snap = await getDoc(ref);
            // if (snap.exists()) {
            //     const data = snap.data()
            //     if (data.branding) {
            //         setBrandingStyle(data.branding)
            //     }
            // } else {
            //     setDoc(doc(db, 'coaches', coach), brandingState).then(() => {
            //         getBrandingStyle()
            //     })
            // }
        }

    }
    async function logoExists(file) {
        if (coach || role === 'coach') {
            const coachId = role === 'coach' ? userDetails.uid : coach
            const storage = getStorage()
            const path = `${coachId}/logo.png`
            getDownloadURL(ref(storage, path)).then((file) => {
                return true
            }).catch()
            return false
        }
    }
    async function addLogo(file) {
        const coachId = userDetails.uid
        const storage = getStorage()
        const path = `${coachId}/logo.png`

        await uploadBytes(ref(storage, path), file)





    }
    async function resetLogo() {
        const coachId = role === 'coach' ? userDetails.uid : coach
        const storage = getStorage()
        const path = `${coachId}/logo.png`
        deleteObject(ref(storage, path)).then(() => {

        })
            .catch((error) => {
            })

    }

    async function getLogoUrl() {
        let coachId = coach
        if (role === 'coach') {
            coachId = userDetails.uid
        }
        const storage = getStorage()
        const path = `${coachId}/logo.png`
        let url = ''
        if (coachId !== '') {
            try {
                url = await getDownloadURL(ref(storage, path))
            } catch (error) {
                url = await getDownloadURL(ref(storage, 'GLOBALS/logo.png'))
            }
        } else {
            url = await getDownloadURL(ref(storage, 'GLOBALS/logo.png'))
        }
        // document.getElementById('logo').src = url

        return url
    }


    function setBrandingStyle(brandingStyle) {
        setBranding(brandingStyle)
        let s = {}
        if (brandingStyle && brandingStyle.colors?.primary) {
            s = { "--primary-color": brandingStyle.colors.primary }
        }
        if (brandingStyle && brandingStyle.colors?.primaryHover) {
            s = { ...s, "--primary-color-hover": brandingStyle.colors.primaryHover }
        }
        if (brandingStyle && brandingStyle.colors?.primaryText) {
            s = { ...s, "--primary-color-text": brandingStyle.colors.primaryText }
        }
        if (brandingStyle && brandingStyle.colors?.secondary) {
            s = { ...s, "--secondary-color": brandingStyle.colors.secondary }
        }
        if (brandingStyle && brandingStyle.colors?.secondaryHover) {
            s = { ...s, "--secondary-color-hover": brandingStyle.colors.secondaryHover }
        }
        if (brandingStyle && brandingStyle.colors?.secondaryText) {
            s = { ...s, "--secondary-color-text": brandingStyle.colors.secondaryText }
        }
        if (brandingStyle && brandingStyle.colors?.mainBackground) {
            s = { ...s, "--main-background-color": brandingStyle.colors.mainBackground }
        }
        if (brandingStyle && brandingStyle.colors?.containerBackground) {
            s = { ...s, "--container-background-color": brandingStyle.colors.containerBackground }
        }
        if (brandingStyle && brandingStyle.colors?.border) {
            s = { ...s, "--border": brandingStyle.colors.border }
        }
        if (brandingStyle && brandingStyle.colors?.tabBackground) {
            s = { ...s, "--tab-background-color": brandingStyle.colors.tabBackground }
        }
        if (brandingStyle && brandingStyle.colors?.cardBackground) {
            s = { ...s, "--card-background-color": brandingStyle.colors.cardBackground }
        }
        if (brandingStyle && brandingStyle.colors?.cardHeader) {
            s = { ...s, "--card-header-color": brandingStyle.colors.cardHeader }
        }
        if (brandingStyle && brandingStyle.colors?.cardText) {
            s = { ...s, "--card-text-color": brandingStyle.colors.cardText }
        }
        setStyle(s)
    }

    const value = { logoUrl, setBrandingStyle, updateStyle, getLogoUrl, resetLogo, addLogo, branding }

    return (
        <BrandingContext.Provider value={value}  >
            <div style={style} className="branding">
                {props.children}
            </div>
        </BrandingContext.Provider>
    )
}

export const BrandingContext = React.createContext([])

export const useBrandingContext = () => React.useContext(BrandingContext)
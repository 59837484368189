export default {
    assignment: {
        sponsored: '',
        duration: '',
        sessionFrequency: '',
        sessionDuration: '',
        totalAmountSessions: '',
        totalBudget: '',
        ratePerHour: '',
        vatPercentage: '',
        paymentTermInDays: '',
        chargeForCommutingPerSession: '',
        additionalCharges: '',
        additionalNotes: ''

    },
    invoicingInformation: {
        company: '',
        department: '',
        streetAndNr: '',
        postcode: '',
        city: '',
        country: '',
        vatNr: '',
        accountNr: '',
        PONumber: '',
        additionalNotes: '',
    },
    notes: '',
    clientContact: {
        name: '',
        StreetAndNr: '',
        postcode: '',
        city: '',
        country: '',
        email: '',
        mobile: '',
        linkedIn: '',
    },
    clientExperience: {
        company: '',
        jobTitle: '',
        function: '',
        department: '',
        managementLevel: '',
        yearsInCurrentRole: '',
        dateOfBirth: '-',
        companySite: '',
        additionalNotes: '',
    },
    sponsor: {
        company: '',
        managerName: '',
        managerEmail: '',
        hrName: '',
        streetAndNr: '',
        postcode: '',
        city: '',
        country: '',
        companySite: '',
        industry: '',
        turnover: '',
        employees: ''
    }
}

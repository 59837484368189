import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useOverviewContext } from "../../providers/OverviewProvider/OverviewProvider";
import { getLogs } from "./clientLogging";
import dayjs from 'dayjs'
import {
    Paper,
    Group,
    Button,
    Title,
    Table,
    Input,
    TextInput,
    Card,
    Stack,
    ActionIcon,
    Modal,
    Box,
    Badge,
    Tooltip,
} from "@mantine/core";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import { Avatar, Text } from "@mantine/core";
import {
    IconAdjustments,
    IconAt,
    IconBuilding,
    IconCake,
    IconLocation,
    IconPhone,
    IconReload,
    IconSettings,
    IconUserPlus,
} from "@tabler/icons-react";
import { NormalButton } from "components/utils/MantineUtils";
import { useForm } from "@mantine/form";
import { useAlertsContext } from "providers/Alerts/AlertsProvider";
import { createUserWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import { auth, db, functions } from "../../firebase";
import { v4 as uuid } from 'uuid'
import { doc, setDoc } from "firebase/firestore";
import { useUserContext } from "App";
import { httpsCallable } from "firebase/functions";
import { useDispatch, useSelector } from "react-redux";
import { toClient } from "../../.../../redux/viewer/viewerSlice";

export default function ClientSection() {
    const { clients } = useSelector(state => state.firestoreUser)
    const userDetails = useSelector(state => state.authUser.userDetails)
    const userData = useSelector(state => state.firestoreUser.userData)
    const [search, setSearch] = useState("");
    const { resetPassword, getTotalHours } = useOverviewContext();
    const matches = useMediaQuery("(max-width: 750px)");
    const [opened, { open, close }] = useDisclosure(false)
    const alertsProvider = useAlertsContext()
    const form = useForm({
        initialValues: {
            email: "",
            name: "",
            companyName: "",
        },
        validate: {
            email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
        },
    });

    async function sendWelcomeEmail(email, sender) {
        // const sender = 
        const mail = httpsCallable(functions, 'sendWelcomeEmail');
        await mail({
            email: email,
            sender: sender
        })

    }

    function onSubmit(email, name, companyName) {
        // console.log(email, name, companyName)
        const sender = userData.name
        // console.log(data)
        let originalUser = userDetails
        const password = uuid()
        if (name !== '' || companyName !== '') {
            createUserWithEmailAndPassword(auth, email, password)
                .then((userCredential) => {
                    const newUser = userCredential.user;
                    sendWelcomeEmail(email, sender).then(() => {
                        alertsProvider.addAlert({ header: 'Added a new client!', content: "The new client can set their password, by clicking on a link in their email.", timeout: 2000, variation: 'positive' })
                        const uid = newUser.uid;
                        const rolesRef = doc(db, 'users', uid)
                        setDoc(rolesRef, { email: email, role: 'client', coach: originalUser.uid, name: name, companyName: companyName }, { merge: true }).then(() => {
                            close()
                            auth.updateCurrentUser(originalUser)
                        })
                    }).catch((error) => {
                        const errorCode = error.code;
                        const errorMessage = error.message;
                        alertsProvider.addAlert({ header: errorCode, content: errorMessage, variation: 'error', timeout: 2000 })
                    })
                })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    alertsProvider.addAlert({ header: errorCode, content: errorMessage, variation: 'error', timeout: 2000 })

                })
        } else {
            const errorCode = "Missing fields"
            const errorMessage = "Not every field is filled in..."
            alertsProvider.addAlert({ header: errorCode, content: errorMessage, variation: 'error', timeout: 2000 })
        }
    }

    function customFilter(client) {
        if (
            (client.name !== undefined && client.name.includes(search)) ||
            (client.companyName !== undefined && client.companyName.includes(search)) ||
            (client.email !== undefined && client.email.includes(search))
        ) {
            return true;
        }
        return false;
    }

    return (
        <Paper p={"md"} shadow="lg" withBorder>
            <Modal opened={opened} onClose={close} title={<Title order={6} tt='uppercase'>Add Client</Title>}>
                <Box maw={300} mx="auto">
                    <form onSubmit={form.onSubmit(({ email, name, companyName }) => onSubmit(email, name, companyName))}>
                        <TextInput
                            withAsterisk
                            label="Email"
                            placeholder="your@email.com"
                            {...form.getInputProps('email')}
                        />
                        <TextInput
                            withAsterisk
                            label="Name"
                            placeholder="John Doe"
                            {...form.getInputProps('name')}
                        />
                        <TextInput
                            withAsterisk
                            label="Company Name"
                            placeholder="Progrezr"
                            {...form.getInputProps('companyName')}
                        />

                        <Group position="right" mt="md">
                            <Button type="submit">Submit</Button>
                        </Group>
                    </form>
                </Box>
            </Modal>
            <Group position={"apart"}>
                <Title order={2}>CLIENTS</Title>
                <Group>
                    <TextInput
                        placeholder="Search Clients"
                        onChange={(e) => setSearch(e.target.value)}
                    />
                    <NormalButton fn={open}>
                        <IconUserPlus />
                    </NormalButton>
                </Group>
            </Group>
            {matches ? (
                <Stack gap="md" my={"md"}>
                    {clients &&
                        clients
                            .filter((client) => customFilter(client))
                            .map((client) => {
                                return (
                                    <UserInfoCard
                                        key={client.id}
                                        client={client}
                                        resetPassword={resetPassword}
                                    />
                                );
                            })}
                </Stack>
            ) : (
                <Table
                    my={"md"}
                    verticalSpacing={"xs"}
                    striped
                    highlightOnHover
                    withBorder
                >
                    <thead>
                        <tr>
                            <th>
                                <Title order={6} >CLIENT</Title>
                            </th>
                            <th></th>
                            <th>
                                <Title order={6}>CONTACT</Title>
                            </th>
                            <th>
                                <Title order={6}>USAGE</Title>
                            </th>
                            <th>
                                <Title order={6}>STATUS</Title>
                            </th>
                            {/* <th><Title order={6}>SETTINGS</Title></th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {clients ? (
                            clients
                                .filter((client) => customFilter(client))
                                .map((client) => {
                                    return (
                                        <UserInfoRow
                                            key={client.id}
                                            client={client}
                                            resetPassword={resetPassword}
                                        />

                                    );
                                })
                        ) : (
                            <tr></tr>
                        )}
                    </tbody>
                </Table>
            )}
        </Paper>
    );
}

export function ClientModal({ client, modal, closeModal, resetPassword }) {
    const [logs, setLogs] = useState({});
    useEffect(() => {
        getLogs(client.id).then((logs) => {
            setLogs(logs);
        });
    }, []);
    return (
        <Modal onClose={() => closeModal()} open={modal}>
            <Modal.Header>
                {client.name} - {client.companyName}
            </Modal.Header>
            <Modal.Content image>
                <Modal.Description>
                    <div className="logs">
                        {logs && logs.lastLogin && (
                            <p>Last Login: {logs.lastLogin}</p>
                        )}
                        {logs && logs.lastEdited && (
                            <p>
                                Last Edited: {logs.lastEdited.tab} (
                                {logs.lastEdited.date}){" "}
                            </p>
                        )}
                    </div>
                    <br />
                    <Button
                        onClick={(e) => {
                            resetPassword();
                        }}
                    >
                        Reset Password
                    </Button>
                </Modal.Description>
            </Modal.Content>
        </Modal>
    );
}


export function UserInfoCard({ client }) {
    const avatar = client.avatar;
    const dispatch = useDispatch()
    function getLastTabEdited() {
        let lastTab = { date: dayjs(0), tab: "N/A" }
        if (client?.logs?.client && client?.logs?.client.length > 0) {
            for (const entry of client.logs.client) {
                let mostRecent = dayjs(entry.date)
                if (mostRecent.isAfter(dayjs(lastTab.date))) {
                    lastTab = entry.tab
                }
            }
        }
        return lastTab.tab
    }

    return (
        <Paper
            key={client.id}
            onClick={() => dispatch(toClient(client))}
            radius="md"
            withBorder
            p="xs"
            sx={(theme) => ({
                backgroundColor:
                    theme.colorScheme === "dark"
                        ? theme.colors.dark[8]
                        : theme.white,
            })}
        >
            <Stack >
                <div>
                    <Avatar src={avatar} size={100} radius={120} mx="auto" />
                    <Title order={6} ta="center" fz="lg" weight={500} mt="xs">
                        {client.name}
                    </Title>
                </div>
                <Stack spacing={"0.125rem"} justify={"flex-start"}>
                    <Group noWrap position="left" spacing={"xs"}>
                        <IconCake stroke={1.5} size={"1rem"} />
                        <Text ta="center" c="dimmed" fz="sm">
                            {client.dateOfBirth}
                        </Text>
                    </Group>
                    <Group noWrap position="left" spacing={"xs"}>
                        <IconBuilding stroke={1.5} size={"1rem"} />
                        <Text ta="center" c="dimmed" fz="sm">
                            {client.companyName}
                        </Text>
                    </Group>

                    <Group noWrap position="left" spacing={"xs"}>
                        <IconLocation stroke={1.5} size={"1rem"} />
                        <Text ta="center" c="dimmed" fz="sm">
                            {client.city}
                        </Text>
                    </Group>
                    <Group noWrap position="left" spacing={"xs"}>
                        <IconAt stroke={1.5} size={"1rem"} />
                        <Text ta="center" c="dimmed" fz="sm">
                            {client.email}
                        </Text>
                    </Group>
                    <Group noWrap position="left" spacing={"xs"}>
                        <IconPhone stroke={1.5} size={"1rem"} />
                        <Text ta="center" c="dimmed" fz="sm">
                            {client.telephone}
                        </Text>
                    </Group>
                    <Text ta="left" c="dimmed" fz="sm">
                        Last Login: {client.logs?.lastLogin}
                    </Text>
                    <Text ta="left" c="dimmed" fz="sm">
                        Last Edited: {getLastTabEdited()}
                    </Text>
                    {/* <ActionIcon>
                        <Title order={6}>Reset password</Title>
                       
                    </ActionIcon> */}
                </Stack>
            </Stack>

            {/* <Button variant="default" fullWidth mt="md">
                Send message
            </Button> */}
        </Paper>
    );
}

export function UserInfoRow({ client, resetPassword }) {
    const avatar = client.avatar;
    const dispatch = useDispatch()
    function getLastTabEdited() {
        let lastTab = { date: dayjs(0), tab: "N/A" }
        if (client?.logs?.client && client?.logs?.client.length > 0) {
            for (const entry of client.logs.client) {
                let mostRecent = dayjs(entry.date)
                if (mostRecent.isAfter(dayjs(lastTab.date))) {
                    lastTab = entry
                }
            }
        }

        return lastTab.tab
    }

    return (
        <tr
            style={{ cursor: "pointer" }}
            onClick={() => dispatch(toClient(client))}
            key={client.id}
        >
            <td  >
                <Group noWrap position="left" spacing={"xs"}>
                    <Avatar src={avatar} size={40} radius={120} />
                    <Title
                        order={6}
                        // maw={"100px"}
                        my={"auto"}
                        ta="center"
                        fz="lg"
                        weight={500}
                        mt="xs"
                    >
                        {client.name}
                    </Title>
                </Group>

            </td>
            <td style={{ verticalAlign: 'top' }}>
                <Group noWrap position="left" spacing={"xs"}>
                    <IconBuilding stroke={1.5} size={"1rem"} />
                    <Text ta="left" c="dimmed" fz="sm">
                        {client.companyName}
                    </Text>
                </Group>
                <Group noWrap position="left" spacing={"xs"}>
                    <IconCake stroke={1.5} size={"1rem"} />
                    <Text ta="left" c="dimmed" fz="sm">
                        {typeof (client.dateOfBirth) === 'string' ? client.dateOfBirth : dayjs(client.dateOfBirth).format('DD-MMM-YYYY')}
                    </Text>
                </Group>
                <Group noWrap position="left" spacing={"xs"}>
                    <IconLocation stroke={1.5} size={"1rem"} />
                    <Text ta="left" c="dimmed" fz="sm">
                        {client.city}
                    </Text>
                </Group>
            </td>
            <td style={{ verticalAlign: 'top' }}>
                <div >
                    <Group noWrap position="left" spacing={"xs"}>
                        <IconAt stroke={1.5} size={"1rem"} />
                        <Text ta="center" c="dimmed" fz="sm">
                            {client.email}
                        </Text>
                    </Group>
                    <Group noWrap position="left" spacing={"xs"}>
                        <IconPhone stroke={1.5} size={"1rem"} />
                        <Text ta="center" c="dimmed" fz="sm">
                            {client.telephone}
                        </Text>
                    </Group>
                </div>
            </td>
            <td style={{ verticalAlign: 'top' }}>
                <Text ta="left" c="dimmed" fz="sm">
                    Last Login: {client?.lastLogin || 'N/A'}
                </Text>
                <Text ta="left" c="dimmed" fz="sm">
                    Last Edited: {getLastTabEdited()}
                </Text>
            </td>
            <td>
                <Tooltip label={client.status.active ? `from: ${client.status.from}\ntill:${client.status.till}` : 'Go to Coaching Modules -> Client Management to activate clients!'} position="left">
                    <Badge color={client.status.active ? 'green.7' : 'red.7'}>
                        <Title order={6}>{`${client.status.active ? 'ACTIVE' : 'INACTIVE'}  ${client.status.type ? client.status.type : ""}`}</Title>
                    </Badge>
                </Tooltip>
            </td>

        </tr>
    );
}

export function UserSettings({ client, setBlocked }) {
    const [opened, { open, close }] = useDisclosure(false);

    function click(e) {
        e.stopPropagation();
        e.preventDefault();
        open();
    }
    function closeModal() {
        setBlocked(true);
        close();
    }

    return (
        <>
            <Modal opened={opened} onClose={closeModal} title="Client Settings">
                {/* Modal content */}
                asdfasdf
            </Modal>
            <ActionIcon
                color={"beige"}
                size={"xl"}
                position="right"
                variant="default"
                onClick={(e) => click(e)}
            >
                <IconAdjustments stroke={1.5} size={"1rem"} />
            </ActionIcon>
        </>
    );
}

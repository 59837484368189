import { ActionIcon, Box, Button, Group, Input, Modal, Paper, Stack, Text, Title, Tooltip, useMantineTheme } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { IconAlertTriangle, IconArrowBackUp, IconArrowDown, IconArrowsSort, IconArrowUp, IconDeviceFloppy, IconEdit, IconPlus, IconRestore, IconSortAscending, IconSortDescending, IconTrash } from "@tabler/icons-react";
import { Divider } from "@mantine/core";
import { formatDate } from "components/clients/ClientService";
import dayjs from "dayjs";
import { DateInput } from "@mantine/dates";
import CoachItem from "./CoachItem";
import { useEffect, useRef, forwardRef, useState } from "react";
import ClientItem from "./ClientItem";
import { useAlertsContext } from "providers/Alerts/AlertsProvider";
import { useSelector } from "react-redux";
import "./mantineUtils.scss";
export function PrimaryButton({ fn, children }) {
    return (
        <ActionIcon
            onClick={fn}
            variant={"transparent"}
            sx={(theme) => ({
                color: theme.white,
            })}
            size="md"
        >
            {children}
        </ActionIcon>
    );
}
export function formattedDate(date) {
    if (date.hasOwnProperty('seconds')) {
        return formatDate(date)
    } else if (dayjs(date).isValid()) {
        return dayjs(date).format("DD-MMM-YYYY").toString()
    } else {
        return date
    }
}

export function SafeDateInput({ date, handleChange, params }) {


    function handleDate(date) {
        if (params) {

            handleChange(formattedDate(date), ...params)
        }
        else {
            handleChange(formattedDate(date))
        }
    }
    return (
        <DateInput
            placeholder={formattedDate(date)}
            maw={"140px"}
            valueFormat={"DD-MMM-YYYY"}
            onChange={(e) => handleDate(e)}
        />
    )
}

export const NormalButton = forwardRef(({ fn, children, color }, ref) => {
    const primaryColor = useSelector(state => state.branding.branding.primaryColor)
    if (!color) color = "black";

    return (
        <ActionIcon
            ref={ref}
            onClick={fn}
            variant={"transparent"}
            size="md"
            sx={(theme) => ({
                color: color,
                "&:hover": {
                    color: primaryColor,
                    // backgroundColor: theme.colors.gray[3],
                },
            })}
            size="md"
        >
            {children}
        </ActionIcon>
    );
})
// export function NormalButton({ fn, children, color }) {
//     if (!color) color = "black";

//     return (
//         <ActionIcon
//             onClick={fn}
//             variant={"transparent"}
//             size="md"
//             sx={(theme) => ({
//                 color: color,
//                 "&:hover": {
//                     color: theme.primaryColor,
//                     // backgroundColor: theme.colors.gray[3],
//                 },
//             })}
//             size="md"
//         >
//             {children}
//         </ActionIcon>
//     );
// }
export function PrimaryTextUpperCase({ children }) {
    return (
        <Text
            span
            color={"white"}
            sx={(theme) => ({
                textTransform: "uppercase",
            })}
        >
            {children}
        </Text>
    );
}
export function DeleteButton(props) {
    const [opened, { toggle }] = useDisclosure(false);
    const theme = useMantineTheme()

    function confirm() {
        toggle();
        props.click();
    }
    return (
        <>
            <Modal
                opened={opened}
                onClose={toggle}
                centered
                title={
                    <Title
                        order={5}
                        color={"gray.9"}
                        tt={"uppercase"}
                        py={0}
                        my={"xs"}
                    >
                        Are you sure?
                    </Title>
                }
            >
                <Divider />
                <Stack>
                    <Text mt={'md'}>
                        {props?.message}
                    </Text>
                    <Button onClick={() => confirm()}>Confirm</Button>
                </Stack>
            </Modal>
            <PrimaryButton fn={toggle}>
                <IconTrash color="red" />
            </PrimaryButton>
        </>
    );
}

export function generateId(editedData, cat) {
    if (editedData[cat] && editedData[cat].length > 0) {
        return Math.max(...editedData[cat].map(o => o?.id)) + 1
    }
    return 0
}

export const sectionTypes = {
    "All": "All",
    "Client": "Client",
    "Coach": "Coach",
}
export function EditingSection({ editing, toggleEditing, save, cancel }) {
    const { addAlert } = useAlertsContext()

    function saveWithNotification() {
        addAlert({
            header: "Success!",
            content: "Saved",
            variation: "positive",
            timeout: 5000,
        })

        save()
    }

    return (
        <Group>
            {!editing ? (
                <Tooltip label="Edit" position="left">
                    <NormalButton fn={toggleEditing}>
                        <IconEdit color="orange"

                        />
                    </NormalButton>
                </Tooltip>
            ) : (
                <Group>
                    <Tooltip label="Save" position="left">
                        <NormalButton fn={saveWithNotification}>
                            <IconDeviceFloppy color="green" />
                        </NormalButton>
                    </Tooltip>
                    <Tooltip label="Cancel" position="left">
                        <NormalButton fn={cancel}>
                            <IconArrowBackUp color="red" />
                        </NormalButton>
                    </Tooltip>
                </Group>
            )}
        </Group>
    )
}

export function ImageUpload({ name, resetImage, addImage, image }) {
    const hiddenFileInput = useRef(null)
    const primaryColor = useSelector(state => state.branding.branding.primaryColor)
    async function onFileChange(event) {
        const files = event.target.files
        if (files[0]) {
            addImage(files[0])
        }
    }

    function clickHidden() {
        hiddenFileInput.current.click()
    }

    return (<Box py={10} sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    }}>
        <Group position="apart" sx={{
            width: '100%'
        }}>
            <input type="file" ref={hiddenFileInput} style={{ display: 'none' }} onChange={(e) => onFileChange(e)} />
            <Tooltip label="Add branding Logo" position="left">
                <NormalButton fn={clickHidden}>
                    <IconPlus color="green"

                    />
                </NormalButton>
            </Tooltip>
            <Tooltip label="Reset branding Logo" position="left">
                <NormalButton fn={resetImage}>
                    <IconRestore color="red"

                    />
                </NormalButton>
            </Tooltip>
        </Group>
        <Box className="imageUploadNew-container" sx={(theme) => ({
            backgroundColor: primaryColor,
        })}>
            <img src={image} alt={name} />
        </Box>
    </Box>)


}


export function PageMenu({ sectionType, name, editing, toggleEditing, save, cancel, children, sort, sortType, setData, data, unsorted, search, searchFunction }) {
    let editSection = EditingSection({ editing, toggleEditing, save, cancel })
    const userData = useSelector(state => state.viewer.currentClient)
    if (userData?.status?.active === false) {
        sectionType = 'None';
    }
    function modifySectionType() {
        switch (sectionType) {
            case "All":
                return editSection
            case "Client":
                return <ClientItem>{editSection}</ClientItem>
            case "Coach":
                return <CoachItem>{editSection}</CoachItem>
            case "None":
                return <></>
            default:
                return <CoachItem>{editSection}</CoachItem>

        }
    }
    return (

        <Group position="apart">


            <Group position="left">
                <Title py={"md"} order={3} tt={"uppercase"}>
                    {name}
                </Title>
                {modifySectionType()}
                {sort && <SortButton unsorted={unsorted} sortType={sortType} setData={setData} data={data} />}
                {search && <SearchBar search={searchFunction} />}
            </Group>
            <Group>
                {children || null}
            </Group>
        </Group>
    )
}


export const dateSortTypes =
{
    "asc": {
        label: "Ascending",
        icon: <IconSortAscending />
    },
    "desc": {
        label: "Descending",
        icon: <IconSortDescending />
    },
    "none": {
        label: "None",
        icon: <IconArrowsSort />
    }
}

export const sortTypes = {
    "date": "date",
    "name": "name",
    "none": "none"
}

export function dateSort(data) {

    if (data.length > 0) {
        return data.sort((a, b) => {
            return new Date(formattedDate(b.date)) - new Date(formattedDate(a.date))
        })
    }
}
export function SortButton({ setData, sortType, data, unsorted }) {


    const [sort, setSort] = useState("none")
    const [label, setLabel] = useState("sort")



    function getIcon() {
        switch (sortType) {
            case "date":
                return dateSortTypes[sort].icon
            default:
                return <></>
        }


    }

    function handleSort() {
        switch (sortType) {
            case "date":
                let sorted = data
                switch (sort) {
                    case 'asc':
                        setSort('desc')
                        sorted = dateSort(data).reverse()

                        setLabel(dateSortTypes['desc'].label)
                        break;
                    case 'desc':
                        setSort('none')
                        sorted = [...unsorted]
                        setLabel(dateSortTypes['none'].label)
                        break;
                    case 'none':
                        setSort('asc')
                        sorted = dateSort(data)
                        setLabel(dateSortTypes['asc'].label)
                        break;
                    default:
                        break;

                }

                setData([...sorted])
                break;
            default:
                setData(data)
                break;
        }

    }
    return (
        <Tooltip label={label} position="left">
            <NormalButton fn={handleSort}>
                {getIcon()}
            </NormalButton>
        </Tooltip>

    )
}
function SearchBar({ search }) {
    const [value, setValue] = useState("")
    function handleSearch(e) {
        setValue(e.target.value)
        search(e.target.value)
    }
    return (
        <Input
            placeholder="Search"
            value={value}
            onChange={handleSearch}
        />
    )
}
export function WarningIcon({ warningText, warning }) {
    if (warning) {
        return <Tooltip label={warningText} position="right">
            <IconAlertTriangle color="orange" size={14} />
        </Tooltip>
    }
    return <></>
}

export function ErrorIcon({ errorText, error }) {
    const theme = useMantineTheme()

    if (error) {
        return <Tooltip label={errorText} position="right">
            <IconAlertTriangle color={theme.colors.red[6]} size={14} />
        </Tooltip>
    }
    return <></>
}
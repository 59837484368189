// Import the functions you need from the SDKs you need
import { initializeApp, firestore } from "firebase/app";
// require('dotenv').config()
import { getFirestore, connectFirestoreEmulator } from "@firebase/firestore";
import { getAuth } from "firebase/auth"
import { getStorage } from 'firebase/storage'
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
let firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: "progrezr.firebaseapp.com",
    projectId: "progrezr",
    storageBucket: "progrezr.appspot.com",
    messagingSenderId: process.env.REACT_APP_MS_ID,
    appId: process.env.REACT_APP_APP_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
};
const firebaseConfigdev = {
    apiKey: "AIzaSyAjONEArhFpz_Bw5pG89HKKfkQoQMyoIuM",
    authDomain: "progrezr-dev.firebaseapp.com",
    projectId: "progrezr-dev",
    storageBucket: "progrezr-dev.appspot.com",
    messagingSenderId: "958666912870",
    appId: "1:958666912870:web:8d92015124a5b3287f69a7",
    measurementId: "G-DLV0NTVTGN"
};
if (process.env.REACT_APP_DEVELOPMENT === 'true') {
    firebaseConfig = firebaseConfigdev
}

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app)
export const functions = getFunctions(app);
// connectFunctionsEmulator(functions, '127.0.0.1', 5001);
// connectFirestoreEmulator(db, 'localhost', 8181);
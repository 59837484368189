import { createSlice } from "@reduxjs/toolkit";
import defaultLogo from '../../assets/logowhite.png'
import { brandingService } from "services/brandingService";
import { stripeService } from "services/stripeService";

export const initialStripeState = {
    products: [],
    payments: [],
}

export const stripeSlice = createSlice({
    name: "stripe",
    initialState: {
        logo: defaultLogo,
        branding: initialStripeState,
    },
    reducers: {
        setProducts: (state, action) => {
            state.products = action.payload;
        },
        resetStripe: (state, action) => {
            state.products = initialStripeState;
        },
        setPayments: (state, action) => {
            state.payments = action.payload;
        }
    },
});

export const fetchProducts = () => async (dispatch) => {
    try {
        // console.log('fetching products in slice...')
        const products = await stripeService.fetchProducts(dispatch); // Assuming this returns the URL
        dispatch(setProducts(products));
    } catch (error) {
        throw error;
    }
};
export const fetchPayments = (uid) => async (dispatch) => {
    try {
        // console.log('fetching payments in slice...')
        const payments = await stripeService.fetchPayments(uid, dispatch); // Assuming this returns the URL
        dispatch(setPayments(payments));
    } catch (error) {
        throw error;
    }
};

export const fetchStripe = (uid) => async (dispatch) => {
    try {
        // console.log('fetching stripe in slice...')
        await dispatch(fetchProducts())
        await dispatch(fetchPayments(uid))
    } catch (error) {
        throw error;
    }
};


export const { setProducts, resetStripe, setPayments } = stripeSlice.actions;

export default stripeSlice.reducer;



import React from 'react'
import { db } from '../../firebase'
import { doc, getDoc as FGetDoc, where, updateDoc as FUpdateDoc, collection, getDocs, query, setDoc as FSetDoc } from 'firebase/firestore'
import { ColorInput } from '@mantine/core'

export const LoggingContext = React.createContext([])
export const useLoggingContext = () => React.useContext(LoggingContext)

export default function LoggingProvider(props) {
    const [count, setCount] = React.useState({
        0: 0,
        1: 0
    })

    function log(KEY, VALUES) {
        if (process.env.REACT_APP_DEVELOPMENT === 'true') {
            incrementCount(KEY, VALUES)
        }
    }


    function incrementCount(KEY, VALUES) {
        let newCount = 0
        if (count.hasOwnProperty(KEY)) {
            setCount((prevState) => {
                newCount = prevState[KEY] + 1
                // console.log(`FLAG[${KEY}]: ${VALUES.toString()} TOTAL: [${newCount}]`)
                return { ...prevState, [KEY]: newCount }
            })
        }
    }
    async function getDoc(ref) {
        log(0, ref._key.path.segments)
        return await FGetDoc(ref)
    }

    async function setDoc(ref, data) {
        log(1, [...ref._key.path.segments, data])
        return await FSetDoc(ref, data)
    }

    async function updateDoc(ref, data) {
        log(1, [...ref._key.path.segments, data])
        return await FUpdateDoc(ref, data)
    }

    const value = {
        setDoc,
        getDoc,
        updateDoc
    }

    return (
        <LoggingContext.Provider value={value}>
            {props.children}
        </LoggingContext.Provider>
    )
}

import React, { useState, useContext, useEffect } from 'react'
import { useUserContext } from '../../App';
import { db } from '../../firebase'
import { doc, where, collection, getDocs, query, } from 'firebase/firestore'
import { Container, Header, Button, Divider, Table, Input, Menu, Segment, Icon, Modal, Checkbox } from 'semantic-ui-react'
import BrandingProvider from '../BrandingProvider/BrandingProvider';
import GDPR from 'components/gdpr/gdpr';
import { useLoggingContext } from 'providers/LoggingProvider/LoggingProvider';
import { firestore } from '../../redux/logging/loggingSlice'
import { useDispatch, useSelector } from 'react-redux'

export function UserDataProvider(props) {
    const dispatch = useDispatch()
    const userDetails = useSelector(state => state.authUser.userDetails)
    const [userData, setUserData] = useState({
        role: 'client',
        name: '',
        coach: '',
        gdpr: true
    })

    const [clients, setClients] = useState([])

    useEffect(() => {
        // console.log(LC)
        updateUserData()
    }, [userDetails])

    useEffect(() => {
        getClients()
    }, [userData])

    function updateUserData() {
        getUserData().then((data) => {
            if (data) {
                setUserData(data)
            }
        })
    }

    async function getUserData() {
        if (userDetails) {
            const docRef = doc(db, 'users', userDetails.uid)
            const docSnap = await firestore.getDoc(docRef)(dispatch)
            if (docSnap.exists()) {
                let data = docSnap.data()
                const gdpr = data['gdpr']
                if (gdpr === undefined) {
                    await firestore.updateDoc(docRef,
                        { gdpr: false }
                    )(dispatch)
                    return await getUserData()
                }
                const status = data['status']

                if (data['role'] === "coach") {
                    data['coach'] = userDetails.uid
                }
                return data
            }
        }
    }

    async function acceptGDPR() {
        if (userDetails) {
            const docRef = doc(db, 'users', userDetails.uid)

            await firestore.updateDoc(docRef,
                { 'gdpr': true }
            )(dispatch)
            updateUserData()


        }
    }

    async function getClients() {
        if (userDetails) {
            let q = query(collection(db, 'users'), where('role', '==', 'client'), where('coach', '==', userDetails.uid))
            const qClient = query(collection(db, 'users'), where('role', '==', 'client'), where('__name__', '==', userDetails.uid))
            if (userData['role'] === 'client') {
                q = qClient
            }
            const querySnapshot = await getDocs(q)
            setClients([])
            querySnapshot.forEach((doc) => {
                const data = doc.data()
                data.id = doc.id

                // console.log(data)
                getAdditionalInfo(data.id).then((info) => {
                    Object.assign(data, info)
                    setClients(clients => (
                        [...clients, data]
                    ))
                })
                // doc.data() is never undefined for query doc snapshots
            });
        }
    }


    // async function seenLastEdited(tabName, id) {
    //     const ref = doc(db, 'clients', id)
    //     let result = {}
    //     result = clientData.lastEdited
    //     if (role === 'coach') {
    //         if (clientData.lastEdited && clientData.lastEdited.client) {
    //             result.client.tab = ''
    //         }
    //     }
    //     if (role === 'client') {
    //         if (clientData.lastEdited && clientData.lastEdited.coach) {
    //             result.coach.tab = ''
    //         }
    //     }
    //     updateDoc(ref, { "lastEdited": result }).then(() => {
    //         setClientData((prevstate) => ({ ...prevstate, result }))
    //     })
    // }


    async function getAdditionalInfo(id) {
        const ref = doc(db, 'clients', id)
        const snap = await firestore.getDoc(ref)(dispatch);
        if (snap.exists()) {
            if (snap.data().bio) {
                return {
                    city: snap.data().bio.clientContact.city,
                    telephone: snap.data().bio.clientContact.telephone,
                    dateOfBirth: snap.data().bio.clientExperience.dateOfBirth,
                    logs: snap.data().logs,
                    lastLogin: snap.data().lastLogin
                }
            }
        }
    }
    // async function getClientData() {
    //     if(role ==='client' && state === 'overview'){

    //         return} 
    //     const ref = doc(db, 'clients', currentClient.id)
    //     const snap = await getDoc(ref);
    //     if (snap.exists()) {
    //         setLoading(false)
    //         const data = snap.data()
    //         setClientData(data)
    //     } else {
    //         setDoc(doc(db, 'clients', getCurrentClient().id), clientsInput).then(() => {
    //             setClientData(getClientData)
    //         })
    //     }
    // }

    const value = { role: userData.role, name: userData.name, clients: clients, coach: userData.coach, gdpr: userData.gdpr, acceptGDPR }

    return (
        <UserDataContext.Provider value={value}>

            <BrandingProvider >
                {props.children}
            </BrandingProvider>

        </UserDataContext.Provider>
    )
}


export const UserDataContext = React.createContext([])

export const useUserDataContext = () => React.useContext(UserDataContext)
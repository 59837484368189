import { Badge, Button, Divider, Group, Modal, Paper, SegmentedControl, Select, SimpleGrid, Stack, Switch, Text, Title, Tooltip } from '@mantine/core'
import { PageMenu } from 'components/utils/MantineUtils'
import React, { useState } from 'react'
import { httpsCallable } from '@firebase/functions';
import { functions } from '../../../firebase';
import { AlertsProvider, useAlertsContext } from 'providers/Alerts/AlertsProvider'
import { useDisclosure } from '@mantine/hooks'
import { Segment } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import * as dayjs from 'dayjs'
import { fetchTokensAsync } from '../../../redux/tokens/tokenSlice'
import { loadUserData } from '../../../redux/firestoreUser/firestoreUserSlice';
import { setLoading } from '../../../redux/loader/loaderSlice';

export default function ClientSettingsComponent() {
    const clients = useSelector(state => state.firestoreUser.clients)
    const [currentClient, setCurrentClient] = useState()
    const authUser = useSelector(state => state.authUser)
    const balance = useSelector(state => state.tokens.balance)

    const AlertsProvider = useAlertsContext()
    const [opened, { open, close }] = useDisclosure(false)
    const dispatch = useDispatch()
    const initialModalState = {
        type: 'ongoing',
        period: '1 month'
    }
    const [modalState, setModalState] = useState(initialModalState)

    function selectClient(data) {
        const client = clients.filter((client) => {
            return client.id === data
        })[0]
        // const client = CoachContext.clients[0]
        setCurrentClient(client)
    }

    function sendEmail() {
        const mail = httpsCallable(functions, 'sendPasswordResetMail');
        mail({ email: currentClient.email })
            .then((result) => {
                AlertsProvider.addAlert({ content: 'Password reset email sent succesfully!', timeout: 2000, variation: 'positive' })
            }).catch((error) => {
                AlertsProvider.addAlert({ header: error.code, content: error.message, timeout: 2000, variation: 'error' })
            })
        AlertsProvider.addAlert({ content: 'Password reset email sent succesfully!', timeout: 2000, variation: 'positive' })

    }
    function openModal() {
        open()
        setModalState(initialModalState)
    }

    function deactivate() {
        dispatch(setLoading(true));

        const deactivate = httpsCallable(functions, 'deactivateClient');
        deactivate({ coachId: authUser.userDetails.uid, clientId: currentClient.id }).then((result) => {
            dispatch(fetchTokensAsync(authUser.userDetails.uid)).then(() => {
                dispatch(loadUserData(authUser.userDetails.uid)).then(() => {
                    AlertsProvider.addAlert({ content: 'Client deactivated succesfully!', timeout: 2000, variation: 'positive' })
                })
            })

        }).catch((error) => {
            AlertsProvider.addAlert({ header: error.code, content: error.message, timeout: 2000, variation: 'error' })
        })
        close()
    }

    function calculateActivationPrice(period, type) {
        if (type === 'fixed') {
            if (period === '1 month') {
                return 1;
            }
            if (period === '6 months') {
                return 5;
            }
        }
        if (type === 'ongoing') {
            if (period === '1 month') {
                return 1;
            }
        }

    }

    function activate() {
        const activate = httpsCallable(functions, 'activateClient');
        dispatch(setLoading(true));
        activate({ coachId: authUser.userDetails.uid, clientId: currentClient.id, type: modalState.type, period: modalState.period })
            .then((result) => {
                dispatch(fetchTokensAsync(authUser.userDetails.uid)).then(() => {
                    dispatch(loadUserData(authUser.userDetails.uid)).then(() => {
                        AlertsProvider.addAlert({ content: 'Client activated succesfully!', timeout: 2000, variation: 'positive' })
                    })
                })

            }).catch((error) => {
                AlertsProvider.addAlert({ header: error.code, content: error.message, timeout: 2000, variation: 'error' })
            })
        close()
    }


    return (
        <>
            <Paper
                p={'md'}
                shadow={'lg'}
                withBorder
                sx={{
                    position: 'sticky',
                    top: 69,
                    padding: '20px',
                    zIndex: 2,
                    transition: 'all 0.3s ease',
                }}
            >
                <PageMenu sectionType={'None'} name={'Client Settings'} />
            </Paper>
            <Divider my={'lg'} />
            <Paper
                p={'md'}
                shadow={'lg'}
                withBorder

            >
                <Title order={5} mb='lg'>Select Client</Title>

                <Select miw={'50%'}
                    placeholder='Select Client'

                    data={clients.map((client) => {
                        return {
                            key: client.id,
                            label: `${client.name} (${client.email})`,
                            value: client.id,
                            text: client.id
                        }
                    })}
                    value={currentClient?.id ? currentClient.id : '-'}

                    onChange={(event) => selectClient(event)}
                />
            </Paper>
            {
                currentClient &&
                <>
                    <Modal opened={opened} onClose={close} title={(currentClient.status.active ? 'Deactivate client' : 'Activate client') + " - " + currentClient.name} size={'100%'} centered >
                        {currentClient.status.active ?
                            <Button onClick={deactivate}>Deactivate</Button> :


                            <Stack spacing={'md'} >
                                <div>
                                    <SegmentedControl
                                        data={['fixed', 'ongoing']}
                                        value={modalState.type}
                                        onChange={(value) => setModalState({ period: '1 month', type: value })}
                                    />
                                </div>
                                <Text><b>Ongoing Status:</b> Choose ongoing when the coaching program has an indefinite duration and lacks a fixed end date. Clients remain active until manually set to inactive or until reaching a 12-month limit. Each month, the corresponding credits are automatically deducted from your wallet, ensuring uninterrupted service delivery. Ideal for coaching relationships with evolving needs or those without predefined timelines.
                                    <br />
                                    <br />
                                    <b>Fixed Status:</b> Opt for fixed status when preferring to manually extend the client's active status for predetermined durations. Choose between 1 month fixed or 6 months fixed based on client preferences and engagement requirements. For 6 months fixed, all corresponding credits are deducted immediately. However, you receive 1 month for free, optimizing credit utilization. Offers greater control over client status extensions, allowing coaches to align with client milestones and objectives effectively.
                                </Text>
                                {modalState.type === 'fixed' &&
                                    <div>
                                        <SegmentedControl
                                            data={[
                                                { label: '1 month', value: '1 month' },
                                                { label: '6 months', value: '6 months' },
                                            ]}
                                            value={modalState.period}
                                            onChange={(value) => setModalState({ ...modalState, period: value })}
                                        />
                                    </div>
                                }
                                <div>
                                    <Button disabled={calculateActivationPrice(modalState.period, modalState.type) > balance} onClick={activate}>Activate</Button>
                                </div>
                            </Stack>
                        }
                    </Modal>
                    <Divider my={'lg'} />
                    <Paper p={'md'}
                        shadow={'lg'}
                        withBorder >
                        <Title order={5} my='lg'>{currentClient.name} </Title>
                        <SimpleGrid cols={2} spacing={10}>
                            <Stack>
                                <Title order={6} my='lg'>Status</Title>
                                <Group>
                                    <Badge color={currentClient.status.active ? 'green.7' : 'red.7'}>
                                        <Title order={6}>{currentClient.status.active ? 'ACTIVE' : 'INACTIVE'}</Title>
                                    </Badge>
                                    {currentClient.status.active &&
                                        <Badge color={'green.7'}>
                                            <Title order={6}>{currentClient.status.type}</Title>
                                        </Badge>
                                    }
                                    {currentClient.status.active && (currentClient.status.type === 'fixed' ?
                                        <Text>{`activated on: ${currentClient.status.from}, will expire on: ${currentClient.status.till}`}</Text>
                                        :
                                        <Text>{`activated on: ${currentClient.status.from}, will renew until: ${dayjs(currentClient.status.from).add(12, 'month').format('DD-MMM-YYYY')}`}</Text>
                                    )
                                    }
                                </Group>

                                <div>
                                    {
                                        <Button onClick={open} disabled={(currentClient.status.active && currentClient.status.type !== 'ongoing')} >{currentClient.status.active ? 'Deactivate' : 'Activate'}</Button>
                                    }
                                </div>
                            </Stack>
                            <Stack>
                                <Title order={6} my='lg'>Actions</Title>
                                {/* <Button label={"Reset password"} onClick={(event) => resetPassword(event, currentClient)}>Reset Password</Button> */}
                                <Button label={"SendMail"} maw={'200px'} onClick={sendEmail}>Send password reset Email</Button>
                                {/* <Switch checked={checked} onChange={(event) => setChecked(event.currentTarget.checked)} /> */}

                            </Stack>

                        </SimpleGrid>

                    </Paper>
                </>
            }
        </>
    )
}

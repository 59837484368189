import React, { useState } from "react";
import {
    AppShell,
    Navbar,
    Header,
    Title,
    Text,
    Divider,
    UnstyledButton,
    ActionIcon,
    Group,
    Avatar,
    Center,
    ScrollArea,
    Tooltip,
    Container,
    Anchor,
    Badge,
    ColorSwatch,
} from "@mantine/core";
import Debugger from "../../components/utils/debugger/Debugger";

import "./home.scss";

import { OverviewComponent } from "../../components/overview/overview";
import ClientComponent from "../../components/clients/clients";
import ClientsMobileMenu from "../../components/clients/clientsMobileMenu/clientsMobileMenu";
import CoachComponent from "../../components/coach/coach";
import { ClientProvider } from "../../providers/ClientProvider/ClientProvider";
import { FileProvider } from "../../providers/FileProvider/FileProvider";
import CoachProvider from "../../providers/CoachProvider/CoachProvider";
import OverviewProvider from "providers/OverviewProvider/OverviewProvider";
import CustomHeader from "components/header/header";
import { useDisclosure } from "@mantine/hooks";
import {
    IconAlertCircle,
    IconArrowBack,
    IconLayoutSidebarLeftCollapse,
    IconLayoutSidebarLeftExpand,
    IconMail,
} from "@tabler/icons-react";
import { NormalButton } from "components/utils/MantineUtils";
import { useEffect } from "react";
import { useMediaQuery } from "@mantine/hooks";
import CoachItem from "components/utils/CoachItem";
import { useDispatch, useSelector } from "react-redux";
import { setActiveTab, toAdmin, toOverview, toClient, seeTab } from "../../redux/viewer/viewerSlice";
export default function Home() {
    const { role } = useSelector(state => state.firestoreUser.userData)
    const [navbarCollapsed, { toggle }] = useDisclosure(false)
    const primaryColor = useSelector(state => state.branding.branding.primaryColor)
    const dispatch = useDispatch()

    const avatar = "https://avatars.githubusercontent.com/u/56592200?v=4";
    const {
        view,
        currentClient,
        activeTab,
        unseenTabs,
        navbarItems, navbarTitle, navbarOn
    } = useSelector(state => state.viewer);
    const matches = useMediaQuery('(max-width: 768px)')

    useEffect(() => {
    }, [activeTab])



    return (
        <AppShell
            padding={"md"}
            sx={(theme) => ({
            })}
            styles={(theme) => ({
                main: { backgroundColor: theme.colors.gray[3] },
            })}
            navbar={
                navbarOn && !matches ? (
                    <Navbar
                        width={{ base: navbarCollapsed ? 60 : 300 }}
                        height={"calc(100vh - 60px)"}
                        sx={(theme) => ({
                            backgroundColor: theme.colors.gray[1],
                            transition: "width 0.3s ease-in-out",
                            borderRight: `1px solid ${theme.colors.gray[3]}`,
                        })}
                        p="xs"
                        hidden
                        zIndex={60}
                    >
                        {!navbarCollapsed && (
                            <>
                                <Navbar.Section py={'md'}>
                                    <Center>
                                        <Avatar src={avatar} size={80} radius={120} my={'xs'} />
                                    </Center>

                                    <Title
                                        order={6}
                                        // maw={"100px"}
                                        my={"auto"}
                                        ta="center"
                                        fz="lg"
                                        weight={500}
                                        mt="xs"
                                    >
                                        {navbarTitle}
                                    </Title>
                                    {currentClient && currentClient.email &&
                                        <Tooltip.Floating label={`Mail: ${currentClient?.email}`}>
                                            <Anchor
                                                sx={(theme) => ({
                                                    textDecoration: 'none',
                                                    color: 'unset',
                                                    '&:hover': {
                                                        textDecoration: 'none',
                                                        color: theme.colors.red[6],
                                                    }

                                                })}
                                                href={`mailto:${currentClient?.email}`} target="_blank" rel="noopener noreferrer">
                                                <Group position="center" spacing={2} >
                                                    <Text
                                                        sx={{
                                                            textDecoration: 'none',
                                                            color: 'black',
                                                            '&:hover': {
                                                                textDecoration: 'none',
                                                                color: 'black',
                                                            }

                                                        }}
                                                        ta="center" size="sm">
                                                        {currentClient.email}

                                                    </Text>
                                                    <IconMail
                                                        size={'1rem'} />
                                                </Group>
                                            </Anchor>
                                        </Tooltip.Floating>
                                    }
                                </Navbar.Section>
                                <Divider size={"xs"} />
                            </>
                        )}
                        <Navbar.Section grow>
                            {!navbarCollapsed ? (
                                navbarItems.length >= 0 &&
                                navbarItems.map((item) => (
                                    <NavbarItem
                                        key={item}
                                        activeTab={activeTab}
                                        name={item}
                                        unseen={unseenTabs ? unseenTabs.filter((tab) => !tab.seen).map((tab) => tab.tab).includes(item) : false}
                                        lastEdited={unseenTabs ? unseenTabs.filter((tab) => !tab.seen).map((tab) => tab.tab).includes(item) && unseenTabs.filter((tab) => tab.tab === item)[0].date : ''}
                                    />
                                ))) : (navbarItems.length >= 0 &&
                                    navbarItems.map((item) => (
                                        <NavbarItem
                                            key={item}
                                            activeTab={activeTab}
                                            name={item}
                                            shortened={true}
                                        />
                                    ))

                            )}
                        </Navbar.Section>
                        {!navbarCollapsed && <Divider size={"xs"} />}
                        <Navbar.Section py={"md"}>
                            <Group
                                position={navbarCollapsed ? "center" : "apart"}
                            >
                                {navbarCollapsed ? (
                                    <></>
                                ) : (
                                    <CoachItem>
                                        <Tooltip label={"Back to overview"}>

                                            <NormalButton fn={() => dispatch(toOverview())}>
                                                <IconArrowBack />
                                            </NormalButton>
                                        </Tooltip>
                                    </CoachItem>
                                )}
                                <Tooltip label={navbarCollapsed ? "Expand" : "Collapse"}>
                                    <NormalButton
                                        fn={() =>
                                            toggle()
                                        }
                                    >
                                        {navbarCollapsed ? (
                                            <IconLayoutSidebarLeftExpand />
                                        ) : (
                                            <IconLayoutSidebarLeftCollapse />
                                        )}
                                    </NormalButton>
                                </Tooltip>
                            </Group>
                        </Navbar.Section>
                    </Navbar>
                ) : (
                    <></>
                )
            }
            header={
                <Header
                    height={80}
                    sx={(theme) => ({
                        backgroundColor: primaryColor,
                    })}
                >
                    <CustomHeader />
                </Header>
            }
            styles={(theme) => ({
                main: {
                    transition: "width 0.6s ease-in-out",
                    backgroundColor:
                        theme.colorScheme === "dark"
                            ? theme.colors.dark[8]
                            : theme.colors.gray[0],
                },
            })}
        >
            <>
                {role === "coach" && view === "overview" ? (
                    <OverviewProvider>
                        <OverviewComponent
                        />
                    </OverviewProvider>
                ) : role === "coach" && view === "admin" ? (
                    <OverviewProvider>

                        <CoachProvider>
                            <CoachComponent
                            ></CoachComponent>
                        </CoachProvider>
                    </OverviewProvider>

                ) : (
                    <ClientProvider currentClient={currentClient}>
                        <FileProvider currentClient={currentClient}>
                            <ClientComponent
                                currentClient={currentClient}
                            ></ClientComponent>
                        </FileProvider>
                    </ClientProvider>
                )}
            </>
            <ClientsMobileMenu></ClientsMobileMenu>
            {/* <Debugger /> */}

        </AppShell>
    );
}

export function NavbarItem({ name, shortened, unseen, lastEdited }) {
    const primaryColor = useSelector(state => state.branding.branding.primaryColor)
    const role = useSelector(state => state.firestoreUser.userData.role);
    const client = useSelector(state => state.viewer.currentClient);
    const dispatch = useDispatch()
    function shortenName(name) {
        let abbreviatedName = name.slice(0, 2)

        if (name.indexOf(' ') >= 0) {
            const splitName = name.split(" ")
            abbreviatedName = splitName[0].slice(0, 1) + splitName[1].slice(0, 1)
        }
        return abbreviatedName
    }


    return (
        <UnstyledButton
            onClick={() => {
                dispatch(seeTab(client, role, name))
                dispatch(setActiveTab(name))
            }}
            my={0}
            sx={(theme) => ({
                display: "block",
                padding: theme.spacing.xs,
                borderRadius: theme.radius.md,
                "&:hover": {
                    color: primaryColor,
                },
            })}
        >
            <Group>
                {
                    shortened !== undefined ?
                        <Tooltip.Floating label={name} >
                            <Title order={5}

                                tt={"uppercase"} py={0} my={0}>
                                {shortened !== undefined ? shortenName(name) : name}
                            </Title>
                        </Tooltip.Floating>
                        : <Title order={5}

                            tt={"uppercase"} py={0} my={0}>
                            {name}
                        </Title>
                }
                {unseen && <Tooltip label={`Last edited on: ${lastEdited}`}>
                    <ActionIcon  >
                        <IconAlertCircle color={"red"} />
                    </ActionIcon>
                </Tooltip>
                }
            </Group>

            {/* <Text
                // sx={(theme) => ({
                //     fontFamily: theme.headings.fontFamily,
                //     fontWeight: theme.headings.fontWeight,
                // })}
                size="sm"
            >
                {name}
            </Text> */}
        </UnstyledButton>
    );
}

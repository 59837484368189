/**
 * 
 * 
 * @returns if editing return child 1 else returns child 2 
 */
export default function BItem(props) {
    return (
        <div className={"BItem " + (props.class ? props.class : '')}>
            {props.editing ? props.children[1] : props.children[0]}
        </div>
    )
}
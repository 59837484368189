export default {
    MD: {
        date: '',
        time: '',
        location: '',
    },
    IQ: [

    ],
    IC: [

    ],
    AS:
        [

        ]
}
import "./overview.scss";
import { useOverviewContext } from '../../providers/OverviewProvider/OverviewProvider'
import { formatMoney } from "components/clients/timesheet/timesheetUtils";
import { Paper, Text, Title, Table, Group, ActionIcon, } from "@mantine/core";
export default function InsightsSection() {
    const { coachSummary } = useOverviewContext()

    return (
        <Paper shadow="lg" p='md' withBorder>
            <Group position={'apart'}>
                <Title order={2}>INSIGHTS</Title>
            </Group>
            {/* </div> */}
            <Table my={'md'} withBorder
                striped
                highlightOnHover
            >
                <thead >
                    <tr>
                        <th><Title order={6} tt={'uppercase'} fz={'md'}>Total hours (ytd)</Title></th>
                        <th><Title order={6} tt={'uppercase'} fz={'md'}>Total Revenue (ytd)</Title></th>
                        <th><Title order={6} tt={'uppercase'} fz={'md'}>Invoiced (ytd)</Title></th>
                        <th><Title order={6} tt={'uppercase'} fz={'md'}>Paid (ytd)</Title></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{coachSummary?.totalHours}</td>
                        <td>
                            {formatMoney(coachSummary?.totalRevenue)}
                        </td>
                        <td>
                            {formatMoney(coachSummary?.totalInvoiced)}
                        </td>
                        <td>
                            {formatMoney(coachSummary?.totalPaid)}
                        </td>
                    </tr>
                </tbody>
            </Table>
        </Paper>
    );
}

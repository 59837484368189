import React from 'react'
import footer_img from '../../assets/steward_bright_footer.png'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { auth } from '../../firebase'
import { useNavigate } from "react-router-dom"
import './login.scss'
import { AppShell, Header, Box, TextInput, Button, PasswordInput, Title, BackgroundImage, SimpleGrid, Image, List, ThemeIcon, Center, Anchor, Flex } from "@mantine/core";
import { useAlertsContext } from '../../providers/Alerts/AlertsProvider';
import CustomHeader from 'components/header/header';
import { useForm } from '@mantine/form';
import { IconCircleCheck, IconLink } from '@tabler/icons-react';
import { useSelector } from 'react-redux';
export default function Login() {
    const alertsProvider = useAlertsContext()
    const primaryColor = useSelector(state => state.branding.branding.primaryColor)

    const onSubmit = async (email, password) => {
        // event.preventDefault();
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                alertsProvider.addAlert({ content: "Logged in succesfully!", timeout: 2000, variation: 'positive' })
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                alertsProvider.addAlert({ header: errorCode, content: errorMessage, variation: 'error', timeout: 2000 })
            })
    }
    const form = useForm({
        initialvalues: {
            email: '',
            password: '',
        }
    })

    function downloadGDPR() {
        fetch("privacy_policy.pdf").then((response) => {
            response.blob().then((blob) => {
                // Creating new object of PDF file
                const fileURL = window.URL.createObjectURL(blob);
                // Setting various property values
                let alink = document.createElement("a");
                alink.href = fileURL;
                alink.download = "Progrezr_Privacy_Policy.pdf";
                alink.click();
            });
        });
    }

    return (
        <AppShell
            padding={0}
            header={
                <Header
                    height={80}
                    sx={(theme) => ({
                        backgroundColor: primaryColor,
                    })}
                >
                    <CustomHeader />
                </Header>
            }
            styles={(theme) => ({
                main: {
                    backgroundColor:
                        theme.colorScheme === "dark"
                            ? theme.colors.dark[8]
                            : theme.colors.gray[0],
                },
            })}
        >
            <BackgroundImage src={require("../../assets/background-4.jpg")}  >
                <Box p={'xl'} sx={{
                    minHeight: 'calc(100vh - 130px)',
                    backgroundColor: 'rgba(0,0,0,0.25)',
                }}>
                    <SimpleGrid cols={2}
                        breakpoints={[
                            { maxWidth: '600', cols: 1 },
                        ]}
                    >


                        <Image my={'md'} maw={'70%'} src={require("../../assets/logowhite.png")} alt="Logo.png" />
                        {/* <Box my={'md'}> */}
                        <Center>
                            <form onSubmit={form.onSubmit(({ email, password }) => onSubmit(email, password))} style={{ width: '400px' }}>
                                <TextInput
                                    label={<Title order={6} color={'white'}>Email</Title>}
                                    type='email'
                                    placeholder="your@email.com"
                                    {...form.getInputProps('email')}
                                    mb={'md'}
                                />
                                <PasswordInput
                                    // withAsterisk
                                    label={<Title order={6} color={'white'}>Password</Title>}
                                    miw={'100%'}
                                    placeholder="******"
                                    mt={'md'}
                                    {...form.getInputProps('password')}
                                />

                                <Button type="submit" my={'md'} >SIGN IN</Button>
                            </form>
                        </Center>
                        {/* </Box> */}


                        <List
                            size={'xl'}

                            icon={
                                <ThemeIcon color={'red'} size={'lg'} radius={'xl'}
                                >
                                    <Center>
                                        <IconCircleCheck size={'1.5rem'} />
                                    </Center>
                                </ThemeIcon>
                            }
                            spacing={'md'}
                            center
                        >
                            <List.Item>
                                <Title order={3} align={'left'} color={'white'}>
                                    FAVOURED BY CLIENTS AND SPONSORS
                                </Title>
                            </List.Item>
                            <List.Item>
                                <Title order={3} align={'left'} color={'white'}>
                                    PROFESSIONAL & EFFECTIVE
                                </Title>
                            </List.Item>
                            <List.Item>
                                <Title order={3} align={'left'} color={'white'}>
                                    ALL CLIENTS IN 1 PLACE
                                </Title>
                            </List.Item>
                            <List.Item>
                                <Title order={3} align={'left'} color={'white'}>
                                    FULL ONLINE CLIENT DOSSIER
                                </Title>
                            </List.Item>
                            <List.Item>
                                <Title order={3} align={'left'} color={'white'}>
                                    END-TO-END COACHING PROGRAM SUPPORT
                                </Title>
                            </List.Item>
                            <List.Item>
                                <Title order={3} align={'left'} color={'white'}>
                                    24/7 ONLINE ACCESSIBLE
                                </Title>
                            </List.Item>
                            <List.Item>
                                <Title order={3} align={'left'} color={'white'}>
                                    SECURE & CONFIDENTIAL
                                </Title>
                            </List.Item>
                            <List.Item>
                                <Title order={3} align={'left'} color={'white'}>
                                    INTEGRATED EASY TO USE PDF INVOICING
                                </Title>
                            </List.Item>
                        </List>



                    </SimpleGrid>
                </Box >
            </BackgroundImage>
            <Box px={'lg'} sx={theme => ({
                backgroundColor: theme.colors.red[6],
                height: '60px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                '@media screen and (max-width: 600px)': {
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: 'auto',
                    padding: '20px 0',
                    gap: '10px'
                }
            })}>
                <Anchor href='privacy_policy.pdf' target="_blank" color='white'>
                    <Flex
                        align={'center'}>
                        <Title order={6} color={'white'}>
                            PRIVACY POLICY <IconLink size={'1rem'} />
                        </Title>
                    </Flex>
                </Anchor>
                <Title order={6} color={'white'}>
                    INFO@PROGREZR.COM
                </Title>
                <Title order={6} color={'white'}>
                    APPLICATIONS INVITATION ONLY
                </Title>
                <Title order={6} color={'white'}>
                    FOR CURATED EXECUTIVE COACHES
                </Title>

                <Anchor href="https://stewardbright.com/">
                    <Image height={30} fit={'contain'} src={footer_img} alt="" />
                </Anchor>
            </Box>
        </AppShell>
    )
}

import { createSlice } from "@reduxjs/toolkit";
import { doc } from "firebase/firestore";
import { firestoreUserService } from "services/firestoreUserService";
import { firestore } from "../logging/loggingSlice";
import { tokenService } from "services/tokenService";

export const initialFirestoreUserState = {
    loaded: false,
    userData: {
        role: 'client',
        name: '',
        coach: '',
        gdpr: true,
        license: {
            type: 'standard'
        },
        status: {
            'active': false,
            'from': false,
            'till': false,
            'type': 'fixed'
        }

    },
    clients: []
}

export const firestoreUserSlice = createSlice({
    name: "firstoreUser",
    initialState: initialFirestoreUserState,
    reducers: {
        resetFirestoreUser: (state, action) => {
            state.loaded = false;
            state = initialFirestoreUserState;
        },
        setUserData: (state, action) => {
            state.userData = action.payload;
            state.loaded = true;
        },
        setClients: (state, action) => {
            state.clients = action.payload;
            state.loaded = true;
        },

    },
});

export const loadUserData = (uid) => async (dispatch) => {
    try {
        const user = await firestoreUserService.fetchUser(uid, dispatch);
        const clients = await firestoreUserService.getClients(uid, user.role, dispatch);
        dispatch(setUserData(user));
        dispatch(setClients(clients));
    }
    catch (error) {
        console.log(error)
    }
}

export const acceptGDPR = (uid) => async (dispatch) => {
    try {
        await firestoreUserService.acceptGDPR(uid, dispatch);
        await dispatch(loadUserData(uid));
    } catch (error) {
        console.log(error)
    }
}

export const { setUserData, setClients, resetFirestoreUser } = firestoreUserSlice.actions;

export default firestoreUserSlice.reducer;


